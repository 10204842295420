import { Box } from "@material-ui/core";
import { RiepilogoPianificazioneDTO } from "../core/components/crudWrappers/PianificazioniPreventivoW";
// import { Colors } from "../utils/utildata";
import { Fields } from "./Fields";

export type PianificazioneKeys = keyof Pianificazione;
export type PianificazioneQualificaKeys = keyof PianificazioneQualifica;

export const elementIdProps: PianificazioneKeys[] = ['idStruttura', 'idDipartimento', 'idUnitaOperativa', 'idAnagraficaSoggetto', 'pianificazioneData', 'anno', 'mese', 'reperibilita'];
export const elementRenderProps: PianificazioneKeys[] = ['idAnagraficaSoggetto', 'pianificazioneData'];

function isPianificazioneQualifica(rowData: RiepilogoPianificazioneDTO) {
  return rowData.idStruttura === -1;
}

export const allFieldsAnagraficaSoggetto: Fields[] = [
  {
    field: 'codiceTurno', type: 'string', titleKey: '', required: false, show: true
  },
  {
    field: "qualificaDescrizioneBreve", type: "string", titleKey: "qualificationParam", required: true, show: true, defaultGroupOrder: 0,
  },
  {
    field: "nominativo", type: "string", titleKey: "registryTitle", required: true, show: true,
    render: (rowData: RiepilogoPianificazioneDTO) => {
      // const color = rowData['coloreAnagraficaSoggetto'];

      return (
        <Box
          // bgcolor={Colors[color]}
          bgcolor={isPianificazioneQualifica(rowData) ? '#fff' : rowData.coloreCodiceTurno}
          fontWeight={isPianificazioneQualifica(rowData) ? undefined : 'bold'}
          fontStyle={isPianificazioneQualifica(rowData) ? 'italic' : undefined}
          fontSize={'0.75em'}
          px={2}
          width={'100%'}
          minHeight={'25px'}
          display='flex'
          justifyContent={isPianificazioneQualifica(rowData) ? 'end' : 'start'}
          borderLeft={'1px solid #cecece'}
          alignItems='center'
        >
          {rowData.nominativo}
        </Box>
      )
    },
  },
  {
    field: "oreLavorative", type: "string", titleKey: "workingHoursParam", required: true, show: true,
    render: (rowData: RiepilogoPianificazioneDTO) => {
      const data = (rowData.pianificazioneAnagraficaSoggetti?.oreLavorative ?? 0) + ':' + (rowData.pianificazioneAnagraficaSoggetti?.minutiLavorativi?.toString().padStart(2, '0') ?? '00');
      return <Box
        bgcolor={isPianificazioneQualifica(rowData) ? '#fff' : rowData.coloreCodiceTurno}
        display='flex'
        justifyContent='center'
        alignItems='center'
        width={'100%'}
        fontSize={'0.75em'}
        minHeight={'25px'}
        borderLeft={'1px solid #cecece'}
      >
        {isPianificazioneQualifica(rowData) ? '' : data}
      </Box>;
    }
  },
  {
    field: "oreTotale", type: "string", titleKey: "hoursParam", required: true, show: true,
    render: (rowData: RiepilogoPianificazioneDTO) => {
      return <Box
        bgcolor={isPianificazioneQualifica(rowData) ? '#fff' : rowData.coloreCodiceTurno}
        display='flex'
        justifyContent='center'
        alignItems='center'
        width={'100%'}
        fontSize={'0.75em'}
        minHeight={'25px'}
        borderLeft={'1px solid #cecece'}
      >
        {isPianificazioneQualifica(rowData) ? '' : ((rowData.oreTotale ?? 0) + ':' + (rowData.minutiTotale?.toString().padStart(2, '0') ?? '00'))}
      </Box>
    }
  },
  {
    field: "differenzaOre", type: "string", titleKey: "differenceHoursParam", required: true, show: true,
    render: (rowData: RiepilogoPianificazioneDTO) => {
      const segno = rowData.pianificazioneAnagraficaSoggetti?.differenzaSegno ?? '';
      const ore = rowData.pianificazioneAnagraficaSoggetti?.differenzaOre ?? 0;
      const minuti = rowData.pianificazioneAnagraficaSoggetti?.differenzaMinuti?.toString().padStart(2, '0') ?? '00';
      const data = segno + ore + ':' + minuti;
      return <Box
        color={segno === '-' ? '#c00' : 'green'}
        bgcolor={isPianificazioneQualifica(rowData) ? '#fff' : rowData.coloreCodiceTurno}
        display='flex'
        justifyContent='center'
        alignItems='center'
        width={'100%'}
        fontSize={'0.75em'}
        minHeight={'25px'}
        borderLeft={'1px solid #cecece'}
      >
        {isPianificazioneQualifica(rowData) ? '' : data}
      </Box>
    }
  },
  {
    field: "numeroRiposi", type: "string", titleKey: "RestParam", required: true, show: true,
    render: (rowData: RiepilogoPianificazioneDTO) => {
      return <Box
        bgcolor={isPianificazioneQualifica(rowData) ? '#fff' : rowData.coloreCodiceTurno}
        display='flex'
        justifyContent='center'
        alignItems='center'
        width={'100%'}
        fontSize={'0.75em'}
        minHeight={'25px'}
        borderLeft={'1px solid #cecece'}
        borderRight={'1px solid #cecece'}
      >
        {isPianificazioneQualifica(rowData) ? '' : rowData.numeroRiposi}
      </Box>
    }
  },
];

export const allFieldsQualifica: Fields[] = [
  {
    field: "qualificaDescrizioneBreve", type: "string", titleKey: "qualificationParam", required: true, show: true, defaultGroupOrder: 0,
  },
  {
    field: "turnoDescrizione", type: "string", titleKey: "shiftParam", required: true, show: true,
  },

];

export const allFieldsStatoPianificazione: Fields[] = [
  {
    field: 'oreLavorativeMese', required: false, show: true, type: 'numeric', titleKey: 'workingHoursPerMonthParam', colsNum: 4, readonly: true,
  },
  {
    field: 'numeroRiposiMese', required: false, show: true, type: 'numeric', titleKey: 'restingHoursPerMonthParam', colsNum: 4, readonly: true,
  },
  {
    field: 'stato', required: false, show: true, type: 'string', titleKey: 'statusParam', autocomplete: true, colsNum: 4, readonly: true,
  },
];

export const allFieldsNuovoSoggetto: Fields[] = [
  {
    field: 'idAnagraficaSoggetto', required: true, show: true, autocomplete: true, titleKey: 'subjectRegistryParam', colsNum: 12, minLen: 3
  },
  {
    field: 'idQualifica', required: true, show: true, type: 'numeric', lookupField: true, titleKey: 'qualificationParam', colsNum: 12,
  },
];

export const allFieldsNuovoTurno: Fields[] = [
  {
    field: 'idTurno', required: true, show: true, type: 'numeric', lookupField: true, titleKey: 'shiftParam', colsNum: 5,
  },
  {
    field: 'idTurnoAssenza', required: false, show: true, type: 'numeric', lookupField: true, titleKey: 'absenceShiftParam', colsNum: 5,
  },
  {
    field: 'oreTurno', required: false, show: true, type: 'numeric', readonly: true, titleKey: 'hoursParam', colsNum: 1,
  },
  {
    field: 'minutiTurno', required: false, show: true, type: 'numeric', readonly: true, titleKey: 'minutesParam', colsNum: 1,
  }
];

export interface NuovoTurno {
  idTurno?: number,
  idTurnoAssenza?: number,
  oreTurno: number
  minutiTurno: number
}

export interface NuovoSoggetto {
  idStruttura: number;
  idAnagraficaSoggetto: number;
  idQualifica: number;
}

export interface UnitaOperativePianificazione {
  idStruttura: number;
  idDipartimento: number;
  idUnitaOperativa: number;
  anno: number;
  mese: number;
  oreLavorativeMese: number;
  numeroRiposiMese: number;
  stato: string;
  pdfProspettoPreventivo?: string;
  pdfProspettoConsuntivo?: string;
  version: number;
  pianificazioni: Pianificazione[];
  pianificazioniQualifiche: PianificazioneQualifica[];
  pianificazioniAnagraficaSoggetti: PianificazioniAnagraficaSoggetti[];
  pianificazioniAnagraficaSoggetto: Pianificazione[];
}

export interface Pianificazione {
  idStruttura: number;
  strutturaNome: string;
  idDipartimento: number;
  dipartimentoNome: string;
  idUnitaOperativa: number;
  unitaOperativaNome: string;
  anno: number;
  mese: number;
  idAnagraficaSoggetto: number;
  cognome: string;
  nome: string;
  idQualifica: number;
  qualificaDescrizione: string;
  qualificaDescrizioneBreve: string;
  pianificazioneData: string;
  reperibilita: boolean;
  tipoInserimento: string;
  idCombinazioneTurni: number;
  combinazioneTurni: string;
  descrizioneCombinazioneBreve: string;
  descrizioneCombinazione: string;
  riposoSabato: boolean;
  riposoDomenicaFestivi: boolean;
  dataInizioTurnoFisso: string;
  numeroOrdineTurnoFisso: number;
  codiceTurno: string;
  idTurno?: number;
  turnoDescrizione?: string;
  turnoDescrizioneBreve?: string;
  idTurnoAssenza?: number;
  turnoAssenzaDescrizione?: string;
  turnoAssenzaDescrizioneBreve?: string;
  inizioTurnoFisso: boolean;
  modificaInizioTurnoFissoCodiceTurno: boolean;
  aggiornaAnagraficaSoggettoTurniAbilitati: boolean;
  oreTurno: number;
  minutiTurno: number,
  nota: string;
  progressivo?: number;
  giornoFestivo: boolean;
  preventivo: boolean;
  consuntivo: boolean;
  idPresenza?: number;
  version: number;
}

export interface PianificazioneQualifica {
  idStruttura: number;
  idDipartimento: number;
  idUnitaOperativa: number;
  anno: number;
  mese: number;
  pianificazioneData: string;
  idQualifica: number;
  qualificaDescrizione: string;
  qualificaDescrizioneBreve: string;
  idTurno: number;
  turnoDescrizioneBreve: string;
  turnoDescrizione: string;
  oreTurno: number;
  minutiTurno: number;
  numeroAddettiUnitaOperativa: number;
  numeroLegaleUnitaOperativa: number;
  numeroReperibilitaRichieste: number;
  totaleNumeroAddetti: number;
  totaleNumeroReperibilita: number;
  totaleOreTurno: number;
  totaleMinutiTurno: number,
  coloreValidazione: 'Verde' | 'Giallo' | 'Rosso' | 'Arancione';
}

export interface PianificazioniAnagraficaSoggetti {
  idStruttura: number;
  idDipartimento: number;
  idUnitaOperativa: number;
  anno: number;
  mese: number;
  idAnagraficaSoggetto: number;
  cognome: string;
  nome: string;
  codiceTurno: string;
  idQualifica: number;
  qualificaDescrizione: string;
  qualificaDescrizioneBreve: string;
  totaleRiposi: number;
  totaleRiposiNonFestivi: number;
  totaleOrePresenza: number;
  totaleMinutiPresenza: number,
  totaleOreAssenza: number;
  totaleMinutiAssenza: number,
  totaleOre: number;
  totaleMinuti: number;
  oreLavorative: number;
  minutiLavorativi: number;
  differenzaSegno: string;
  differenzaOre: number;
  differenzaMinuti: number;
  coloreValidazioneRiposi: string;
  coloreValidazioneOre: string;
  pianificazioniTurni: PianificazioniTurni[];
}

interface PianificazioniTurni {
  idTurno: number;
  turnoDescrizione: string;
  turnoDescrizioneBreve: string;
  totale: number;
}