import { Grid, useTheme } from "@material-ui/core";

interface LoadingSvgProps {
  color: 'primary' | 'white',
  width: number
}

const LoadingSvg = ({ color, width }: LoadingSvgProps) => {
  const theme = useTheme();
  const fillColor = color === 'primary' ? theme.palette.primary.main : '#ffffff';
  return <>
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Grid item>
        <svg xmlns="http://www.w3.org/2000/svg" width={width + "px"} height={width + "px"} viewBox="38 27 24 46" preserveAspectRatio="xMidYMid">
          <g transform="rotate(0 50 50)">
            <rect x="49.5" y="27" width="1" height="12" fill={fillColor}>
              <animate attributeName="opacity" values="1;0" dur="1s" begin="-0.923s" repeatCount="indefinite" />
            </rect>
          </g>
          <g transform="rotate(27.69 50 50)">
            <rect x="49.5" y="27" width="1" height="12" fill={fillColor}>
              <animate attributeName="opacity" values="1;0" dur="1s" begin="-0.846s" repeatCount="indefinite" />
            </rect>
          </g>
          <g transform="rotate(55.38 50 50)">
            <rect x="49.5" y="27" width="1" height="12" fill={fillColor}>
              <animate attributeName="opacity" values="1;0" dur="1s" begin="-0.769s" repeatCount="indefinite" />
            </rect>
          </g>
          <g transform="rotate(83.07 50 50)">
            <rect x="49.5" y="27" width="1" height="12" fill={fillColor}>
              <animate attributeName="opacity" values="1;0" dur="1s" begin="-0.692s" repeatCount="indefinite" />
            </rect>
          </g>
          <g transform="rotate(110.76 50 50)">
            <rect x="49.5" y="27" width="1" height="12" fill={fillColor}>
              <animate attributeName="opacity" values="1;0" dur="1s" begin="-0.615s" repeatCount="indefinite" />
            </rect>
          </g>
          <g transform="rotate(138.46 50 50)">
            <rect x="49.5" y="27" width="1" height="12" fill={fillColor}>
              <animate attributeName="opacity" values="1;0" dur="1s" begin="-0.538s" repeatCount="indefinite" />
            </rect>
          </g>
          <g transform="rotate(166.15 50 50)">
            <rect x="49.5" y="27" width="1" height="12" fill={fillColor}>
              <animate attributeName="opacity" values="1;0" dur="1s" begin="-0.461s" repeatCount="indefinite" />
            </rect>
          </g>
          <g transform="rotate(193.84 50 50)">
            <rect x="49.5" y="27" width="1" height="12" fill={fillColor}>
              <animate attributeName="opacity" values="1;0" dur="1s" begin="-0.384s" repeatCount="indefinite" />
            </rect>
          </g>
          <g transform="rotate(221.53 50 50)">
            <rect x="49.5" y="27" width="1" height="12" fill={fillColor}>
              <animate attributeName="opacity" values="1;0" dur="1s" begin="-0.307s" repeatCount="indefinite" />
            </rect>
          </g>
          <g transform="rotate(249.23 50 50)">
            <rect x="49.5" y="27" width="1" height="12" fill={fillColor}>
              <animate attributeName="opacity" values="1;0" dur="1s" begin="-0.230s" repeatCount="indefinite" />
            </rect>
          </g>
          <g transform="rotate(276.92 50 50)">
            <rect x="49.5" y="27" width="1" height="12" fill={fillColor}>
              <animate attributeName="opacity" values="1;0" dur="1s" begin="-0.153s" repeatCount="indefinite" />
            </rect>
          </g>
          <g transform="rotate(304.61 50 50)">
            <rect x="49.5" y="27" width="1" height="12" fill={fillColor}>
              <animate attributeName="opacity" values="1;0" dur="1s" begin="-0.076s" repeatCount="indefinite" />
            </rect>
          </g>
          <g transform="rotate(332.30 50 50)">
            <rect x="49.5" y="27" width="1" height="12" fill={fillColor}>
              <animate attributeName="opacity" values="1;0" dur="1s" begin="0s" repeatCount="indefinite" />
            </rect>
          </g>
        </svg>
      </Grid>
    </Grid>
  </>
}
export default LoadingSvg;