import { Column } from "@material-table/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { anagrafichePath } from "../../../utils/utilconst";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import {
  fetchExtendedByIdAndDate,
  resetError as resetErrorAnagraficaSoggetti
} from "../../../store/slices/anagraficaSoggettiSlice";

import {
  insert,
  physicalDel,
  update,
  resetError as resetErrorAnagraficaSoggettiUnitaOperative
} from "../../../store/slices/anagraficaSoggettiUnitaOperativeSlice";
import { elementIdProps, elementRenderProps, AnagraficaSoggettiUnitaOperativa, allFields, allFieldsUnitaOperativa, AnagraficaSoggettiUnitaOperativaKeys } from '../../../models/AnagraficaSoggettiUnitaOperativa';
import {
  componentTabsPath,
  unitaOperativeTabPath,
  componentInsertPath,
} from '../../../utils/innerFuncPaths';
import { Redirect, Route, Switch, useHistory } from "react-router";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import GeneralForm, { ChecksCallbackReturn } from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";

import {
  lookupFiltered as fetchDipartimentiFiltered,
  resetError as resetErrorDipartimentiFiltered
} from '../../../store/slices/dipartimentiFilteredSlice';
import {
  lookupFiltered as fetchUnitaOperativaFiltered,
  resetError as resetErrorUnitaOperativeFiltered
} from '../../../store/slices/unitaOperativeFilteredSlice';
import {
  fetchAllValidById as fetchQualificaPerAnagraficaSoggetto,
  resetError as resetErrorAnagraficaSoggettiQualifica
} from '../../../store/slices/anagraficaSoggettiQualificaSlice';

import { createLookup } from "../../../utils/utilfunctions";
import { getTodayStart } from "../../../utils/utilfunctions";
import { PDFExtraData } from "../../../models/Utils";
import i18n from "../../../i18n";
import { Fields } from "../../../models/Fields";
import { Box, Button, Paper } from "@material-ui/core";
import InnerComponentViewsAnagrafiche from "./AnagraficaSoggettiComponents/InnerComponentsViewsAnagrafiche";
import _ from 'lodash';
import useInitValid from "./hooks/useInitValid";

interface AnagraficaSoggettiUnitaOperativaWProps {
  idAnagraficaSoggetto: number,
  anagraficaSoggettiUnitaOperativa: AnagraficaSoggettiUnitaOperativa[],
}

const AnagraficaSoggettiUnitaOperativaW = ({ idAnagraficaSoggetto, anagraficaSoggettiUnitaOperativa, }: AnagraficaSoggettiUnitaOperativaWProps) => {
  const { t } = useTranslation();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const abilitazione = useAppSelector(state => getAbilitazione(state, anagrafichePath));
  const errorBE = useAppSelector(state => state.anagraficaSoggettiUnitaOperative.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorAnagraficaSoggetti());
    dispatch(resetErrorAnagraficaSoggettiUnitaOperative());
    dispatch(resetErrorDipartimentiFiltered());
    dispatch(resetErrorUnitaOperativeFiltered());
    dispatch(resetErrorAnagraficaSoggettiQualifica());
  }, [dispatch]);

  const qualificaLookup = useAppSelector(state => state.qualifiche.lookupDescrizione);
  const unitaOperativaLookup = useAppSelector(state => state.unitaOperativeFiltered.lookup);
  const strutturaLookup = useAppSelector(state => state.strutture.lookup)
  const dipartimentoLookup = useAppSelector(state => state.dipartimentiFiltered.lookup)
  const lookupAnagraficaSoggetto = useAppSelector(state => state.anagraficaSoggetti.lookup);

  const qualificheAbilitate = useAppSelector(state => state.anagraficaSoggettiQualifica.qualificheAbilitate);

  const idAnagSoggConst = "idAnagraficaSoggetto";
  const idQualificaConst = "idQualifica";
  const idQualificaIncaricoConst = "idQualificaIncarico";
  const idUnitaOperativaConst = "idUnitaOperativa";
  const idStruttura = "idStruttura";
  const idDipartimento = "idDipartimento";
  const idStrutturaUnitaOperativa = "idStrutturaUnitaOperativa";
  const idDipartimentoUnitaOperativa = "idDipartimentoUnitaOperativa";

  const formLookups = useMemo(() => {
    return {
      [idStruttura]: strutturaLookup,
      [idDipartimento]: dipartimentoLookup,
      [idStrutturaUnitaOperativa]: strutturaLookup,
      [idDipartimentoUnitaOperativa]: dipartimentoLookup,
      [idUnitaOperativaConst]: unitaOperativaLookup,
      [idQualificaIncaricoConst]: createLookup(qualificheAbilitate, idQualificaConst, ['descrizioneBreve']),
    }
  }, [dipartimentoLookup, qualificheAbilitate, strutturaLookup, unitaOperativaLookup])

  const fixedProps = useMemo(() => {
    return {
      [idAnagSoggConst]: idAnagraficaSoggetto,
    }
  }, [idAnagraficaSoggetto]);

  const title = t('operatingUnitTitle');

  const [obj, setObj] = useState<AnagraficaSoggettiUnitaOperativa | null>(null);
  const anagraficaSoggettiEstesa = useAppSelector(state => state.anagraficaSoggetti.anagraficaSoggettoEstesa);
  const [isArchiveOpen, setIsArchiveOpen] = useState<boolean>(false);

  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettiUnitaOperativa>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettiUnitaOperativa> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          // emptyValue: f.defaultValue ?? 'N/A',
        }

        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as AnagraficaSoggettiUnitaOperativaKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          };
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        /*+++*/
        switch (f.field) {
          case idQualificaIncaricoConst:
            obj.lookup = qualificaLookup;
            break;
          case idUnitaOperativaConst:
            obj.lookup = unitaOperativaLookup;
            break;
          case idStruttura:
            obj.lookup = strutturaLookup;
            break;
          case idDipartimento:
            obj.lookup = dipartimentoLookup;
            break;
        }
        /*+++*/
        return obj;
      }));
  }, [qualificaLookup, unitaOperativaLookup, strutturaLookup, dipartimentoLookup, t, allFieldsState]);

  const statusAnagraficaSoggettiUnitaOperative = useAppSelector(state => state.anagraficaSoggettiUnitaOperative.statusValidAnagraficaSoggettiUnitaOperative);

  const [data, setData] = useState<Array<AnagraficaSoggettiUnitaOperativa>>([]);

  useEffect(() => {
    setData(anagraficaSoggettiUnitaOperativa)
  }, [anagraficaSoggettiUnitaOperativa]);

  useInitValid(
    statusAnagraficaSoggettiUnitaOperative,
    () => dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto }))
  );

  useEffect(() => {
    dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto }))
  }, [dispatch, idAnagraficaSoggetto]);

  /**
   * Updates lookup in riepilogo
   */
  useEffect(() => {
    if (history.location.pathname === anagrafichePath + componentTabsPath + unitaOperativeTabPath && anagraficaSoggettiEstesa) {
      dispatch(fetchDipartimentiFiltered({
        idStruttura: anagraficaSoggettiEstesa.idStrutturaUnitaOperativa
      }));
      dispatch(fetchUnitaOperativaFiltered({
        idStruttura: anagraficaSoggettiEstesa.idStrutturaUnitaOperativa,
        idDipartimento: anagraficaSoggettiEstesa.idDipartimentoUnitaOperativa
      }));
    }
  }, [anagraficaSoggettiEstesa, dispatch, history.location.pathname]);

  useEffect(() => {
    dispatch(fetchQualificaPerAnagraficaSoggetto([idAnagraficaSoggetto, getTodayStart()]));
  }, [dispatch, idAnagraficaSoggetto]);

  const insertCallback = () => {
    const temp: Required<AnagraficaSoggettiUnitaOperativa> = {
      cognome: "",
      nome: "",
      idAnagraficaSoggetto: 0,
      dataAssegnazione: "",
      idStruttura: 0,
      idDipartimento: 0,
      idUnitaOperativa: 0,
      unitaOperativaNome: "",
      motivoVariazione: "",
      idQualificaIncarico: 0,
      qualificaIncaricoDescrizione: "",
      qualificaIncaricoDescrizioneBreve: "",
      descrizioneQualifica: "",
      dataAssegnazionePrecedente: "",
      unitaOperativaPrecedente: "",
      version: 0
    }

    let lastAnagraficaSoggettiUnitaOperativa: Partial<AnagraficaSoggettiUnitaOperativa> = {
      ..._.pick(anagraficaSoggettiEstesa, Object.keys(temp)),
      dataAssegnazione: undefined,
    };
    setObj(lastAnagraficaSoggettiUnitaOperativa as AnagraficaSoggettiUnitaOperativa);
    history.push(anagrafichePath + componentTabsPath + unitaOperativeTabPath + componentInsertPath)
  };

  const updateDetailCallback = (anagraficaSoggettoUnitaOperativa: AnagraficaSoggettiUnitaOperativa) => {
    setObj(anagraficaSoggettoUnitaOperativa);
    if (anagraficaSoggettoUnitaOperativa.idStruttura)
      dispatch(fetchDipartimentiFiltered({
        idStruttura: anagraficaSoggettoUnitaOperativa.idStruttura
      }));
    if (anagraficaSoggettoUnitaOperativa.idDipartimento)
      dispatch(fetchUnitaOperativaFiltered({
        idStruttura: anagraficaSoggettoUnitaOperativa.idStruttura,
        idDipartimento: anagraficaSoggettoUnitaOperativa.idDipartimento
      }));
    history.push(anagrafichePath + componentTabsPath + unitaOperativeTabPath + componentTabsPath)
  };

  const formCallback = useCallback((obj: AnagraficaSoggettiUnitaOperativa | null): ChecksCallbackReturn | void => {
    if (obj) {
      if (obj.idStruttura) {
        dispatch(fetchDipartimentiFiltered({ idStruttura: obj[idStruttura] }))
        if (obj.idDipartimento) {
          dispatch(fetchUnitaOperativaFiltered({ idStruttura: obj[idStruttura], idDipartimento: obj[idDipartimento] }))
        }
      }
    }
  }, [dispatch]);

  const exportDataExtra: PDFExtraData = useMemo(() => {
    const _idAnagSoggConst = fixedProps[idAnagSoggConst];

    const _nominativo = _idAnagSoggConst ? lookupAnagraficaSoggetto[_idAnagSoggConst] : ''

    return {
      head: {
        title: [t('nominativeLabel')],
        value: [_nominativo]
      }
    }
  }, [fixedProps, lookupAnagraficaSoggetto, t]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return <Switch>
    <Route path={anagrafichePath + componentTabsPath + unitaOperativeTabPath + componentInsertPath} exact>
      <InnerComponentViewsAnagrafiche
        abilitazione={abilitazione}
        mainUri={anagrafichePath + componentTabsPath + unitaOperativeTabPath}
        tabsUri={anagrafichePath + componentTabsPath + unitaOperativeTabPath + componentInsertPath}
        tabsView={false}
        buttonTitle={t("cancelLabel")}
        info1={t("update")
        }
        tabs={[
          {
            label: t("registryTitle"),
            tabPath: "",
            abilitazione: Abilitazione.READ_UPDATE,
            componentIf: (
              <GeneralForm
                readOnly={false}
                language={i18n.language}
                componentPath={anagrafichePath + componentTabsPath + unitaOperativeTabPath}
                action={insert}
                fixedProps={fixedProps}
                status={statusAnagraficaSoggettiUnitaOperative}
                error={errorBE}
                update={false}
                fields={allFieldsState}
                translate={t}
                lookups={formLookups}
                checksCallback={formCallback}
                obj={obj}
                buttonLabel="saveLabel"

              />
            ),
            componentElse: <Redirect to={anagrafichePath} />,
          },
        ]}
      />
    </Route>
    <Route path={anagrafichePath + componentTabsPath + unitaOperativeTabPath + componentTabsPath} exact>
      <InnerComponentViews
        abilitazione={abilitazione}
        mainUri={anagrafichePath + componentTabsPath + unitaOperativeTabPath}
        tabsUri={anagrafichePath + componentTabsPath + unitaOperativeTabPath + componentTabsPath}
        tabsView={false}
        buttonTitle={t("cancelLabel")}
        info1={""}
        tabs={[
          {
            label: t("registryTitle"),
            tabPath: "",
            abilitazione: Abilitazione.READ_UPDATE,
            componentIf: (
              <GeneralForm
                readOnly={false}
                language={i18n.language}
                componentPath={anagrafichePath + componentTabsPath + unitaOperativeTabPath}
                action={update}
                fixedProps={fixedProps}
                status={statusAnagraficaSoggettiUnitaOperative}
                error={errorBE}
                update={true}
                fields={allFieldsState}
                translate={t}
                lookups={formLookups}
                checksCallback={formCallback}
                obj={obj}
                buttonLabel="confirmLabel"
              />
            ),
            componentElse: <Redirect to={anagrafichePath} />,
          },
        ]}
      />
    </Route>
    <Route path={anagrafichePath + componentTabsPath + unitaOperativeTabPath} exact>
      <Box flex={1} display='flex' justifyContent='center'>
      </Box>
      <Box mt={6} display="flex" justifyContent='right'>
        <Button
          size="large"
          variant="contained"
          onClick={() => insertCallback()}
        >
          {t("update")}
        </Button>
      </Box>
      <GeneralForm
        readOnly={true}
        language={i18n.language}
        componentPath={anagrafichePath + componentTabsPath + unitaOperativeTabPath}
        action={update}
        fixedProps={fixedProps}
        status={statusAnagraficaSoggettiUnitaOperative}
        error={errorBE}
        update={false}
        fields={allFieldsUnitaOperativa}
        translate={t}
        obj={anagraficaSoggettiEstesa}
        lookups={formLookups}

      />
      <Box mt={6} display="flex">
        <Box flex={1} display='flex' justifyContent='right'>
          <Button
            size="large"
            variant="contained"
            onClick={() => {
              setIsArchiveOpen(state => !state);
            }}
          >
            {t("archive")}
          </Button>
        </Box>
      </Box>
      {
        isArchiveOpen &&
        <>
          <Box mt={6}>
            <Paper >
              <CrudMaterialTableWithoutLogicDelete
                abilitazione={abilitazione}
                title={title}
                columns={columns}
                columnsButton={true}
                data={data}
                elementIdProps={elementIdProps}
                elementRenderProps={elementRenderProps}
                fetchAllValid={fetchExtendedByIdAndDate}
                physicalDel={physicalDel}
                statusValid={statusAnagraficaSoggettiUnitaOperative}
                errorBE={errorBE}
                fixedProps={fixedProps}
                exportDataExtra={exportDataExtra}
                logoUri={logoUri}
                nullableTextFields={true}
                update={update}
                updateCallback={updateDetailCallback}
                detailCallback={updateDetailCallback}
                localizedDatePicker={true}
                resetErrorCallback={resetError}
                extraOptions={{
                  minBodyHeight: 150,
                  maxBodyHeight: 460
                }}
              />
            </Paper>
          </Box>
        </>
      }
    </Route>

  </Switch>
}
export default AnagraficaSoggettiUnitaOperativaW;