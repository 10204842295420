import Axios from "axios";
import { Ids, SimpleResponse } from "../models/Utils";
import { allComplete, allData, allDeleted, allValid, definitiveDelete, find, insert, refresh, remove, restore, update, noAbilitazione } from "../utils/serviceConst";
import { applicazioneURL } from "../utils/utilconst";

const requestControllers: Record<string, {
  controller: AbortController | null,
  aborted: boolean,
}
> = {};

const requestControllersHandler = (abortController: AbortController, key: string): AbortController | null => {
  if (requestControllers[key]?.controller && !requestControllers[key]?.aborted) {
    requestControllers[key].controller?.abort();
    requestControllers[key].aborted = true;
    delete requestControllers[key];
  }

  requestControllers[key] = {
    controller: null,
    aborted: false
  };
  requestControllers[key].controller =
    requestControllers[key] && requestControllers[key].controller
      ? requestControllers[key].controller
      : abortController;
  requestControllers[key].aborted = false;

  return requestControllers[key].controller;
}

/*SIMPLE CRUD*/
export function getById(microservice: string, entity: string, ids: any[]) {
  let call = applicazioneURL + "/" + microservice + find + "/" + entity + "/id";
  ids.forEach(id => call += ("/" + id));
  return Axios.get<object>(call);
}

export function getAllComplete(microservice: string, entity: string) {
  return Axios.get<object[]>(applicazioneURL + "/" + microservice + find + "/" + entity + allComplete);
}

export function getAll(microservice: string, entity: string) {
  return Axios.get<object[]>(applicazioneURL + "/" + microservice + find + "/" + entity + "/all");
}

export function getAllValid(microservice: string, entity: string, invocationKey: string[]) {
  const key = microservice + '/' + entity + '/' + invocationKey.join('/')
  const abortController = requestControllersHandler(new AbortController(), key);

  return Axios.get<object[]>(applicazioneURL + "/" + microservice + find + "/" + entity + allValid, { signal: abortController?.signal });
}

export function getAllDeleted(microservice: string, entity: string) {
  return Axios.get<object[]>(applicazioneURL + "/" + microservice + find + "/" + entity + allDeleted);
}

export function create(obj: object, microservice: string, entity: string) {
  return Axios.post<object>(applicazioneURL + "/" + microservice + insert + "/" + entity, obj);
}

export function createWithOption(obj: object, microservice: string, entity: string, options: string[]) {
  const option = options.join('/')
  return Axios.post<object | object[]>(applicazioneURL + "/" + microservice + insert + "/" + entity + "/" + option, obj);
}

export function upd(obj: object, microservice: string, entity: string) {
  return Axios.put<object>(applicazioneURL + "/" + microservice + update + "/" + entity, obj);
}

export function refr(obj: object, microservice: string, entity: string) {
  return Axios.put<object>(applicazioneURL + "/" + microservice + refresh + "/" + entity, obj);
}

export function cancelById(ids: Ids<any>[], microservice: string, entity: string) {
  let call = applicazioneURL + "/" + microservice + remove + "/" + entity + "/id";
  ids.forEach(id => call += ("/" + id.id));
  return Axios.put<SimpleResponse>(call);
}

export function restoreById(ids: Ids<any>[], microservice: string, entity: string) {
  let call = applicazioneURL + "/" + microservice + restore + "/" + entity + "/id";
  ids.forEach(id => call += ("/" + id.id));
  return Axios.put<SimpleResponse>(call);
}

export function definitiveDeleteById(ids: { name: string, id: any }[], microservice: string, entity: string) {
  let call = applicazioneURL + "/" + microservice + definitiveDelete + "/" + entity + "/id";
  ids.forEach(id => call += ("/" + id.id));
  return Axios.delete<SimpleResponse>(call);
}

/** Generic operation */
export function executeGetOpByIds(microservice: string, entity: string, operation: string, ids: any[]) {
  let call = applicazioneURL + "/" + microservice + operation + "/" + entity;
  ids.forEach(id => call += ("/" + id));
  return Axios.get<object[] | object>(call);
}

/* FILTERED Variant */
export function postAllValidByIds(microservice: string, entity: string, ids: any[], obj: object, invocationKey: string[]) {
  const key = microservice + '/' + entity + '/' + invocationKey.join('/')
  const abortController = requestControllersHandler(new AbortController(), key);

  let call = applicazioneURL + "/" + microservice + find + "/" + entity + allValid;
  ids.forEach(id => call += ("/" + id));
  return Axios.post<object[]>(call, obj, { signal: abortController?.signal });
}

/* FILTERED */
export function getAllValidByIds(microservice: string, entity: string, ids: any[], invocationKey: string[]) {
  const key = microservice + '/' + entity + '/' + invocationKey.join('/')
  const abortController = requestControllersHandler(new AbortController(), key);

  let call = applicazioneURL + "/" + microservice + find + "/" + entity + allValid;
  ids.forEach(id => call += ("/" + id));
  return Axios.get<object[]>(call, { signal: abortController?.signal });
}

export function getAllDeletedByIds(microservice: string, entity: string, ids: any[], invocationKey: string[]) {
  const key = microservice + '/' + entity + '/' + invocationKey.join('/')
  const abortController = requestControllersHandler(new AbortController(), key);

  let call = applicazioneURL + "/" + microservice + find + "/" + entity + allDeleted;
  ids.forEach(id => call += ("/" + id));
  return Axios.get<object[]>(call, { signal: abortController?.signal });
}

export function getByIds(microservice: string, entity: string, ids: any[], invocationKey: string[]) {
  const key = microservice + '/' + entity + '/' + invocationKey.join('/')
  const abortController = requestControllersHandler(new AbortController(), key);

  let call = applicazioneURL + "/" + microservice + find + "/" + entity;
  ids.forEach(id => call += ("/" + id));
  return Axios.get<object[] | object>(call, { signal: abortController?.signal });
}

export function postByIds(microservice: string, entity: string, ids: any[], obj: object) {
  let call = applicazioneURL + "/" + microservice + find + "/" + entity;
  ids.forEach(id => call += ("/" + id));
  return Axios.post<object[] | object>(call, obj);
}

export function getAllByIds(microservice: string, entity: string, ids: any[]) {
  let call = applicazioneURL + "/" + microservice + find + "/" + entity + "/all";
  ids.forEach(id => call += ("/" + id));
  return Axios.get<object[]>(call);
}

export function getAllDataByIds(microservice: string, entity: string, ids: any[]) {
  let call = applicazioneURL + "/" + microservice + find + "/" + entity + allData;
  ids.forEach(id => call += ("/" + id));
  return Axios.get<object[]>(call);
}

export function getAllValidByFilter(microservice: string, entity: string, filterName: string, filter: any[]) {
  let call = applicazioneURL + "/" + microservice + find + "/" + entity + allValid + "/" + filterName;
  filter.forEach(f => call += ("/" + f));
  return Axios.get<object[]>(call);
}
export function getAllDeletedByFilter(microservice: string, entity: string, filterName: string, filter: any[]) {
  let call = applicazioneURL + "/" + microservice + find + "/" + entity + allDeleted + "/" + filterName;
  filter.forEach(f => call += ("/" + f));
  return Axios.get<object[]>(call);
}

/*EXTENDED*/
export function getNotExtendedEntityById(microservice: string, entity: string, ids: any[]) {
  let call = applicazioneURL + "/" + microservice + find + "/" + entity + "/id/" + false;
  ids.forEach(k => call += ("/" + k));
  return Axios.get<object>(call);
}
export function getExtendedEntityById(microservice: string, entity: string, ids: any[]) {
  let call = applicazioneURL + "/" + microservice + find + "/" + entity + "/id/" + true;
  ids.forEach(k => call += ("/" + k));
  return Axios.get<object>(call);
}
export function getAllExtendedByIds(microservice: string, entity: string, ids: any[]) {
  let call = applicazioneURL + "/" + microservice + find + "/" + entity + "/id/" + true;
  ids.forEach(id => call += ("/" + id));
  return Axios.get<object>(call);
}

/*LOOKUPS*/
export function lookupService(microservice: string, entity: string) {
  return Axios.get<object[]>(applicazioneURL + "/" + microservice + find + "/" + entity + "/lookup");
}
export function lookupServiceNoAbilitazione(microservice: string, entity: string) {
  return Axios.get<object[]>(applicazioneURL + "/" + microservice + find + "/" + entity + "/lookup" + noAbilitazione);
}

/*LOOKUPS FILTERED*/
export function postLookupServiceBySubject(microservice: string, entity: string, subject: string) {
  return Axios.post(applicazioneURL + "/" + microservice + find + "/" + entity + subject);
}
export function lookupServiceByIdList(microservice: string, entity: string, subject: string, ids: any[]) {
  return Axios.post(applicazioneURL + "/" + microservice + find + "/" + entity + subject, ids);
}
export function getLookupServiceBySubject(microservice: string, entity: string, subject: string) {
  return Axios.get(applicazioneURL + "/" + microservice + find + "/" + entity + subject);
}
export function lookupServiceByIds(microservice: string, entity: string, ids: any[]) {

  let call = applicazioneURL + "/" + microservice + find + "/" + entity + "/lookup";
  ids.forEach(id => call += ("/" + id));
  return Axios.get<object[]>(call);
}
export function lookupServiceFiltered(microservice: string, entity: string, filterName: string, filter: any[]) {
  let call = applicazioneURL + "/" + microservice + find + "/" + entity + "/lookup/" + filterName;
  filter.forEach(f => call += ("/" + f));
  return Axios.get<object[]>(call);
}
export function lookupServiceByName(microservice: string, entity: string, filter: any[]) {
  let call = applicazioneURL + "/" + microservice + find + "/" + entity + "/lookup";
  filter.forEach(f => call += ("/" + f));
  return Axios.get<object>(call);
}