import { VersoTimbraturaKeys } from "../utils/utildata";
import { validateInputNumberMinMax, validateInputTextMinMaxLen } from "../utils/utilfunctions";
import { Fields } from "./Fields";
import { Presenza } from "./Presenze";

export type TimbratureKeys = keyof Timbrature;

export const elementIdProps: TimbratureKeys[] = ["idTimbratura"];
export const elementRenderProps: TimbratureKeys[] = ["tracciato"];

export const allFields: Fields[] = [
  {
    field: "idAnagraficaSoggetto", titleKey: "nominativeParam", required: false, show: false, autocomplete: true, showOn: 'form', colsNum: 12,
  },
  {
    field: "button", type: 'string', titleKey: "", required: false, removable: false, show: true, showOn: 'table', exportExclude: true
  },
  {
    field: "nominativo", type: 'string', titleKey: "nominativeParam", required: false, show: true, showOn: 'table'
  },
  {
    field: "tracciato", type: 'string', titleKey: "tracedParam", required: false, show: true, editable: 'never'
  },
  {
    field: "matricola", type: 'string', titleKey: "numberParam", required: false, readonly: false, show: true, editable: "never",

  },
  {
    field: "data", type: 'date', titleKey: "dateParam.1", required: false, show: true, editable: 'never',
  },
  {
    field: "oraInizioTurno", type: 'time', titleKey: "turnStartParam", required: false, show: true, editable: 'never',

  },
  {
    field: "oraFineTurno", type: 'time', titleKey: "turnEndDateParam", required: false, show: true, editable: 'never',

  },
  {
    field: "versoTimbratura", type: 'string', titleKey: "clockingDirectionParam", required: false, readonly: false, show: true, editable: "never", lookupField: true,

  },
  {
    field: "causale", type: 'string', titleKey: "explanationParam", required: false, readonly: false, show: true, editable: "never",

  },
  {
    field: "modelloTerminale", type: 'string', titleKey: "terminalModelParam", required: false, readonly: false, show: false, editable: "never",

  },
  {
    field: "indirizzoIPV4", type: 'string', titleKey: "IPv4Param", required: false, readonly: false, show: false, editable: "never",

  },
  {
    field: "identificativoTerminale", type: 'string', titleKey: "terminalIdParam", required: false, readonly: false, show: false, editable: "never",

  },
  {
    field: "statoFunzionamentoTerminale", type: 'string', titleKey: "terminalStateParam", required: false, readonly: false, show: false, editable: "never",

  },
  {
    field: "buttonDettagli", type: 'string', titleKey: "", required: false, removable: false, show: true, showOn: 'table', exportExclude: true
  },

]

export const presenzaTimbratura: Fields[] = [
  {
    field: "strutturaNome", type: 'string', titleKey: "structuresTitle", required: false, removable: false, show: false,
  },
  {
    field: "dipartimentoNome", type: 'string', titleKey: "departmentsTitle", required: false, removable: false, show: true,
  },
  {
    field: "unitaOperativaNome", type: 'string', titleKey: "operatingUnitTabLabel", required: false, removable: false, show: true,
  },
  {
    field: "nominativo", type: 'string', titleKey: "nominativeParam", required: false, show: true, showOn: 'form',
  },
  {
    field: "qualificaDescrizioneBreve", type: "string", titleKey: "qualificationParam", required: false, removable: true, show: true, editable: "never", showOn: 'form',
  },
  {
    field: "turnoDescrizione", type: "string", titleKey: "shiftParam", required: false, removable: false, show: true, defaultValue: "", showOn: 'form',
  },
  {
    field: "oraInizioTurno", type: 'time', titleKey: "turnStartParam", required: true, show: true, readonly: true, colsNum: 3,
    field2Validation: "oraFineTurno",
    validate: (x: any, s: string) => true,
  },
  {
    field: "timbraturaInizioTurnoIndicatore", type: "string", titleKey: " ", required: false, show: false, readonly: true, colsNum: 3, showOn: "form", isLabel: true,
  },
  {
    field: "oraFineTurno", type: 'time', titleKey: "turnEndDateParam", required: false, show: true, readonly: true, colsNum: 3,
    field2Validation: "oraInizioTurno",
    validate: (x: any, s: string) => true,
  },
  {
    field: "timbraturaFineTurnoIndicatore", type: "string", titleKey: " ", required: false, show: false, readonly: true, colsNum: 3, showOn: "form", isLabel: true,
  },
  {
    field: "oreTurno", type: 'numeric', titleKey: "hoursParam", required: false, show: true, minVal: 0, maxVal: 24, readonly: true, colsNum: 3,
    validate: (x: number, helperText: string) => validateInputNumberMinMax(x, 0, 24, false, helperText, true), keyTradValidation: "between0And24Validation"
  },
  {
    field: "minutiTurno", type: 'numeric', titleKey: "minutesParam", required: false, show: true, minVal: 0, maxVal: 59, readonly: true, colsNum: 3,
    validate: (x: number, helperText: string) => validateInputNumberMinMax(x, 0, 59, false, helperText, true), keyTradValidation: "between0And59Validation"
  },
  {
    field: "nota", type: 'string', titleKey: "noteParam", required: false, show: false, minLen: 0, maxLen: 255,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 0, 255, false, helperText, true), keyTradValidation: "size0to255ParamValidation",
  },
]

export const nuovaPresenzaTimbratura: Fields[] = [
  {
    field: "idStruttura", titleKey: "structuresTitle", required: false, removable: false, show: false, lookupField: true, defaultValue: "",
  },
  {
    field: "idDipartimento", titleKey: "departmentsTitle", required: false, removable: false, show: false, lookupField: true, defaultValue: "",
  },
  {
    field: "idUnitaOperativa", titleKey: "operatingUnitTabLabel", required: false, removable: false, show: false, lookupField: true, defaultValue: "",
  },
  {
    field: "nominativo", type: 'string', titleKey: "nominativeParam", required: false, show: true, showOn: 'form',
  },
  {
    field: "idQualifica", titleKey: "qualificationParam", required: false, removable: false, show: false, lookupField: true, defaultValue: "", editable: "onAdd",
  },
  {
    field: "idTurno", titleKey: "shiftParam", required: false, removable: false, show: false, lookupField: true, defaultValue: "",
  },
  {
    field: "oraInizioTurno", type: 'time', titleKey: "turnStartParam", required: true, show: true, readonly: true, colsNum: 3,
    field2Validation: "oraFineTurno",
    validate: (x: any, s: string) => true,
  },
  {
    field: "timbraturaInizioTurnoIndicatore", type: "string", titleKey: " ", required: false, show: false, readonly: true, colsNum: 3, showOn: "form", isLabel: true,
  },
  {
    field: "oraFineTurno", type: 'time', titleKey: "turnEndDateParam", required: false, show: true, readonly: true, colsNum: 3,
    field2Validation: "oraInizioTurno",
    validate: (x: any, s: string) => true,
  },
  {
    field: "timbraturaFineTurnoIndicatore", type: "string", titleKey: " ", required: false, show: false, readonly: true, colsNum: 3, showOn: "form", isLabel: true,
  },
  {
    field: "oreTurno", type: 'numeric', titleKey: "hoursParam", required: true, show: true, minVal: 0, maxVal: 24, readonly: true, colsNum: 3,
    validate: (x: number, helperText: string) => validateInputNumberMinMax(x, 0, 24, false, helperText, true), keyTradValidation: "between0And24Validation"
  },
  {
    field: "minutiTurno", type: 'numeric', titleKey: "minutesParam", required: true, show: true, minVal: 0, maxVal: 59, readonly: true, colsNum: 3,
    validate: (x: number, helperText: string) => validateInputNumberMinMax(x, 0, 59, false, helperText, true), keyTradValidation: "between0And59Validation"
  },
  {
    field: "nota", type: 'string', titleKey: "noteParam", required: false, show: false, minLen: 0, maxLen: 255,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 0, 255, false, helperText, true), keyTradValidation: "size0to255ParamValidation",
  },

]

export interface Timbrature {
  idOrganizzazione: number,
  idTimbratura?: number,
  idAnagraficaSoggetto: number,
  forzaturaAnagraficaSoggetto: boolean,
  nome?: string,
  cognome?: string,
  tracciato: string,
  oraInizioTurno?: string,
  oraFineTurno?: string,
  modelloTerminale: string,
  indirizzoIPV4: string,
  identificativoTerminale?: string,
  statoFunzionamentoTerminale?: string,
  matricola?: string,
  versoTimbratura?: VersoTimbraturaKeys,
  causale?: string,
  data?: string,
  notaMancataAssegnazione?: string,
  eliminato: boolean,
  version: number,
  presenza?: Presenza,
}

export interface TimbratureAnagraficaSoggetto {
  idOrganizzazione: number,
  idAnagraficaSoggetto: number,
  cognome: string,
  nome: string,
  timbratureInizioTurno: Timbrature[],
  timbratureFineTurno: Timbrature[],

}