import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { Dipartimento, DipartimentoKeys, elementIdProps, LookupDipartimentoElem } from '../../models/Dipartimenti'
import { cancelById, create, definitiveDeleteById, lookupServiceByIds, restoreById, upd, getAllValidByIds, getAllDeletedByIds } from '../../services/services.service'
import { createLookup } from '../../utils/utilfunctions';

const microservice = "dipartimenti";
const entity = "dipartimenti";
interface DipartimentiState {
  statusValidDipartimentiFiltered: StatusEnum,
  validDipartimentiFiltered: Dipartimento[],
  statusDeletedDipartimentiFiltered: StatusEnum,
  deletedDipartimentiFiltered: Dipartimento[],
  dipartimento: Dipartimento | null,
  lookup: Lookup,
  lookupObject: LookupDipartimentoElem[];
  error: string | null
}

const initialState: DipartimentiState = {
  statusValidDipartimentiFiltered: StatusEnum.Succeeded,
  validDipartimentiFiltered: [],
  statusDeletedDipartimentiFiltered: StatusEnum.Succeeded,
  deletedDipartimentiFiltered: [],
  dipartimento: null,
  lookup: {},
  lookupObject: [],
  error: null
}

export const fetchAllValidByIdStruttura = createAsyncThunk(microservice + 'filtered/fetchAllValidByIdStruttura', async (obj: { idStruttura: number }) => {
  const response = await getAllValidByIds(microservice, entity, [obj.idStruttura], [microservice + 'filtered/fetchAllValidByIdStruttura']);
  return response.data as Dipartimento[];
});

export const fetchAllDeletedByIdStruttura = createAsyncThunk(microservice + 'filtered/fetchAllDeletedByIdStruttura', async (obj: { idStruttura: number }) => {
  const response = await getAllDeletedByIds(microservice, entity, [obj.idStruttura], [microservice + 'filtered/fetchAllDeletedByIdStruttura']);
  return response.data as Dipartimento[];
});

export const lookupFiltered = createAsyncThunk(microservice + 'filtered/lookupFiltered', async (obj: { idStruttura: number }) => {
  let idArr = [obj.idStruttura];
  const response = await lookupServiceByIds(microservice, entity, idArr);
  return response.data as LookupDipartimentoElem[];
});

export const insertFiltered = createAsyncThunk(microservice + 'filtered/insertFiltered', async (dipartimento: Dipartimento) => {
  const response = await create(dipartimento, microservice, entity);
  return response.data as Dipartimento;
});

export const updateFiltered = createAsyncThunk(microservice + 'filtered/updateFiltered', async (dipartimento: Dipartimento) => {
  const response = await upd(dipartimento, microservice, entity);
  return response.data as Dipartimento;
});

export const logicalDelFiltered = createAsyncThunk(microservice + 'filtered/logicalDelFiltered', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

export const restoreFiltered = createAsyncThunk(microservice + 'filtered/restoreFiltered', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

export const physicalDelFiltered = createAsyncThunk(microservice + 'filtered/physicalDelFiltered', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const dipartimentiFilteredSlice = createSlice({
  name: 'dipartimentiFiltered',
  initialState,
  reducers: {
    cleanLookup: (state) => {
      state.lookup = {}
    },
    reset: (state: DipartimentiState) => {
      return initialState;;
    },
    resetError: (state: DipartimentiState) => {
      state.error = initialState.error;
      state.statusValidDipartimentiFiltered = initialState.statusValidDipartimentiFiltered;
      state.statusDeletedDipartimentiFiltered = initialState.statusDeletedDipartimentiFiltered;
      state.statusValidDipartimentiFiltered = initialState.statusValidDipartimentiFiltered;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchAllValidByIdStruttura.pending, (state) => {
      state.statusValidDipartimentiFiltered = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidByIdStruttura.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidDipartimentiFiltered = StatusEnum.Failed;
      state.validDipartimentiFiltered = [];
    })
    builder.addCase(fetchAllValidByIdStruttura.fulfilled, (state, { payload }: PayloadAction<Dipartimento[]>) => {
      state.statusValidDipartimentiFiltered = StatusEnum.Succeeded;
      state.validDipartimentiFiltered = payload ?? [];
      state.lookup = createLookup(payload, 'idDipartimento', ['nome'])
    })
    builder.addCase(fetchAllDeletedByIdStruttura.pending, (state) => {
      state.statusDeletedDipartimentiFiltered = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeletedByIdStruttura.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusDeletedDipartimentiFiltered = StatusEnum.Failed;
      state.deletedDipartimentiFiltered = [];
    })
    builder.addCase(fetchAllDeletedByIdStruttura.fulfilled, (state, { payload }: PayloadAction<Dipartimento[]>) => {
      state.statusDeletedDipartimentiFiltered = StatusEnum.Succeeded;
      state.deletedDipartimentiFiltered = payload ?? [];
    })
    builder.addCase(lookupFiltered.pending, (state) => {
      state.statusValidDipartimentiFiltered = StatusEnum.Loading;
    })
    builder.addCase(lookupFiltered.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidDipartimentiFiltered = StatusEnum.Failed;
      state.lookup = {};
    })
    builder.addCase(lookupFiltered.fulfilled, (state, { payload }: PayloadAction<LookupDipartimentoElem[]>) => {
      state.statusValidDipartimentiFiltered = StatusEnum.Succeeded;
      state.lookup = createLookup(payload, 'idDipartimento', ['nome']);
      state.lookupObject = payload;
    })
    builder.addCase(insertFiltered.pending, (state) => {
      state.statusValidDipartimentiFiltered = StatusEnum.Loading;
    })
    builder.addCase(insertFiltered.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidDipartimentiFiltered = StatusEnum.Failed;
      state.dipartimento = null;
    })
    builder.addCase(insertFiltered.fulfilled, (state, { payload }: PayloadAction<Dipartimento>) => {
      state.statusValidDipartimentiFiltered = StatusEnum.Succeeded;
      state.dipartimento = payload;
      state.validDipartimentiFiltered = state.validDipartimentiFiltered.concat([payload]);
    })
    builder.addCase(updateFiltered.pending, (state) => {
      state.statusValidDipartimentiFiltered = StatusEnum.Loading;
    })
    builder.addCase(updateFiltered.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidDipartimentiFiltered = StatusEnum.Failed;
      state.dipartimento = null;
    })
    builder.addCase(updateFiltered.fulfilled, (state, { payload }: PayloadAction<Dipartimento>) => {
      state.statusValidDipartimentiFiltered = StatusEnum.Succeeded;
      state.validDipartimentiFiltered = state.validDipartimentiFiltered.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.dipartimento = payload;
    })
    builder.addCase(logicalDelFiltered.pending, (state) => {
      state.statusValidDipartimentiFiltered = StatusEnum.Loading;
    })
    builder.addCase(logicalDelFiltered.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidDipartimentiFiltered = StatusEnum.Failed;
    })
    builder.addCase(logicalDelFiltered.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidDipartimentiFiltered = StatusEnum.Succeeded;
      const deleted = state.validDipartimentiFiltered.find(el => payload.ids.every(idObj => el[idObj.name as DipartimentoKeys] === idObj.id));
      if (deleted) {
        deleted.version += 1;
        state.deletedDipartimentiFiltered = state.deletedDipartimentiFiltered.concat([deleted]);
      }
      state.validDipartimentiFiltered = state.validDipartimentiFiltered.filter(el => payload.ids.some(idObj => el[idObj.name as DipartimentoKeys] !== idObj.id));
    })
    builder.addCase(restoreFiltered.pending, (state) => {
      state.statusDeletedDipartimentiFiltered = StatusEnum.Loading;
    })
    builder.addCase(restoreFiltered.rejected, (state, action) => {
      state.statusDeletedDipartimentiFiltered = StatusEnum.Failed;
      state.error = (action.error.message) ? action.error.message : "";
    })
    builder.addCase(restoreFiltered.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedDipartimentiFiltered = StatusEnum.Succeeded;
      const valid = state.deletedDipartimentiFiltered.find(el => payload.ids.every(idObj => el[idObj.name as DipartimentoKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validDipartimentiFiltered = state.validDipartimentiFiltered.concat([valid]);
      }
      state.deletedDipartimentiFiltered = state.deletedDipartimentiFiltered.filter(el => payload.ids.some(idObj => el[idObj.name as DipartimentoKeys] !== idObj.id));
    })
    builder.addCase(physicalDelFiltered.pending, (state) => {
      state.statusDeletedDipartimentiFiltered = StatusEnum.Loading;
    })
    builder.addCase(physicalDelFiltered.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusDeletedDipartimentiFiltered = StatusEnum.Failed;
    })
    builder.addCase(physicalDelFiltered.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedDipartimentiFiltered = StatusEnum.Succeeded;
      state.deletedDipartimentiFiltered = state.deletedDipartimentiFiltered.filter(el => payload.ids.some(idObj => el[idObj.name as DipartimentoKeys] !== idObj.id));
    })
  }
});

export const { cleanLookup, reset, resetError } = dipartimentiFilteredSlice.actions;
export default dipartimentiFilteredSlice.reducer;