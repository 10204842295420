import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UserSession } from '../../models/User';

const entity = "utenteloggato";

const initialState: UserSession = {
  idUtente: null,
  nome: null,
  cognome: null,
  nomeUtente: null,
  organizzazioneDescrizione: null,
  applicazioneSoftwareDescrizione: null,
  profiloDescrizione: null
}

export const utentiLoggatoSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    setUtenteLoggato: (state: UserSession, { payload }: PayloadAction<UserSession>) => {
      return {
        ...state,
        ...payload
      };
    }
  }
});

export const { reset, setUtenteLoggato } = utentiLoggatoSlice.actions;
export default utentiLoggatoSlice.reducer;