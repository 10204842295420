import { Column } from "@material-table/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { anagrafichePath } from "../../../utils/utilconst";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import {
  fetchExtendedByIdAndDate,
  resetError as resetErrorAnagraficaSoggetti
} from "../../../store/slices/anagraficaSoggettiSlice";

import {
  fetchAllValidById as fetchAllValidAnagraficaSoggettoDocumentiById,
  fetchAllDeletedById as fetchAllDeletedAnagraficaSoggettoDocumentiById,
  logicalDel,
  physicalDel,
  restore,
  insert,
  update,
  resetError as resetErrorAnagraficaSoggettiDocumenti
} from "../../../store/slices/anagraficaSoggettiDocumentiSlice";
import {
  fetchAllValid as fetchTipiDocumentiValidLookup,
  resetError as resetErrorTipiDocuemnti
} from '../../../store/slices/tipoDocumentoSlice';
import { elementIdProps, elementRenderProps, AnagraficaSoggettiDocumenti, allFields, AnagraficaSoggettiDocumentiKeys } from '../../../models/AnagraficaSoggettiDocumenti';
import useInitValid from "./hooks/useInitValid";
import useInitDeleted from "./hooks/useInitDeleted";
import { PDFExtraData } from "../../../models/Utils";
import { Fields } from "../../../models/Fields";
import { componentInsertPath, componentTabsPath, documentoTabPath } from "../../../utils/innerFuncPaths";
import {
  Redirect,
  Route,
  Switch,
  useHistory
} from "react-router-dom";
import GeneralForm, { OptionalArgs } from "../forms/GeneralForm";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import Button from "@material-ui/core/Button";
import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import clsx from "clsx";
import AnteprimaDocumenti from "./AnagraficaSoggettiComponents/AnteprimaDocumenti";
import { AnteprimaModal } from "./AnagraficaSoggettiComponents/AnteprimaModal";
import { UploadedFile } from "../../../models/UploadedFile";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
    indicator: {
      backgroundColor: "#fff",
    },
    legendTopOffset: {
      marginTop: -75,
    }
  }),
);

interface anagraficaSoggettiDocumentiWrops {
  idAnagraficaSoggetto: number,
  anagraficaSoggettiDocumenti: AnagraficaSoggettiDocumenti[],
  anagraficaSoggettiDocumentiEliminati: AnagraficaSoggettiDocumenti[],
}

const AnagraficaSoggettiDocumentiW = ({ idAnagraficaSoggetto, anagraficaSoggettiDocumenti, anagraficaSoggettiDocumentiEliminati }: anagraficaSoggettiDocumentiWrops) => {
  const { t } = useTranslation();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClose = () => {
    setIsOpen(false);
  }
  const handleOpen = () => {
    setIsOpen(true);
  }

  const abilitazione = useAppSelector(state => getAbilitazione(state, anagrafichePath));
  const errorBE = useAppSelector(state => state.anagraficaSoggettiDocumenti.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorTipiDocuemnti());
    dispatch(resetErrorAnagraficaSoggettiDocumenti());
    dispatch(resetErrorAnagraficaSoggetti());
  }, [dispatch]);

  const documentoLookup = useAppSelector(state => state.tipoDocumento.lookup);
  const lookupAnagraficaSoggetto = useAppSelector(state => state.anagraficaSoggetti.lookup);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  const idAnagSoggConst = "idAnagraficaSoggetto"
  const idTipoDocumento = "idTipoDocumento";

  const fixedProps = useMemo(() => {
    return {
      [idAnagSoggConst]: idAnagraficaSoggetto,
    }
  }, [idAnagraficaSoggetto]);

  const title = t('documentsTitle');

  const [obj, setObj] = useState<AnagraficaSoggettiDocumenti | null>(null);

  useEffect(() => {
    if (idAnagraficaSoggetto) {
      dispatch(fetchAllDeletedAnagraficaSoggettoDocumentiById({ idAnagraficaSoggetto }));
      dispatch(fetchAllValidAnagraficaSoggettoDocumentiById({ idAnagraficaSoggetto }));
    }
  }, [dispatch, idAnagraficaSoggetto]);

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettiDocumenti>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettiDocumenti> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate) {
              return f.validate(rowData[f.field as AnagraficaSoggettiDocumentiKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            }
            return false;
          };
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        if (f.type && f.type === "file") {
          obj.title = t('documents');
          obj.emptyValue = t('noFile');
          obj.render = (rowData: AnagraficaSoggettiDocumenti) => {
            return <Button
              onClick={() => {
                handleOpen();
                setUploadedFile({
                  content: rowData.contenutoDocumento,
                  name: rowData.nomeDocumento
                });
              }}
              size='small'
              variant='outlined'
              color='primary'>
              {t('show')}
            </Button>
          };
        }
        // /*+++*/
        switch (f.field) {
          case idTipoDocumento:
            obj.lookup = documentoLookup;
            break;
        }

        /*+++*/
        return obj;
      }));
  }, [allFieldsState, excludedFieldInTable, documentoLookup, t]);

  const statusValidAnagraficaSoggettiDocumenti = useAppSelector(state => state.anagraficaSoggettiDocumenti.statusValidAnagraficaSoggettiDocumenti);
  const statusDeletedAnagraficaSoggettiDocumenti = useAppSelector(state => state.anagraficaSoggettiDocumenti.statusDeletedAnagraficaSoggettiDocumenti);

  const [data, setData] = useState<Array<AnagraficaSoggettiDocumenti>>([]);

  useEffect(() => {
    toggleValidDeleted ? setData(anagraficaSoggettiDocumenti) : setData(anagraficaSoggettiDocumentiEliminati);
  }, [
    anagraficaSoggettiDocumenti,
    anagraficaSoggettiDocumentiEliminati,
    toggleValidDeleted
  ]);

  useInitValid(statusValidAnagraficaSoggettiDocumenti, () => dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto })));
  useInitDeleted(statusDeletedAnagraficaSoggettiDocumenti, () => dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto })));

  const formLookups = useMemo(() => {
    return {
      idTipoDocumento: documentoLookup,
    }
  }, [documentoLookup]);

  const insertCallback = useCallback(() => {
    dispatch(fetchTipiDocumentiValidLookup());
    setUploadedFile(null);
    history.push(anagrafichePath + componentTabsPath + documentoTabPath + componentInsertPath);
  }, [dispatch, history]);

  const updateDetailCallback = (anagraficaSoggettiDocumenti: AnagraficaSoggettiDocumenti) => {
    setObj(anagraficaSoggettiDocumenti);
    setUploadedFile({
      content: anagraficaSoggettiDocumenti.contenutoDocumento,
      name: anagraficaSoggettiDocumenti.nomeDocumento
    });
    history.push(anagrafichePath + componentTabsPath + documentoTabPath + componentTabsPath)
  };

  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);

  const formCallback = useCallback((object: AnagraficaSoggettiDocumenti | null, field: string, optionalArgs: OptionalArgs<AnagraficaSoggettiDocumenti>) => {
    const { setInternalObj, file } = optionalArgs;
    const _file = file as File

    if (object) {
      if (field === 'contenutoDocumento') {
        setUploadedFile({
          content: object[field],
          name: object['nomeDocumento']
        });

        setInternalObj(state => {
          if (_file) {
            let ext: string | string[] = _file['type']?.split('/');
            if (ext)
              ext = ext[ext.length - 1];

            return {
              ...state,
              estensioneDocumento: ['pdf', 'jpg', 'jpeg', 'png'].includes(ext as string) ? (ext as string) : '',
              mediaType: _file['type'],
              nomeDocumento: _file['name']
            };
          } else {
            return {
              ...state,
              estensioneDocumento: '',
              mediaType: '',
              nomeDocumento: '',
            };
          }
        });
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  const exportDataExtra: PDFExtraData = useMemo(() => {
    const _idAnagSoggConst = fixedProps[idAnagSoggConst];

    const _nominativo = _idAnagSoggConst ? lookupAnagraficaSoggetto[_idAnagSoggConst] : ''

    return {
      head: {
        title: [t('nominativeLabel')],
        value: [_nominativo]
      }
    }
  }, [fixedProps, lookupAnagraficaSoggetto, t]);

  return (
    <Switch>
      <Route exact path={anagrafichePath + componentTabsPath + documentoTabPath + componentInsertPath}>
        <InnerComponentViews
          abilitazione={abilitazione}
          mainUri={anagrafichePath + componentTabsPath + documentoTabPath}
          tabsUri={anagrafichePath + componentTabsPath + documentoTabPath + componentInsertPath}
          tabsView={false}
          buttonTitle={t("cancelLabel")}
          info1={t("update")}
          tabs={[
            {
              label: t("subjectRegistryTabLabel"),
              tabPath: "",
              abilitazione: Abilitazione.READ_UPDATE,
              componentIf: (
                <Grid container spacing={2}>
                  <Grid item xs xl>
                    <GeneralForm
                      readOnly={false}
                      language={i18n.language}
                      componentPath={anagrafichePath + componentTabsPath + documentoTabPath}
                      action={insert}
                      fixedProps={fixedProps}
                      status={statusValidAnagraficaSoggettiDocumenti}
                      error={errorBE}
                      update={false}
                      fields={allFieldsState}
                      translate={t}
                      lookups={formLookups}
                      checksCallback={formCallback}
                    />
                  </Grid>
                  <Grid item xs={5} xl={5} className={clsx(classes.legendTopOffset)}>
                    {
                      uploadedFile &&
                      <AnteprimaDocumenti file={uploadedFile} />
                    }
                  </Grid>
                </Grid>
              ),
              componentElse: <Redirect to={anagrafichePath} />,
            },
          ]}
        />
      </Route>
      <Route path={anagrafichePath + componentTabsPath + documentoTabPath + componentTabsPath} exact>
        <InnerComponentViews
          abilitazione={abilitazione}
          mainUri={anagrafichePath + componentTabsPath + documentoTabPath}
          tabsUri={anagrafichePath + componentTabsPath + documentoTabPath + componentTabsPath}
          tabsView={false}
          buttonTitle={t("cancelLabel")}
          info1={""}
          tabs={[
            {
              label: t("subjectRegistryTabLabel"),
              tabPath: "",
              abilitazione: Abilitazione.READ_UPDATE,
              componentIf: (
                <Grid container spacing={2}>
                  <Grid item xs xl>
                    <GeneralForm
                      readOnly={false}
                      language={i18n.language}
                      componentPath={anagrafichePath + componentTabsPath + documentoTabPath}
                      action={update}
                      fixedProps={fixedProps}
                      status={statusValidAnagraficaSoggettiDocumenti}
                      error={errorBE}
                      update={true}
                      fields={allFields}
                      translate={t}
                      obj={obj}
                      lookups={formLookups}
                      buttonLabel="confirmLabel"
                      checksCallback={formCallback}
                    />
                  </Grid>
                  <Grid item xs={5} xl={4} className={clsx(classes.legendTopOffset)}>
                    {
                      uploadedFile &&
                      <AnteprimaDocumenti file={uploadedFile} isDownloadable={true} />
                    }
                  </Grid>
                </Grid>
              ),
              componentElse: <Redirect to={anagrafichePath} />,
            },
          ]}
        />
      </Route>
      <Route path='*'>
        <CrudMaterialTable
          abilitazione={abilitazione}
          title={title}
          columns={columns}
          columnsButton={true}
          data={data}
          elementIdProps={elementIdProps}
          elementRenderProps={elementRenderProps}
          fetchAllValid={fetchExtendedByIdAndDate}
          restore={restore}
          logicalDel={logicalDel}
          physicalDel={physicalDel}
          update={update}
          insert={insert}
          dataValid={toggleValidDeleted}
          setToggleValidDeleted={setToggleValidDeleted}
          insertCallback={insertCallback}
          updateCallback={updateDetailCallback}
          detailCallback={updateDetailCallback}
          statusValid={statusValidAnagraficaSoggettiDocumenti}
          statusDeleted={statusDeletedAnagraficaSoggettiDocumenti}
          errorBE={errorBE}
          fixedProps={fixedProps}
          exportDataExtra={exportDataExtra}
          logoUri={logoUri}
          nullableTextFields={true}
          localizedDatePicker={true}
          resetErrorCallback={resetError}
          extraOptions={{
            minBodyHeight: 150,
            maxBodyHeight: 460
          }}
        />
        {
          uploadedFile &&
          <AnteprimaModal
            open={isOpen}
            handleClose={handleClose}
            file={uploadedFile}
          />
        }
      </Route>
    </Switch>
  )
}
export default AnagraficaSoggettiDocumentiW;


