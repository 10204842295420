import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils';
import { Chat, ChatKeys, elementIdProps } from '../../models/Chat';
import { cancelById, definitiveDeleteById, restoreById, upd } from '../../services/services.service'
import { createPortaleSaaS, executeOpByIdsPortaleSaaS, getAllDeletedByIdsPortaleSaaS, getAllValidByIdsPortaleSaaS, getByIdPortaleSaaS } from '../../services/funzionalita.service';
import { User } from '../../models/User';
import { sessionUser } from '../../utils/utilconst';
import EventSource from 'eventsource';

const microservice = "chat";
const entity = "chat";

interface ChatState {
  statusValidChat: StatusEnum,
  validChat: Chat[],
  statusDeletedChat: StatusEnum,
  deletedChat: Chat[],
  statusAllChat: StatusEnum,
  allChat: Chat[],
  chat: Chat | null,
  lookup: Lookup,
  lookupExtended: Lookup,
  error: string | null,
  receivers: number[],
  connection: EventSource | null,
  online: boolean,
  currentUser: User | null
}

const _utente = sessionStorage.getItem(sessionUser);
const utente = _utente ? JSON.parse(_utente) as User : null;

const initialState: ChatState = {
  statusValidChat: StatusEnum.Succeeded,
  validChat: [],
  statusDeletedChat: StatusEnum.Succeeded,
  deletedChat: [],
  statusAllChat: StatusEnum.Succeeded,
  allChat: [],
  chat: null,
  lookup: {},
  lookupExtended: {},
  error: null,
  receivers: [],
  connection: null,
  online: false,
  currentUser: utente
}

interface IDChat {
  idApplicazioneSoftware: number,
  nomeUtente1?: string,
  nomeUtente2?: string
}

// cerca/chat/id/{idApplicazioneSoftware}
export const fetchAllByAppSoftwareAndUtente = createAsyncThunk(entity + '/fetchAllByAppSoftwareAndUtente', async (id: IDChat) => {
  const args = [id.idApplicazioneSoftware];

  const response = await getByIdPortaleSaaS(microservice, entity, args);
  return response.data as Chat[];
});

// get cerca/chat/all/valid/{idApplicazioneSoftware}
export const fetchAllValidByAppSoftware = createAsyncThunk(microservice + '/fetchAllValidByAppSoftware', async (id: IDChat) => {
  const args = [id.idApplicazioneSoftware];

  const response = await getAllValidByIdsPortaleSaaS(microservice, entity, args);
  return response.data as Chat[];
});

// get cerca/chat/all/deleted/{idApplicazioneSoftware}
export const fetchAllDeletedByAppSoftware = createAsyncThunk(microservice + '/fetchAllDeletedByAppSoftware', async (id: IDChat) => {
  const args = [id.idApplicazioneSoftware];

  const response = await getAllDeletedByIdsPortaleSaaS(microservice, entity, args);
  return response.data as Chat[];
});

// get cerca/chat/disconnect/{idApplicazioneSoftware}
export const disconnectUser = createAsyncThunk(microservice + '/disconnectUser', async (idApplicazioneSoftware: number) => {
  const response = await executeOpByIdsPortaleSaaS(microservice, entity, 'get', '', ['disconnect', idApplicazioneSoftware], undefined, ['disconnectUser']);
  return response.data as boolean;
});

// post insert/chat
export const insert = createAsyncThunk(entity + '/insert', async (chat: Chat) => {
  const response = await createPortaleSaaS(chat, microservice, entity);
  return response.data as Chat;
});

// put modifica/chat
export const update = createAsyncThunk(entity + '/update', async (chat: Chat) => {
  const response = await upd(chat, microservice, entity);
  return response.data as Chat;
});

// put elimina/chat/id/{idArt}
export const logicalDel = createAsyncThunk(entity + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

// put restore/chat/id/{idArt}
export const restore = createAsyncThunk(entity + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

// delete delete/chat/id/{idArt}
export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

// export const closeConnection = createAsyncThunk(entity + '/closeConnection', async (ids: Ids<string>[]) => {
//   await definitiveDeleteById(ids, microservice, entity);
//   return { ids };
// });

export const chatSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: (state: ChatState) => {
      return initialState;;
    },
    resetError: (state: ChatState) => {
      state.error = initialState.error;
      state.statusValidChat = initialState.statusValidChat;
      state.statusDeletedChat = initialState.statusDeletedChat;
      state.statusAllChat = initialState.statusAllChat;
    },
    /**
     * Adds a set of new Chats
     * @param data.payload should already be filtered
     */
    addChats: (state: ChatState, data: { payload: Chat[] }) => {
      state.validChat = [
        ...state.validChat,
        ...data.payload
      ]
    },
    setConnection: (state: ChatState, data: { payload: EventSource }) => {
      state.connection = data.payload;
      state.online = true;
    },
    closeConnection: (state: ChatState) => {
      state.connection?.close();
      state.connection = null;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchAllByAppSoftwareAndUtente.pending, (state) => {
      state.statusAllChat = StatusEnum.Loading;
      state.error = null;
    })
    builder.addCase(fetchAllByAppSoftwareAndUtente.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusAllChat = StatusEnum.Failed;
      state.allChat = [];
    })
    builder.addCase(fetchAllByAppSoftwareAndUtente.fulfilled, (state, { payload }: PayloadAction<Chat[]>) => {
      state.statusValidChat = StatusEnum.Succeeded;
      state.allChat = payload;
    })

    // fetchAllValidByAppSoftware
    builder.addCase(fetchAllValidByAppSoftware.pending, (state) => {
      state.statusValidChat = StatusEnum.Loading;
      state.error = null;
    })
    builder.addCase(fetchAllValidByAppSoftware.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidChat = StatusEnum.Failed;
      state.validChat = [];
    })
    builder.addCase(fetchAllValidByAppSoftware.fulfilled, (state, { payload }: PayloadAction<Chat[]>) => {
      state.statusValidChat = StatusEnum.Succeeded;
      state.validChat = payload;

      const utente1 = payload.map(elem => elem.idUtente1).filter(elem => state.currentUser && elem !== state.currentUser.idUtente);
      const utente2 = payload.map(elem => elem.idUtente2).filter(elem => state.currentUser && elem !== state.currentUser.idUtente);
      state.receivers = Array.from(new Set([...utente1, ...utente2]));
    })

    // fetchAllDeletedByAppSoftware
    builder.addCase(fetchAllDeletedByAppSoftware.pending, (state) => {
      state.statusDeletedChat = StatusEnum.Loading;
      state.error = null;
    })
    builder.addCase(fetchAllDeletedByAppSoftware.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusDeletedChat = StatusEnum.Failed;
      state.deletedChat = [];
    })
    builder.addCase(fetchAllDeletedByAppSoftware.fulfilled, (state, { payload }: PayloadAction<Chat[]>) => {
      state.statusDeletedChat = StatusEnum.Succeeded;
      state.deletedChat = payload;
    })

    // disconnect
    builder.addCase(disconnectUser.pending, (state) => {
      state.error = null;
    })
    builder.addCase(disconnectUser.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.allChat = [];
    })
    builder.addCase(disconnectUser.fulfilled, (state, { payload }: PayloadAction<boolean>) => {
      state.online = false;
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidChat = StatusEnum.Loading;
      state.error = null;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidChat = StatusEnum.Failed;
      state.chat = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<Chat>) => {
      state.statusValidChat = StatusEnum.Succeeded;
      state.chat = payload;
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidChat = StatusEnum.Loading;
      state.error = null;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidChat = StatusEnum.Failed;
      state.chat = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<Chat>) => {
      state.statusValidChat = StatusEnum.Succeeded;
      state.validChat = state.validChat.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.chat = payload;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidChat = StatusEnum.Loading;
      state.error = null;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidChat = StatusEnum.Failed;
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidChat = StatusEnum.Succeeded;
      const deleted = state.validChat.find(el => payload.ids.every(idObj => el[idObj.name as ChatKeys] === idObj.id));
      if (deleted) {
        deleted.version += 1;
        state.deletedChat = state.deletedChat.concat([deleted]);
      }
      state.validChat = state.validChat.filter(el => payload.ids.some(idObj => el[idObj.name as ChatKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedChat = StatusEnum.Loading;
      state.error = null;
    })
    builder.addCase(restore.rejected, (state, action) => {
      state.statusDeletedChat = StatusEnum.Failed;
      state.error = (action.error.message) ? action.error.message : "";
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedChat = StatusEnum.Succeeded;
      const valid = state.deletedChat.find(el => payload.ids.every(idObj => el[idObj.name as ChatKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validChat = state.validChat.concat([valid]);
      }
      state.deletedChat = state.deletedChat.filter(el => payload.ids.some(idObj => el[idObj.name as ChatKeys] !== idObj.id));
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedChat = StatusEnum.Loading;
      state.error = null;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusDeletedChat = StatusEnum.Failed;
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedChat = StatusEnum.Succeeded;
      state.deletedChat = state.deletedChat.filter(el => payload.ids.some(idObj => el[idObj.name as ChatKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError, addChats, setConnection, closeConnection } = chatSlice.actions;
export default chatSlice.reducer;