import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getByIds, upd } from '../../services/services.service'
import { elementIdProps, UnitaOperativaCodiceVisite, UnitaOperativaCodiceVisiteKeys } from '../../models/UnitaOperativaCodiceVisite';

const microservice = "unitaoperative";
const entity = "unitaoperativecodicevisite";

interface UnitaOperativeCodiceVisiteState {
  statusValidUnitaOperativaCodiceVisiteState: StatusEnum,
  validUnitaOperativaCodiceVisite: UnitaOperativaCodiceVisite[],
  unitaOperativaCodiceVisite: UnitaOperativaCodiceVisite | null,
  error: string | null
}

const initialState: UnitaOperativeCodiceVisiteState = {
  statusValidUnitaOperativaCodiceVisiteState: StatusEnum.Succeeded,
  validUnitaOperativaCodiceVisite: [],
  unitaOperativaCodiceVisite: null,
  error: null
}

export const fetchByIdUnitaOperativa = createAsyncThunk(entity + '/fetchByIdUnitaOperativa', async (obj: { idStruttura: number, idDipartimento: number, idUnitaOperativa: number }) => {
  let response = await getByIds(microservice, entity, ['id', obj.idStruttura, obj.idDipartimento, obj.idUnitaOperativa], ['/fetchByIdUnitaOperativa']);
  return response.data as UnitaOperativaCodiceVisite[];
});

export const insert = createAsyncThunk(entity + '/insert', async (UnitaOperativaCodiceVisite: UnitaOperativaCodiceVisite) => {
  const response = await create(UnitaOperativaCodiceVisite, microservice, entity);
  return response.data as UnitaOperativaCodiceVisite;
});

export const update = createAsyncThunk(entity + '/update', async (UnitaOperativaTurnoNumeroAddetti: UnitaOperativaCodiceVisite) => {
  const response = await upd(UnitaOperativaTurnoNumeroAddetti, microservice, entity);
  return response.data as UnitaOperativaCodiceVisite;
});

export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const unitaOperativeCodiceVisiteSlice = createSlice({
  name: entity,
  initialState,
  reducers: {

    reset: () => {
      return initialState;
    },
    resetError: (state: UnitaOperativeCodiceVisiteState) => {
      state.error = initialState.error;
      state.statusValidUnitaOperativaCodiceVisiteState = initialState.statusValidUnitaOperativaCodiceVisiteState;
    }
  },
  extraReducers: builder => {
    // fetchByIdUnitaOperativa
    builder.addCase(fetchByIdUnitaOperativa.pending, (state) => {
      state.statusValidUnitaOperativaCodiceVisiteState = StatusEnum.Loading;
    })
    builder.addCase(fetchByIdUnitaOperativa.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativaCodiceVisiteState = StatusEnum.Failed;
      state.validUnitaOperativaCodiceVisite = [];
    })
    builder.addCase(fetchByIdUnitaOperativa.fulfilled, (state, { payload }: PayloadAction<UnitaOperativaCodiceVisite[]>) => {
      state.statusValidUnitaOperativaCodiceVisiteState = StatusEnum.Succeeded;
      state.validUnitaOperativaCodiceVisite = payload ?? [];
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidUnitaOperativaCodiceVisiteState = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativaCodiceVisiteState = StatusEnum.Failed;
      state.unitaOperativaCodiceVisite = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<UnitaOperativaCodiceVisite>) => {
      state.statusValidUnitaOperativaCodiceVisiteState = StatusEnum.Succeeded;
      state.unitaOperativaCodiceVisite = payload;
      state.validUnitaOperativaCodiceVisite = state.validUnitaOperativaCodiceVisite.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidUnitaOperativaCodiceVisiteState = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativaCodiceVisiteState = StatusEnum.Failed;
      state.unitaOperativaCodiceVisite = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<UnitaOperativaCodiceVisite>) => {
      state.statusValidUnitaOperativaCodiceVisiteState = StatusEnum.Succeeded;
      state.validUnitaOperativaCodiceVisite = state.validUnitaOperativaCodiceVisite.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.unitaOperativaCodiceVisite = payload;
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidUnitaOperativaCodiceVisiteState = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativaCodiceVisiteState = StatusEnum.Failed;
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidUnitaOperativaCodiceVisiteState = StatusEnum.Succeeded;
      state.validUnitaOperativaCodiceVisite = state.validUnitaOperativaCodiceVisite.filter(el => payload.ids.some(idObj => el[idObj.name as UnitaOperativaCodiceVisiteKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError } = unitaOperativeCodiceVisiteSlice.actions;
export default unitaOperativeCodiceVisiteSlice.reducer;