export const propCreationDate = 'data_creazione';
export const propName = 'nome';

export const orderAsc = 'asc';
export const orderDesc = 'desc';

export const loginPagePath = '/login';
export const loginTitle = 'Login';



export const notFoundPath = '/notfound';
export const forbiddenPath = '/forbidden';
export const articoliDiLeggePath = '/articolidilegge';
export const centriDiCostoPath = '/centridicosto';
export const codiciVisitePath = '/codicivisite';
export const dipartimentiPath = '/dipartimenti';
export const dittePath = '/ditte';
export const formazionePath = '/formazione';
export const anagraficaFormazionePath = '/anagraficaformazione';
export const presenzeDipendentiPath = '/presenzedipendenti';
export const pianificazioniDipendentiPath = '/pianificazionidipendenti';
export const timbratureDipendentiPath = '/timbraturedipendenti';
export const documentiDipendentePath = '/documentidipendente';
export const idoneitaPath = '/idoneita';
export const prescrizioniPath = '/prescrizioni';
export const motiviPath = '/motivi';
export const qualifichePath = '/qualifiche';
export const strutturePath = '/strutture';
export const unitaOperativePath = '/unitaoperative';
export const turniPath = '/turni';
export const assenzePath = '/assenze';
export const pianificazioniConsuntivoPath = '/pianificazioniconsuntivo';
export const pianificazioniPath = '/pianificazioni';
export const presenzePath = '/presenze';
export const assenzeProgrammatePath = '/assenzeprogrammate';
export const approvaAssenzeProgrammatePath = '/approvaassenze';
export const lungheAssenzePath = '/lungheassenze';
export const richiesteAssenzeProgrammatePath = '/richiesteassenzeprogrammate';
export const anagrafichePath = '/anagrafiche';
export const anagraficheCoordinatorePath = '/anagrafichecoordinatore';
export const entratiUsciti104Path = '/entratiUsciti104';
export const variazioniUOPath = '/variazioniuo';
export const verificaStraordinariPath = '/verificastraordinari';
export const dotazioneOrganicaPath = '/dotazioneorganica';
export const medicoCompetentePath = '/medicocompetente';
export const medicoCompetenteCoordinatorePath = '/medicocompetentecoordinatore';
export const presenzeUOPath = '/presenzeUO';
export const monitoraggioPath = '/monitoraggio';
export const abilitazioneUtentePath = '/abilitazioneutente'
export const festivitaPath = '/festivita'
export const tipiDocumentiPath = '/tipidocumento'
export const tipiIncaricoPath = '/tipiincarico'
export const approvazionePresenzePath = '/approvazionepresenze'
export const approvazioneAssenzePath = '/approvazioneassenze'
export const timbraturePath = '/timbrature'
export const dipendentiPresenzePath = '/dipendentipresenza'
export const caricamentoDocumentiPath = '/caricamentodocumenti'
export const modelliTerminalePath = '/modelliterminale';
export const modelliTerminaleCausaliPath = '/modelliterminalecausali';
export const combinazioniTurniPath = '/combinazioniturni';
export const combinazioniTurniTurniPath = '/combinazioniturniturni';

export const codiceTerminale = 'ApplicazioneGestioneTurniWeb';

export const nameMinLength = 3;

export const cookieHome = 'home';
export const cookiePortal = 'portalesaas';
export const cookieExpires = 60 * 60 * 1000;

export const sessionOrg = 'portaleorg';
export const sessionProfil = 'portaleprofil';
export const sessionUser = 'utente'
export const sessionSkinTheme = 'skinTheme';
export const sessionAppPathName = 'appPathName';

export const cookieOrg = 'portaleorg';
export const cookieProfil = 'portaleprofil'
export const cookieUser = 'utente'
export const cookieSkinTheme = 'skinTheme';
export const cookieAppPathName = 'appPathName';

export const sessionLogo = 'appLogo';

export const cookieCheckTime = 3000; //in millisec

export const refreshTokenTime = 900000;

export const localStorageKeyLoggedApp = 'kgh1a';
export const localStorageValueLoggedApp = '(hfbb14%%£&';

export const gestioneTurniOrg = 'gestioneturniorg';

const domains = window.location.hostname.split(".");

export const secondLevelDomain = (domains[domains.length - 1] !== "localhost") ? domains[domains.length - 2] + "." + domains[domains.length - 1] : domains[domains.length - 1];

export const protocol = window.location.protocol + "//";

export const zuulUrl = (protocol + process.env.REACT_APP_API_THIRDLEVELDOMAIN + '.') + ((secondLevelDomain !== "localhost") ? (secondLevelDomain) : (process.env.REACT_APP_ZUUL_TEST_BE_API_URL));

export const authURL = (process.env.REACT_APP_API_THIRDLEVELDOMAIN && process.env.REACT_APP_AUTH_PATH) ? zuulUrl + process.env.REACT_APP_AUTH_PATH : "";

export const applicazioneURL = (process.env.REACT_APP_API_THIRDLEVELDOMAIN && process.env.REACT_APP_APPLICAZIONE_PATH) ? zuulUrl + process.env.REACT_APP_APPLICAZIONE_PATH : "";

export const portaleBEUrl = (process.env.REACT_APP_API_THIRDLEVELDOMAIN && process.env.REACT_APP_PORTALE_PATH) ? zuulUrl + process.env.REACT_APP_PORTALE_PATH : "";

export const portaleFEUrl = (document.cookie.split(';').find(row => row.trim().startsWith(cookieHome + '='))?.split('=')[1])
