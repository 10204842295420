import { Column } from "@material-table/core";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { allFields, elementIdProps, elementRenderProps, Prescrizioni, PrescrizioniKeys } from "../../../models/Prescrizioni";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  fetchAllDeleted,
  fetchAllValid,
  insert,
  logicalDel,
  physicalDel,
  restore,
  update,
  reset as resetIdonieta,
  resetError as resetErrorPrescrizioni
} from "../../../store/slices/prescrizioniSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { prescrizioniPath } from "../../../utils/utilconst";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import { Fields } from "../../../models/Fields";
import { sortingStringCI } from "../../../utils/utilfunctions";
import { calculateMTableHeight } from "../../../utils/styleconst";

const PrescrizioniW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const abilitazione = useAppSelector(state => getAbilitazione(state, prescrizioniPath));
  const errorBE = useAppSelector(state => state.idoneita.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorPrescrizioni());
  }, [dispatch]);

  const title = t('prescriptionTitle');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<Prescrizioni>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        const obj: Column<Prescrizioni> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field, removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = (rowData: Prescrizioni) => {
            if (f.validate)
              return f.validate(rowData[f.field as PrescrizioniKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        if (f.type === 'string')
          obj.customSort = (a, b) => sortingStringCI(a.descrizione, b.descrizione);
        return obj;
      })
    );
  }, [allFieldsState, excludedFieldInTable, t]);

  const validPrescrizioni = useAppSelector(state => state.prescrizioni.validPrescrizioni);
  const deletedPrescrizioni = useAppSelector(state => state.prescrizioni.deletedPrescrizioni);

  const statusValidPrescrizioni = useAppSelector(state => state.prescrizioni.statusValidPrescrizioni);
  const statusDeletedPrescrizioni = useAppSelector(state => state.prescrizioni.statusDeletedPrescrizioni);

  const [data, setData] = useState<Array<Prescrizioni>>([]);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  useEffect(() => {
    if (toggleValidDeleted)
      setData(validPrescrizioni)
    else
      setData(deletedPrescrizioni);
  }, [validPrescrizioni, deletedPrescrizioni, toggleValidDeleted]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
      dispatch(resetIdonieta());
    };
  }, [dispatch]);

  return (
    <CrudMaterialTable
      abilitazione={abilitazione}
      title={title}
      columns={columns}
      data={data}
      elementIdProps={elementIdProps}
      elementRenderProps={elementRenderProps}
      fetchAllValid={fetchAllValid}
      fetchAllDeleted={fetchAllDeleted}
      insert={insert}
      logicalDel={logicalDel}
      physicalDel={physicalDel}
      restore={restore}
      update={update}
      dataValid={toggleValidDeleted}
      setToggleValidDeleted={setToggleValidDeleted}
      statusValid={statusValidPrescrizioni}
      statusDeleted={statusDeletedPrescrizioni}
      errorBE={errorBE}
      logoUri={logoUri}
      resetErrorCallback={resetError}
      extraOptions={{
        minBodyHeight: calculateMTableHeight(0, false, false),
        maxBodyHeight: calculateMTableHeight(0, false, false)
      }}
    />
  )
}
export default PrescrizioniW;