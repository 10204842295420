import { MutableRefObject, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import RefreshIcon from "@material-ui/icons/Refresh";
import jsPDF from "jspdf";
import autoTable, { CellDef, RowInput } from "jspdf-autotable";
import { saveAs } from "file-saver";
import { useAppDispatch } from "../../../../store/hooks";
import { AsyncThunk } from "@reduxjs/toolkit";
import { PDFOptions, StatusEnum } from "../../../../models/Utils";
import { Grid, Theme, Typography } from "@material-ui/core";
import { Components, MTableCell, MTableEditField, MTableToolbar, MTableHeader } from "@material-table/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import itIT from "date-fns/locale/it";
import enGB from "date-fns/locale/en-GB";
import { addFooter, addHeader, getDateDDMMYYYY, getDateDDMMYYYY_HHMM, openPDF } from '../../../../utils/utilfunctions';
import { PDFExtraData } from '../../../../models/Utils';
import { ExportType } from '../../../../utils/utildata';
import { FixedProps, TCsvCallback, TPdfCallback } from "../../../../utils/data.types";
import 'jspdf-autotable'

const useReadOnlyMaterialTable = (
  theme: Theme,
  title: string,
  logoUri: string | null,
  columnsButton: boolean,
  statusValid: string,
  errorBE: string | null,
  fetchAllValid?:
    | AsyncThunk<Object[], void, {}>
    | AsyncThunk<Object[], Object, {}>
    | AsyncThunk<Object, Object, {}>,
  localizedDatePicker: boolean = true,
  nullableTextFields?: boolean,
  fixedProps?: FixedProps,
  exportDataExtra?: PDFExtraData,
  exportType?: ExportType,
  isExportLandscape: boolean = true,
  pdfOptions?: PDFOptions,
  exportPDFCallback?: TPdfCallback,
  exportCSVCallback?: TCsvCallback,
  isLoading: boolean = false,
  exportMenuRef?: MutableRefObject<Record<string, any>[]>
) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [wrapValidResolve, setWrapValidResolve] = useState<(() => void) | null>(null);
  const [wrapValidReject, setWrapValidReject] = useState<(() => void) | null>(null);
  const [actions, setActions] = useState<any[]>([]);
  const [editable, setEditable] = useState<Object>({});
  const [components, setComponents] = useState<Object>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [logoImage, setLogoImage] = useState<HTMLImageElement>();
  const materialTableRef = useRef();

  useEffect(() => {
    return () => {
      setErrorMessage(null);
      setWrapValidResolve(null);
      setWrapValidReject(null);
      setActions([]);
      setEditable({});
      setComponents({});
      setLoading(false);
      setLogoImage(undefined);
    };
  }, []);

  useEffect(() => {
    if (wrapValidResolve && statusValid === StatusEnum.Succeeded) {
      setErrorMessage(null);
      setTimeout(() => {
        wrapValidResolve();
      }, 1000);
      setWrapValidResolve(null);
      setLoading(false);
    } else if (wrapValidReject && statusValid === StatusEnum.Failed) {
      setErrorMessage(errorBE);
      wrapValidReject();
      setWrapValidReject(null);
      setLoading(false);
    }
  }, [wrapValidResolve, statusValid, dispatch, errorBE, wrapValidReject]);

  useEffect(() => {
    const im = new Image();
    im.onload = () => {
      setLogoImage(im);
    };
    if (logoUri)
      im.src = logoUri.startsWith('data:') ? logoUri : 'data:image/png;base64,' + logoUri;
  }, [logoUri]);

  useEffect(() => {
    setLoading(isLoading)
  }, [isLoading]);

  // useEffect(() => {
  //   statusValid === StatusEnum.Loading ? setLoading(true) : setLoading(false);
  // }, [statusValid]);

  const localization = useMemo(() => {
    return {
      body: {
        emptyDataSourceMessage: t("emptyDataSourceMessage"),
        addTooltip: t("addTooltip"),
        deleteTooltip: t("deleteTooltip"),
        editTooltip: t("editTooltip"),
        filterRow: {
          filterTooltip: t("filterTooltip"),
        },
        editRow: {
          deleteText: t("rowDefDeleteText"),
          cancelTooltip: t("cancelTooltip"),
          saveTooltip: t("saveTooltip"),
        },
      },
      grouping: {
        placeholder: t("groupingPlaceholder"),
        groupedBy: t("groupedBy"),
      },
      header: {
        actions: t("actions"),
      },
      pagination: {
        labelDisplayedRows: t("labelDisplayedRows"),
        labelRowsSelect: t("labelRowsSelect"),
        labelRowsPerPage: t("labelRowsPerPage"),
        firstPageLabel: t("firstPageLabel"),
        firstTooltip: t("firstTooltip"),
        previousPageLabel: t("previousPageLabel"),
        previousTooltip: t("previousTooltip"),
        nextPageLabel: t("nextPageLabel"),
        nextTooltip: t("nextTooltip"),
        lastPageLabel: t("lastPageLabel"),
        lastTooltip: t("lastTooltip"),
      },
      toolbar: {
        addRemoveColumns: t("addRemoveColumns"),
        nRowsSelected: t("nRowsSelected"),
        showColumnsTitle: t("showColumnsTitle"),
        showColumnsAriaLabel: t("showColumnsAriaLabel"),
        exportTitle: t("exportTitle"),
        exportAriaLabel: t("exportAriaLabel"),
        exportName: t("exportName"),
        searchTooltip: t("searchTooltip"),
        searchPlaceholder: t("searchPlaceholder"),
      },
    };
  }, [t]);

  useEffect(() => {
    const refreshData = (resolve: (value: unknown) => void, reject: (value: unknown) => void) => {
      setErrorMessage(null);
      setWrapValidResolve(() => resolve);
      setWrapValidReject(() => reject);
      if (fetchAllValid)
        if (fixedProps) {
          let fav = fetchAllValid as AsyncThunk<Object[], Object, {}>;
          dispatch(fav(fixedProps));
        } else {
          let fav = fetchAllValid as AsyncThunk<Object[], void, {}>;
          dispatch(fav());
        }
    };
    setLoading(true);
    new Promise((resolve, reject) => {
      refreshData(resolve, reject);
    });
  }, [fetchAllValid, fixedProps, dispatch, t]);

  /**
   * Used for refresh event handler
   */
  useEffect(() => {
    if (fetchAllValid) {
      const refreshData = (resolve: (value: unknown) => void, reject: (value: unknown) => void) => {
        setErrorMessage(null);
        setWrapValidResolve(() => resolve);
        setWrapValidReject(() => reject);
        if (fixedProps) {
          let fav = fetchAllValid as AsyncThunk<Object[], Object, {}>;
          dispatch(fav(fixedProps));
        } else {
          let fav = fetchAllValid as AsyncThunk<Object[], void, {}>;
          dispatch(fav());
        }
      };

      let act = [];
      act.push({
        icon: RefreshIcon,
        isFreeAction: true,
        tooltip: t("refreshData"),
        onClick: () => {
          setLoading(true);
          new Promise((resolve, reject) => {
            refreshData(resolve, reject);
          });
        },
      });
      setActions(act);
    }

    let edit = {};

    setEditable(edit);
  }, [t, dispatch, fetchAllValid, fixedProps]);

  useEffect(() => {
    const comp: Components = {};

    comp["Header"] = (props) => {
      const _props = {
        ...props,
        headerStyle: {
          position: 'sticky',
          top: 0,
          zIndex: 10
        },
      }

      return <MTableHeader {..._props} />
    }

    comp["Cell"] = (props) => {
      if (localizedDatePicker && props.columnDef.type === "date") {
        const formattedDate = props.value ? getDateDDMMYYYY(new Date(props.value)) : '';
        const updatedProps = { ...props, "value": formattedDate }
        return <MTableCell {...updatedProps} />
      }
      else return <MTableCell {...props} />
    }

    comp["Toolbar"] = (props) => (
      <>
        <MTableToolbar {...props} />
        {errorMessage && errorMessage.length > 0 && (
          <Grid container justifyContent="center">
            <Typography color="secondary" variant="body1">
              {errorMessage}
            </Typography>
          </Grid>
        )}
      </>
    );

    comp["EditField"] = (props) => {
      if (localizedDatePicker && props.columnDef.type === "date") {
        return (
          <MuiPickersUtilsProvider
            utils={DateFnsUtils}
            locale={i18n.language === "it-IT" || i18n.language === "it" ? itIT : enGB}
          >
            <KeyboardDatePicker
              okLabel={t('insertLabel')}
              clearLabel={t("clearLabel")}
              cancelLabel={t("cancelLabel")}
              clearable
              margin="none"
              format="dd/MM/yyyy"
              value={props.value ? props.value : null}
              error={props.error}
              helperText={props.helperText}
              onChange={(d) => {
                if (d) {
                  let month = (d.getMonth() + 1).toString().padStart(2, '0');
                  let day = d.getDate().toString().padStart(2, '0');
                  props.onChange(
                    d.getFullYear() + "-" + month + "-" + day + "T00:00:00"
                  );
                } else props.onChange(null);
              }}
              KeyboardButtonProps={{ "aria-label": "change date" }}
            />
          </MuiPickersUtilsProvider>
        );
      } else if (
        nullableTextFields &&
        props.columnDef.type === "string" &&
        (props.value === null || props.value === undefined)
      ) {
        let editProps = { ...props, value: "" };
        return <MTableEditField {...editProps} />;
      } else return <MTableEditField {...props} />;
    };

    setComponents(comp);
  }, [
    errorMessage,
    i18n.language,
    localizedDatePicker,
    nullableTextFields,
    t,
    theme.palette.secondary.main,
  ]);

  const exportMenuOptions = useMemo(() => [
    {
      label: t("exportPDF"),
      exportFunc: (cols: Record<string, any>[], data: Record<string, any>[], tableData: Record<string, object[]>, standalone?: boolean) => {
        const PAPER_FORMAT = pdfOptions?.format ?? 'a4';
        const PAPER_ORIENTATION = isExportLandscape ? "landscape" : "portrait";

        const imgHeight = 12;
        const Y_OFFSET = 5;

        const startY = imgHeight + 8 + Y_OFFSET;

        const MARGIN_H = 5;
        const HEADER_SPACE = exportDataExtra?.head ? 44 : startY;
        const TABLE_MARGIN_BOTTOM = 10;

        const filteredCols = cols.filter(elem => !elem?.external?.fieldData.exportExclude);

        if (exportPDFCallback) {
          const extra = {
            logoImage,
            title,
            t,
            theme,
            exportDataExtra,
            fixedProps: fixedProps ?? {},
            spacing: {
              HEADER_SPACE,
              TABLE_MARGIN_BOTTOM,
              MARGIN_H
            }
          }

          const _pdfOptions: PDFOptions = {
            ...pdfOptions,
            format: PAPER_FORMAT,
            orientation: PAPER_ORIENTATION
          };

          const pdfDoc = exportPDFCallback(
            cols,
            data,
            tableData,
            extra,
            _pdfOptions,
            standalone ?? false
          );

          addHeader(
            pdfDoc,
            {
              logoImage,
              MARGIN_H,
              Y_OFFSET,
              PAPER_FORMAT,
              PAPER_ORIENTATION,
              exportDataExtra,
              theme,
              title,
            }
          );
          addFooter(
            pdfDoc,
            {
              MARGIN_H,
              PAPER_FORMAT,
              PAPER_ORIENTATION
            }
          );

          const base64String = pdfDoc.output('datauristring');
          if (standalone) {
            return base64String;
          } else {
            openPDF(base64String, { tabId: title });
          }

          return;
        }

        let header: Record<string, unknown>[] = [];
        let modData: RowInput[] = [];

        if (tableData.groupedData.length > 0 && cols.filter(elem => elem.defaultGroupOrder != null).length > 0) {

          const groupsTitle = filteredCols.filter(col => col.defaultGroupOrder != null).map(col => col.title);

          let newCols = filteredCols.filter((elem: Record<string, any>) => {
            return [undefined, null].includes(elem.defaultGroupOrder as any)
          });

          header = newCols.map((colonna: Record<string, any>, index) => {
            return {
              ...colonna,
              cellWidth: { colonna: colonna.width ? (colonna.width as number) / 5 : 'auto' },
              styles: {
                fillColor: theme.palette.primary.main,
                halign: 'center',
                valign: 'middle'
              }
            };
          });

          (tableData.groupedData as Record<string, any>[]).forEach((gruppo) => {
            const baseFillColor = 220;
            const step = 15;

            const mapData = (data: Record<string, unknown>[]) => {
              return data.map((riga: Record<string, unknown>) => {
                return header.map((colonna, index) => {
                  const value = riga[colonna.field as string] as string;
                  let cellDef: CellDef = {};

                  if ((value != null && value !== '') && colonna.type === 'date') {
                    cellDef.content = getDateDDMMYYYY(new Date(value));
                  } else if ((value != null && value !== '') && colonna.type === 'datetime') {
                    cellDef.content = getDateDDMMYYYY_HHMM(new Date(value));
                  } else if (typeof value === "boolean" || colonna.type === 'boolean') {
                    cellDef.content = value ? t("yes") : t("no");
                  } else cellDef.content = value;

                  if (cellDef.content == null) {
                    cellDef.content = "";
                  }
                  if (newCols[index] && newCols[index].cellStyle && newCols[index].cellStyle['backgroundColor']) {
                    cellDef.styles = { fillColor: newCols[index].cellStyle['backgroundColor'] };
                  }

                  if (colonna.type === 'numeric') {
                    cellDef.styles = {
                      ...cellDef.styles,
                      halign: 'right',
                      valign: 'middle'
                    };
                  } else if (['date', 'datetime'].includes(colonna.type as string)) {
                    cellDef.styles = {
                      ...cellDef.styles,
                      halign: 'center',
                      valign: 'middle'
                    }
                  }

                  if (index === 0) {
                    cellDef.styles = {
                      ...cellDef.styles,
                      cellPadding: {
                        top: 2,
                        left: 5,
                        right: 5
                      }
                    }
                  }

                  return cellDef;
                }) as RowInput
              }) as RowInput[][]
            }

            const mapGroup = (group: Record<string, unknown>, _level: number) => {
              const subGroup = group['groups'] as any[];
              const groupData = group['data'] as any[];

              let retval: Record<string, unknown>[][] = [
                [
                  {
                    content: groupsTitle[_level - 1] + ': ' + group['value'],
                    styles: {
                      halign: 'left',
                      fontStyle: 'bold',
                      fillColor: baseFillColor + _level * step,
                      cellPadding: {
                        top: 2,
                        bottom: 2,
                        left: _level * 10,
                        right: 2
                      }
                    },
                    colSpan: header.length
                  } as Record<string, unknown>
                ]
              ];

              if (subGroup?.length > 0) {
                subGroup.forEach(elem => {
                  retval = retval.concat(mapGroup(elem, _level + 1))
                })
              } else {
                retval = retval.concat(mapData(groupData) as any[][])
              }

              return retval;
            }

            let level = 1;

            let tableRows = [
              [
                {
                  content: groupsTitle[level - 1] + ': ' + gruppo['value'],
                  styles: {
                    halign: 'left',
                    fontStyle: 'bold',
                    fillColor: baseFillColor + step,
                  },
                  colSpan: header.length
                } as Record<string, unknown>
              ]
            ];

            if (gruppo['groups']?.length > 0) { // Se esistono sotto gruppi
              gruppo['groups'].forEach((_gruppo: Record<string, unknown>,) => {
                tableRows = tableRows.concat(mapGroup(_gruppo, level + 1));
              })
            } else {  // mappare i dati
              tableRows = tableRows.concat(mapData(gruppo['data']) as any[][])
            }

            modData = modData.concat(tableRows)
          })
        } else {  // se non ci sono gruppi
          header = filteredCols.map(colonna => {
            let halign = 'left';

            if (colonna.type === 'numeric') {
              halign = 'right';
            } else if (['date', 'datetime'].includes(colonna.type as string)) {
              halign = 'center';
            }

            return {
              ...colonna,
              cellWidth: { colonna: colonna.width ? colonna.width / 5 : 'auto' },
              styles: {
                fillColor: theme.palette.primary.main,
                halign,
                valign: 'middle'
              }
            };
          });

          modData = data.map((riga) => {
            return header.map((colonna, index) => {
              let cellDef: CellDef = {};
              const value = riga[colonna.field as string];

              if ((value != null && value !== '') && colonna.type === 'date') {
                cellDef.content = getDateDDMMYYYY(new Date(value));
              } else if ((value != null && value !== '') && colonna.type === 'datetime') {
                cellDef.content = getDateDDMMYYYY_HHMM(new Date(value));
              } else if (typeof value === "boolean" || colonna.type === 'boolean') {
                cellDef.content = value ? t("yes") : t("no");
              } else cellDef.content = value;

              if (cellDef.content == null) {
                cellDef.content = "";
              }

              if (colonna.cellStyle && (colonna.cellStyle as React.CSSProperties)?.backgroundColor) {
                cellDef.styles = { fillColor: (colonna.cellStyle as React.CSSProperties)?.backgroundColor };
              }

              let halign: 'left' | 'right' | 'center' = 'left';

              if (colonna.type === 'numeric') {
                halign = 'right';
              } else if (['date', 'datetime'].includes(colonna.type as string)) {
                halign = 'center';
              }

              cellDef.styles = {
                ...cellDef.styles,
                halign,
                valign: 'middle',
              }

              return cellDef;
            }) as RowInput;
          });
        }

        const reworkData = (data: RowInput[], headerLength: number) => {
          return data.length > 0
            ? data
            : [[{
              content: t('noDataPresentLabel'),
              styles: {
                halign: 'center',
                fontStyle: 'italic'
              } as CellDef,
              colSpan: headerLength
            }]] as RowInput[];
        }

        const doc = new jsPDF({ orientation: PAPER_ORIENTATION, format: PAPER_FORMAT, });

        /*
         * DATI
         */
        autoTable(doc, {
          startY: HEADER_SPACE,
          margin: {
            top: HEADER_SPACE,
            horizontal: MARGIN_H,
          },
          head: [header],
          body: reworkData(modData, header.length),
          headStyles: {
            fillColor: theme.palette.primary.main,
            halign: 'center',
            cellPadding: 2,
            valign: 'middle'
          },
          theme: "grid",
          styles: {
            fontSize: pdfOptions && pdfOptions.bodyFontSize ? pdfOptions.bodyFontSize : 10,
          },
        });

        if (exportDataExtra && exportDataExtra.extra) {
          exportDataExtra.extra.forEach(extra =>
            autoTable(doc, {
              head: [extra.title],
              body: reworkData(extra.value, extra.title.length),
              headStyles: {
                fillColor: theme.palette.primary.main,
              }
            })
          )
        }

        // addHeader(doc);
        addHeader(
          doc,
          {
            logoImage,
            MARGIN_H,
            Y_OFFSET,
            PAPER_FORMAT,
            PAPER_ORIENTATION,
            exportDataExtra,
            theme,
            title,
          }
        );
        // addFooter(doc);
        addFooter(
          doc,
          {
            MARGIN_H,
            PAPER_FORMAT,
            PAPER_ORIENTATION
          }
        );

        const base64String = doc.output('datauristring');
        if (standalone) {
          return base64String;
        } else {
          openPDF(base64String, { tabId: title });
        }
      },
    },
    {

      label: t("exportCSV"),
      exportFunc: (cols: Record<string, any>[], data: Record<string, any>[], tableData: Record<string, object[]>) => {
        const sep = '|'
        let csvData = "sep=" + sep + "\n";

        const filteredCols = cols.filter(elem => !elem?.external?.fieldData.exportExclude);

        if (exportCSVCallback) {
          csvData = exportCSVCallback(filteredCols, data, tableData, csvData, sep);
        } else {
          // headers
          filteredCols.forEach((col, ind) => {
            csvData += col.title.replace("\n", " ");
            if (ind !== filteredCols.length - 1) csvData += sep;
            else csvData += "\n";
          });

          // data
          if (tableData.groupedData.length > 0) {
            tableData.groupedData.forEach((value: Record<string, any>) => {
              value['data'].forEach((riga: Record<string, any>, indexRow: number) => {
                return filteredCols.forEach(({ field }, indexCol) => {
                  let val = riga[field];
                  csvData += val ?? ""
                  if (indexCol === filteredCols.length - 1)
                    csvData += "\n"
                  else csvData += sep
                })
              })

            });
          } else {
            data?.forEach((row, ind) => {
              const values = Object.values(row);
              values?.forEach((el, ind) => {
                csvData += el ?? '';
                if (ind !== filteredCols.length - 1) csvData += sep;
              });
              if (ind !== data.length - 1) csvData += "\n";
            });
          }
        }

        var myFile = new File([csvData], title + ".csv", {
          type: "text/csv;charset=utf-8",
        });
        saveAs(myFile);
      },
    },
  ], [exportCSVCallback, exportDataExtra, exportPDFCallback, fixedProps, isExportLandscape, logoImage, pdfOptions, t, theme, title]);

  if (exportMenuRef)
    exportMenuRef.current = exportMenuOptions;

  const options = useMemo(() => {
    return {
      toolbarButtonAlignment: "left" as "left",
      columnsButton: columnsButton,
      exportAllData: true,
      thirdSortClick: false,
      exportMenu: exportType === ExportType.PDF || exportType === ExportType.CSV
        ? [exportMenuOptions[exportType]]
        : exportMenuOptions,
    };
  }, [columnsButton, exportMenuOptions, exportType]);

  return {
    loading,
    materialTableRef,
    localization,
    editable,
    actions,
    options,
    components,
    exportPDF: exportMenuOptions[0].exportFunc,
    exportCSV: exportMenuOptions[1].exportFunc
  };
};
export default useReadOnlyMaterialTable;
