import React from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

interface HttpInterceptorProps {
  header: Record<string, string | null>,
  hasIdAppSoftwareCookie: boolean
}

const useHttpInterceptor = (props: HttpInterceptorProps) => {
  const { header, hasIdAppSoftwareCookie } = props;

  const dispatch = useAppDispatch();

  const token = useAppSelector(state => state.authInfo.token);
  const idProfil = useAppSelector(state => state.authInfo.idProfil);
  const organization = useAppSelector(state => state.authInfo.idOrganization);

  /**
   * Gestisce HTTP Request
   */
  React.useEffect(() => {
    let num: number | null = null;

    if (!hasIdAppSoftwareCookie) return;

    num = Axios.interceptors.request.use((config: AxiosRequestConfig) => {

      if (config && config.headers) {
        if (header['Authorization'])
          config.headers['Authorization'] = header['Authorization'];
        if (header['IdOrganizzazione'])
          config.headers['IdOrganizzazione'] = header['IdOrganizzazione'];
        if (header['lang'])
          config.headers['lang'] = header['lang'];
      }

      return config;
    },
      (error: AxiosError) => Promise.reject((error.request && error.request.data.messaggio) ? error.request.data.messaggio : error.message)
    );

    return () => {
      if (num)
        Axios.interceptors.request.eject(num);
    }
  }, [token, organization, idProfil, dispatch, header, hasIdAppSoftwareCookie]);

  /**
   * Gestisce HTTP Response
   */
  React.useEffect(() => {
    let num: number | null = null;

    if (!hasIdAppSoftwareCookie) return;

    num = Axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      (error: AxiosError) => Promise.reject((error.response && error.response.data.messaggio) ? error.response.data.messaggio : error.message)
    );

    return () => {
      if (num)
        Axios.interceptors.response.eject(num);
    }
  }, [dispatch, hasIdAppSoftwareCookie]);
}

export default useHttpInterceptor