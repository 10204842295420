import {
  Grid,
  Typography
} from "@material-ui/core"
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../../store/hooks";

import { RiepilogoPianificazioneDTO } from "../../../PianificazioniPreventivoW"

interface InfoSoggettoPianificazioneProps {
  currentSelectedAS: RiepilogoPianificazioneDTO;
  currentSelectedDate: Date;
}

const InfoSoggettoPianificazione = ({ currentSelectedAS, currentSelectedDate }: InfoSoggettoPianificazioneProps) => {
  const { t } = useTranslation();
  const lingua = useAppSelector(state => state.guida.lingua);

  return (
    <Grid container>
      {/* Nominativo */}
      <Grid item xs={3}>
        <Typography variant='overline'>{t('nominativeLabel')}</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>{currentSelectedAS.nominativo}</Typography>
      </Grid>

      {/* Qualifica */}
      <Grid item xs={3}>
        <Typography variant='overline'>{t('qualificationLabel')}</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>{currentSelectedAS.qualificaDescrizioneBreve}</Typography>
      </Grid>

      {/* Data */}
      <Grid item xs={3}>
        <Typography variant='overline'>{t('dateLabel')}</Typography>
      </Grid>
      <Grid item xs={9}>
        <Typography>{
          currentSelectedDate?.toLocaleDateString(
            lingua,
            {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
            },
          )
        }</Typography>
      </Grid>
    </Grid>
  )
}

export default InfoSoggettoPianificazione