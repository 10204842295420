import AccountCircle from "@material-ui/icons/AccountCircle";
import { IconButton, Tooltip } from "@material-ui/core";
import { useAppSelector } from "../../../store/hooks";
import { UserSession } from "../../../models/User";


const ButtonUser = () => {
  const utente: UserSession | null = useAppSelector(state => state.authInfo.user);

  return <>
    <Tooltip
      title={
        utente
          ? <div
            style={{
              fontSize: 15,
              padding: 5,
              lineHeight: 2
            }}
          >
            {utente.nomeUtente}<br></br>
            Nominativo : {utente.nome + ' ' + utente.cognome}<br></br>
            Organizzazione : {utente.organizzazioneDescrizione}<br></br>
            Profilo : {utente.profiloDescrizione}<br></br>
          </div>
          : <></>
      }
    >
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        color="inherit"
      >
        <AccountCircle />
      </IconButton>
    </Tooltip>

  </>
}
export default ButtonUser;

