import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getByIds, upd } from '../../services/services.service'
import { elementIdProps, UnitaOperativaFormazione, UnitaOperativaFormazioneKeys } from '../../models/UnitaOperativaFormazione';


const microservice = "unitaoperative";
const entity = "unitaoperativeformazioni";

interface UnitaOperativeFormazioneState {
    statusValidUnitaOperativaFormazioneState: StatusEnum,
    validUnitaOperativaFormazione: UnitaOperativaFormazione[],
    unitaOperativaFormazione: UnitaOperativaFormazione | null,
    error: string | null
}

const initialState: UnitaOperativeFormazioneState = {
    statusValidUnitaOperativaFormazioneState: StatusEnum.Succeeded,
    validUnitaOperativaFormazione: [],
    unitaOperativaFormazione: null,
    error: null
}

export const fetchAllValidByFilter = createAsyncThunk(entity + '/fetchAllValidByFilter', async (obj: { idStruttura: number, idDipartimento: number, idUnitaOperativa: number }) => {
    const response = await getByIds(microservice, entity, ['id', obj.idStruttura, obj.idDipartimento, obj.idUnitaOperativa], ['/fetchAllValidByFilter']);
    return response.data as UnitaOperativaFormazione[];
});

export const insert = createAsyncThunk(entity + '/insert', async (UnitaOperativaCodiceVisite: UnitaOperativaFormazione) => {
    const response = await create(UnitaOperativaCodiceVisite, microservice, entity);
    return response.data as UnitaOperativaFormazione;
});

export const update = createAsyncThunk(entity + '/update', async (UnitaOperativaTurnoNumeroAddetti: UnitaOperativaFormazione) => {
    const response = await upd(UnitaOperativaTurnoNumeroAddetti, microservice, entity);
    return response.data as UnitaOperativaFormazione;
});

export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
    await definitiveDeleteById(ids, microservice, entity);
    return { ids };
});

export const unitaOperativeFormazioneSlice = createSlice({
    name: entity,
    initialState,
    reducers: {

        reset: (state: UnitaOperativeFormazioneState) => {
            return initialState;
        },
        resetError: (state: UnitaOperativeFormazioneState) => {
            state.error = initialState.error;
            state.statusValidUnitaOperativaFormazioneState = initialState.statusValidUnitaOperativaFormazioneState;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchAllValidByFilter.pending, (state) => {
            state.statusValidUnitaOperativaFormazioneState = StatusEnum.Loading;
        })
        builder.addCase(fetchAllValidByFilter.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusValidUnitaOperativaFormazioneState = StatusEnum.Failed;
            state.validUnitaOperativaFormazione = [];
        })
        builder.addCase(fetchAllValidByFilter.fulfilled, (state, { payload }: PayloadAction<UnitaOperativaFormazione[]>) => {
            state.statusValidUnitaOperativaFormazioneState = StatusEnum.Succeeded;
            state.validUnitaOperativaFormazione = payload ?? [];
        })
        builder.addCase(insert.pending, (state) => {
            state.statusValidUnitaOperativaFormazioneState = StatusEnum.Loading;
        })
        builder.addCase(insert.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusValidUnitaOperativaFormazioneState = StatusEnum.Failed;
            state.unitaOperativaFormazione = null;
        })
        builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<UnitaOperativaFormazione>) => {
            state.statusValidUnitaOperativaFormazioneState = StatusEnum.Succeeded;
            state.unitaOperativaFormazione = payload;
            state.validUnitaOperativaFormazione = state.validUnitaOperativaFormazione.concat([payload]);
        })
        builder.addCase(update.pending, (state) => {
            state.statusValidUnitaOperativaFormazioneState = StatusEnum.Loading;
        })
        builder.addCase(update.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusValidUnitaOperativaFormazioneState = StatusEnum.Failed;
            state.unitaOperativaFormazione = null;
        })
        builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<UnitaOperativaFormazione>) => {
            state.statusValidUnitaOperativaFormazioneState = StatusEnum.Succeeded;
            state.validUnitaOperativaFormazione = state.validUnitaOperativaFormazione.map(el => {
                if (elementIdProps.every(prop => el[prop] === payload[prop])) {
                    return { ...payload, version: payload.version + 1 };
                } else return el;
            });
            state.unitaOperativaFormazione = payload;
        })
        builder.addCase(physicalDel.pending, (state) => {
            state.statusValidUnitaOperativaFormazioneState = StatusEnum.Loading;
        })
        builder.addCase(physicalDel.rejected, (state, action) => {
            state.error = (action.error.message) ? action.error.message : "";
            state.statusValidUnitaOperativaFormazioneState = StatusEnum.Failed;
        })
        builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
            state.statusValidUnitaOperativaFormazioneState = StatusEnum.Succeeded;
            state.validUnitaOperativaFormazione = state.validUnitaOperativaFormazione.filter(el => payload.ids.some(idObj => el[idObj.name as UnitaOperativaFormazioneKeys] !== idObj.id));
        })
    }
});

export const { reset, resetError } = unitaOperativeFormazioneSlice.actions;
export default unitaOperativeFormazioneSlice.reducer;