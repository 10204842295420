import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { cancelById, create, definitiveDeleteById, getAllComplete, getAllDeleted, getAllValid, getById, restoreById, upd } from '../../services/services.service'
import { Prescrizioni, elementIdProps, PrescrizioniKeys } from '../../models/Prescrizioni';
import { createLookup } from '../../utils/utilfunctions';

const microservice = "idoneita";
const entity = "prescrizioni";

interface PrescrizioniState {
  statusValidPrescrizioni: StatusEnum,
  validPrescrizioni: Prescrizioni[],
  statusDeletedPrescrizioni: StatusEnum,
  deletedPrescrizioni: Prescrizioni[],
  statusAllPrescrizioni: StatusEnum,
  allPrescrizioni: Prescrizioni[],
  prescrizioni: Prescrizioni | null,
  lookup: Lookup,
  error: string | null
}

const initialState: PrescrizioniState = {
  statusValidPrescrizioni: StatusEnum.Succeeded,
  validPrescrizioni: [],
  statusDeletedPrescrizioni: StatusEnum.Succeeded,
  deletedPrescrizioni: [],
  statusAllPrescrizioni: StatusEnum.Succeeded,
  allPrescrizioni: [],
  prescrizioni: null,
  lookup: {},
  error: null
}

// get cerca/prescrizioni/id/{idPrescrizione}
export const fetchById = createAsyncThunk(entity + '/fetchById', async (ids: Ids<string>[]) => {
  const args = ids.map(elem => elem.id)

  const response = await getById(microservice, entity, args);
  return response.data as Prescrizioni;
});

// get cerca/prescrizioni/all/completa
export const fetchAll = createAsyncThunk(entity + '/fetchAll', async () => {
  const response = await getAllComplete(microservice, entity);
  return response.data as Prescrizioni[];
});

// get cerca/prescrizioni/all/valid
export const fetchAllValid = createAsyncThunk(entity + '/fetchAllValid', async () => {
  const response = await getAllValid(microservice, entity, ['/fetchAllValid']);
  return response.data as Prescrizioni[];
});

// get cerca/prescrizioni/all/deleted
export const fetchAllDeleted = createAsyncThunk(entity + '/fetchAllDeleted', async () => {
  const response = await getAllDeleted(microservice, entity);
  return response.data as Prescrizioni[];
});

// post inserisci/prescrizioni
export const insert = createAsyncThunk(entity + '/insert', async (prescrizioni: Prescrizioni) => {
  const response = await create(prescrizioni, microservice, entity);
  return response.data as Prescrizioni;
});

// put modifica/prescrizioni
export const update = createAsyncThunk(entity + '/update', async (prescrizioni: Prescrizioni) => {
  const response = await upd(prescrizioni, microservice, entity);
  return response.data as Prescrizioni;
});

// put elimina/prescrizioni/id/{idPrescrizione}
export const logicalDel = createAsyncThunk(entity + '/logicalDelete', async (ids: Ids<string>[]) => {
  await cancelById(ids, microservice, entity);
  return { ids };
});

// put restore/prescrizioni/id/{idPrescrizione}
export const restore = createAsyncThunk(entity + '/restore', async (ids: Ids<string>[]) => {
  await restoreById(ids, microservice, entity);
  return { ids };
});

// delete delete/prescrizioni/id/{idPrescrizione}
export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const prescrizioniSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
    resetError: (state: PrescrizioniState) => {
      state.error = initialState.error;
      state.statusValidPrescrizioni = initialState.statusValidPrescrizioni;
      state.statusDeletedPrescrizioni = initialState.statusDeletedPrescrizioni;
      state.statusAllPrescrizioni = initialState.statusAllPrescrizioni;
    }
  },
  extraReducers: builder => {
    // fetchById
    builder.addCase(fetchById.pending, (state) => {
      state.statusValidPrescrizioni = StatusEnum.Loading;
    })
    builder.addCase(fetchById.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPrescrizioni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidPrescrizioni = StatusEnum.Failed;
      }
      state.prescrizioni = null;
    })
    builder.addCase(fetchById.fulfilled, (state, { payload }: PayloadAction<Prescrizioni>) => {
      state.statusValidPrescrizioni = StatusEnum.Succeeded;
      state.prescrizioni = payload;
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusAllPrescrizioni = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusAllPrescrizioni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusAllPrescrizioni = StatusEnum.Failed;
      }
      state.lookup = {};
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<Prescrizioni[]>) => {
      state.statusAllPrescrizioni = StatusEnum.Succeeded;
      state.lookup = createLookup(payload, 'idPrescrizione', ['descrizione']);
    })

    // fetchAllValid
    builder.addCase(fetchAllValid.pending, (state) => {
      state.statusValidPrescrizioni = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValid.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPrescrizioni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidPrescrizioni = StatusEnum.Failed;
      }
      state.validPrescrizioni = [];
    })
    builder.addCase(fetchAllValid.fulfilled, (state, { payload }: PayloadAction<Prescrizioni[]>) => {
      state.statusValidPrescrizioni = StatusEnum.Succeeded;
      state.validPrescrizioni = payload ?? [];

      const _lookup: Record<string, string> = {};
      payload?.forEach(pres => {
        const val = [pres.descrizioneBreve, pres.descrizione].join(' - ');
        _lookup[val] = val;
      })
      state.lookup = _lookup;
    })

    // fetchAllDeleted
    builder.addCase(fetchAllDeleted.pending, (state) => {
      state.statusDeletedPrescrizioni = StatusEnum.Loading;
    })
    builder.addCase(fetchAllDeleted.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedPrescrizioni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedPrescrizioni = StatusEnum.Failed;
      }
      state.deletedPrescrizioni = [];
    })
    builder.addCase(fetchAllDeleted.fulfilled, (state, { payload }: PayloadAction<Prescrizioni[]>) => {
      state.statusDeletedPrescrizioni = StatusEnum.Succeeded;
      state.deletedPrescrizioni = payload ?? [];
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidPrescrizioni = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPrescrizioni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidPrescrizioni = StatusEnum.Failed;
      }
      state.prescrizioni = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<Prescrizioni>) => {
      state.statusValidPrescrizioni = StatusEnum.Succeeded;
      state.prescrizioni = payload;
      state.validPrescrizioni = state.validPrescrizioni.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidPrescrizioni = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPrescrizioni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidPrescrizioni = StatusEnum.Failed;
      }
      state.prescrizioni = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<Prescrizioni>) => {
      state.statusValidPrescrizioni = StatusEnum.Succeeded;
      state.validPrescrizioni = state.validPrescrizioni.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.prescrizioni = payload;
    })

    // logicalDel
    builder.addCase(logicalDel.pending, (state) => {
      state.statusValidPrescrizioni = StatusEnum.Loading;
    })
    builder.addCase(logicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusValidPrescrizioni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusValidPrescrizioni = StatusEnum.Failed;
      }
    })
    builder.addCase(logicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidPrescrizioni = StatusEnum.Succeeded;
      const deleted = state.validPrescrizioni.find(el => payload.ids.every(idObj => el[idObj.name as PrescrizioniKeys] === idObj.id))
      if (deleted) {
        deleted.version += 1;
        state.deletedPrescrizioni = state.deletedPrescrizioni.concat([deleted]);
      }
      state.validPrescrizioni = state.validPrescrizioni.filter(el => payload.ids.some(idObj => el[idObj.name as PrescrizioniKeys] !== idObj.id));
    })

    // restore
    builder.addCase(restore.pending, (state) => {
      state.statusDeletedPrescrizioni = StatusEnum.Loading;
    })
    builder.addCase(restore.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedPrescrizioni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedPrescrizioni = StatusEnum.Failed;
      }
    })
    builder.addCase(restore.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedPrescrizioni = StatusEnum.Succeeded;
      const valid = state.deletedPrescrizioni.find(el => payload.ids.every(idObj => el[idObj.name as PrescrizioniKeys] === idObj.id));
      if (valid) {
        valid.version += 1;
        state.validPrescrizioni = state.validPrescrizioni.concat([valid]);
      }
      state.deletedPrescrizioni = state.deletedPrescrizioni.filter(el => payload.ids.some(idObj => el[idObj.name as PrescrizioniKeys] !== idObj.id));
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusDeletedPrescrizioni = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      if (action.error.message === 'canceled') {
        state.error = null;
        state.statusDeletedPrescrizioni = StatusEnum.Loading;
      } else {
        state.error = (action.error.message) ? action.error.message : "";
        state.statusDeletedPrescrizioni = StatusEnum.Failed;
      }
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusDeletedPrescrizioni = StatusEnum.Succeeded;
      state.deletedPrescrizioni = state.deletedPrescrizioni.filter(el => payload.ids.some(idObj => el[idObj.name as PrescrizioniKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError } = prescrizioniSlice.actions;
export default prescrizioniSlice.reducer;