import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CheckJWTResponse, TokenResponse } from '../../models/AuthModels';
import { OrganizationInfo } from '../../models/Organizzazione';
import { StatusEnum } from '../../models/Utils';
import { checkJWT, getOrganizationByID, refreshToken } from '../../services/authentication.service';
import { cookieAppPathName, cookieHome, cookieOrg, cookiePortal, cookieProfil, cookieSkinTheme, cookieUser, secondLevelDomain, sessionAppPathName, sessionLogo, sessionOrg, sessionProfil, sessionSkinTheme, sessionUser } from '../../utils/utilconst';
import { deleteCookie, getCookie, getTokenFromCookie, setCookie } from '../../utils/utilfunctions';
import { RootState } from '../store';
import { UserSession } from '../../models/User';

interface AuthInfoState {
  status: string,
  token: string | null,
  idOrganization: string | null,
  organizationName: string | null,
  logoUri: string | null,
  idProfil: string[] | null,
  domain: string | undefined,
  user: UserSession | null,
  skinTheme: string,
  appPathName: string | null,
  home: string,
  expire: number,
  error: string | null,
}

const _sessionProfil = sessionStorage.getItem(sessionProfil)

const initialState: AuthInfoState = {
  status: StatusEnum.Succeeded,
  token: getTokenFromCookie() ? getTokenFromCookie() : null,
  idOrganization: sessionStorage.getItem(sessionOrg),
  organizationName: null,
  logoUri: null,
  idProfil: _sessionProfil ? JSON.parse(_sessionProfil) : null,
  domain: secondLevelDomain,
  user: null,
  skinTheme: 'asl',
  appPathName: null,
  home: getCookie(cookieHome) ?? '',
  expire: 60 * 60 * 1000,
  error: null
}

export const fetchRefreshToken = createAsyncThunk('authInfo/refreshToken', async () => {
  const response = await refreshToken();
  const respData = response.data as TokenResponse;
  return respData.token;
})

export const checkJsonWebToken = createAsyncThunk('authInfo/checkJsonWebToken', async (jwt: string) => {
  const response = await checkJWT(jwt);
  const respData = response.data as CheckJWTResponse;
  return { isValid: respData.isValid, token: jwt };
})

export const fetchOrganizzazioneInfo = createAsyncThunk('authInfo/fetchOrganizzazioneInfo', async (obj: { IDOrg: number, token: string }) => {
  const response = await getOrganizationByID({ IDOrg: obj.IDOrg, token: obj.token });
  return response.data as OrganizationInfo;
})

export const authInfoSlice = createSlice({
  name: 'authInfo',
  initialState,
  reducers: {
    logoutAction: state => {
      removeAllSessionData();
      state.status = StatusEnum.Succeeded;
      state.idOrganization = null;
      state.idProfil = null;
      state.appPathName = null;
      state.skinTheme = initialState.skinTheme;
      state.home = initialState.home;
      state.user = null;
      state.token = null;
      state.logoUri = null;
      state.error = null;
    },
    autoLoginAction: (state, { payload }: PayloadAction<string>) => {
      state.status = StatusEnum.Succeeded;
      state.token = payload;
      state.error = null;
    },
    setSessionOrganizationAction: state => {
      let idOrg = getCookie(cookieOrg);

      if (idOrg) window.sessionStorage.setItem(sessionOrg, idOrg);
      else idOrg = window.sessionStorage.getItem(sessionOrg)

      state.idOrganization = idOrg ? JSON.parse(idOrg) : null;
      deleteCookie(cookieOrg, state.domain);
      if (idOrg == null) goto(state.home);
    },
    setSessionProfilAction: state => {
      let profil = getCookie(cookieProfil)

      if (profil) window.sessionStorage.setItem(sessionProfil, profil);
      else profil = window.sessionStorage.getItem(sessionProfil)

      state.idProfil = profil ? JSON.parse(profil) : null;
      deleteCookie(cookieProfil, state.domain);
      if (profil == null) goto(state.home);
    },
    setSessionAppPathNameAction: state => {
      let appPathName = getCookie(cookieAppPathName);

      if (appPathName) window.sessionStorage.setItem(sessionAppPathName, appPathName);
      else appPathName = window.sessionStorage.getItem(sessionAppPathName)

      state.appPathName = appPathName ?? initialState.appPathName;
      deleteCookie(cookieAppPathName, state.domain);
      if (appPathName == null) goto(state.home);
    },
    setSessionSkinThemeAction: state => {
      let skinTheme = getCookie(cookieSkinTheme);

      if (skinTheme) window.sessionStorage.setItem(sessionSkinTheme, skinTheme);
      else skinTheme = window.sessionStorage.getItem(sessionSkinTheme)

      state.skinTheme = skinTheme ?? initialState.skinTheme;
      deleteCookie(cookieSkinTheme, state.domain);
      // if (skinTheme == null) goto(state.home);
    },
    setSessionUserAction: state => {
      let user = getCookie(cookieUser);

      if (user) window.sessionStorage.setItem(sessionUser, user);
      else user = window.sessionStorage.getItem(sessionUser)

      state.user = user ? JSON.parse(user) : null;
      deleteCookie(cookieUser, state.domain);
      if (user == null) goto(state.home);
    },
    setLogoUri: (state, { payload }: PayloadAction<string>) => {
      sessionStorage.setItem(sessionLogo, payload);
      state.logoUri = payload;
      if (payload == null) goto(state.home);
    },
    setToken: (state, { payload }: PayloadAction<string | null>) => {
      if (state.token !== payload)
        state.token = payload;
    },
    removeSessionData: () => removeAllSessionData()
  },
  extraReducers: builder => {
    builder.addCase(fetchOrganizzazioneInfo.pending, (state) => {
      state.status = StatusEnum.Loading;
    })
    builder.addCase(fetchOrganizzazioneInfo.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.status = StatusEnum.Failed;
      sessionStorage.removeItem(sessionLogo);
      state.organizationName = null;
      state.logoUri = null;
    })
    builder.addCase(fetchOrganizzazioneInfo.fulfilled, (state, { payload }: PayloadAction<OrganizationInfo>) => {
      state.status = StatusEnum.Succeeded;
      sessionStorage.setItem(sessionLogo, payload.logoOrganizzazione);
      state.organizationName = payload.nome;
      state.logoUri = payload.logoOrganizzazione;
    })
    builder.addCase(fetchRefreshToken.pending, (state) => {
      state.status = StatusEnum.Loading;
    })
    builder.addCase(fetchRefreshToken.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.status = StatusEnum.Failed;
      state.token = null;
      deleteCookie(cookiePortal, state.domain);
    })
    builder.addCase(fetchRefreshToken.fulfilled, (state, { payload }: PayloadAction<string>) => {
      setCookie(cookiePortal, payload, state.domain, state.expire);
      state.status = StatusEnum.Succeeded;
      state.token = payload;
    })
    builder.addCase(checkJsonWebToken.pending, (state) => {
      state.status = StatusEnum.Loading;
    })
    builder.addCase(checkJsonWebToken.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.status = StatusEnum.Failed;
      state.token = null;
      deleteCookie(cookiePortal, state.domain);
    })
    builder.addCase(checkJsonWebToken.fulfilled, (state, { payload }: PayloadAction<{ isValid: boolean, token: string }>) => {
      if (payload && payload.isValid) {
        state.status = StatusEnum.Succeeded;
        state.token = payload.token;
      } else {
        state.status = StatusEnum.Failed;
        state.token = null;
        deleteCookie(cookiePortal, state.domain);
      }
    })
  }
})

export const { logoutAction, autoLoginAction, setLogoUri, setToken, removeSessionData,
  setSessionOrganizationAction, setSessionProfilAction, setSessionAppPathNameAction, setSessionSkinThemeAction, setSessionUserAction } = authInfoSlice.actions

export const isLoadingAuth = (state: RootState) => state.authInfo.status === StatusEnum.Loading
export const isLogged = (state: RootState) => state.authInfo.token !== null

export default authInfoSlice.reducer;

function goto(home: string) {
  window.location.href = home;
}

function removeAllSessionData() {
  sessionStorage.clear();
}