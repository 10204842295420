import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { View, Text } from 'react-pdf-renderer-v2';
import { AnagraficaSoggettoExtended } from '../../../../../models/AnagraficaSoggetti';
import { FieldType } from '../../../../../models/Fields';
import styles from './styles/shared';
import { defaultValue, filterGroup, Group, renderData } from './utils/utils';

interface UnitaOperativaSectionProps {
  unitaOperativa: AnagraficaSoggettoExtended
}

const groups: Group = {
  noGroup: {
    dataAssegnazione: ['assignDateParam', 'date'],
    unitaOperativaNome: ['operationalUnitParam', 'date'],
    qualificaIncaricoDescrizione: ['jobTitleParam', 'string'],
    motivoVariazioneUnitaOperativa: ['variationReasonParam', 'string'],
  },
}

const UnitaOperativaSection = (props: UnitaOperativaSectionProps) => {
  const { unitaOperativa } = props;

  const { t } = useTranslation();

  const [noGroup, setNoGroup] = useState({});

  useEffect(() => {
    setNoGroup(filterGroup(groups.noGroup, unitaOperativa))
  }, [unitaOperativa]);

  return (
    <View wrap={false}>

      {/* No group */}
      {
        Object.values(noGroup).some(elem => elem !== defaultValue) &&
        <View style={[styles.view, styles.viewWithGroup]}>
          <Text style={styles.groupTitlemax}>{t('operationalUnitParam')}</Text>
          {
            Object.entries(noGroup).map(([attr, value], index) => {
              const [key, dataType] = attr.split(',') as [string, FieldType];
              const translationKey = groups.noGroup[key][0];

              return (
                <View
                  key={'no-group-' + index}
                  style={styles.flexBox}
                >

                  <Text style={styles.col1}>{t(translationKey)}</Text>
                  <Text style={styles.col2}>{renderData(value, dataType, t)}</Text>
                </View>
              )
            })
          }
        </View>
      }
    </View>
  )
};

export default UnitaOperativaSection;