import { Column } from "@material-table/core";
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  pianificazioniPath
} from "../../../utils/utilconst";
import {
  AppBar,
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
  Tabs,
  Tab,
  Button,
  Paper,
  Tooltip,
} from "@material-ui/core";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { addMonths, getDaysInMonth, isAfter, isBefore, isEqual, setDate } from "date-fns";
import {
  fetchAllValid as fetchArticoliLeggeLookup,
  reset as resetArticoliLegge,
  resetError as resetErrorArticoliLegge
} from "../../../store/slices/articoliLeggeSlice";
import {
  fetchAllValid as fetchAssenzeLookup,
  reset as resetAssenze,
  resetError as resetErrorAssenze
} from "../../../store/slices/assenzeSlice";
import {
  lookup as fetchStruttureLookup,
  reset as resetStrutture,
  resetError as resetErrorStrutture
} from "../../../store/slices/struttureSlice";
import {
  lookupFiltered as fetchDipartimentiLookup,
  cleanLookup as cleanDipartimentiLookup,
  reset as resetDipartimentiFiltered,
  resetError as resetErrorDipartimentiFiltered
} from "../../../store/slices/dipartimentiFilteredSlice";
import {
  lookupFiltered as fetchUnitaOperativeLookup,
  cleanLookup as cleanUOLookup,
  reset as resetUnitaOperativeFiltered,
  resetError as resetErrorUnitaOperativeFiltered
} from "../../../store/slices/unitaOperativeFilteredSlice";
import {
  fetchAllValid as fetchTurniLookup,
  reset as resetTurni,
  resetError as resetErrorTurni
} from '../../../store/slices/turniSlice';
import {
  fetchAllValid as fetchQualificaLookup,
  reset as resetQualifiche,
  resetError as resetErrorQualifiche
} from '../../../store/slices/qualificheSlice';
import {
  fetchByYearAndMonth,
  update as reopenPianificazione,
  insert as saveNuovaPianificazione,
  reset as resetPianificazione,
  resetError as resetErrorPianificazioni,
  setIsDirty,
} from "../../../store/slices/pianificazioniSlice";
import {
  lookupByUnitaOperativa as fetchLookupByUO,
  reset as resetAnagraficaSoggettiUnitaOperative,
  resetError as resetErrorAnagraficaSoggettiUnitaOperative
} from '../../../store/slices/anagraficaSoggettiUnitaOperativeSlice';
import {
  reset as resetAnagrificaSoggetti,
  resetError as resetErrorAnagraficaSoggetti
} from '../../../store/slices/anagraficaSoggettiSlice';

import { useHistory, Switch, Route, Redirect } from "react-router-dom";
import { componentTabsPath } from "../../../utils/innerFuncPaths";
import { Fields } from "../../../models/Fields";
import { daysInMonth, createLookup, getDateYYYYMMDD, getDateYYYYMMDD_BackEnd, getDateDDMMYYYY, getTodayEnd, regroupData } from '../../../utils/utilfunctions';
import { Colors, ColorsKeys, DimensioneFogli, MonthsList, WeekDays, WeekList } from '../../../utils/utildata';

import AnagraficaSoggettiList, { AS } from './pianificazioniComponents/AnagraficaSoggettiList/AnagraficaSoggettiList';
import Calendar from './pianificazioniComponents/Calendar/Calendar';
import i18n from "../../../i18n";
import { it, enGB } from "date-fns/locale";
import ReadOnlyMaterialTable from "../tables/ReadOnly/ReadOnlyMaterialTable";
import {
  allFieldsAnagraficaSoggetto as allFieldsPianificazioneAS,
  allFieldsQualifica as allFieldsPianificazioneQualifica,
  allFieldsStatoPianificazione,
  Pianificazione,
  PianificazioneKeys,
  PianificazioneQualifica,
  PianificazioneQualificaKeys,
  PianificazioniAnagraficaSoggetti,
} from "../../../models/Pianificazioni";
import { IdsConfig, PDFExtraData, PDFOptions, StatusEnum, } from "../../../models/Utils";
import GeneralForm from "../forms/GeneralForm";
import { calculateMTableHeight } from "../../../utils/styleconst";
import { ClassNameMap } from "@material-ui/styles";
import LoadingSvg from "../svgs/LoadingSvg";
import TabPanel from "../tabs/TabPanel/TabPanel";
import { fetchAllValidByFilter as fetchAllValidTurniAddetti } from "../../../store/slices/unitaOperativeTurniNumeroAddettiSlice";
import useCalendar from "./pianificazioniComponents/Calendar/useCalendar";
import InsertTurniModal from "./pianificazioniComponents/Calendar/InsertTurniModal";
import { fetchAllValidById as fetchTurniAbilitati } from '../../../store/slices/anagraficaSoggettiTurnoAbilitatoSlice';
import jsPDF from "jspdf";
import autoTable, { CellDef, RowInput } from "jspdf-autotable";
import { CellDefExt, Extra, FixedProps, TableData, TPdfCallback } from "../../../utils/data.types";
import usePianificazione from "../../hooks/usePianificazione";

type TipoInserimento = 'Manuale' | 'Calcolato' | null;

const NO_PRINT_SUFFIX = '_noprint';
const ERROR_VALUE = -1;
const festiviNormali = [WeekDays.SUNDAY];
const today = new Date();

function isUrlOnUpdate(url: string) {
  return url === pianificazioniPath + componentTabsPath;
}

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    h55: {
      height: 55
    },
    hCell: {
      height: 120
    },
    flexGrow: {
      flexGrow: 1,
    },
    appbar: {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
    indicator: {
      backgroundColor: "#fff",
    },
    warning: {
      color: "#d00",
      fontWeight: "bold"
    },
    hoverCell: {
      '&:hover': {
        filter: 'brightness(0.9)'
      }
    },
    cell: {
      '&:hover': {
        filter: 'brightness(0.9)',
        boxShadow: '0 4px 8px 0 rgba(0, 255, 0, 0.2), 0 6px 20px 0 rgba(0, 255, 0, 0.19)'
      },
      cursor: 'pointer'
    },
    defaultCursor: {
      '&:hover': {
        filter: 'brightness(0.9)',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
      },
      cursor: 'default'
    }
  }),
);

interface StatoPianificazione {
  oreLavorativeMese: number,
  numeroRiposiMese: number,
  stato: string,
}

export interface IdsConfigExtended extends IdsConfig {
  isTurno: boolean;
  reperibilita?: boolean;
  uoEsterna?: boolean;
  tipoInserimento?: TipoInserimento;
  pianificazione?: Pianificazione
}

export interface RiepilogoPianificazioneDTO {
  idStruttura: number;           // contiene idStruttura e vuoto nelle righe del riepilogo di qualifica
  idDipartimento?: number;
  idUnitaOperativa?: number;
  idAnagraficaSoggetto: number;  // contiene idAnagraficaSoggetto e vuoto nelle righe del riepilogo di qualifica
  idQualifica: number;
  qualificaDescrizione?: string;
  qualificaDescrizioneBreve: string,
  codiceTurno: string;
  coloreCodiceTurno?: string;
  nominativo: string;             // contiene nominativo e turnoDescrizione
  cognome: string;
  nome: string;
  combinazioneTurni?: string;
  turniObj: IdsConfigExtended[][],
  quantita: string[],
  qualificheIds: number[][],
  coloreAnagraficaSoggetto: 'Verde' | 'Giallo' | 'Rosso' | 'Bianco',
  coloreQualifica: Array<'Verde' | 'Giallo' | 'Rosso' | 'Arancione' | 'Bianco'>,
  oreTotale?: number;
  minutiTotale?: number;
  oreTotalePresenza?: number;
  minutiTotalePresenza?: number;
  oreTotaleAssenza?: number;
  minutiTotaleAssenza?: number;
  numeroRiposi?: number;
  turnoFisso: boolean;
  modificaInizioTurnoFissoCodiceTurno: boolean;
  numeroLegaleUnitaOperativa: number[];
  numeroAddettiUnitaOperativa: number[];
  numeroReperibilitaRichieste: number[];
  tipoInserimento: TipoInserimento[];        // Nel caso di Riepilogo qualifica, il valore è null
  uoEsterna: (boolean | null)[];
  tooltipDati: (Record<string, any> | null)[];
  statisticaTurni: number[];
  pianificazioneAnagraficaSoggetti?: PianificazioniAnagraficaSoggetti
}

export interface RiepilogoPianificazioneQualificheDTO {
  idQualifica: number;
  qualificaDescrizione: string;
  qualificaDescrizioneBreve: string;
  turnoDescrizione: string;
  quantita: string[];

  colore: Array<'Verde' | 'Giallo' | 'Rosso' | 'Arancione'>;
}

export interface MappaTurniOre {
  [turno: string]: {
    ore: number,
    minuti: number
  };
}

enum TabIndex {
  ANAGRAFICA,
  QUALIFICA,
  MODIFICA,
}

const PianificazioniPreventivoW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector((state) => state.authInfo.logoUri);
  const classes = useStyles1();

  const exportMenuRef = useRef<Record<'exportFunc', TPdfCallback>[]>([]);

  const [selectedMonth, setSelectedMonth] = useState<Date | null>(new Date());
  const [selectedAnagraficaSoggetto, setSelectedAnagraficaSoggetto] = useState<number>(ERROR_VALUE);

  const strSelectName = useMemo(() => t("structuresTitle"), [t]);
  const strSelectLabel = useMemo(() => t("structureSelect"), [t]);
  const dipSelectName = useMemo(() => t("departmentsTitle"), [t]);
  const dipSelectLabel = useMemo(() => t("departmentSelect"), [t]);
  const unitOpSelectName = useMemo(() => t("operatingUnitTitle"), [t]);
  const unitOpSelectLabel = useMemo(() => t("operatingUnitSelect"), [t]);
  const monthYearSelectName = useMemo(() => t("dateTitle"), [t]);
  const monthYearSelectLabel = useMemo(() => t("monthSelect"), [t]);

  const [savedClicked, setSavedClicked] = useState<boolean>(false);
  const [approvedClicked, setApprovedClicked] = useState<boolean>(false);

  const [states, setStates] = useState<{
    [selectName: string]: number | string | null;
  }>({
    [strSelectName]: null,
    [dipSelectName]: null,
    [unitOpSelectName]: null,
    [monthYearSelectName]: getDateYYYYMMDD_BackEnd(new Date(), 'START'),
  });

  const fixedProps = useMemo(() => {
    const _monthSelected = states[monthYearSelectName];

    const selectedDate: Date | null = _monthSelected ? new Date(_monthSelected) : null;

    return {
      idStruttura: states[strSelectName] as number,
      idDipartimento: states[dipSelectName] as number,
      idUnitaOperativa: states[unitOpSelectName] as number,
      anno: selectedDate ? selectedDate.getFullYear() : ERROR_VALUE,
      mese: selectedDate ? selectedDate.getMonth() + 1 : ERROR_VALUE,   // month starts from 1
    };
  }, [states, strSelectName, dipSelectName, unitOpSelectName, monthYearSelectName]);

  const idAnagraficaSoggettoConst = "idAnagraficaSoggetto";
  const idTurnoAssenzaConst = "idTurnoAssenza";
  const idArticoloLeggeConst = "idArticoloLegge";
  const idTurnoConst = "idTurno";
  const idQualificaConst = "idQualifica";

  const lookupStr = useAppSelector((state) => state.strutture.lookup);
  const lookupDip = useAppSelector((state) => state.dipartimentiFiltered.lookup);
  const lookupUnitOp = useAppSelector((state) => state.unitaOperativeFiltered.lookup);
  const lookupTurnoAssenza = useAppSelector((state) => state.assenze.lookupAssenzaProgrammata);
  const lookupTurni = useAppSelector(state => state.turni.lookupDescrizione);
  const lookupQualificheDescrizione = useAppSelector(state => state.qualifiche.lookupDescrizione);
  const lookupQualificheDescrizioneBreve = useAppSelector(state => state.qualifiche.lookupDescrizioneBreve);
  const _lookupAnagraficaSoggettoPerUnitaOperativa = useAppSelector((state) => state.anagraficaSoggettiUnitaOperative.lookup);

  const lookupAnagraficaSoggettoPerUnitaOperativa = useMemo(() => {
    return { ..._lookupAnagraficaSoggettoPerUnitaOperativa }
  }, [_lookupAnagraficaSoggettoPerUnitaOperativa]);

  const lookupArticoloLeggeTemp = useAppSelector((state) => state.articoliLegge.lookup);
  const lookupArticoloLegge = useMemo(() => {
    return { null: t("fieldNullSelect"), ...lookupArticoloLeggeTemp };
  }, [lookupArticoloLeggeTemp, t]);

  const qualificheAbilitateFiltered = useAppSelector(state => state.anagraficaSoggettiQualifica.qualificheAbilitate);
  const turniAbilitatiFiltered = useAppSelector(state => state.anagraficaSoggettiTurnoAbilitato.turniAbilitati);
  const validAssenze = useAppSelector(state => state.assenze.validAssenze);

  const validUnitaOperativaPianificazione = useAppSelector(state => state.pianificazione.validUnitaOperativaPianificazioni);
  const validPianificazione = useAppSelector(state => state.pianificazione.validPianificazioni);
  const validPianificazioneQualifiche = useAppSelector(state => state.pianificazione.validPianificazioniQualifiche);
  const validPianificazioneAnagraficaSoggetti = useAppSelector(state => state.pianificazione.validPianificazioniAnagraficaSoggetti);
  const initPianificazione = useAppSelector(state => state.pianificazione.initPianificazione);
  const salvato = useAppSelector(state => state.pianificazione.saved);

  const statoPianificazioneTranslation = useAppSelector(state => state.lookup.statoPianificazioneTranslation);
  const statoPianificazioneMap = useAppSelector(state => state.lookup.statoPianificazioneMap);

  const [currentStatoPianificazione, setCurrentStatoPianificazione] = useState<StatoPianificazione>();
  const [currentValidPianificazione, setCurrentValidPianificazione] = useState<RiepilogoPianificazioneDTO[]>([]);
  const [currentValidPianificazioneQualifica, setCurrentValidPianificazioneQualifica] = useState<RiepilogoPianificazioneQualificheDTO[]>([]);
  const [currentSelectedAS, setCurrentSelectedAS] = useState<RiepilogoPianificazioneDTO | null>(null);

  const [dateFestivi, setDateFestivi] = useState<number[]>([]);

  // stati del buttone 'Approva'
  const [approvato, setApprovato] = useState<boolean>(false);

  useEffect(() => {
    setApprovato(validUnitaOperativaPianificazione?.stato === statoPianificazioneMap.APPROVATO);
  }, [statoPianificazioneMap.APPROVATO, validUnitaOperativaPianificazione?.stato]);

  // lista delle anagrafiche nella pianificazione
  const [listAS, setListAS] = useState<AS[]>([]);

  const [serializedInitPianificazione, setSerializedInitPianificazione] = useState<string>('');
  useEffect(() => {
    setSerializedInitPianificazione(JSON.stringify(initPianificazione));
  }, [initPianificazione]);

  // aggiorna lo stato della pianificazione ad ogni modifica sul calendario
  useEffect(() => {
    setCurrentStatoPianificazione(state => {
      if (validUnitaOperativaPianificazione?.stato) {
        return {
          numeroRiposiMese: validUnitaOperativaPianificazione?.numeroRiposiMese,
          oreLavorativeMese: validUnitaOperativaPianificazione?.oreLavorativeMese,
          stato: validUnitaOperativaPianificazione.stato,
        } as StatoPianificazione;
      }

      return state
    })
  }, [statoPianificazioneTranslation, t, validUnitaOperativaPianificazione?.numeroRiposiMese, validUnitaOperativaPianificazione?.oreLavorativeMese, validUnitaOperativaPianificazione?.stato]);

  /**
   * Tabs
   */
  const [tabValue, setTabValue] = useState<TabIndex>(TabIndex.ANAGRAFICA);
  const handleTabIndexChange = (event: React.ChangeEvent<{}>, newValue: TabIndex) => {
    setTabValue(newValue);
    setCurrentSelectedAS(null);
  };
  const handleTabChange = (index: TabIndex) => {
    return {
      id: 'tab-' + index,
      'aria-controls': 'tabpanel-' + index,
    };
  }

  /**
   * resettare selected AS quando cambia tab
   * per non mostrare il calendario se non
   * è stato selezionato un soggetto in tab di modifica
   */
  useEffect(() => {
    if ([TabIndex.ANAGRAFICA, TabIndex.QUALIFICA].includes(tabValue)) {
      setSelectedAnagraficaSoggetto(ERROR_VALUE)
    }
  }, [tabValue]);

  /**
   * Initial API calls
   */
  useEffect(() => {
    dispatch(fetchStruttureLookup());
    dispatch(fetchAssenzeLookup());
    dispatch(fetchArticoliLeggeLookup());
    dispatch(fetchTurniLookup());
    dispatch(fetchQualificaLookup());
  }, [dispatch]);

  const resetError = useCallback(() => {
    dispatch(resetErrorArticoliLegge());
    dispatch(resetErrorAssenze());
    dispatch(resetErrorStrutture());
    dispatch(resetErrorDipartimentiFiltered());
    dispatch(resetErrorUnitaOperativeFiltered());
    dispatch(resetErrorTurni());
    dispatch(resetErrorQualifiche());
    dispatch(resetErrorPianificazioni());
    dispatch(resetErrorAnagraficaSoggettiUnitaOperative());
    dispatch(resetErrorAnagraficaSoggetti());
  }, [dispatch]);

  useEffect(() => {
    resetError()
  }, [resetError, tabValue]);

  const [isFixedPropsChanged, setIsFixedPropsChanged] = useState(false);
  useEffect(() => {
    setIsFixedPropsChanged(true);
  }, [fixedProps.idStruttura, fixedProps.idDipartimento, fixedProps.idUnitaOperativa, fixedProps]);

  /**
   * Ottiene i riepiloghi per pianificazioneAnagraficaSoggetti e pianificazioneQualifiche
   */
  useEffect(() => {
    if (validPianificazione && ERROR_VALUE !== fixedProps.mese && ERROR_VALUE !== fixedProps.anno)
      setCurrentValidPianificazione(
        createRiepilogoDatiAnagraficaSoggetto(
          validPianificazione,
          validPianificazioneAnagraficaSoggetti,
          validPianificazioneQualifiche,
          fixedProps.mese,
          fixedProps.anno,
          tabValue,
          fixedProps
        )
      );

    if (validPianificazioneQualifiche && ERROR_VALUE !== fixedProps.mese && ERROR_VALUE !== fixedProps.anno)
      setCurrentValidPianificazioneQualifica(createRiepilogoDatiQualifica(validPianificazioneQualifiche, fixedProps.mese, fixedProps.anno));
  }, [fixedProps, fixedProps.anno, fixedProps.mese, tabValue, validPianificazione, validPianificazioneAnagraficaSoggetti, validPianificazioneQualifiche]);

  useEffect(() => {
    let _dateFestivi = validPianificazione
      ?.filter(elem => elem.giornoFestivo || festiviNormali.includes(new Date(elem.pianificazioneData).getDay()))
      .map(elem => new Date(elem.pianificazioneData).getDate());

    _dateFestivi = [...new Set(_dateFestivi ?? [])];

    setDateFestivi(_dateFestivi);
  }, [validPianificazione]);

  /**
   * Data fetch
   */
  useEffect(() => {
    if (fixedProps.idStruttura && fixedProps.idDipartimento && fixedProps.idUnitaOperativa && ERROR_VALUE !== fixedProps.anno && ERROR_VALUE !== fixedProps.mese && isFixedPropsChanged) {
      setListAS([]);
      setTabValue(TabIndex.ANAGRAFICA);
      dispatch(resetPianificazione());

      dispatch(fetchByYearAndMonth({
        idStruttura: Number(fixedProps.idStruttura),
        idDipartimento: Number(fixedProps.idDipartimento),
        idUnitaOperativa: Number(fixedProps.idUnitaOperativa),
        anno: fixedProps.anno,
        mese: fixedProps.mese,
        soloEsistenti: false,
        isPreventivo: true
      }));
    }
    isFixedPropsChanged && setIsFixedPropsChanged(false);
  }, [dispatch, fixedProps.anno, fixedProps.idDipartimento, fixedProps.idStruttura, fixedProps.idUnitaOperativa, fixedProps.mese, isFixedPropsChanged]);

  /**
   * Lookup fetch
   */
  useEffect(() => {
    if (states[strSelectName] && states[dipSelectName] && states[unitOpSelectName]) {
      dispatch(fetchLookupByUO({
        idStruttura: states[strSelectName] as number,
        idDipartimento: states[dipSelectName] as number,
        idUnitaOperativa: states[unitOpSelectName] as number,
      }));
      dispatch(fetchAllValidTurniAddetti({
        idStruttura: states[strSelectName] as number,
        idDipartimento: states[dipSelectName] as number,
        idUnitaOperativa: states[unitOpSelectName] as number,
      }));
    }
    if (states[strSelectName] && states[dipSelectName]) {
      dispatch(
        fetchUnitaOperativeLookup({
          idStruttura: states[strSelectName] as number,
          idDipartimento: states[dipSelectName] as number,
        })
      );
    }
    if (states[strSelectName]) {
      dispatch(
        fetchDipartimentiLookup({
          idStruttura: states[strSelectName] as number,
        })
      );
    } else {
      dispatch(fetchStruttureLookup());
    }
  }, [states, strSelectName, dipSelectName, unitOpSelectName, dispatch]);

  /**
   * Used by InsertTurniModal
   */
  const [currentSelectedDate, setCurrentSelectedDate] = useState<Date | null>(new Date());
  const [openTurniModal, setOpenTurniModal] = useState<boolean>(false);
  const [selectedDateIndex, setSelectedDateIndex] = useState<number>(0);

  const [dateToShow, setDateToShow] = useState<Date[]>([]);

  const excludedFieldInTable: string[] = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFieldsPianificazioneAS);
  const [columnsQualifica, setColumnsQualifica] = useState<Column<PianificazioneQualifica>[]>([]);
  const [columnsAnagraficaSoggetto, setColumnsAnagraficaSoggetto] = useState<Column<Pianificazione>[]>([]);
  useEffect(() => {
    /**
     * Column per PianificazioneQualifica
     */
    if (tabValue === TabIndex.ANAGRAFICA) {
      const selectedAS = createLookup(listAS, 'idAnagraficaSoggetto', ['nominativo'])

      if (ERROR_VALUE !== fixedProps.mese && ERROR_VALUE !== fixedProps.anno) {
        setColumnsAnagraficaSoggetto(
          reworkPianificazioneAnagraficaFieldsNoBgColor(allFieldsPianificazioneAS, fixedProps.mese, fixedProps.anno, t, validPianificazioneAnagraficaSoggetti)
            .filter(f => !excludedFieldInTable.includes(f.field))
            .map((f) => {
              let obj: Column<Pianificazione> = {
                title: f.titleKey ? t(f.titleKey) : "",
                field: f.field,
                removable: f.removable ?? !f.required,
                editable: f.editable ? f.editable : "always",
                defaultSort: f.sort,
              };

              if (f.validate2) {
                obj.validate = (rowData) => {
                  let resp: boolean | { isValid: boolean, helperText: string } = false;
                  let resp2: boolean | { isValid: boolean, helperText: string } = false;

                  if (f.validate2)
                    resp2 = f.validate2(
                      rowData[f.field as PianificazioneKeys],
                      rowData[f.field2Validation as PianificazioneKeys],
                      f.keyTradValidation2 ? t(f.keyTradValidation2) : ''
                    );

                  if (f.validate) {
                    resp = f.validate(
                      rowData[f.field as PianificazioneKeys],
                      f.keyTradValidation ? t(f.keyTradValidation) : ''
                    );
                  }
                  if (resp === true) {
                    return resp2;
                  } else return resp;
                }
              } else if (f.validate) {
                obj.validate = (rowData) => {
                  if (f.validate)
                    return f.validate(
                      rowData[f.field as PianificazioneKeys],
                      f.keyTradValidation ? t(f.keyTradValidation) : ''
                    );
                  return false;
                }
              }

              if (!f.show) {
                obj.hidden = true;
                obj.hiddenByColumnsButton = false;
              }
              if (f.type && f.type !== "image" && f.type !== "file") {
                obj.type = f.type;
              }

              if (f.render) {
                obj.render = f.render;
              }

              const isTurnoColumn = f.field.startsWith('_t');

              if (!['nominativo', 'oreTotale', 'numeroRiposi', 'qualificaDescrizioneBreve', 'oreLavorative', 'differenzaOre'].includes(f.field)) {
                obj.render = (rowData: Record<string, any>) => {

                  if (rowData.idStruttura === -1) {
                    /**
                     * Render pianificazioni qualifiche
                    */
                    if (f.field.startsWith('_')) {
                      const colorIndex = f.field.replace('_', 'colore');
                      const index = Number(f.field.replace('_', '')) - 1;

                      const data = rowData[f.field];
                      const color = rowData[colorIndex];

                      return (
                        <Tooltip title={<span style={{ fontSize: 12 }}>
                          Dotazione Minima: {rowData?.numeroLegaleUnitaOperativa[index] ?? ''}<br />
                          Dotazione Ottimale: {rowData?.numeroAddettiUnitaOperativa[index] ?? ''} <br />
                          Reperibilità Richieste: {rowData?.numeroReperibilitaRichieste[index] ?? ''}
                        </span>}
                        >
                          <Box
                            bgcolor={Colors[color as ColorsKeys] === '#FFFFFF' ? 'transparent' : Colors[color as ColorsKeys]}
                            minHeight={'25px'}
                            pt={'6px'}
                            fontWeight={'bold'}
                            fontSize={'0.75em'}
                            className={classes.hoverCell}
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                          >
                            {data}
                          </Box>
                        </Tooltip>
                      )
                    }
                  } else {
                    /**
                     * Render pianificazioni anagraficaSoggetti
                     */
                    const onClick = () => {
                      const _currentSelectedDate = new Date(fixedProps.anno, fixedProps.mese - 1, Number(f.field.replace('_', '')));
                      const dateIndex = dateToShow.findIndex(elem => isEqual(_currentSelectedDate, elem));

                      setCurrentSelectedDate(_currentSelectedDate)
                      setSelectedDateIndex(dateIndex);
                      setOpenTurniModal(true);

                      const fetchTurniLookupAsync = async () => {
                        if (selectedAnagraficaSoggetto !== rowData.idAnagraficaSoggetto || (currentSelectedDate != null && getDateYYYYMMDD(new Date(_currentSelectedDate)) !== getDateYYYYMMDD(new Date(currentSelectedDate)))) {
                          setSelectedAnagraficaSoggetto(rowData.idAnagraficaSoggetto);
                          await dispatch(fetchTurniAbilitati([fixedProps.idStruttura, rowData.idAnagraficaSoggetto, getDateYYYYMMDD_BackEnd(_currentSelectedDate, 'START')]));
                        }
                      }

                      fetchTurniLookupAsync();
                    }

                    const _date = Number(f.field.replace('_', ''));
                    const _currentSelectedDate = new Date(fixedProps.anno, fixedProps.mese - 1, _date);
                    const index = dateToShow.findIndex(elem => isEqual(_currentSelectedDate, elem));

                    const dateIndex = _date - 1;

                    const isDateAfterToday = isAfter(dateToShow[index], (new Date(getTodayEnd())));
                    const turniDelGiorno = rowData.turniObj?.[dateIndex] ?? [];
                    const tuttiPresenze = turniDelGiorno.every((elem: IdsConfigExtended) => elem.pianificazione?.idPresenza != null);
                    const UOEsterna = rowData.turniObj?.[dateIndex]?.some((elem: any) => elem.uoEsterna) ?? false;
                    const haTurniMultipli = rowData.turniObj?.[dateIndex]?.length > 1;
                    const hasInsertimentoManuale = rowData.turniObj?.[dateIndex]?.some((elem: any) => elem.tipoInserimento === 'Manuale') ?? false;
                    const haAlmenoUnaPresenzaNonAssociata = !tuttiPresenze && !isDateAfterToday;

                    const turnidelGiorno = rowData.turniObj?.[dateIndex] ?? [];

                    const element = (
                      <Box
                        onClick={onClick}
                        className={index != null && isAfter(dateToShow[index], new Date(getTodayEnd())) ? classes.cell : classes.defaultCursor}
                        width='100%'
                        minHeight='25px'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        bgcolor={(UOEsterna ? '#99ffff' : (hasInsertimentoManuale && !approvato ? 'pink' : undefined))}
                        style={{
                          backgroundImage: UOEsterna && (haTurniMultipli || hasInsertimentoManuale) ? 'linear-gradient(to right, #99ffff, pink)' : undefined
                        }}
                        fontStyle={haAlmenoUnaPresenzaNonAssociata ? 'italic' : undefined}
                        fontWeight={haTurniMultipli ? 'bold' : undefined}
                        fontSize={'0.75em'}
                      >
                        {
                          turnidelGiorno.map((elem: any, index: number) => {
                            return (
                              <span
                                style={{
                                  marginLeft: index > 0 ? 3 : 0
                                }}
                              >
                                {(elem.reperibilita ? '(' : '') + elem.descrizioneBreve + (elem.reperibilita ? ')' : '')}
                              </span>
                            )
                          })
                        }
                      </Box>
                    )

                    const elementCodiceTurno = (
                      <Box
                        bgcolor={rowData.coloreCodiceTurno}
                        className={classes.defaultCursor}
                        width='100%'
                        minHeight='25px'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        fontSize={'0.75em'}
                      >
                        {(rowData as any)[f.field]}
                      </Box>
                    );

                    const elementTurno = (
                      <Box
                        width='30px'
                        minHeight='25px'
                        display='flex'
                        justifyContent='center'
                        alignItems='center'
                        fontSize={'0.75em'}
                      >
                        {(rowData as any)[f.field]}
                      </Box>
                    )

                    if (UOEsterna) {
                      return (
                        <Tooltip
                          enterDelay={50}
                          title={<div style={{ fontSize: 12 }}>
                            Turno {rowData.tooltipDati[dateIndex]?.turnoDescrizione} svolto presso {rowData.tooltipDati[dateIndex]?.unitaOperativaNome}.
                          </div>}
                        >
                          {element}
                        </Tooltip>
                      );
                    } else if (f.field === 'codiceTurno') {
                      return elementCodiceTurno;
                    } else if (isTurnoColumn) {
                      return elementTurno;
                    } else {
                      return element;
                    }
                  }
                }
              }

              if (['qualificaDescrizioneBreve'].includes(f.field)) {
                obj.cellStyle = {
                  padding: 0,
                  backgroundColor: '#fff'
                };
                obj.headerStyle = {
                  backgroundColor: '#cecece'
                }
              } else if (['codiceTurno'].includes(f.field)) {
                obj.cellStyle = {
                  padding: 0,
                  position: "sticky",
                  left: 0,
                  zIndex: 10,
                  minWidth: 50,
                  maxWidth: 50,
                  backgroundColor: '#fff'
                }
                obj.headerStyle = {
                  padding: 0,
                  position: "sticky",
                  left: 0,
                  zIndex: 11,
                  fontSize: '0.75em',
                  minWidth: 50,
                  maxWidth: 50,
                  backgroundColor: '#fff'
                };
              }
              else if (['nominativo'].includes(f.field)) {
                obj.cellStyle = {
                  padding: 0,
                  position: "sticky",
                  left: 50,
                  zIndex: 10,
                  backgroundColor: "#fff",
                  minWidth: 300,
                  maxWidth: 300,
                }
                obj.headerStyle = {
                  padding: 0,
                  paddingLeft: '16px',
                  position: "sticky",
                  left: 50,
                  zIndex: 11,
                  backgroundColor: "#ccc",
                  fontSize: '0.75em',
                  minWidth: 300,
                  maxWidth: 300,
                };
              } else if (['oreLavorative'].includes(f.field)) {
                obj.cellStyle = {
                  padding: 0,
                  position: "sticky",
                  left: 350,
                  zIndex: 10,
                  boxSizing: 'inherit',
                  lineHeight: '1.25em',
                  minWidth: 50,
                  maxWidth: 50,
                  backgroundColor: '#fff'
                }
                obj.headerStyle = {
                  padding: 0,
                  position: "sticky",
                  left: 350,
                  zIndex: 11,
                  fontSize: '0.75em',
                  textAlign: 'center',
                  lineHeight: '1.25em',
                  minWidth: 50,
                  maxWidth: 50,
                  backgroundColor: '#fff'
                };
              } else if (['oreTotale'].includes(f.field)) {
                obj.cellStyle = {
                  padding: 0,
                  position: "sticky",
                  left: 400,
                  zIndex: 10,
                  boxSizing: 'inherit',
                  minWidth: 50,
                  maxWidth: 50,
                  backgroundColor: '#fff'
                }
                obj.headerStyle = {
                  padding: 0,
                  position: "sticky",
                  left: 400,
                  zIndex: 11,
                  fontSize: '0.75em',
                  textAlign: 'center',
                  minWidth: 50,
                  maxWidth: 50,
                  backgroundColor: '#fff'
                };
              } else if (['differenzaOre'].includes(f.field)) {
                obj.cellStyle = {
                  padding: 0,
                  position: "sticky",
                  left: 450,
                  zIndex: 10,
                  boxSizing: 'inherit',
                  lineHeight: '1.25em',
                  minWidth: 50,
                  maxWidth: 50,
                  backgroundColor: '#fff'
                }
                obj.headerStyle = {
                  padding: 0,
                  position: "sticky",
                  left: 450,
                  zIndex: 11,
                  fontSize: '0.75em',
                  textAlign: 'center',
                  lineHeight: '1.25em',
                  minWidth: 50,
                  maxWidth: 50,
                  backgroundColor: '#fff'
                };
              } else if (['numeroRiposi'].includes(f.field)) {
                obj.cellStyle = {
                  padding: 0,
                  position: "sticky",
                  left: 500,
                  zIndex: 10,
                  boxSizing: 'inherit',
                  minWidth: 50,
                  maxWidth: 50,
                  backgroundColor: '#fff'
                }
                obj.headerStyle = {
                  padding: 0,
                  position: "sticky",
                  left: 500,
                  zIndex: 11,
                  fontSize: '0.75em',
                  textAlign: 'center',
                  minWidth: 50,
                  maxWidth: 50,
                  backgroundColor: '#fff'
                };
              } else if (f.field.startsWith('separatore')) {
                obj.cellStyle = {
                  borderStyle: 'solid',
                  borderColor: '#e0e0e0',
                  borderWidth: 1,
                  backgroundColor: '#000',
                  padding: 0,
                };
                obj.headerStyle = {
                  backgroundColor: '#000',
                  padding: 0,
                  width: 5
                };
              } else if (isTurnoColumn) {
                obj.cellStyle = {
                  borderStyle: 'solid',
                  borderColor: '#e0e0e0',
                  borderWidth: 1,
                  padding: 0,
                };
                obj.headerStyle = {
                  borderStyle: 'solid',
                  borderColor: '#e0e0e0',
                  borderWidth: 1,
                  padding: 0,
                  textAlign: 'center',
                  fontSize: '0.75em',
                  lineHeight: '1.25em'
                };
              } else {
                obj.align = 'center';
                obj.cellStyle = {
                  borderStyle: 'solid',
                  borderColor: '#e0e0e0',
                  borderWidth: 1,
                  backgroundColor: dateFestivi.includes(Number(f.field.substring(1))) ? '#cecece' : undefined,
                  padding: 0,
                }
                if (!isNaN(Number(f.field.replace('_', ''))) && getDateYYYYMMDD(new Date()) === getDateYYYYMMDD(new Date(fixedProps.anno, fixedProps.mese - 1, Number(f.field.replace('_', ''))))) {
                  obj.headerStyle = {
                    backgroundColor: '#ccffcc',
                    padding: 10,
                    fontSize: '0.75em',
                    lineHeight: '1.25em'
                  }
                } else {
                  obj.headerStyle = {
                    padding: 10,
                    fontSize: '0.75em',
                    lineHeight: '1.25em'
                  }
                }
              }

              /*+++*/
              switch (f.field) {
                case idAnagraficaSoggettoConst:
                  obj.lookup = { ...lookupAnagraficaSoggettoPerUnitaOperativa, ...selectedAS };
                  break;
              }
              /*+++*/

              if (f.defaultGroupOrder !== null || f.defaultGroupOrder !== undefined) {
                obj.defaultGroupOrder = f.defaultGroupOrder;
              }

              return obj;
            })
        );
      }
    } else if (tabValue === TabIndex.QUALIFICA) {
      if (ERROR_VALUE !== fixedProps.mese && ERROR_VALUE !== fixedProps.anno) {
        setColumnsQualifica(
          reworkPianificazioneQualificaFieldsBgColor(allFieldsPianificazioneQualifica, validPianificazioneQualifiche, fixedProps.mese, fixedProps.anno, t, classes)
            .filter(f => !excludedFieldInTable.includes(f.field))
            .map((f) => {
              let obj: Column<PianificazioneQualifica> = {
                title: f.titleKey ? t(f.titleKey) : "",
                field: f.field,
                removable: f.removable ?? !f.required,
                editable: f.editable ? f.editable : "always",
                sorting: false
              };

              if (f.validate2) {
                obj.validate = (rowData) => {
                  let resp: boolean | { isValid: boolean, helperText: string } = false;
                  let resp2: boolean | { isValid: boolean, helperText: string } = false;

                  if (f.validate2)
                    resp2 = f.validate2(
                      rowData[f.field as PianificazioneQualificaKeys],
                      rowData[f.field2Validation as PianificazioneQualificaKeys],
                      f.keyTradValidation2 ? t(f.keyTradValidation2) : ''
                    );

                  if (f.validate) {
                    resp = f.validate(
                      rowData[f.field as PianificazioneQualificaKeys],
                      f.keyTradValidation ? t(f.keyTradValidation) : ''
                    );
                  }
                  if (resp === true) {
                    return resp2;
                  } else return resp;
                }
              } else if (f.validate) {
                obj.validate = (rowData) => {
                  if (f.validate)
                    return f.validate(
                      rowData[f.field as PianificazioneQualificaKeys],
                      f.keyTradValidation ? t(f.keyTradValidation) : ''
                    );
                  return false
                }
              }

              if (!f.show) {
                obj.hidden = true;
                obj.hiddenByColumnsButton = false;
              }
              if (f.type && f.type !== "image" && f.type !== "file") {
                obj.type = f.type;
              }

              if (f.render) {
                obj.render = f.render;
              }

              if (['qualificaDescrizioneBreve'].includes(f.field)) {
                obj.width = 300;
              } else if (['turnoDescrizione'].includes(f.field)) {
                obj.cellStyle = {
                  fontStyle: 'bold',
                  fontSize: '0.75em',
                  width: 300
                };
                obj.headerStyle = {
                  fontSize: '0.75em',
                  width: 300
                };
              } else {  // tutte le date
                obj.align = 'center';
                obj.cellStyle = (_, rowData) => {
                  const index = 'colore' + (f.field.replace('_', '')) as PianificazioneQualificaKeys;
                  const color = rowData[index] as ColorsKeys;

                  return {
                    backgroundColor: Colors[color],
                    borderStyle: 'solid',
                    borderColor: '#e0e0e0',
                    borderWidth: 1,
                    padding: 5,
                    fontSize: '0.75em',
                    width: 20,
                  }
                };
                obj.headerStyle = {
                  padding: 15,
                  width: 20,
                  fontSize: '0.75em',
                  lineHeight: '1.25em',
                };
              }

              if (f.defaultGroupOrder !== null || f.defaultGroupOrder !== undefined) {
                obj.defaultGroupOrder = f.defaultGroupOrder;
              }

              /*+++*/
              switch (f.field) {
                case idAnagraficaSoggettoConst:
                  obj.lookup = lookupAnagraficaSoggettoPerUnitaOperativa;
                  break;
              }
              /*+++*/
              return obj;
            })
        );
      }
    }
  }, [t, lookupTurnoAssenza, lookupArticoloLegge, lookupAnagraficaSoggettoPerUnitaOperativa, lookupUnitOp, lookupTurni, allFieldsState, excludedFieldInTable, tabValue, fixedProps.mese, fixedProps.anno, listAS, dateFestivi, validPianificazioneQualifiche, classes, dateToShow, dispatch, selectedAnagraficaSoggetto, fixedProps.idStruttura, currentSelectedDate, fixedProps.idUnitaOperativa, lookupStr, lookupDip, fixedProps.idDipartimento, validPianificazioneAnagraficaSoggetti, approvato]);

  const errorBE = useAppSelector((state) => state.pianificazione.error);
  const validPianificazioni = useAppSelector((state) => state.pianificazione.validUnitaOperativaPianificazioni);
  const statusValidUnitaOperativaPianificazioni = useAppSelector((state) => state.pianificazione.statusValidUnitaOperativaPianificazioni);
  const statusSavePianificazione = useAppSelector((state) => state.pianificazione.statusSavePianificazione);

  const [dataPianificazioniAnagraficaSoggetto, setDataPianificazioniAnagraficaSoggetto] = useState<RiepilogoPianificazioneDTO[]>([]);
  const [dataPianificazioniQualifica, setDataPianificazioniQualifica] = useState<RiepilogoPianificazioneQualificheDTO[]>([]);

  const pianificazioneStatoLookup = useMemo(() => {
    const lookup: Record<string, string> = {}

    Object.keys(statoPianificazioneTranslation).forEach((elem: string) => {
      lookup[elem] = t(statoPianificazioneTranslation[elem])
    })

    return lookup;
  }, [statoPianificazioneTranslation, t]);

  const [collezioneCurrentTurniEOre, setCollezioneCurrentTurniEOre] = useState<MappaTurniOre>({});
  useEffect(() => {
    const mappaTurniOre: Record<string, unknown> = {};
    const getMappaTurniOre = () => {
      validPianificazione.forEach(elem => {
        if (elem.turnoDescrizioneBreve && !mappaTurniOre[elem.turnoDescrizioneBreve] && elem.idAnagraficaSoggetto === selectedAnagraficaSoggetto) {
          mappaTurniOre[elem.turnoDescrizioneBreve] = {
            ore: elem.oreTurno,
            minuti: elem.minutiTurno
          };
        } else if (elem.turnoAssenzaDescrizioneBreve && !mappaTurniOre[elem.turnoAssenzaDescrizioneBreve]) {
          mappaTurniOre[elem.turnoAssenzaDescrizioneBreve] = {
            ore: elem.oreTurno,
            minuti: elem.minutiTurno
          };
        }
      });
    }
    getMappaTurniOre();
    setCollezioneCurrentTurniEOre(mappaTurniOre as MappaTurniOre);
  }, [selectedAnagraficaSoggetto, validPianificazione]);

  /**
   * Prepare data for Material Table
   */
  useEffect(() => {
    let newDataAS: RiepilogoPianificazioneDTO[] = []
    let newRiepilogoSoggetto: Partial<RiepilogoPianificazioneDTO>[] = []
    switch (tabValue) {
      case TabIndex.ANAGRAFICA:
        /**
         * validPianificazioniAnagraficaSoggetto #############################################################
         */
        newDataAS = currentValidPianificazione;

        newRiepilogoSoggetto = newDataAS.map((elem: RiepilogoPianificazioneDTO) => {
          type MTValueDataType = string | number | undefined | number[] | (boolean | null)[] |
            (Record<string, any> | null)[] | TipoInserimento[] | PianificazioniAnagraficaSoggetti;

          const temp: Record<string, MTValueDataType> = {
            idStruttura: elem.idStruttura,
            idDipartimento: elem.idDipartimento,
            idUnitaOperativa: elem.idUnitaOperativa,
            idAnagraficaSoggetto: elem.idAnagraficaSoggetto,
            nominativo: elem.nominativo,
            oreTotale: elem.oreTotale,
            minutiTotale: elem.minutiTotale,
            numeroRiposi: elem.numeroRiposi,
            qualificaDescrizione: elem.qualificaDescrizione,
            qualificaDescrizioneBreve: elem.qualificaDescrizioneBreve,
            coloreAnagraficaSoggetto: elem.coloreAnagraficaSoggetto,
            numeroAddettiUnitaOperativa: elem.numeroAddettiUnitaOperativa,
            numeroLegaleUnitaOperativa: elem.numeroLegaleUnitaOperativa,
            numeroReperibilitaRichieste: elem.numeroReperibilitaRichieste,
            tipoInserimento: elem.tipoInserimento,
            uoEsterna: elem.uoEsterna,
            tooltipDati: elem.tooltipDati,
            combinazioneTurni: elem.combinazioneTurni,
            turniObj: elem.turniObj,
            codiceTurno: elem.codiceTurno,
            coloreCodiceTurno: elem.coloreCodiceTurno,
            pianificazioneAnagraficaSoggetti: elem.pianificazioneAnagraficaSoggetti
          }

          // dati delle anagrafiche
          if (elem.idStruttura !== -1) {
            elem.turniObj.forEach((turni, index) => {
              let val = '';
              turni.forEach(turno => {
                val += (val !== '' ? ' ' : '') + turno.descrizioneBreve
              });
              temp['_' + (index + 1)] = val;
            });
          } else { // dati delle qualifica
            elem.quantita.forEach((quantita, index) => {
              temp['_' + (index + 1)] = quantita;
            });
            elem.coloreQualifica.forEach((coloreQualifica, index) => {
              temp['colore' + (index + 1)] = coloreQualifica;
            });
          }

          // statistica turni
          elem.statisticaTurni.forEach((totale, index) => {
            temp['_t' + index + NO_PRINT_SUFFIX] = totale;
          });

          return temp;
        });
        setDataPianificazioniAnagraficaSoggetto(newRiepilogoSoggetto as RiepilogoPianificazioneDTO[]);
        break;
      case TabIndex.QUALIFICA:
        /**
         * validPianificazioniQualifica #############################################################
         */
        const newDataQualifica: RiepilogoPianificazioneQualificheDTO[] = currentValidPianificazioneQualifica;

        const newRiepilogoQualifiche: Partial<RiepilogoPianificazioneQualificheDTO>[] = newDataQualifica.map((elem: RiepilogoPianificazioneQualificheDTO) => {
          const temp: Record<string, string | number | undefined> = {
            qualificaDescrizioneBreve: elem.qualificaDescrizioneBreve,
            turnoDescrizione: elem.turnoDescrizione,
          }

          elem.quantita.forEach((val, index) => {
            temp['_' + (index + 1)] = val;
            temp['colore' + (index + 1)] = elem.colore[index];
          })

          return temp;
        });
        setDataPianificazioniQualifica(newRiepilogoQualifiche as RiepilogoPianificazioneQualificheDTO[]);
        break;
      case TabIndex.MODIFICA:
        newDataAS = currentValidPianificazione;

        newRiepilogoSoggetto = newDataAS.map((elem: RiepilogoPianificazioneDTO) => {
          const temp: Record<string, string | number | undefined> = {
            idAnagraficaSoggetto: elem.idAnagraficaSoggetto,
            nominativo: elem.nominativo,
            oreTotale: elem.oreTotale,
            minutiTotale: elem.minutiTotale,
            numeroRiposi: elem.numeroRiposi,
          }

          elem.turniObj.forEach((turni, index) => {

            temp['colore'] = elem.coloreAnagraficaSoggetto;

            let val = '';
            turni.forEach(turno => {
              val += (val !== '' ? ' ' : '') + turno.descrizioneBreve
            });
            temp['_' + (index + 1)] = val;
          })

          return temp;
        });
        setDataPianificazioniAnagraficaSoggetto(newRiepilogoSoggetto as RiepilogoPianificazioneDTO[]);
        break;
    }
  }, [collezioneCurrentTurniEOre, currentValidPianificazione, currentValidPianificazioneQualifica, fixedProps.anno, fixedProps.mese, lookupTurni, t, tabValue, validPianificazione, validPianificazioneQualifiche, validPianificazioni, validUnitaOperativaPianificazione?.stato]);

  const handleDateChange = (d: Date | null) => {
    let fieldDate: string = "";
    if (d) {
      fieldDate = getDateYYYYMMDD_BackEnd(d, 'START');
      setSelectedMonth(new Date(d.setHours(0, 0, 0, 0)));
    }
    setStates(prev => { return { ...prev, [monthYearSelectName]: fieldDate } });
    setSelectedAnagraficaSoggetto(ERROR_VALUE);
    setAS_selectedMenu(0);
  };

  const setInternalObjectRef = useRef<{ internalStateSetter: Dispatch<SetStateAction<Object>>, formObject: Object, field: string } | null>(null);
  useEffect(() => {
    const oreTurnoConst = 'oreTurno';
    const defaultValues = fixedProps;

    if (setInternalObjectRef.current) {

      const setter = setInternalObjectRef.current.internalStateSetter;
      const formObject: Record<string, any> = setInternalObjectRef.current.formObject;
      const field = setInternalObjectRef.current.field;

      setter(state => {
        let newState: Record<string, any> = { state };
        switch (field) {
          case idTurnoConst:
            setAllFieldsState(state => {
              state.forEach(elem => {
                if (elem.field === idArticoloLeggeConst)
                  elem.readonly = true;
              });
              return state;
            });

            const selectedTurno = turniAbilitatiFiltered.find(elem => elem.idTurno === Number(formObject[idTurnoConst]));
            newState = { ...state, [oreTurnoConst]: selectedTurno ? selectedTurno.ore : '' };

            if (newState[idTurnoAssenzaConst]) delete newState[idTurnoAssenzaConst];
            if (newState[idArticoloLeggeConst]) delete newState[idArticoloLeggeConst];
            break;
          case idTurnoAssenzaConst:
            setAllFieldsState(state => {
              state.forEach(elem => {
                if (elem.field === idArticoloLeggeConst)
                  delete elem.readonly;
              });
              return state;
            });
            const selectedAssenza = validAssenze.find(elem => elem.idTurnoAssenza === Number(formObject[idTurnoAssenzaConst]));
            newState = { ...state, [oreTurnoConst]: selectedAssenza ? selectedAssenza.ore : '' };
            if (newState[idTurnoConst]) delete newState[idTurnoConst];
            break;
          case idAnagraficaSoggettoConst:
            const defaultQualifica = qualificheAbilitateFiltered.find(elem => elem.selected);
            newState = {
              ...defaultValues,
              idAnagraficaSoggetto: formObject[idAnagraficaSoggettoConst],
              reperibilita: false,
              [idQualificaConst]: defaultQualifica ? defaultQualifica[idQualificaConst] : '',
            };
            break;
        }
        return newState;
      });
    }
  }, [fixedProps, qualificheAbilitateFiltered, turniAbilitatiFiltered, validAssenze]);

  // list of AS
  useEffect(() => {
    switch (tabValue) {
      case TabIndex.ANAGRAFICA:
        break;
      case TabIndex.QUALIFICA:
        break;
      case TabIndex.MODIFICA:
        setListAS(state => {
          const listSoggetti: Array<AS> = [];

          if (state.length === 0) {
            validPianificazione.forEach((soggetto) => {
              if (listSoggetti.some(elem => elem.idAnagraficaSoggetto === soggetto.idAnagraficaSoggetto)) {
                const index = listSoggetti.findIndex(elem => elem.idAnagraficaSoggetto === soggetto.idAnagraficaSoggetto)
                const qualificheDescrizioneList = listSoggetti[index].qualificheDescrizione;

                if (qualificheDescrizioneList?.every(elem => elem.id !== soggetto.idQualifica)) {
                  qualificheDescrizioneList.push({
                    id: soggetto.idQualifica,
                    name: soggetto.qualificaDescrizione,
                  });
                  qualificheDescrizioneList?.push({
                    id: soggetto.idQualifica,
                    name: soggetto.qualificaDescrizioneBreve,
                  });
                }
              } else {
                const { cognome, nome } = validPianificazioneAnagraficaSoggetti?.find(elem => elem.idAnagraficaSoggetto === soggetto.idAnagraficaSoggetto) ?? { cognome: '', nome: '' };

                listSoggetti.push({
                  idStruttura: soggetto.idStruttura,
                  idDipartimento: soggetto.idDipartimento,
                  idUnitaOperativa: soggetto.idUnitaOperativa,
                  idAnagraficaSoggetto: soggetto.idAnagraficaSoggetto,
                  nominativo: cognome + ' ' + nome,
                  cognome: soggetto.cognome,
                  nome: soggetto.nome,
                  qualificheDescrizione: [{
                    id: soggetto.idQualifica,
                    name: soggetto.qualificaDescrizione,
                  }],
                  qualificheDescrizioneBreve: [{
                    id: soggetto.idQualifica,
                    name: soggetto.qualificaDescrizioneBreve,
                  }]
                });
              }
            });

            return listSoggetti;
          }

          return [...state]
        });
        break;
    }
  }, [lookupAnagraficaSoggettoPerUnitaOperativa, tabValue, validPianificazione, validPianificazioneAnagraficaSoggetti]);

  const [selectedIdQualifica, setSelectedIdQualifica] = useState<number>();
  const [AS_selectedMenu, setAS_selectedMenu] = useState<number>(0);
  const handleSelectItem = useCallback((idAnagraficaSoggetto: number | string, idQualifica: number) => {
    setSelectedAnagraficaSoggetto(typeof idAnagraficaSoggetto === 'number' ? idAnagraficaSoggetto : Number(idAnagraficaSoggetto?.split('-')[1]));
    setAS_selectedMenu(typeof idAnagraficaSoggetto === 'number'
      ? idAnagraficaSoggetto
      : Number(idAnagraficaSoggetto?.split('-')[1]));
    setSelectedIdQualifica(typeof idAnagraficaSoggetto === 'string' ? Number(idAnagraficaSoggetto.split('-')[0]) : idQualifica);
  }, []);

  useEffect(() => {
    setCurrentSelectedAS(state => {
      let retval = currentValidPianificazione
        .find(elem => {
          const selectedIdAnagraficaSoggetto = selectedAnagraficaSoggetto;
          return elem?.idAnagraficaSoggetto === selectedIdAnagraficaSoggetto;
        });

      if (!retval) {
        const soggettoNuovo = listAS.find(elem => {
          const selectedIdAnagraficaSoggetto = selectedAnagraficaSoggetto;
          return elem.idAnagraficaSoggetto === selectedIdAnagraficaSoggetto;
        });

        if (soggettoNuovo?.idStruttura
          && soggettoNuovo?.idAnagraficaSoggetto
          && soggettoNuovo?.nominativo
          && selectedIdQualifica
        )
          retval = {
            idStruttura: soggettoNuovo?.idStruttura,
            idDipartimento: soggettoNuovo.idDipartimento,
            idUnitaOperativa: soggettoNuovo.idUnitaOperativa,
            idAnagraficaSoggetto: soggettoNuovo?.idAnagraficaSoggetto,
            nominativo: soggettoNuovo?.nominativo,
            cognome: soggettoNuovo?.cognome,
            nome: soggettoNuovo?.nome,
            idQualifica: selectedIdQualifica,
            qualificaDescrizione: lookupQualificheDescrizione[selectedIdQualifica],
            qualificaDescrizioneBreve: lookupQualificheDescrizioneBreve[selectedIdQualifica],
            combinazioneTurni: '',
            codiceTurno: '',
            turniObj: [],
            quantita: [],
            qualificheIds: [],
            coloreAnagraficaSoggetto: 'Rosso',
            coloreQualifica: [],
            turnoFisso: false,
            modificaInizioTurnoFissoCodiceTurno: false,
            numeroAddettiUnitaOperativa: [],
            numeroLegaleUnitaOperativa: [],
            numeroReperibilitaRichieste: [],
            tipoInserimento: [],
            uoEsterna: [],
            tooltipDati: [],
            statisticaTurni: []
          }
      }

      if (retval) {
        return {
          ...retval,
          qualificaDescrizione: lookupQualificheDescrizione[retval.idQualifica],
          qualificaDescrizioneBreve: lookupQualificheDescrizioneBreve[retval.idQualifica],
        };
      } else {
        return state
      }
    });
  }, [currentValidPianificazione, listAS, lookupAnagraficaSoggettoPerUnitaOperativa, lookupQualificheDescrizione, lookupQualificheDescrizioneBreve, selectedAnagraficaSoggetto, selectedIdQualifica]);

  const firstDateOfMonth = useMemo(() => {
    if (selectedMonth)
      return new Date(selectedMonth.getFullYear() + "-" + (selectedMonth.getMonth() + 1) + "-1");
    return null;
  }, [selectedMonth]);

  const lastDateOfMonth = useMemo(() => {
    if (selectedMonth)
      return new Date(selectedMonth.getFullYear() + "-" + (selectedMonth.getMonth() + 1) + "-" + daysInMonth(selectedMonth.getMonth() + 1, selectedMonth.getFullYear()));
    return null;
  }, [selectedMonth]);

  const thisMonth = useMemo(() => {
    return {
      dayOfFirst: firstDateOfMonth?.getDay() ?? null,
      dayOfLast: lastDateOfMonth?.getDay() ?? null,
      month: selectedMonth ? selectedMonth.getMonth() + 1 : null, // month: index from 1
      year: selectedMonth?.getFullYear() ?? null,
      totalDays: selectedMonth ? daysInMonth(selectedMonth.getMonth() + 1, selectedMonth.getFullYear()) : 0
    }
  }, [firstDateOfMonth, lastDateOfMonth, selectedMonth]);

  const lastMonth = useMemo(() => {
    return {
      month: selectedMonth ? (selectedMonth.getMonth() === 0 ? 12 : selectedMonth.getMonth()) : null,  // month: index from 1
      year: selectedMonth ? (selectedMonth.getMonth() === 0 ? selectedMonth.getFullYear() - 1 : selectedMonth.getFullYear()) : null,
      totalDays: selectedMonth ? (daysInMonth(
        selectedMonth.getMonth() === 0 ? 12 : selectedMonth.getMonth(),
        selectedMonth.getMonth() === 0 ? selectedMonth.getFullYear() - 1 : selectedMonth.getFullYear()
      )) : 0
    }
  }, [selectedMonth]);

  const nextMonth = useMemo(() => {
    return {
      month: selectedMonth ? (selectedMonth.getMonth() === 11 ? 1 : selectedMonth.getMonth() + 2) : null, // month: index from 1
      year: selectedMonth ? (selectedMonth.getMonth() === 11 ? selectedMonth.getFullYear() + 1 : selectedMonth.getFullYear()) : null,
      totalDays: selectedMonth ? (daysInMonth(
        selectedMonth.getMonth() === 11 ? 1 : selectedMonth.getMonth() + 2,
        selectedMonth.getMonth() === 11 ? selectedMonth.getFullYear() + 1 : selectedMonth.getFullYear()
      )) : 0
    }
  }, [selectedMonth]);

  useEffect(() => {
    const temp: Date[] = [];

    // all date previous month
    for (let i = 0; null !== thisMonth.dayOfFirst && i < thisMonth.dayOfFirst; i++) temp.unshift(new Date(lastMonth.year + "-" + lastMonth.month + "-" + (lastMonth.totalDays - i)));
    // all date current month
    for (let i = 1; i <= thisMonth.totalDays; i++) if (thisMonth.year && thisMonth.month) temp.push(new Date(thisMonth.year, thisMonth.month - 1, i));
    // all date next month
    for (let i = 1; null !== thisMonth.dayOfLast && i <= 6 - thisMonth.dayOfLast; i++) temp.push(new Date(nextMonth.year + "-" + nextMonth.month + "-" + i));

    setDateToShow(temp);
  }, [lastMonth.month, lastMonth.totalDays, lastMonth.year, nextMonth.month, nextMonth.year, selectedMonth, thisMonth.dayOfFirst, thisMonth.dayOfLast, thisMonth.month, thisMonth.totalDays, thisMonth.year])

  const numberOfWeeks: number[] = [];
  for (let i = 0; i < (dateToShow.length / 7); i++) numberOfWeeks.push(i);

  const validations: string[] = [];
  for (let i = 0; i < 5; i++) validations.push("Validation " + (i + 1));

  const [exportDataExtra, setExportDataExtra] = useState<PDFExtraData>(
    {
      head: {
        title: [t("structureTitle"), t("departmentTitle"), t("operatingUnitTitle"), t("dateTitle")],
        value: [
          lookupStr[fixedProps['idStruttura']],
          lookupDip[fixedProps['idDipartimento']],
          lookupUnitOp[fixedProps['idUnitaOperativa']],
          // getDateDDMMYYYY(new Date(fixedProps['presenzaData'])),
        ]
      }
    }
  );

  /**
   * set export data (head and additional) based on selected tab
   */
  useEffect(() => {
    if (tabValue === TabIndex.ANAGRAFICA) {
      setExportDataExtra(() => {
        const _fixedPropsMese = fixedProps['mese'];

        return {
          head: {
            title: [t("structureTitle"), t("departmentTitle"), t("operatingUnitTitle"), t("yearTitle"), t("monthTitle"), t('statusTitle')],
            value: [
              lookupStr[fixedProps['idStruttura']],
              lookupDip[fixedProps['idDipartimento']],
              lookupUnitOp[fixedProps['idUnitaOperativa']],
              fixedProps['anno'],
              null !== _fixedPropsMese ? t(MonthsList[_fixedPropsMese - 1]) : '',  // mese inizia da 0
              currentStatoPianificazione?.stato != null ? pianificazioneStatoLookup[currentStatoPianificazione.stato] : ''
            ]
          },
          misc: {
            dateFestivi
          }
        }
      });
    } else if (tabValue === TabIndex.QUALIFICA) {
      setExportDataExtra(() => {
        const _fixedPropsMese = fixedProps['mese'];

        return {
          head: {
            title: [t("structureTitle"), t("departmentTitle"), t("operatingUnitTitle"), t("yearTitle"), t("monthTitle")],
            value: [
              lookupStr[fixedProps['idStruttura']],
              lookupDip[fixedProps['idDipartimento']],
              lookupUnitOp[fixedProps['idUnitaOperativa']],
              fixedProps['anno'],
              null !== _fixedPropsMese ? t(MonthsList[_fixedPropsMese - 1]) : '',  // mese inizia da 0
            ]
          },
          misc: {
            dateFestivi
          }
        }
      });
    } else if (tabValue === TabIndex.MODIFICA) {
      // TODO: pdf del calendario
    }

  }, [currentStatoPianificazione?.stato, dateFestivi, fixedProps, lookupDip, lookupStr, lookupUnitOp, pianificazioneStatoLookup, t, tabValue]);

  useEffect(() => {
    return () => {
      dispatch(resetArticoliLegge());
      dispatch(resetAssenze());
      dispatch(resetStrutture());
      dispatch(resetTurni());
      dispatch(resetQualifiche());
      dispatch(resetAnagraficaSoggettiUnitaOperative());
      dispatch(resetAnagrificaSoggetti());
      dispatch(resetDipartimentiFiltered());
      dispatch(resetUnitaOperativeFiltered());

      setStates({});
      setSelectedMonth(null);
      setSelectedAnagraficaSoggetto(ERROR_VALUE);
      setCurrentValidPianificazione([]);
      setCurrentValidPianificazioneQualifica([]);
      setCurrentSelectedAS(null);
      setTabValue(TabIndex.ANAGRAFICA);
      setDataPianificazioniAnagraficaSoggetto([]);
      setDataPianificazioniQualifica([]);
      setDateToShow([]);
      setListAS([]);
    };
  }, [dispatch]);

  const riepilogoAnagraficaModifica: RiepilogoPianificazioneDTO | null = useMemo(() => {
    const retval = dataPianificazioniAnagraficaSoggetto?.find(elem => elem.idAnagraficaSoggetto === currentSelectedAS?.idAnagraficaSoggetto);

    if (retval)
      return {
        ...retval,
        idAnagraficaSoggetto: retval?.idAnagraficaSoggetto ?? currentSelectedAS?.idAnagraficaSoggetto,
        nominativo: retval?.nominativo ?? currentSelectedAS?.nominativo,
        qualificaDescrizione: currentSelectedAS?.qualificaDescrizione,
        qualificaDescrizioneBreve: currentSelectedAS?.qualificaDescrizioneBreve ?? '',
        idQualifica: currentSelectedAS?.idQualifica ?? ERROR_VALUE,
        modificaInizioTurnoFissoCodiceTurno: retval?.modificaInizioTurnoFissoCodiceTurno,
        combinazioneTurni: currentSelectedAS?.combinazioneTurni ?? '',
        codiceTurno: '',
        numeroAddettiUnitaOperativa: [],
        numeroLegaleUnitaOperativa: [],
        numeroReperibilitaRichieste: [],
        tipoInserimento: [],
        uoEsterna: [],
        tooltipDati: [],
        statisticaTurni: []
      };

    if (currentSelectedAS)
      return {
        idStruttura: currentSelectedAS.idStruttura,
        idAnagraficaSoggetto: currentSelectedAS?.idAnagraficaSoggetto,
        nominativo: currentSelectedAS?.nominativo,
        cognome: '',
        nome: '',
        codiceTurno: '',
        turniObj: [],
        quantita: [],
        qualificheIds: [],
        oreTotale: 0,
        minutiTotale: 0,
        numeroRiposi: 0,
        turnoFisso: false,
        modificaTurnoFisso: false,
        coloreAnagraficaSoggetto: 'Verde',
        coloreQualifica: [],
        qualificaDescrizione: currentSelectedAS?.qualificaDescrizione,
        qualificaDescrizioneBreve: currentSelectedAS?.qualificaDescrizioneBreve ?? '',
        idQualifica: currentSelectedAS?.idQualifica ?? ERROR_VALUE,
        modificaInizioTurnoFissoCodiceTurno: currentSelectedAS?.modificaInizioTurnoFissoCodiceTurno,
        combinazioneTurni: currentSelectedAS?.combinazioneTurni ?? '',
        numeroAddettiUnitaOperativa: [],
        numeroLegaleUnitaOperativa: [],
        numeroReperibilitaRichieste: [],
        tipoInserimento: [],
        uoEsterna: [],
        tooltipDati: [],
        statisticaTurni: []
      };

    return null;
  }, [currentSelectedAS, dataPianificazioniAnagraficaSoggetto]);

  const { preparedData } = useCalendar({
    currentSelectedAS,
    mappaTurniOre: collezioneCurrentTurniEOre,
    dateToShow: dateToShow,
    selectedMonth,
    selectedIdQualifica,
    riepilogoAnagraficaModifica,
    fixedProps,
    isApprovato: approvato
  });

  const { unsavedChangesPrompt } = usePianificazione();

  const isDirtyUnmount = useAppSelector(state => state.pianificazione.isDirty);

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (isDirtyUnmount) {
        const confirmLeave = window.confirm(
          "Esistono delle modifiche non salvate. Prosegui?"
        );
        if (!confirmLeave) {
          // Torna indietro se l'utente annulla
          return false;
        }
      }
      dispatch(setIsDirty(false));
      // Permetti la navigazione
      // return true;
    });

    return () => {
      unblock(); // Pulisci il blocco quando il componente si smonta
    };
  }, [dispatch, history, isDirtyUnmount]);

  return (
    <>
      {/* SIMPLE SELECT */}
      <Paper elevation={2}>
        {
          <Box p={4}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={10}>
                <SimpleIdSelects
                  selectsArray={[
                    {
                      name: strSelectName,
                      lookup: lookupStr,
                      label: strSelectLabel,
                      disabled: isUrlOnUpdate(history.location.pathname),
                      breakpoints: { xs: 12, sm: 12, md: 12, lg: 4 },
                    },
                    {
                      name: dipSelectName,
                      lookup: lookupDip,
                      label: dipSelectLabel,
                      disabled: isUrlOnUpdate(history.location.pathname),
                      callback: cleanDipartimentiLookup,
                      breakpoints: { xs: 12, sm: 12, md: 12, lg: 4 },
                    },
                    {
                      name: unitOpSelectName,
                      lookup: lookupUnitOp,
                      label: unitOpSelectLabel,
                      disabled: isUrlOnUpdate(history.location.pathname),
                      callback: cleanUOLookup,
                      breakpoints: { xs: 12, sm: 12, md: 12, lg: 4 },
                    },
                  ]}
                  states={states}
                  setStates={setStates}
                  preChangeCallback={unsavedChangesPrompt}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={2}>
                {(states[strSelectName] && states[dipSelectName] && states[unitOpSelectName]) &&
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === "it-IT" || i18n.language === "it" ? it : enGB}>
                    <DatePicker
                      label={monthYearSelectLabel}
                      format={"MMMM yyyy"}
                      onChange={(e) => {
                        const ok = unsavedChangesPrompt();

                        if (ok) handleDateChange(e)
                      }}
                      okLabel={t('insertLabel')}
                      clearLabel={t('clearLabel')}
                      cancelLabel={t('cancelLabel')}
                      clearable={true}
                      disabled={isUrlOnUpdate(history.location.pathname)}
                      value={states[monthYearSelectName] ? states[monthYearSelectName] : null}
                      views={['month', 'year',]}
                    />
                  </MuiPickersUtilsProvider>
                }
              </Grid>
            </Grid> {/* Grid container */}
          </Box>
        }
      </Paper>
      {
        ERROR_VALUE !== fixedProps.mese && ERROR_VALUE !== fixedProps.anno && isOldPianificazione(fixedProps.mese, fixedProps.anno) && errorBE ?
          <Paper elevation={2}>
            <Box mt={2} p={4}>
              <Typography align="center" variant="h5">
                {errorBE}
              </Typography>
            </Box>
          </Paper>
          :
          <>
            {/* STATO DELLA PIANIFICAZIONE */}
            {
              states[strSelectName] &&
              states[dipSelectName] &&
              states[unitOpSelectName] &&
              states[monthYearSelectName] &&
              validUnitaOperativaPianificazione &&

              <Paper elevation={2} >
                <Box mt={2} p={4}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm={12} md={12} lg={8}>
                      <GeneralForm
                        fields={allFieldsStatoPianificazione}
                        translate={t}
                        obj={currentStatoPianificazione}
                        readOnly={true}
                        action={undefined}
                        status={statusValidUnitaOperativaPianificazioni}
                        error={errorBE}
                        update={false}
                        lookups={{
                          stato: pianificazioneStatoLookup
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={2}>
                      <Grid item xs>
                        {/* APPROVA */}
                        {
                          (approvedClicked && (statusSavePianificazione === StatusEnum.Loading || statusValidUnitaOperativaPianificazioni === StatusEnum.Loading))
                            ? <LoadingSvg color="primary" width={40} />
                            : <Button
                              variant={'contained'}
                              disabled={
                                !isApprovable(validPianificazioneAnagraficaSoggetti, validPianificazioneQualifiche)
                                || (
                                  approvato
                                  && selectedMonth != null
                                  && selectedMonthBeforeOrEqualCurrentMonth(selectedMonth)
                                )}
                              fullWidth
                              onClick={() => {
                                setApprovedClicked(true);
                                if (!approvato) {
                                  const groupedData = regroupData(columnsAnagraficaSoggetto, dataPianificazioniAnagraficaSoggetto);
                                  const pdfBase64: string = (exportMenuRef.current?.[0].exportFunc as any)(columnsAnagraficaSoggetto, [], { groupedData }, true);

                                  dispatch(saveNuovaPianificazione({
                                    unitaOperativePianificazione: {
                                      ...validUnitaOperativaPianificazione,
                                      pdfProspettoPreventivo: pdfBase64
                                    },
                                    pianificazione: validPianificazione,
                                    stato: statoPianificazioneMap.APPROVATO,
                                  })).finally(() => setApprovedClicked(false));
                                } else {
                                  dispatch(reopenPianificazione({
                                    unitaOperativePianificazione: validUnitaOperativaPianificazione,
                                    stato: statoPianificazioneMap.INLAVORAZIONE
                                  })).finally(() => setApprovedClicked(false));
                                }
                                setApprovato(state => !state);
                              }}>
                              {approvato
                                ? t('reopenLabel')
                                : t('approveLabel')}
                            </Button>
                        }
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={2}>
                      {/* SALVA */}
                      {
                        savedClicked && statusSavePianificazione === StatusEnum.Loading
                          ? <LoadingSvg color="primary" width={40} />
                          : <Button
                            variant={'contained'}
                            fullWidth
                            disabled={(approvato || salvato) ||
                              (serializedInitPianificazione === JSON.stringify(validPianificazione) && validUnitaOperativaPianificazione?.stato === statoPianificazioneMap.INLAVORAZIONE)
                            }
                            onClick={() => {
                              if (currentStatoPianificazione) {
                                setSavedClicked(true);
                                dispatch(saveNuovaPianificazione({
                                  unitaOperativePianificazione: validUnitaOperativaPianificazione,
                                  pianificazione: validPianificazione,
                                  stato: currentStatoPianificazione.stato === statoPianificazioneMap.APPROVATO
                                    ? statoPianificazioneMap.DAAPPROVARE
                                    : currentStatoPianificazione.stato
                                })).finally(() => setSavedClicked(false));
                              }
                            }}>
                            {t('saveLabel')}
                          </Button>
                      }
                    </Grid>
                  </Grid>
                  {
                    errorBE &&

                    <Box mt={2} p={4}>
                      <Typography align="center" variant="h5" color="error">
                        {errorBE}
                      </Typography>
                    </Box>

                  }

                </Box>
              </Paper>
            }

            {/* Material Table */}
            <Box marginTop={2}>
              {
                states[strSelectName] &&
                  states[dipSelectName] &&
                  states[unitOpSelectName] &&
                  states[monthYearSelectName] &&
                  validUnitaOperativaPianificazione
                  ? (
                    <>
                      <AppBar position="static" className={classes.appbar}>
                        <Tabs value={tabValue ?? TabIndex.ANAGRAFICA} onChange={handleTabIndexChange} classes={{ indicator: classes.indicator }}>
                          <Tab label={t("summaryPlanTabLabel")} {...handleTabChange(TabIndex.ANAGRAFICA)} />
                          <Tab label={t("summaryQualificationTabLabel")} {...handleTabChange(TabIndex.QUALIFICA)} />
                          <Tab label={t("Modifica")} {...handleTabChange(TabIndex.MODIFICA)} />
                        </Tabs>
                      </AppBar>
                      <TabPanel value={tabValue} index={TabIndex.ANAGRAFICA}>
                        <ReadOnlyMaterialTable
                          title={t("summaryPlanTabLabel")}
                          columns={columnsAnagraficaSoggetto}
                          data={dataPianificazioniAnagraficaSoggetto}
                          statusValid={statusValidUnitaOperativaPianificazioni}
                          errorBE={null}
                          logoUri={logoUri}
                          fixedProps={fixedProps}
                          exportDataExtra={exportDataExtra}
                          isExportLandscape={true}
                          localizedDatePicker={true}
                          extraOptions={{
                            draggable: false,
                            headerStyle: {
                              position: "sticky", top: 0, borderStyle: 'solid',
                              borderColor: '#e0e0e0',
                              borderWidth: 1,
                            },
                            doubleHorizontalScroll: true,
                            defaultExpanded: true,
                            sorting: false,
                            padding: 'dense',
                            paging: false,
                            minBodyHeight: calculateMTableHeight(0, true, false),
                            maxBodyHeight: calculateMTableHeight(0, true, false),
                            rowStyle: (rowData) => {
                              return {
                                borderTop: rowData.idStruttura === -1 ? "2px solid #000" : undefined,
                                borderBottom: rowData.idStruttura === -1 ? "2px solid #000" : undefined
                              }
                            }
                          }}
                          pdfOptions={{
                            bodyFontSize: 5,
                            format: 'a3'
                          }}
                          exportPDFCallback={exportPDFAnagrafica}
                          exportMenuRef={exportMenuRef}
                        />
                      </TabPanel>
                      <TabPanel value={tabValue} index={TabIndex.QUALIFICA}>
                        <ReadOnlyMaterialTable
                          title={t('planTitle') + ' - ' + t('summaryQualificationTabLabel')}
                          columns={columnsQualifica}
                          data={dataPianificazioniQualifica}
                          statusValid={statusValidUnitaOperativaPianificazioni}
                          errorBE={null}
                          logoUri={logoUri}
                          fixedProps={fixedProps}
                          exportDataExtra={exportDataExtra}
                          isExportLandscape={true}
                          localizedDatePicker={true}
                          extraOptions={{
                            draggable: false,
                            minBodyHeight: calculateMTableHeight(150, true, true),
                            maxBodyHeight: calculateMTableHeight(150, true, true),
                            padding: 'dense',
                            pageSize: dataPianificazioniQualifica.length >= 20
                              ? 20
                              : dataPianificazioniQualifica.length <= 5
                                ? 5
                                : 10,
                            defaultExpanded: true,
                          }}
                          pdfOptions={{
                            bodyFontSize: 5,
                            format: 'a3'
                          }}
                          exportPDFCallback={exportPDFQualifica}
                        />
                      </TabPanel>
                      <TabPanel value={tabValue} index={TabIndex.MODIFICA}>
                        <Paper>
                          <Box p={4} className={classes.flexGrow}>
                            <Grid container spacing={2}>
                              {/* AS LIST & CALENDAR */}
                              <Grid item container spacing={2}>
                                <Grid item xs={3}>
                                  {/* Anagrafica Soggetti list */}
                                  {
                                    AS_selectedMenu != null &&
                                    <AnagraficaSoggettiList
                                      selectedItem={AS_selectedMenu}
                                      handleSelectItem={handleSelectItem}
                                      setSelectedAnagraficaSoggetto={setSelectedAnagraficaSoggetto}
                                      listAsState={{
                                        listAS: listAS,
                                        setListAS: setListAS
                                      }}
                                      lookupAnagrafica={lookupAnagraficaSoggettoPerUnitaOperativa}
                                      fixedProps={fixedProps}
                                      approvato={approvato}
                                    />
                                  }
                                </Grid>
                                <Grid item xs={9}>
                                  {/* Calendar & Validations */}
                                  {
                                    ERROR_VALUE !== selectedAnagraficaSoggetto
                                      ? (
                                        statusSavePianificazione === StatusEnum.Loading
                                          ? <LoadingSvg color="primary" width={100} />
                                          : <>
                                            {/* Calendar */}
                                            {
                                              selectedIdQualifica && selectedMonth && currentSelectedAS && riepilogoAnagraficaModifica && ERROR_VALUE !== riepilogoAnagraficaModifica.idQualifica &&
                                              <Calendar
                                                selectedIdQualifica={selectedIdQualifica}
                                                mappaTurniOre={collezioneCurrentTurniEOre}
                                                dateToShow={dateToShow}
                                                selectedMonth={selectedMonth}
                                                currentSelectedAS={currentSelectedAS}
                                                fixedProps={fixedProps}
                                                approvato={approvato}
                                                riepilogoAnagraficaModifica={riepilogoAnagraficaModifica}
                                              />
                                            }
                                          </>)
                                      : <Box p={4}>
                                        <Typography align="center" variant="h5">
                                          {t("registrySelect")}
                                        </Typography>
                                      </Box>
                                  }
                                </Grid>
                              </Grid>
                            </Grid>
                          </Box>
                        </Paper>
                      </TabPanel>
                    </>
                  ) :
                  <Switch>
                    <Route path={pianificazioniPath} exact>
                      <Paper elevation={2}>
                        <Box p={4}>
                          <Typography align="center" variant="h5">
                            {
                              !states[strSelectName]
                                ? strSelectLabel
                                : !states[dipSelectName]
                                  ? dipSelectLabel
                                  : !states[unitOpSelectName]
                                    ? unitOpSelectLabel
                                    : !states[monthYearSelectName]
                                      ? monthYearSelectLabel
                                      : errorBE
                                        ? errorBE
                                        : 'Pianificazione in fase di Elaborazione'
                            }
                          </Typography>
                        </Box>
                      </Paper>
                    </Route>
                    <Route><Redirect to={pianificazioniPath} /></Route>
                  </Switch>
              }
            </Box>
          </>
      }
      {
        currentSelectedDate && currentSelectedAS &&
        <InsertTurniModal
          currentSelectedAS={currentSelectedAS}
          currentSelectedDate={currentSelectedDate}
          preparedData={preparedData}
          selectedDateIndex={selectedDateIndex}
          approvato={approvato}
          fixedProps={fixedProps}
          openState={{
            open: openTurniModal,
            setOpen: setOpenTurniModal
          }}
        />
      }
    </>
  );
};
export default PianificazioniPreventivoW;

function reworkPianificazioneQualificaFieldsBgColor(fields: Fields[], pianificazioneQualifica: PianificazioneQualifica[], month: number, year: number, t: (s: string) => string, classes: ClassNameMap<string>): Fields[] {
  if (month > 12 || month < 1 || year < 1) return [];

  /*******************/
  const retval = [...fields];
  const validWeekDays = WeekList.slice(1);

  const first = new Date(year, month - 1, 1)

  const maxDays = daysInMonth(month, year);
  const weekDay = first.getDay() - 1 < 0
    ? validWeekDays.length - 1
    : first.getDay() - 1;       // [0,6] - first day of the week: monday


  for (let i = 0; i < maxDays; i++) {
    retval.push({
      field: '_' + (i + 1).toString(),
      titleKey: (i + 1) + ' ' + t(validWeekDays[((i) + weekDay) % validWeekDays.length]).substring(0, 2),
      required: false,
      removable: false,
      show: true,
      render: (rowData) => { // rowData: {nominativo: string, _{date}: string, colore{date}: string, qualificaDescrizioneBreve: string, turnoDescrizioneBreve: string}
        const data = rowData['_' + (i + 1)];

        const turnoNumeroAddettiInfo = pianificazioneQualifica?.find(elem => {
          const month = new Date(elem.pianificazioneData).getMonth();
          const year = new Date(elem.pianificazioneData).getFullYear()
          return isEqual(new Date(elem.pianificazioneData), new Date(year, month, i + 1, 0, 0, 0, 0))
            && elem.qualificaDescrizioneBreve === rowData['qualificaDescrizioneBreve']
            && elem.turnoDescrizione === rowData['turnoDescrizione']
        })

        return (
          <Tooltip title={<span style={{ fontSize: 12 }}>
            Dotazione Minima: {turnoNumeroAddettiInfo?.numeroLegaleUnitaOperativa ?? ''}<br />
            Dotazione Ottimale: {turnoNumeroAddettiInfo?.numeroAddettiUnitaOperativa ?? ''} <br />
            Reperibilità Richieste: {turnoNumeroAddettiInfo?.numeroReperibilitaRichieste ?? ''}
          </span>}
          >
            <Box fontWeight={'bold'}>
              {data}
            </Box>
          </Tooltip>
        )
      }
    });
  }

  return retval;
}

function reworkPianificazioneAnagraficaFieldsNoBgColor(fields: Fields[], month: number, year: number, t: (s: string) => string, pianificazioniAnagrafiche: PianificazioniAnagraficaSoggetti[]): Fields[] {
  if (month > 12 || month < 1 || year < 1) return [];

  /*******************/
  const retval = [...fields];
  const validWeekDays = WeekList.slice(1);

  const first = new Date(year, month - 1, 1)

  const maxDays = daysInMonth(month, year);
  const weekDay = first.getDay() - 1 < 0
    ? validWeekDays.length - 1
    : first.getDay() - 1;       // [0,6] - first day of the week: monday


  for (let i = 0; i < maxDays; i++) {
    retval.push({
      field: '_' + (i + 1).toString(),
      titleKey: (i + 1) + ' ' + t(validWeekDays[((i) + weekDay) % validWeekDays.length]).substring(0, 2),
      required: false,
      removable: false,
      show: true,
    });
  }

  retval.push({
    field: 'separatore' + NO_PRINT_SUFFIX,
    titleKey: '.',
    required: false,
    removable: false,
    show: true,
  });

  if (pianificazioniAnagrafiche.length > 0) {
    const listaTurni = pianificazioniAnagrafiche[0].pianificazioniTurni;

    listaTurni.forEach((turno, index) => {
      retval.push({
        field: '_t' + index + NO_PRINT_SUFFIX,
        titleKey: turno.turnoDescrizioneBreve,
        required: false,
        removable: false,
        show: true,
      });
    })
  }

  return retval;
}

function createRiepilogoDatiAnagraficaSoggetto(pianificazioni: Pianificazione[], pianificazioniAnagrafiche: PianificazioniAnagraficaSoggetti[], pianificazioniQualifiche: PianificazioneQualifica[], mese: number, anno: number, tabValue: TabIndex, fixedProps: FixedProps): RiepilogoPianificazioneDTO[] {
  const retval: RiepilogoPianificazioneDTO[] = [];
  const maxDays = daysInMonth(mese, anno);

  const coloreTurni: Record<string, string> = {};
  [...new Set(pianificazioniAnagrafiche.map(elem => elem.codiceTurno))]
    .forEach((_codiceTurno, index) => {
      let key = _codiceTurno ?? 'null';

      if (!coloreTurni[key]) {
        coloreTurni[key] = index % 2 !== 0 ? '#e8e8e8' : '#fff';
      }
    });

  /**
   * Collect all anagrafica soggetti
   */
  pianificazioni
    .filter(elem => elem.idTurno || elem.idTurnoAssenza)
    .forEach((pianificazione, pianificazioneIndex) => {
      const date = (new Date(pianificazione.pianificazioneData)).getDate();

      if (retval.every(elem => (elem.idAnagraficaSoggetto !== pianificazione.idAnagraficaSoggetto))) {

        const soggetto = pianificazioniAnagrafiche.find(soggetto => soggetto.idAnagraficaSoggetto === pianificazione.idAnagraficaSoggetto);
        const statisticaTurni = pianificazioniAnagrafiche.find(soggetto => soggetto.idAnagraficaSoggetto === pianificazione.idAnagraficaSoggetto)?.pianificazioniTurni ?? [];
        const colore = soggetto?.coloreValidazioneOre.toLowerCase() === 'rosso' || soggetto?.coloreValidazioneRiposi.toLowerCase() === 'rosso'
          ? 'Rosso'
          : soggetto?.coloreValidazioneOre.toLowerCase() === 'giallo' || soggetto?.coloreValidazioneRiposi.toLowerCase() === 'giallo'
            ? 'Giallo'
            : 'Verde';

        const temp: RiepilogoPianificazioneDTO = {
          idStruttura: pianificazione.idStruttura,
          idDipartimento: pianificazione.idDipartimento,
          idUnitaOperativa: pianificazione.idUnitaOperativa,
          idAnagraficaSoggetto: pianificazione.idAnagraficaSoggetto,
          idQualifica: pianificazione.idQualifica,
          nominativo: pianificazione.cognome + ' ' + pianificazione.nome,
          cognome: pianificazione?.cognome,
          nome: pianificazione?.nome,
          codiceTurno: soggetto?.codiceTurno ?? '',
          coloreCodiceTurno: coloreTurni[soggetto?.codiceTurno ?? 'null'],  // 'null' -> per soggetti senza codice turno
          coloreAnagraficaSoggetto: colore,
          coloreQualifica: new Array(maxDays).fill('Bianco'),
          turniObj: new Array(maxDays).fill(null).map(() => []),
          quantita: new Array(maxDays).fill(''),
          qualificheIds: new Array(maxDays).fill(null).map(() => []),
          oreTotale: soggetto?.totaleOre,
          minutiTotale: soggetto?.totaleMinuti,
          oreTotalePresenza: soggetto?.totaleOrePresenza,
          minutiTotalePresenza: soggetto?.totaleMinutiPresenza,
          oreTotaleAssenza: soggetto?.totaleOreAssenza,
          minutiTotaleAssenza: soggetto?.totaleMinutiAssenza,
          numeroRiposi: soggetto?.totaleRiposi,
          combinazioneTurni: pianificazione.combinazioneTurni,
          turnoFisso: pianificazione.inizioTurnoFisso,
          modificaInizioTurnoFissoCodiceTurno: pianificazione.modificaInizioTurnoFissoCodiceTurno,
          qualificaDescrizione: pianificazione.qualificaDescrizione,
          qualificaDescrizioneBreve: pianificazione.qualificaDescrizioneBreve,
          numeroAddettiUnitaOperativa: [],
          numeroLegaleUnitaOperativa: [],
          numeroReperibilitaRichieste: [],
          tipoInserimento: new Array(maxDays).fill(null), // Nota: Utilizzare il tipo insermento nel turniObj
          uoEsterna: new Array(maxDays).fill(false),
          tooltipDati: new Array(maxDays).fill(null),
          statisticaTurni: statisticaTurni.map(elem => elem.totale),
          pianificazioneAnagraficaSoggetti: soggetto
        };

        // Nota: Utilizzare il tipo insermento nel turniObj
        temp.tipoInserimento[date - 1] = pianificazione.tipoInserimento as TipoInserimento;

        temp.uoEsterna[date - 1] = pianificazione.idStruttura !== fixedProps.idStruttura || pianificazione.idDipartimento !== fixedProps.idDipartimento || pianificazione.idUnitaOperativa !== fixedProps.idUnitaOperativa;

        const _id = pianificazione.idTurno ?? pianificazione.idTurnoAssenza;
        if (_id != null) {
          temp.turniObj[date - 1]?.push({
            id: _id,
            descrizione: pianificazione.turnoDescrizione ?? pianificazione.turnoAssenzaDescrizione ?? '',
            descrizioneBreve: pianificazione.turnoDescrizioneBreve ?? pianificazione.turnoAssenzaDescrizioneBreve ?? '',
            reperibilita: pianificazione.reperibilita,
            uoEsterna: pianificazione.idStruttura !== fixedProps.idStruttura || pianificazione.idDipartimento !== fixedProps.idDipartimento || pianificazione.idUnitaOperativa !== fixedProps.idUnitaOperativa,
            tipoInserimento: pianificazione.tipoInserimento as TipoInserimento,
            isTurno: pianificazione.idTurno != null
          });
        }
        temp.qualificheIds[date - 1]?.push(pianificazione.idQualifica);
        retval.push(temp);
      } else {
        const index = retval.findIndex(elem => elem.idAnagraficaSoggetto === pianificazione.idAnagraficaSoggetto);

        // nel caso di turni multipli in un'unica giornata, se almeno un turno è Manuale, rimane manuale
        if (retval[index].tipoInserimento[date - 1] === 'Calcolato')
          retval[index].tipoInserimento[date - 1] = pianificazione.tipoInserimento as TipoInserimento;

        // nel caso di turni multipli in un'unica giornata, se almeno un turno va svolto esternamente, rimane esterna
        if (!retval[index].uoEsterna[date - 1]) {
          retval[index].uoEsterna[date - 1] = pianificazione.idStruttura !== fixedProps.idStruttura || pianificazione.idDipartimento !== fixedProps.idDipartimento || pianificazione.idUnitaOperativa !== fixedProps.idUnitaOperativa;
          if (retval[index].uoEsterna[date - 1]) {
            retval[index].tooltipDati[date - 1] = {
              turnoDescrizione: pianificazione.turnoDescrizione,
              unitaOperativaNome: pianificazione.unitaOperativaNome
            }
          }
        }

        const _id = pianificazione.idTurno ?? pianificazione.idTurnoAssenza;
        if (_id) {
          retval[index].turniObj[date - 1]?.push({
            id: _id,
            descrizione: pianificazione.turnoDescrizione ?? pianificazione.turnoAssenzaDescrizione ?? '',
            descrizioneBreve: pianificazione.turnoDescrizioneBreve ?? pianificazione.turnoAssenzaDescrizioneBreve ?? '',
            reperibilita: pianificazione.reperibilita,
            uoEsterna: pianificazione.idStruttura !== fixedProps.idStruttura || pianificazione.idDipartimento !== fixedProps.idDipartimento || pianificazione.idUnitaOperativa !== fixedProps.idUnitaOperativa,
            tipoInserimento: pianificazione.tipoInserimento as TipoInserimento,
            isTurno: pianificazione.idTurno != null
          });
        }
        retval[index].qualificheIds[date - 1]?.push(pianificazione.idQualifica);
      }
    });

  /**
   * Collect all qualifiche
   */
  if (TabIndex.ANAGRAFICA === tabValue) {
    pianificazioniQualifiche
      .filter(pianificazione => {
        return ['M', 'P', 'N', 'SN', 'R'].includes(pianificazione.turnoDescrizioneBreve)
      })
      .forEach(pianificazione => {
        const date = (new Date(pianificazione.pianificazioneData)).getDate();

        if (!retval.find(elem => elem.qualificaDescrizione === pianificazione.qualificaDescrizione && elem.nominativo === pianificazione.turnoDescrizione && elem.idStruttura === -1)) {
          const temp: RiepilogoPianificazioneDTO = {
            idStruttura: -1,
            idAnagraficaSoggetto: -1,
            idQualifica: pianificazione.idQualifica,
            nominativo: pianificazione.turnoDescrizione,
            cognome: '',
            nome: '',
            codiceTurno: '',
            coloreAnagraficaSoggetto: 'Bianco',
            coloreQualifica: new Array(maxDays).fill('Bianco'),
            turniObj: new Array(maxDays).fill([]),
            quantita: new Array(maxDays).fill(''),
            qualificheIds: new Array(maxDays).fill([]),
            oreTotale: 0,
            minutiTotale: 0,
            numeroRiposi: 0,
            combinazioneTurni: '',
            turnoFisso: false,
            modificaInizioTurnoFissoCodiceTurno: false,
            qualificaDescrizione: pianificazione.qualificaDescrizione,
            qualificaDescrizioneBreve: pianificazione.qualificaDescrizioneBreve,
            numeroAddettiUnitaOperativa: new Array(maxDays).fill(0),
            numeroLegaleUnitaOperativa: new Array(maxDays).fill(0),
            numeroReperibilitaRichieste: new Array(maxDays).fill(0),
            tipoInserimento: new Array(maxDays).fill(null), // Nota: Utilizzare il tipo insermento nel turniObj
            uoEsterna: new Array(maxDays).fill(null),
            tooltipDati: new Array(maxDays).fill(null),
            statisticaTurni: []
          };

          temp.quantita[date - 1] = pianificazione.totaleNumeroAddetti + (pianificazione.totaleNumeroReperibilita > 0 ? ' (' + pianificazione.totaleNumeroReperibilita + ')' : '');
          temp.coloreQualifica[date - 1] = pianificazione.coloreValidazione;
          temp.numeroAddettiUnitaOperativa[date - 1] = pianificazione.numeroAddettiUnitaOperativa;
          temp.numeroLegaleUnitaOperativa[date - 1] = pianificazione.numeroLegaleUnitaOperativa;
          temp.numeroReperibilitaRichieste[date - 1] = pianificazione.numeroReperibilitaRichieste;

          retval.push(temp);
        } else {
          const index = retval.findIndex(elem => elem.qualificaDescrizione === pianificazione.qualificaDescrizione && elem.nominativo === pianificazione.turnoDescrizione && elem.idStruttura === -1);
          retval[index].coloreQualifica[date - 1] = pianificazione.coloreValidazione;
          retval[index].quantita[date - 1] = pianificazione.totaleNumeroAddetti + (pianificazione.totaleNumeroReperibilita > 0 ? ' (' + pianificazione.totaleNumeroReperibilita + ')' : '');

          retval[index].numeroAddettiUnitaOperativa[date - 1] = pianificazione.numeroAddettiUnitaOperativa;
          retval[index].numeroLegaleUnitaOperativa[date - 1] = pianificazione.numeroLegaleUnitaOperativa;
          retval[index].numeroReperibilitaRichieste[date - 1] = pianificazione.numeroReperibilitaRichieste;
        }
      });
  }

  return retval;
}

function createRiepilogoDatiQualifica(pianificazioniQualifica: PianificazioneQualifica[], mese: number, anno: number): RiepilogoPianificazioneQualificheDTO[] {
  const retval: RiepilogoPianificazioneQualificheDTO[] = [];
  const maxDays = daysInMonth(mese, anno);

  /**
   * Collect all qualifiche
   */
  pianificazioniQualifica.forEach(pianificazione => {
    const date = (new Date(pianificazione.pianificazioneData)).getDate();

    if (!retval.find(elem => elem.qualificaDescrizione === pianificazione.qualificaDescrizione && elem.turnoDescrizione === pianificazione.turnoDescrizione)) {
      const temp: RiepilogoPianificazioneQualificheDTO = {
        qualificaDescrizioneBreve: pianificazione.qualificaDescrizioneBreve,
        qualificaDescrizione: pianificazione.qualificaDescrizione,
        turnoDescrizione: pianificazione.turnoDescrizione,
        idQualifica: pianificazione.idQualifica,
        colore: new Array(maxDays).fill(''),
        quantita: new Array(maxDays).fill(0),
      };

      temp.quantita[date - 1] = pianificazione.totaleNumeroAddetti + (pianificazione.totaleNumeroReperibilita > 0 ? ' / (' + pianificazione.totaleNumeroReperibilita + ')' : '');
      temp.colore[date - 1] = pianificazione.coloreValidazione;
      retval.push(temp);
    } else {
      const index = retval.findIndex(elem => elem.qualificaDescrizione === pianificazione.qualificaDescrizione && elem.turnoDescrizione === pianificazione.turnoDescrizione);
      retval[index].colore[date - 1] = pianificazione.coloreValidazione;
      retval[index].quantita[date - 1] = pianificazione.totaleNumeroAddetti + (pianificazione.totaleNumeroReperibilita > 0 ? ' / (' + pianificazione.totaleNumeroReperibilita + ')' : '');
    }
  });
  return retval;
}

function selectedMonthBeforeOrEqualCurrentMonth(date: Date) {
  return isBefore(date, addMonths(setDate(today, 1).setHours(0, 0, 0, 0), 1));
}

function isApprovable(validPianificazioneAnagraficaSoggetti: PianificazioniAnagraficaSoggetti[], validPianificazioneQualifiche: PianificazioneQualifica[]) {
  // let tuttiVerde = validPianificazioneAnagraficaSoggetti.every(elem => elem.coloreValidazioneRiposi === elem.coloreValidazioneOre &&
  //   elem.coloreValidazioneOre.toLowerCase() === 'verde');
  // tuttiVerde = tuttiVerde && validPianificazioneQualifiche.every(elem => elem.coloreValidazione.toLowerCase() === 'verde');

  // return tuttiVerde;
  return true
}

function isOldPianificazione(mese: number, anno: number) {
  const currentMonth = today.getMonth() + 1;
  const currentYear = today.getFullYear();

  return anno <= currentYear && mese < currentMonth;
}

function exportPDFAnagrafica(cols: Record<string, any>[], data: Record<string, any>[], tableData: TableData, extra: Extra, pdfOptions?: PDFOptions): jsPDF {
  const { t, theme, exportDataExtra, fixedProps } = extra;
  const { MARGIN_H, HEADER_SPACE } = extra.spacing;

  let dateFestive: number[] = festiviNormali;

  if (exportDataExtra) {
    dateFestive = exportDataExtra.misc?.dateFestivi ?? festiviNormali;
  }

  const PAPER_FORMAT = pdfOptions?.format ?? 'a3';
  const PAPER_ORIENTATION = pdfOptions?.orientation ?? 'landscape';

  const MESE = (fixedProps.mese as number) ?? null; // [1, 12]
  const ANNO = (fixedProps.anno as number) ?? null;

  let referenceDate: Date | null = null;
  if (ANNO != null && MESE != null) {
    referenceDate = new Date(ANNO, MESE - 1);
  }

  let newCols: Column<RiepilogoPianificazioneDTO>[] = [];
  let header: CellDefExt[] = [];
  let modData: RowInput[] = [];

  const monthTotalDays = referenceDate ? getDaysInMonth(referenceDate) : 30;

  const getCellWidth = (field: string, section: 'HEADER' | 'BODY') => {
    let width: number | 'auto' = 7;

    if (section === 'HEADER') {
      width = 10;  // default

      const ORE_TOTALE_WIDTH = 14;
      const NUMERO_RIPOSI_WIDTH = 12;
      const ORE_LAVORATIVE_WIDTH = newCols.some(elem => elem.field === 'oreLavorative') ? 15 : 0;
      const DIFFERENZA_ORE_WIDTH = newCols.some(elem => elem.field === 'differenzaOre') ? 16 : 0;

      switch (field) {
        case 'nominativo':
          // +1 = codice turno
          width = DimensioneFogli[PAPER_FORMAT][PAPER_ORIENTATION].width -
            (
              (monthTotalDays + 1) * width +
              (MARGIN_H * 2) +
              ORE_TOTALE_WIDTH +
              NUMERO_RIPOSI_WIDTH +
              ORE_LAVORATIVE_WIDTH +
              DIFFERENZA_ORE_WIDTH
            );

          break;
        case 'oreTotale':
          width = ORE_TOTALE_WIDTH;
          break;
        case 'numeroRiposi':
          width = NUMERO_RIPOSI_WIDTH;
          break;
        case 'oreLavorative':
          width = ORE_LAVORATIVE_WIDTH;
          break;
        case 'differenzaOre':
          width = DIFFERENZA_ORE_WIDTH;
          break;
      }
    }

    return width;
  }

  const getFontSize = (field: string, section: 'HEADER' | 'BODY') => {
    let fontSize = 7;

    if (section === 'HEADER') {
      fontSize = 7; // default

      switch (field) {
        case 'oreTotale':
          fontSize = 7;
          break;
        case 'numeroRiposi':
          fontSize = 7;
          break;
      }
    } else if (section === 'BODY') {
      fontSize = 9; // default

      switch (field) {
        case 'nominativo':
          fontSize = 9;
          break;
      }
    }

    return fontSize;
  }

  if (tableData.groupedData.length > 0) {

    newCols = cols.filter((elem: Column<RiepilogoPianificazioneDTO>) => {
      return [undefined, null].includes(elem.defaultGroupOrder as any) &&
        !(elem.field as string)?.endsWith(NO_PRINT_SUFFIX) &&
        !['oreLavorative', 'differenzaOre'].includes(elem.field as string);
    });

    header = newCols.map((colonna: Record<string, any>, index) => {
      return {
        field: colonna.field,
        content: (colonna.title as string).replace(' ', '\n'),
        rowSpan: 1,
        colSpan: 1,
        styles: {
          fillColor: theme.palette.primary.main,
          textColor: '#fff',
          cellWidth: getCellWidth(colonna.field, 'HEADER'),
          halign: "center",
          valign: 'middle',
          fontSize: getFontSize(colonna.field, 'HEADER'),
        }
      } as CellDefExt
    });

    tableData.groupedData
      .sort((groupA, groupB) => groupA['value'].toLowerCase() < groupB['value'].toLowerCase() ? -1 : 1)
      .map((gruppo) => {

        // ciclo per ogni colonna
        let retval = gruppo['data'].map((riga: Record<string, unknown>) => {
          const isSoggetto = riga['idStruttura'] !== -1;

          // ciclo per ogni riga
          return header.map(({ field }) => {
            const pattern: RegExp = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/;    // yyyy-mm-ddT##:##:##

            const dateIndex = isNaN(Number(field.replace('_', ''))) ? null : Number(field.replace('_', '')) - 1;
            let val = riga[field as string] as string;

            let cellDef: CellDef = {};

            /**
             ************** GESTIONE VALORE **************
             */

            // Composite value
            const pianificazioneAS = riga['pianificazioneAnagraficaSoggetti'] as PianificazioniAnagraficaSoggetti;

            if (isSoggetto) {
              if (field === 'oreLavorative') {
                val = (pianificazioneAS.oreLavorative ?? 0) + ':' + (pianificazioneAS.minutiLavorativi?.toString().padStart(2, '0') ?? '00');
              } else if (field === 'differenzaOre') {
                const segno = pianificazioneAS.differenzaSegno ?? '';
                const ore = pianificazioneAS.differenzaOre ?? 0;
                const minuti = pianificazioneAS.differenzaMinuti?.toString().padStart(2, '0') ?? '00';
                val = segno + ore + ':' + minuti;
              } else if (field === 'oreTotale') {
                val += ':' + ((riga['minutiTotale'] as number).toString().padStart(2, '0'));
              } else if (field.startsWith('_') && dateIndex != null) {
                val = '';
                (riga['turniObj'] as any[])[dateIndex].forEach((elem: { isTurno: boolean; reperibilita: boolean; descrizioneBreve: string; }) => {
                  val += (elem.isTurno
                    ? ((elem.reperibilita ? '(' : '') + elem.descrizioneBreve + (elem.reperibilita ? ')' : ''))
                    : 'A') + ' ';
                });
                val = val.trim();
              }
            } else {
              if (dateIndex != null) {
                const reperibilitaRichiesta = (riga['numeroReperibilitaRichieste'] as number[])[dateIndex];
                if (reperibilitaRichiesta > 0) {
                  val += '(' + reperibilitaRichiesta + ')';
                }
              }
            }

            if (!isSoggetto && (['numeroRiposi', 'oreTotale'].includes(field))) {
              cellDef.content = '';
            }
            else if (val == null) {
              cellDef.content = '';
            }
            else if (pattern.test(val)) {
              cellDef.content = getDateDDMMYYYY(new Date(val));
            }
            else if (typeof val === "boolean") {
              cellDef.content = val ? t("yes") : t("no");
            }
            else cellDef.content = val;

            /**
             ************** GESTIONE STYLE **************
             */

            let fillColor = isSoggetto ? (riga['coloreCodiceTurno'] as string) : undefined;

            if (field === 'oreTotale') {
              cellDef.styles = {
                valign: 'middle',
                halign: 'center',
                fontSize: getFontSize(field, 'BODY'),
                fontStyle: isSoggetto ? 'normal' : 'italic',
                fillColor,
                cellPadding: {
                  left: 0,
                  right: 0,
                  bottom: 2,
                  top: 2,
                },
              }
            } else if (field === 'numeroRiposi') {
              cellDef.styles = {
                valign: 'middle',
                halign: 'center',
                fontSize: getFontSize(field, 'BODY'),
                fontStyle: isSoggetto ? 'normal' : 'italic',
                fillColor,
                cellPadding: {
                  left: 0,
                  right: 0,
                  bottom: 2,
                  top: 2,
                },
              }
            } else if (['nominativo'].includes(field)) {
              cellDef.styles = {
                valign: 'middle',
                halign: isSoggetto ? 'left' : 'right',
                fontStyle: isSoggetto ? 'normal' : 'italic',
                fontSize: isSoggetto ? getFontSize(field, 'BODY') : 10,
                fillColor,
                cellPadding: {
                  left: 2,
                  right: 2,
                  bottom: 2,
                  top: 2,
                },
              }
            } else if (field === 'oreLavorate') {
              cellDef.styles = {
                valign: 'middle',
                halign: 'center',
                fontSize: getFontSize(field, 'BODY'),
                fontStyle: isSoggetto ? 'normal' : 'italic',
                fillColor,
                cellPadding: {
                  left: 0,
                  right: 0,
                  bottom: 2,
                  top: 2,
                },
              }
            } else if (field === 'differenzaOre' && isSoggetto) {
              cellDef.styles = {
                valign: 'middle',
                halign: 'center',
                fontSize: getFontSize(field, 'BODY'),
                fontStyle: 'normal',
                fillColor,
                cellPadding: {
                  left: 0,
                  right: 0,
                  bottom: 2,
                  top: 2,
                },
                textColor: pianificazioneAS.differenzaSegno === '-' ? 'red' : 'green'
              }
            } else {
              // Tutte le date e codice turno
              const vals = val?.toString().split(' ') ?? [];

              let maxLenElem = 0;
              vals.forEach(elem => {
                maxLenElem = elem.length > maxLenElem ? elem.length : maxLenElem;
              });

              /**
               * Imposta lo sfondo in grigio quando è sabato o domenica
               */
              if (dateIndex != null && dateFestive.includes(dateIndex + 1)) {
                fillColor = '#cecece';
              }

              // Imposta il background rosa se il turno è stato inserito manualmente
              // if (isSoggetto && dateIndex != null) {
              //   const hasInsertimentoManuale = (riga['turniObj'] as any)[dateIndex]?.some((elem: any) => elem.tipoInserimento === 'Manuale') ?? false;
              //   if (hasInsertimentoManuale) {
              //     fillColor = 'pink';
              //   }
              // }

              const turniDelGiorno = dateIndex != null ? ((riga.turniObj as any[])?.[dateIndex] ?? []) : [];
              const tuttiPresenze = turniDelGiorno.every((elem: IdsConfigExtended) => elem.pianificazione?.idPresenza != null);
              const haTurniMultipli = dateIndex != null && (riga['turniObj'] as any[])[dateIndex].length > 1;

              const isDateAfterToday = dateIndex != null && pianificazioneAS != null ? isAfter(new Date(pianificazioneAS.anno, pianificazioneAS.mese - 1, dateIndex + 1), (new Date(getTodayEnd()))) : false;
              const haAlmenoUnaPresenzaNonAssociata = !tuttiPresenze && !isDateAfterToday;

              cellDef.styles = {
                fontSize: getFontSize(field, 'BODY'),
                valign: 'middle',
                halign: 'center',
                // fontStyle: val?.includes(' ') ? 'bold' : 'normal',
                fontStyle: haTurniMultipli && haAlmenoUnaPresenzaNonAssociata
                  ? 'bolditalic'
                  : haTurniMultipli
                    ? 'bold'
                    : haAlmenoUnaPresenzaNonAssociata
                      ? 'italic'
                      : 'normal',
                fillColor,
                cellPadding: {
                  left: 1,
                  right: 1,
                  bottom: 2,
                  top: 2,
                },
              }
            }

            return cellDef;
          }) as RowInput
        })

        return [
          [
            {
              content: t('qualificationTitle') + ': ' + gruppo['value'] + ' - ' + gruppo['data'][0]['qualificaDescrizione'],
              styles: {
                halign: 'left',
                fontStyle: 'bold',
                fillColor: '#cecece',
                fontSize: getFontSize('qualificaDescrizioneBreve', 'BODY'),
                cellPadding: {
                  top: 1,
                  bottom: 1,
                  left: 1
                }
              },
              colSpan: header.length
            }
          ],
          ...retval
        ];

      })
      .forEach(elem => {
        modData.push(...elem)
      })
  }

  const doc = new jsPDF({ orientation: PAPER_ORIENTATION, format: PAPER_FORMAT, unit: 'mm', });

  /*
   * DATI
   */
  autoTable(doc, {
    startY: HEADER_SPACE,
    margin: {
      top: HEADER_SPACE,
      horizontal: MARGIN_H
    },
    head: [header],
    body: modData,
    theme: "grid",
  });

  return doc;
}

function exportPDFQualifica(cols: Record<string, any>[], data: Record<string, any>[], tableData: TableData, extra: Extra, pdfOptions?: PDFOptions): jsPDF {
  const { t, theme, exportDataExtra, fixedProps } = extra;
  const { MARGIN_H, HEADER_SPACE } = extra.spacing;

  let dateFestive: number[] = festiviNormali;

  if (exportDataExtra) {
    dateFestive = exportDataExtra.misc?.dateFestivi ?? festiviNormali;
  }

  const PAPER_FORMAT = 'a3';
  const PAPER_ORIENTATION = "landscape";

  const MESE = (fixedProps.mese as number) ?? null; // [1, 12]
  const ANNO = (fixedProps.anno as number) ?? null;

  let referenceDate: Date | null = null;
  if (ANNO != null && MESE != null) {
    referenceDate = new Date(ANNO, MESE - 1);
  }

  const monthTotalDays = referenceDate ? getDaysInMonth(referenceDate) : 30;

  let header: CellDefExt[] = [];
  let modData: RowInput[] = [];

  const getCellWidth = (field: string, section: 'HEADER' | 'BODY') => {
    let width: number | 'auto' = 7;

    if (section === 'HEADER') {
      width = 11;  // default

      switch (field) {
        case 'turnoDescrizione':
          width = DimensioneFogli[PAPER_FORMAT][PAPER_ORIENTATION].width - ((monthTotalDays) * width + (MARGIN_H * 2));
          break;
      }
    }

    return width;
  }

  const getFontSize = (field: string, section: 'HEADER' | 'BODY') => {
    let fontSize = 7;

    if (section === 'HEADER') {
      fontSize = 7; // default

    } else if (section === 'BODY') {
      fontSize = 9; // default

      switch (field) {
        case 'turnoDescrizione':
          fontSize = 9;
          break;
      }
    }

    return fontSize;
  }

  if (tableData.groupedData.length > 0) {

    let newCols = cols.filter((elem: Record<string, any>) => {
      return [undefined, null].includes(elem.defaultGroupOrder as any)
    })

    header = newCols.map((colonna: Record<string, any>, index) => {
      const dateIndex = isNaN(Number(colonna.field.replace('_', ''))) ? null : Number(colonna.field.replace('_', '')) - 1;

      let fillColor: string | undefined = undefined;

      if (dateIndex != null && dateFestive.includes(dateIndex + 1)) {
        fillColor = '#197fec';
      }

      return {
        field: colonna.field,
        content: (colonna.title as string).replace(' ', '\n'),
        rowSpan: 1,
        colSpan: 1,
        styles: {
          fillColor: fillColor ?? theme.palette.primary.main,
          textColor: '#fff',
          cellWidth: getCellWidth(colonna.field, 'HEADER'),
          halign: "center",
          valign: 'middle',
          fontSize: getFontSize(colonna.field, 'HEADER'),
        }
      } as CellDefExt
    });

    tableData.groupedData
      .sort((groupA, groupB) => groupA['value'].toLowerCase() < groupB['value'].toLowerCase() ? -1 : 1)
      .map((gruppo) => {

        let retval = gruppo['data'].map((riga: Record<string, unknown>) => {
          return header.map(({ field }) => {
            const pattern: RegExp = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/;    // yyyy-mm-ddT##:##:##

            const dateIndex = isNaN(Number(field.replace('_', ''))) ? null : Number(field.replace('_', '')) - 1;
            let val = riga[field as string] as string;

            let cellDef: CellDef = {};

            /**
             ************** GESTIONE VALORE **************
             */

            // Composite value
            if (val == null) {
              cellDef.content = '';
            }
            else if (pattern.test(val)) {
              cellDef.content = getDateDDMMYYYY(new Date(val));
            }
            else if (typeof val === "boolean") {
              cellDef.content = val ? t("yes") : t("no");
            }
            else cellDef.content = val;

            /**
             ************** GESTIONE STYLE **************
             */

            // Tutte le date
            if (!['turnoDescrizione'].includes(field)) {
              const vals = val?.toString().split(' ') ?? [];

              let maxLenElem = 0;
              vals.forEach(elem => {
                maxLenElem = elem.length > maxLenElem ? elem.length : maxLenElem;
              });

              let fillColor = undefined;

              /**
               * Imposta lo sfondo in grigio quando è sabato o domenica
               */
              if (dateIndex != null) {
                if (dateFestive.includes(dateIndex + 1)) {
                  fillColor = '#cecece';
                }

                fillColor = Colors[riga['colore' + (dateIndex + 1)] as ColorsKeys] ?? fillColor;

                // Override color (grayscale printing)
                switch (riga['colore' + (dateIndex + 1)] as ColorsKeys) {
                  case 'Rosso':
                    fillColor = '#ff4d4d';
                    break;
                }
              }

              cellDef.styles = {
                fontSize: getFontSize(field, 'BODY'),
                valign: 'middle',
                halign: 'center',
                fontStyle: 'bold',
                fillColor,
                cellPadding: {
                  left: 1,
                  right: 1,
                  bottom: 2,
                  top: 2,
                },
              }
            } else if (['turnoDescrizione'].includes(field)) {
              cellDef.styles = {
                valign: 'middle',
                halign: 'left',
                fontStyle: 'normal',
                fontSize: getFontSize(field, 'BODY'),
                cellPadding: {
                  left: 2,
                  right: 2,
                  bottom: 2,
                  top: 2,
                },
              }
            }

            return cellDef;
          }) as RowInput
        })

        return [
          [
            {
              content: t('qualificationTitle') + ': ' + gruppo['value'] /* + ' - ' + gruppo['data'][0]['qualificaDescrizione'] */,
              styles: {
                halign: 'left',
                fontStyle: 'bold',
                fillColor: '#cecece',
                fontSize: getFontSize('qualificaDescrizioneBreve', 'BODY'),
                cellPadding: {
                  top: 1,
                  bottom: 1,
                  left: 1
                }
              },
              colSpan: header.length
            }
          ],
          ...retval
        ];

      })
      .forEach(elem => {
        modData.push(...elem)
      })

  }

  const doc = new jsPDF({ orientation: PAPER_ORIENTATION, format: PAPER_FORMAT, unit: 'mm', });

  /*
   * DATI
   */
  autoTable(doc, {
    startY: HEADER_SPACE,
    margin: {
      top: HEADER_SPACE,
      horizontal: MARGIN_H
    },
    head: [header],
    body: modData,
    theme: "grid",
  });

  if (exportDataExtra && exportDataExtra.extra)
    exportDataExtra.extra.forEach(extra =>
      autoTable(doc, {
        head: [extra.title],
        body: extra.value,
        headStyles: {
          fillColor: theme.palette.primary.main,
        }
      })
    )

  return doc;
}