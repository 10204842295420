import { validateInputNumberMin, validateRequired } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type UnitaOperativaQualificheKeys = keyof UnitaOperativaQualifiche;

export const elementIdProps: UnitaOperativaQualificheKeys[] = ["idStruttura", "idDipartimento", "idUnitaOperativa", "idQualifica"];
export const elementRenderProps: UnitaOperativaQualificheKeys[] = [];

export const allFields: Fields[] = [
  {
    field: "idQualifica", required: true, show: true, titleKey: "qualificationParam", editable: "onAdd", lookupField: true, sort: 'asc',
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
  },

  {
    field: "numeroAddetti", type: "numeric", required: false, show: true, titleKey: "EquipmentTabLabeoptimal",
    validate: (x: number, helperText: string) => validateInputNumberMin(x, 0, false, helperText, false), keyTradValidation: "greaterOrEqualThanZeroValidation",
  },
]
export interface UnitaOperativaQualifiche {

  idStruttura: number,
  idDipartimento: number,
  idUnitaOperativa: number,
  idQualifica: number,
  qualificaDescrizione: string,
  qualificaDescrizioneBreve: string,
  numeroAddetti: number,  // ottimale
  version: number

}


