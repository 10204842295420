import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { Paper, makeStyles, Theme, createStyles, Typography, Grid, Box, Modal, IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { addDays } from "date-fns";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import clsx from 'clsx';
import SituazioneUnitaOperativeW from "./tabelle/SituazioneUnitaOperativeW";
import PresenzeDaApprovareW from "./tabelle/PresenzeDaApprovareW";
import ScadenzaPeriodoProvaW from "./tabelle/ScadenzaPeriodoProvaW";
import ScadenzaContrattiW from "./tabelle/ScadenzaContrattiW";
import { ConteggioQualifica } from "../../../../models/ConteggioQualifica";
import { Presenza } from "../../../../models/Presenze";
import { ScadenzaFinePeriodoProva } from "../../../../models/ScadenzaPeriodoProva";
import { ScadenzaContratti } from "../../../../models/ScadenzaContratti";
import { fetchConteggioPerQualificaCompleto } from "../../../../store/slices/presenzeSlice";
import { fetchByIds as fetchAApprovazionePresenze } from "../../../../store/slices/approvazionePresenzeSlice";
import { fetchAll as fetchAllScadenzePeriodoProva } from "../../../../store/slices/scadenzaPeriodoProvaSlice";
import { fetchAll as fetchAllScadenzeContratti } from "../../../../store/slices/scadenzaContrattiSlice";
import { fetchAllValid } from "../../../../store/slices/timbratureSlice";
import { fetchAll as fetchAllScadenzeVisite } from "../../../../store/slices/codiciVisiteSlice";
import { Timbrature } from "../../../../models/Timbrature";
import TimbratureNonAssegnateW from "./tabelle/TimbratureNonAssegnateW";
import ScadenzeVisiteW from "./tabelle/ScadenzeVisiteW";
import { ScadenzeVisite } from "../../../../models/ScadenzeVisite";
import { getDateYYYYMMDD_BackEnd } from "../../../../utils/utilfunctions";
import { AssenzaProgrammata } from "../../../../models/AssenzeProgrammate";
import AssenzeDaApprovareW from "./tabelle/AssenzeDaApprovareW";
import { fetchAll as fetchAllFormazione } from "../../../../store/slices/formazioneSlice";
import { ScadenzaFormazione } from "../../../../models/ScadenzaFormazione";
import ScadenzeFormazioneW from "./tabelle/ScadenzeFormazioneW";
import CloseIcon from '@material-ui/icons/Close';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PresenzeMancantiW from "./tabelle/PresenzeMancantiW";
import { PresenzeMancanti } from "../../../../models/PresenzeMancanti";
import { fetchAll } from "../../../../store/slices/presenzeMancantiSlice";
import { fetchByIds } from "../../../../store/slices/assenzeProgrammateSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardContainer: {
      padding: theme.spacing(0.5),
      transition: 'all 0.3s ease',
      '&:hover': {
        transform: 'translateY(-5px)',
      }
    },
    card: {
      borderRadius: 8,
      border: `1px solid ${theme.palette.divider}`,
      backgroundColor: 'white',
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
      position: 'relative',
      overflow: 'hidden',
      '&:hover': {
        boxShadow: '0 4px 12px rgba(0,0,0,0.15)',
      },
      cursor: 'pointer',
      height: '100%'
    },
    cardHeader: {
      padding: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.primary.main,
      color: 'white'
    },
    title: {
      fontSize: '0.95rem',
      fontWeight: 500,
      color: 'white'
    },
    subtitle: {
      color: theme.palette.error.main,
      fontWeight: 'bold',
      fontSize: '0.9rem',
      marginTop: theme.spacing(0.5),
      textAlign: 'center'
    },
    badge: {
      position: 'absolute',
      top: theme.spacing(1),
      right: theme.spacing(1),
      backgroundColor: theme.palette.error.main,
      color: 'white',
      borderRadius: '50%',
      padding: theme.spacing(0.5),
      minWidth: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '0.8rem'
    },
    iconBadge: {
      position: 'absolute',
      right: theme.spacing(1),
      top: '50%',
      transform: 'translateY(-50%)',
      backgroundColor: theme.palette.error.main,
      color: 'white',
      borderRadius: '50%',
      width: 24,
      height: 24,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    sectionTitle: {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      color: theme.palette.text.secondary,
      borderBottom: `2px solid ${theme.palette.primary.main}`,
      paddingBottom: theme.spacing(0.5),
      fontSize: '1.1rem',
      fontWeight: 500
    },
    container: {
      marginTop: 60,
      maxWidth: 1500,
      maxHeight: 500,
      marginLeft: 'auto',
      marginRight: 'auto',
      overflow: 'auto',
    },
    icon: {
      position: 'absolute',
      right: theme.spacing(2),
      bottom: theme.spacing(2),
      color: theme.palette.primary.main,
      fontSize: 30
    },
  })
);

enum TabIndex {
  SITUAZIONE_UNITA_OPERATIVA,
  TIMBRATURE_NON_ASSEGNATE,
  PRESENZE_DA_APPROVARE,
  ASSENZE_DA_APPROVARE,
  SCADENZA_PERIODO_DI_PROVA,
  SCADENZA_CONTRATTI,
  SCADENZE_VISITE,
  SCADENZE_FORMAZIONE,
  PRESENZE_MANCANTI
}

const MonitoraggioW = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const MAX_NOTIFICA = 99;

  const [component, setComponent] = useState<ReactNode | null>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = () => { setIsOpen(false); }

  const handleOpen = (title: string,) => {
    setComponent(() => {
      switch (title) {
        case titles[0]:
          return <SituazioneUnitaOperativeW validData={filteredValidConteggioQualifica} />
        case titles[1]:
          return <AssenzeDaApprovareW validData={filteredValidAssenzeDaApprovare} />
        case titles[2]:
          return <PresenzeDaApprovareW validData={filteredValidPresenzeDaApprovare} />
        case titles[3]:
          return <TimbratureNonAssegnateW validData={filteredValidTimbratureNonAssegnate} />
        case titles[4]:
          return <ScadenzaPeriodoProvaW validData={filteredValidAnagraficaSoggettiFinePeriodoProva} />
        case titles[5]:
          return <ScadenzaContrattiW validData={filteredValidFineContratto} />
        case titles[6]:
          return <ScadenzeVisiteW validData={filteredValidScadenzeVisite} />
        case titles[7]:
          return <ScadenzeFormazioneW validData={filteredValidScadenzeFormazione} />
        case titles[8]:
          return <PresenzeMancantiW validData={filteredPresenzeMancanti} />
      }
    })
    setIsOpen(true);
  }

  const fixedProps = useMemo(() => {
    return {
      [TabIndex.PRESENZE_DA_APPROVARE]: {
        soloDaApprovare: true,
        inizioData: null,
        fineData: null,
      },
      [TabIndex.ASSENZE_DA_APPROVARE]: {
        soloDaApprovare: true,
        inizioData: null,
        fineData: null,
      },
      [TabIndex.SCADENZA_PERIODO_DI_PROVA]: {
        dataInizio: getDateYYYYMMDD_BackEnd(addDays(new Date(), -15)),
        dataFine: getDateYYYYMMDD_BackEnd(addDays(new Date(), 30)),
      },
      [TabIndex.SCADENZA_CONTRATTI]: {
        dataRiferimento: getDateYYYYMMDD_BackEnd(addDays(new Date(), -15)),
      },
      [TabIndex.SCADENZE_VISITE]: {
        dataRiferimento: getDateYYYYMMDD_BackEnd(new Date())
      },
      [TabIndex.SCADENZE_FORMAZIONE]: {
        dataRiferimento: getDateYYYYMMDD_BackEnd(new Date())
      },
      [TabIndex.PRESENZE_MANCANTI]: {
        dataRiferimento: getDateYYYYMMDD_BackEnd(new Date())
      }
    }
  }, []);

  useEffect(() => {
    dispatch(fetchConteggioPerQualificaCompleto());
    dispatch(fetchAllValid());
    dispatch(fetchAApprovazionePresenze(fixedProps[TabIndex.PRESENZE_DA_APPROVARE]));
    dispatch(fetchByIds(fixedProps[TabIndex.ASSENZE_DA_APPROVARE]));
    dispatch(fetchAllScadenzePeriodoProva(fixedProps[TabIndex.SCADENZA_PERIODO_DI_PROVA]));
    dispatch(fetchAllScadenzeContratti(fixedProps[TabIndex.SCADENZA_CONTRATTI]))
    dispatch(fetchAllScadenzeVisite());
    dispatch(fetchAllFormazione());
    dispatch(fetchAll())
  }, [dispatch, fixedProps]);

  const validConteggioQualifica = useAppSelector((state) => state.presenze.conteggioQualifica);
  const validPresenzeDaApprovare = useAppSelector((state) => state.approvazionePresenze.validPresenze);
  const validAssenzeDaApprovare = useAppSelector((state) => state.assenzeProgrammate.validAssenzeProgrammate);
  const validAnagraficaSoggettiFinePeriodoProva = useAppSelector((state) => state.scadenzaPeriodoProva.validScadenzaPeriodoProva);
  const validAnagraficaSoggettiDataFineContratto = useAppSelector((state) => state.scadenzaContratti.validScadenzaContratti);
  const validTimbrature = useAppSelector((state) => state.timbrature.validTimbrature);
  const validScadenzeVisite = useAppSelector((state) => state.scadenzeVisite.validScadenzeVisite);
  const validFormazione = useAppSelector((state) => state.scadenzaFormazione.validScadenzeFormazione);
  const validPresenzeMancanti = useAppSelector((state) => state.presenzeMancanti.validPresenzeMancanti)

  const [filteredValidConteggioQualifica, setFilteredValidConteggioQualifica] = useState<ConteggioQualifica[]>([]);
  const [filteredValidPresenzeDaApprovare, setFilteredValidPresenzeDaApprovare] = useState<Presenza[]>([]);
  const [filteredValidAssenzeDaApprovare, setFilteredValidAssenzeDaApprovare] = useState<AssenzaProgrammata[]>([]);
  const [filteredValidAnagraficaSoggettiFinePeriodoProva, setFilteredValidAnagraficaSoggettiFinePeriodoProva] = useState<ScadenzaFinePeriodoProva[]>([]);
  const [filteredValidFineContratto, setFilteredValidFineContratto] = useState<ScadenzaContratti[]>([]);
  const [filteredValidTimbratureNonAssegnate, setFilteredValidTimbratureNonAssegnate] = useState<Timbrature[]>([]);
  const [filteredValidScadenzeVisite, setFilteredScadenzeVisite] = useState<ScadenzeVisite[]>([]);
  const [filteredValidScadenzeFormazione, setFilteredFormazione] = useState<ScadenzaFormazione[]>([]);
  const [filteredPresenzeMancanti, setFilteredPresenzeMancanti] = useState<PresenzeMancanti[]>([]);

  useEffect(() => {
    setFilteredValidConteggioQualifica(validConteggioQualifica?.filter(elem => elem.colorMonitoring === 'Rosso'));
    setFilteredValidPresenzeDaApprovare(validPresenzeDaApprovare);
    setFilteredValidAssenzeDaApprovare(validAssenzeDaApprovare);
    setFilteredValidAnagraficaSoggettiFinePeriodoProva(validAnagraficaSoggettiFinePeriodoProva);
    setFilteredValidFineContratto(validAnagraficaSoggettiDataFineContratto);
    setFilteredValidTimbratureNonAssegnate(validTimbrature.filter(elem => !elem.presenza))
    setFilteredScadenzeVisite(validScadenzeVisite);
    setFilteredFormazione(validFormazione);
    setFilteredPresenzeMancanti(validPresenzeMancanti);
  }, [validAnagraficaSoggettiDataFineContratto, validAnagraficaSoggettiFinePeriodoProva, validPresenzeDaApprovare, validConteggioQualifica, validScadenzeVisite, validTimbrature, validAssenzeDaApprovare, validFormazione, validPresenzeMancanti]);

  const titles = useMemo(() => {
    return [
      t("Unita Operative"),
      t("Assenze"),
      t("Presenze"),
      t("Timbrature"),
      t("Periodo di Prova"),
      t("Contratti"),
      t("Scheda Sanitaria"),
      t("Formazione"),
      t("Presenze ")
    ]
  }, [t])

  const subtitles = useMemo(() => {
    return [
      "Situazione",
      "Approvazione",
      "Approvazione",
      "Assegnazione",
      "Scadenze",
      "Scadenze",
      "Scadenze",
      "Scadenze",
      "Mancanti"
    ]
  }, [])

  const dati = useMemo(() => {
    return [
      filteredValidConteggioQualifica || [],        // Unità Operative (Situazione)
      filteredValidAssenzeDaApprovare || [],        // Assenze (Approvazione)
      filteredValidPresenzeDaApprovare || [],       // Presenze (Approvazione)
      filteredValidTimbratureNonAssegnate || [],    // Timbrature (Assegnazione)
      filteredValidAnagraficaSoggettiFinePeriodoProva || [],  // Periodo di Prova (Scadenze)
      filteredValidFineContratto || [],             // Contratti (Scadenze)
      filteredValidScadenzeVisite || [],            // Scheda Sanitaria (Scadenze)
      filteredValidScadenzeFormazione || [],        // Formazione (Scadenze)
      filteredPresenzeMancanti || []                // Presenze (Mancanti)
    ]
  }, [filteredPresenzeMancanti, filteredValidAnagraficaSoggettiFinePeriodoProva, filteredValidAssenzeDaApprovare, filteredValidConteggioQualifica, filteredValidFineContratto, filteredValidPresenzeDaApprovare, filteredValidScadenzeFormazione, filteredValidScadenzeVisite, filteredValidTimbratureNonAssegnate]);

  return <>
    <Box p={1}>
      {/* Sezione Monitoraggio Operativo */}
      <Typography variant="h6" className={classes.sectionTitle}>
        Monitoraggio Operativo
      </Typography>
      <Grid container spacing={1}>
        {titles.slice(0,1).map((title, index) => (
          <Grid item xs={12} md={3} key={index}>
            <Box className={classes.cardContainer}>
              <Paper className={classes.card} onClick={() => handleOpen(title)}>
                <Box className={classes.cardHeader}>
                  <Typography className={classes.title}>{title}</Typography>
                </Box>
                <Box p={2} position="relative">
                  <Typography className={classes.subtitle}>
                    {subtitles[index]}
                  </Typography>
                  {dati[index]?.length > 0 ? (
                    <Box className={classes.badge}>
                      {dati[index]?.length > MAX_NOTIFICA ? `${MAX_NOTIFICA}+` : dati[index]?.length}
                    </Box>
                  ) : (
                    <Box className={classes.iconBadge}>
                      <ChevronRightIcon />
                    </Box>
                  )}
                </Box>
              </Paper>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Sezione Gestione Amministrativa */}
      <Typography variant="h6" className={classes.sectionTitle}>
        Gestione Amministrativa
      </Typography>
      <Grid container spacing={1}>
        {titles.slice(1,4).map((title, index) => (
          <Grid item xs={12} md={3} key={index + 1}>
            <Box className={classes.cardContainer}>
              <Paper className={classes.card} onClick={() => handleOpen(title)}>
                <Box className={classes.cardHeader}>
                  <Typography className={classes.title}>{title}</Typography>
                </Box>
                <Box p={2} position="relative">
                  <Typography className={classes.subtitle}>
                    {subtitles[index + 1]}
                  </Typography>
                  {dati[index + 1]?.length > 0 ? (
                    <Box className={classes.badge}>
                      {dati[index + 1]?.length > MAX_NOTIFICA ? `${MAX_NOTIFICA}+` : dati[index + 1]?.length}
                    </Box>
                  ) : (
                    <Box className={classes.iconBadge}>
                      <ChevronRightIcon />
                    </Box>
                  )}
                </Box>
              </Paper>
            </Box>
          </Grid>
        ))}
      </Grid>

      {/* Sezione Controlli e Scadenze */}
      <Typography variant="h6" className={classes.sectionTitle}>
        Controlli e Scadenze
      </Typography>
      <Grid container spacing={1}>
        {titles.slice(4).map((title, index) => (
          <Grid item xs={12} md={3} key={index + 4}>
            <Box className={classes.cardContainer}>
              <Paper className={classes.card} onClick={() => handleOpen(title)}>
                <Box className={classes.cardHeader}>
                  <Typography className={classes.title}>{title}</Typography>
                </Box>
                <Box p={2} position="relative">
                  <Typography className={classes.subtitle}>
                    {subtitles[index + 4]}
                  </Typography>
                  {dati[index + 4]?.length > 0 ? (
                    <Box className={classes.badge}>
                      {dati[index + 4]?.length > MAX_NOTIFICA ? `${MAX_NOTIFICA}+` : dati[index + 4]?.length}
                    </Box>
                  ) : (
                    <Box className={classes.iconBadge}>
                      <ChevronRightIcon />
                    </Box>
                  )}
                </Box>
              </Paper>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>

    <Modal
      className={clsx(classes.container)}
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper>
        <Grid container>
          <Grid item xs={9} />
          <Grid item xs={3}>
            <Box display='flex' justifyContent='right'>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs>
            {component}
          </Grid>
        </Grid>
      </Paper>
    </Modal>
  </>;
}

export default MonitoraggioW;