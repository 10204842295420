import { Column } from "@material-table/core";
import React, { useEffect, useMemo, useState, } from "react";
import { useTranslation } from "react-i18next";
import { elementIdProps, elementRenderProps, allFields, UnitaOperativaCodiceVisiteKeys } from "../../../models/UnitaOperativaCodiceVisite";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { insert, physicalDel, reset, fetchByIdUnitaOperativa as fetchAllUOCodiceVisite } from "../../../store/slices/unitaOperativaCodiceVisiteSlice";

import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { unitaOperativePath } from "../../../utils/utilconst";

import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";

import { UnitaOperativaCodiceVisite } from "../../../models/UnitaOperativaCodiceVisite";
import { PDFExtraData } from "../../../models/Utils";
import { fetchAllValid as fetchAllValidCodiceVisite } from "../../../store/slices/codiciVisiteSlice";

interface UnitaOperativaCodiceVisiteWProps {
  idStruttura: number,
  idDipartimento: number,
  idUnitaOperativa: number
}

const UnitaOperativeTurniNumeroAddettiW = ({ idStruttura, idDipartimento, idUnitaOperativa }: UnitaOperativaCodiceVisiteWProps) => {
  const { t } = useTranslation();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const dispatch = useAppDispatch();

  const abilitazione = useAppSelector(state => getAbilitazione(state, unitaOperativePath));
  const errorBE = useAppSelector(state => state.unitaOperativeTurniNumeroAddetti.error);

  const fixedProps = useMemo(() => { return { "idStruttura": idStruttura, "idDipartimento": idDipartimento, "idUnitaOperativa": idUnitaOperativa } }, [idStruttura, idDipartimento, idUnitaOperativa]);

  const title = t('visitCodeParam');

  const lookup = useAppSelector(state => state.codiciVisite.lookup)
  const lookupStr = useAppSelector(state => state.strutture.lookup);
  const lookupDip = useAppSelector(state => state.dipartimentiFiltered.lookup);
  const lookupUO = useAppSelector(state => state.unitaOperativeFiltered.lookup);

  useEffect(() => {
    dispatch(fetchAllValidCodiceVisite());
  }, [dispatch]);

  const excludedFieldInTable = useMemo(() => [], []);
  const [data, setData] = useState<Array<UnitaOperativaCodiceVisite>>([]);
  const allFieldsState = useMemo(() => allFields, []);
  const [columns, setColumns] = useState<Array<Column<UnitaOperativaCodiceVisite>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<UnitaOperativaCodiceVisite> = {
          title: f.titleKey ? t(f.titleKey) : '', field: f.field, removable: f.removable ?? !f.required, editable: f.editable ? f.editable : "always", defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as UnitaOperativaCodiceVisiteKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.lookupField) {
          switch (f.field) {
            case 'idCodiceVisita':
              obj.lookup = lookup;
          }
        }

        return obj;
      }));
  }, [t, allFieldsState, excludedFieldInTable, lookup]);

  const statusValidUnitaOperativaCodiceVisite = useAppSelector(state => state.unitaOperativaCodiceVisite.statusValidUnitaOperativaCodiceVisiteState);
  const validUnitaOperativaCodiceVisite = useAppSelector(state => state.unitaOperativaCodiceVisite.validUnitaOperativaCodiceVisite)

  // title to be finalize
  const exportDataExtra: PDFExtraData = { head: { title: [], value: [] }, extra: [] };
  Object.keys(fixedProps).forEach(elem => {
    switch (elem) {
      case 'idStruttura':
        if (exportDataExtra.head)
          exportDataExtra.head['Struttura'] = lookupStr[fixedProps[elem]];
        break;
      case 'idDipartimento':
        if (exportDataExtra.head)
          exportDataExtra.head['Dipartimento'] = lookupDip[fixedProps[elem]];
        break;
      case 'idUnitaOperativa':
        if (exportDataExtra.head)
          exportDataExtra.head['Unità Operativa'] = lookupUO[fixedProps[elem]];
        break;
    }
  });

  useEffect(() => {
    setData(validUnitaOperativaCodiceVisite);
  }, [validUnitaOperativaCodiceVisite]);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, [dispatch]);

  return <CrudMaterialTableWithoutLogicDelete
    abilitazione={abilitazione}
    title={title}
    columns={columns}
    columnsButton={true}
    data={data}
    elementIdProps={elementIdProps}
    elementRenderProps={elementRenderProps}
    fetchAllValid={fetchAllUOCodiceVisite}
    insert={insert}
    physicalDel={physicalDel}
    statusValid={statusValidUnitaOperativaCodiceVisite}
    errorBE={errorBE}
    fixedProps={fixedProps}
    logoUri={logoUri}
    extraOptions={{
      minBodyHeight: 150,
      maxBodyHeight: 460
    }}
  />
}
export default UnitaOperativeTurniNumeroAddettiW;
