import React, { useEffect, useState } from "react";
import i18n from "../i18n";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setLogoUri, fetchOrganizzazioneInfo } from "../store/slices/authInfoSlice";
import { clearFunzionalita } from "../store/slices/funzionalitaSlice";
import { forbiddenPath, monitoraggioPath, sessionLogo } from "../utils/utilconst";
import { fetchFunzionalita } from "../store/slices/funzionalitaSlice";
import { fetchById as fetchApplicazioneSoftware } from "../store/slices/applicazioniSoftwareSlice";
import useConfig from "./hooks/useConfig";
// import useChatClient from "../core/components/chatBox/useChatClient";
import useHttpInterceptor from "./hooks/useHttpInterceptor";
import useAuth from "./hooks/useAuth";
import { useHistory } from "react-router-dom";
import { setUtenteLoggato } from "../store/slices/utenteLoggatoSlice";

export const useApp = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const idOrganization = useAppSelector(state => state.authInfo.idOrganization);
  const idProfil = useAppSelector(state => state.authInfo.idProfil);
  const token = useAppSelector(state => state.authInfo.token);
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const nomeOrganizzazione = useAppSelector(state => state.authInfo.organizationName);
  const skinTheme = useAppSelector(state => state.authInfo.skinTheme);
  const user = useAppSelector(state => state.authInfo.user);

  const homeUri = useAppSelector(state => state.funzionalita.homeuri);
  const appSoftware = useAppSelector(state => state.applicazioneSoftware.appSoftware);

  const [hasIdAppSoftwareCookie, setHasIdAppSoftwareCookie] = useState<boolean>(false);

  useEffect(() => {
    if (!hasIdAppSoftwareCookie) {
      if (idProfil || token) {
        setHasIdAppSoftwareCookie(true);
      } else if (!window.location.pathname.includes(forbiddenPath)) {
        history.replace(forbiddenPath);
      }
    }
  }, [hasIdAppSoftwareCookie, history, idProfil, token]);

  const HttpHeader = React.useMemo(() => {
    return {
      Authorization: 'Bearer ' + token,
      IdOrganizzazione: idOrganization,
      lang: i18n.language,
    }
  }, [idOrganization, token]);

  /**
   * Setup chat client (EventSource)
   */
  // useChatClient({ header: HttpHeader, hasIdAppSoftwareCookie });

  /**
   * Setup Http Request and Response interceptor
   */
  useHttpInterceptor({ header: HttpHeader, hasIdAppSoftwareCookie });

  /**
   * Inizializza le configurazioni dell'applicazione web
   */
  useConfig({ hasIdAppSoftwareCookie });

  /**
   * Gestisce lo stato dell'autenticazione dell'utente
   */
  useAuth({ hasIdAppSoftwareCookie });

  useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    if (token && idProfil) {
      dispatch(fetchFunzionalita({ idAppSoft: idProfil[0], idAppSoftProfil: idProfil[1] }))
      dispatch(fetchApplicazioneSoftware({ idApplicazioneSoftware: Number(idProfil[0]) }));
    }
  }, [dispatch, hasIdAppSoftwareCookie, idProfil, token]);

  /**
   * Modifica vari elementi della pagina
   */
  useEffect(() => {
    document.title = appSoftware?.descrizione ?? '';
    document.body.style.backgroundColor = window.location.pathname === monitoraggioPath ? '#fff' : '#d4e9Ddd';
    const linkElement = document.getElementById('favicon') as HTMLLinkElement;
    if (appSoftware?.favIcon)
      linkElement.href = appSoftware.favIcon
  }, [appSoftware?.descrizione, appSoftware?.favIcon]);

  useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    const logo = sessionStorage.getItem(sessionLogo);
    if (idOrganization != null && token) {
      dispatch(fetchOrganizzazioneInfo({
        IDOrg: Number(idOrganization),
        token
      }));
    }
    else if (logo)
      dispatch(setLogoUri(logo));
  }, [dispatch, hasIdAppSoftwareCookie, idOrganization, nomeOrganizzazione, token])

  useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    if (token === null) {
      dispatch(clearFunzionalita());
    }
  }, [token, dispatch, hasIdAppSoftwareCookie]);

  /**
   * Memorizza le informazioni passate dal portale dell'utente loggato prese dal cookie
   */
  useEffect(() => {
    if (user) {
      dispatch(setUtenteLoggato(user));
    }
  }, [dispatch, user]);

  return { homeUri, logoUri, nomeOrganizzazione, hasIdAppSoftwareCookie, skinTheme };
}