import { useEffect, useState } from "react";
import { useAppDispatch } from "../../store/hooks";
import { setSessionAppPathNameAction, setSessionOrganizationAction, setSessionProfilAction, setSessionSkinThemeAction, setSessionUserAction, setToken } from "../../store/slices/authInfoSlice";
import { getTokenFromCookie } from "../../utils/utilfunctions";
import { cookieCheckTime } from "../../utils/utilconst";

const useInit = () => {
  const dispatch = useAppDispatch();

  const [t, setTimer] = useState<NodeJS.Timeout | null>(null);

  useEffect(() => {
    setTimer(state => {
      if (state) clearInterval(state);

      return setInterval(() => {
        dispatch(setToken(getTokenFromCookie()));
      }, cookieCheckTime);
    });

  }, [dispatch]);

  useEffect(() => {
    return () => {
      if (t) clearInterval(t);
    }
  }, [t])

  useEffect(() => {
    dispatch(setSessionOrganizationAction());
    dispatch(setSessionProfilAction());
    dispatch(setSessionAppPathNameAction());
    dispatch(setSessionSkinThemeAction());
    dispatch(setSessionUserAction());
  }, [dispatch]);
}

export default useInit;