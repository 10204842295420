import { validateDateXGreaterThanY } from "../utils/utilfunctions";
import { Fields } from "./Fields";
import StatusIconIndicator from '../core/components/svgs/StatusIconIndicator';

export const elementIdProps = [];
export const elementRenderProps = [];

export const allMedicoNumericaFields: Fields[] = [
    {
        field: "codiceVisitaDescrizione", titleKey: "visitCodeParam", required: false, show: true,
    },
    {
        field: "dipartimentoDescrizione", titleKey: "departmentParam", required: false, show: true,
    },

    {
        field: "unitaOperativaNome", titleKey: "operationalUnitParam", required: false, show: true,
    },

    {
        field: "qualifica", titleKey: "qualificationParam", required: false, show: true, defaultGroupOrder: 0
    },

    {
        field: "idoneitaDescrizione", titleKey: "eligibilityParam", required: false, show: true,
    },

    {
        field: "lungaAssenza", titleKey: "longAbsenceParam", type: 'numeric', required: false, show: true,
    },

    {
        field: "totale", titleKey: "totalParam", type: 'numeric', required: false, show: true,
    },
    {
        field: "totaleScadute", titleKey: "expired", type: 'numeric', required: false, show: true,
    }
];

export const allMedicoNominativaFields: Fields[] = [
    {
        field: "matricola", titleKey: "numberParam", required: false, show: true,
    },
    {
        field: "qualifica", titleKey: "qualificationParam", required: false, show: true, defaultGroupOrder: 0
    },
    {
        field: "nominativo", titleKey: "nominativeParam", required: false, show: true,
    },
    {
        field: "idoneitaDescrizione", titleKey: "resultParamVisit", required: false, show: true,
    },
    {
        field: "codiceVisitaDescrizione", titleKey: "descriptionVisit", required: false, show: true,
    },
    {
        field: "dataVisita", titleKey: "lastvisitDateParam", type: "date", required: false, show: true,
    },
    {
        field: "dataControlloSuccessivo", titleKey: "nextcheckDateParam", type: "date", required: false, show: true,
    },
    {
        field: "totaleScadute", titleKey: "", required: false, show: true,
        render: (rowData) => {
            if (rowData.dataControlloSuccessivo === undefined || validateDateXGreaterThanY(Date(), rowData.dataControlloSuccessivo, true, null, true) ? 1 : 0) {
                return <StatusIconIndicator color='red' />
            }
            return <StatusIconIndicator color='green' />
        }, exportExclude: true
    },
    {
        field: "dipartimentoDescrizione", titleKey: "departmentParam", required: false, show: true,
    },
    {
        field: "unitaOperativaNome", titleKey: "operationalUnitParam", required: false, show: true,
    },
];