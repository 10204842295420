import { Column } from "@material-table/core";
import React, { useCallback, useEffect, useMemo, useState, } from "react";
import { useTranslation } from "react-i18next";
import { Fields } from "../../../models/Fields";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { documentiDipendentePath } from "../../../utils/utilconst";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import { Box, Button, Grid, Paper, Typography, } from "@material-ui/core";
import { fetchAllLookupByAbilitazione as fetchAllSoggettiLookupByAbilitazione } from "../../../store/slices/anagraficaSoggettiSlice";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import { fetchAllValidByIdDocumentiDipendenti, resetError as resetErrorAnagraficaSoggettiDocumenti } from "../../../store/slices/anagraficaSoggettiDocumentiSlice";
import { AnteprimaModal } from "./AnagraficaSoggettiComponents/AnteprimaModal";
import { Route, Switch, } from "react-router-dom";
import { AnagraficaSoggettiDocumenti, allFieldsDipendenti, elementIdProps, elementRenderProps, AnagraficaSoggettiDocumentiKeys } from "../../../models/AnagraficaSoggettiDocumenti";
import { calculateMTableHeight } from "../../../utils/styleconst";

export interface UploadedFile {
  content: string;
  name: string;
  mediaType?: string;
  extension?: string;
}

const DocumentiDipendenteW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const resetError = useCallback(() => {
    dispatch(resetErrorAnagraficaSoggettiDocumenti());
  }, [dispatch])

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClose = () => {
    setIsOpen(false);
  }
  const handleOpen = () => {
    setIsOpen(true);
  }

  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);

  const abilitazione = useAppSelector(state => getAbilitazione(state, documentiDipendentePath));

  const title = t('documentDipendenti');

  const soggettoSelectName = useMemo(() => t('subjectRegistryTitle'), [t]);
  const soggettoSelectLabel = useMemo(() => t('registrySelect'), [t]);

  const lookupAnagraficaSoggettoPerAbilitazione = useAppSelector((state) => state.anagraficaSoggetti.lookup); const documentoLookup = useAppSelector(state => state.tipoDocumento.lookup);
  const statusValidAnagraficaSoggettiDocumenti = useAppSelector(state => state.anagraficaSoggettiDocumenti.statusValidAnagraficaSoggettiDocumenti);

  const [states, setStates] = useState<{ [selectName: string]: string | number | null }>({ [soggettoSelectName]: null });

  const fixedProps = useMemo(() => {
    return {
      idAnagraficaSoggetto: states[soggettoSelectName],
    };
  }, [states, soggettoSelectName]);

  useEffect(() => {
    dispatch(fetchAllSoggettiLookupByAbilitazione());
  }, [dispatch]);

  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFieldsDipendenti);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettiDocumenti>>>([]);
  useEffect(() => {
    return setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettiDocumenti> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        };
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as AnagraficaSoggettiDocumentiKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        if (f.type && f.type === "file") {
          obj.title = t('documents');
          obj.emptyValue = t('noFile');
          obj.render = (rowData: AnagraficaSoggettiDocumenti) => {
            return <Button
              onClick={() => {
                handleOpen();
                setUploadedFile({
                  content: rowData.contenutoDocumento,
                  name: rowData.nomeDocumento
                });
              }}
              size='small'
              variant='outlined'
              color='primary'>
              {t('show')}
            </Button>
          };
        }
        // /*+++*/
        switch (f.field) {
          case "idTipoDocumento":
            obj.lookup = documentoLookup;
            break;
        }
        return obj;
      })
    );
  }, [allFieldsState, dispatch, documentoLookup, setColumns, t]);

  const validAnagraficaSoggettiDocumenti = useAppSelector(state => state.anagraficaSoggettiDocumenti.validAnagraficaSoggettiDocumenti);
  const errorBE = useAppSelector(state => state.anagraficaSoggettiDocumenti.error);

  const [data, setData] = useState<Array<AnagraficaSoggettiDocumenti>>([]);

  useEffect(() => {
    setData(validAnagraficaSoggettiDocumenti);
  }, [validAnagraficaSoggettiDocumenti]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={6}>
              <SimpleIdSelects
                selectsArray={[
                  {
                    name: soggettoSelectName,
                    lookup: lookupAnagraficaSoggettoPerAbilitazione,
                    label: soggettoSelectLabel,
                    disabled: false,
                    breakpoints: { xs: 12, sm: 12, md: 12, lg: 10 }
                  },
                ]}
                states={states}
                setStates={setStates}
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box marginTop={2}>
        {states[soggettoSelectName]
          ? (
            <Switch>
              <Route path={documentiDipendentePath} exact>
                <CrudMaterialTableWithoutLogicDelete
                  abilitazione={abilitazione}
                  title={title}
                  columns={columns}
                  columnsButton={true}
                  data={data}
                  elementIdProps={elementIdProps}
                  elementRenderProps={elementRenderProps}
                  fetchAllValid={fetchAllValidByIdDocumentiDipendenti}
                  statusValid={statusValidAnagraficaSoggettiDocumenti}
                  errorBE={errorBE}
                  logoUri={logoUri}
                  localizedDatePicker={true}
                  fixedProps={fixedProps}
                  resetErrorCallback={resetError}
                  extraOptions={{
                    minBodyHeight: calculateMTableHeight(0, false, true),
                    maxBodyHeight: calculateMTableHeight(0, false, true),
                  }}
                />
                {
                  uploadedFile &&
                  <AnteprimaModal
                    open={isOpen}
                    handleClose={handleClose}
                    file={uploadedFile}
                  />
                }
              </Route>
            </Switch>
          ) : (
            <Paper elevation={2}>
              <Box p={4}>
                <Typography align="center" variant="h5">
                  {soggettoSelectLabel}
                </Typography>
              </Box>
            </Paper>
          )}
      </Box>
    </>
  )
}
export default DocumentiDipendenteW;
