import { useState, useEffect } from "react";
import { Pianificazione } from "../../../../../models/Pianificazioni";
import { MappaTurniOre, RiepilogoPianificazioneDTO } from "../../PianificazioniPreventivoW";
import { OrderedListOfTurni, prepareData } from "./Calendar";

interface CalendarHookProps {
  currentSelectedAS?: RiepilogoPianificazioneDTO | null;
  mappaTurniOre: MappaTurniOre;
  dateToShow: Date[];
  selectedMonth?: Date | null;
  selectedIdQualifica?: number;
  riepilogoAnagraficaModifica?: RiepilogoPianificazioneDTO | null;
  fixedProps: {
    idStruttura: number;
    idDipartimento: number;
    idUnitaOperativa: number;
    mese: number;
    anno: number;
  };
  isApprovato: boolean;
  pianificazioni?: Pianificazione[];
}

// TODO: Memoize prepare data

const useCalendar = (props: CalendarHookProps) => {
  const {
    currentSelectedAS, mappaTurniOre, selectedMonth
  } = props;

  const dayOfOne: number | null = selectedMonth ? new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1).getDay() : null;

  const [preparedData, setPreparedData] = useState<OrderedListOfTurni[][]>([]);
  // const [mapPreparedData, setMapPreparedData] = useState<Record<number, OrderedListOfTurni[][]>>({});

  useEffect(() => {
    const setPreparedDataAsync = async () => {
      if (currentSelectedAS && dayOfOne != null) {
        let _result = prepareData(currentSelectedAS, mappaTurniOre, dayOfOne);
        // let _result = mapPreparedData[currentSelectedAS.idAnagraficaSoggetto];

        // if (!_result) {
        //   _result = prepareData(currentSelectedAS, mappaTurniOre, dayOfOne);
        //   setMapPreparedData(state => {
        //     return {
        //       ...state,
        //       [currentSelectedAS.idAnagraficaSoggetto]: _result
        //     }
        //   });
        // }

        setPreparedData(_result);
      }
    }

    setPreparedDataAsync();
  }, [currentSelectedAS, dayOfOne, /* mapPreparedData, */ mappaTurniOre]);

  return {
    preparedData,
    // setMapPreparedData
  }
}

export default useCalendar