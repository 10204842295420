import { useEffect, useMemo, useState } from "react";
import { Column } from "@material-table/core";
import { useTranslation } from "react-i18next";
import {
  allDotazioneNumericaFields,
  allDotazioneNominativaFields,
  allDotazioneRiepilogoTotaleFields
} from "../../../models/DotazioneOrganica";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { dotazioneOrganicaPath, notFoundPath } from "../../../utils/utilconst";
import Paper from "@material-ui/core/Paper";
import {
  AppBar,
  Box,
  createStyles,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
  FormControlLabel,
  FormControl,
  Switch as MuiSwitch
} from "@material-ui/core";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { Switch, Route, Redirect } from "react-router-dom";
import { getDateDDMMYYYY, sortAscObjectsBy } from '../../../utils/utilfunctions';
import { Fields } from "../../../models/Fields";
import { PDFExtraData, PDFOptions } from '../../../models/Utils';
import ReadOnlyMaterialTable from '../tables/ReadOnly/ReadOnlyMaterialTable';
import i18n from "../../../i18n";
import { it, enGB } from "date-fns/locale";
import { AnagraficaSoggettoCurrentData, AnagraficaSoggettoCurrentDataKeys } from "../../../models/AnagraficaSoggetti";
import { fetchAllValidByIdStrutturaInForza, reset as resetAnagraficaSoggetti } from '../../../store/slices/anagraficaSoggettiSlice';
import { lookup as fetchLookupStrutture, reset as resetStrutture } from '../../../store/slices/struttureSlice';
import { lookupFiltered as fetchLookupDipartimenti, reset as resetDipartimentiFiltered } from '../../../store/slices/dipartimentiFilteredSlice';
import TabPanel from "../tabs/TabPanel/TabPanel";
import { calculateMTableHeight } from "../../../utils/styleconst";
import { ColumnExt, Extra, TableData } from "../../../utils/data.types";
import jsPDF from "jspdf";
import autoTable, { CellDef, RowInput } from "jspdf-autotable";
import { DimensioneFogli } from "../../../utils/utildata";
import { fetchAllExtendedByIdStruttura } from "../../../store/slices/unitaOperativeSlice";

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
    indicator: {
      backgroundColor: "#fff",
    },
    legendTopOffset: {
      marginTop: -75,
    }
  }),
);

interface DotazioneNumericaModel {
  idDipartimento: number;
  idUnitaOperativa: number;
  idQualifica?: number | null;
  idCentroDiCosto: number;
  unitaOperativaNome: string;
  dipartimentoDescrizione: string | null;
  qualificaDescrizioneBreve: string;
  qualificaIncaricoDescrizioneBreve: string;
  unitaOperativa: string;           // utilizzato per raggruppamento
  qualificaIncarico: string;      // utilizzato per raggruppamento
  centroDiCostoCodice?: string;
  totale: number;

  postiLetto?: number | null;

  // Totale Assegnati al CDC
  totaleCdc?: number;             // h24Cdc + h12Cdc + partTimeCdc + assenze6MesiCdc
  h24Cdc?: number;
  h12Cdc?: number;
  partTimeCdc?: number;
  assenze6MesiCdc?: number;

  l104?: number;

  // Limitazioni
  totaleLimit?: number;           // per il momento 0
  movimentiCarichiLimit?: number;
  esoneroTurniLimit?: number;
  altroLimit?: number;

  // UO
  unitaPrevisteUO?: number;       // per il momento 0
  totalePresentiUO?: number;      // totaleCdc - assenze6MesiCdc
  carentiEsuberoUO?: number;      // unitaPrevisteUO - totalePresentiUO
}

type TCurrentValidAnagraficaSoggetti = Partial<AnagraficaSoggettoCurrentData> & Partial<DotazioneNumericaModel>;

interface RiepilogoTotali {
  qualificaIncarico: string;
  totaleCdc: number;
  h24Cdc: number;
  h12Cdc: number;
  partTimeCdc: number;
  assenze6MesiCdc: number;
  l104: number;
  totaleLimit: number;
}

enum TabIndex {
  DOTAZIONE_ORGANICA_NUMERICA,
  DOTAZIONE_ORGANICA_NOMINATIVA,
  DOTAZIONE_ORGANICA_RIEPILOGO_TOTALE,
}

const DotazioneOrganicaW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector((state) => state.authInfo.logoUri);
  const classes = useStyles1();

  const strSelectName = useMemo(() => t("structuresTitle"), [t]);
  const strSelectLabel = useMemo(() => t("structureSelect"), [t]);
  const dateSelectName = useMemo(() => t("dateTitle"), [t]);
  const dateSelectLabel = useMemo(() => t("dateSelect"), [t]);

  const [states, setStates] = useState<{
    [selectName: string]: number | string | null;
  }>({
    [strSelectName]: null,
    [dateSelectName]: null,
  });

  const fixedProps = useMemo(() => {
    return {
      idStruttura: states[strSelectName],
      presenzaData: states[dateSelectName],
    };
  }, [states, strSelectName, dateSelectName]);

  const [isFixedPropsChanged, setIsFixedPropsChanged] = useState(false)
  useEffect(() => {
    setIsFixedPropsChanged(true);
  }, [fixedProps.idStruttura, fixedProps.presenzaData]);

  const idDipartimentoConst = "idDipartimentoUnitaOperativa";

  const lookupStr = useAppSelector((state) => state.strutture.lookup);
  const lookupDip = useAppSelector(state => state.dipartimentiFiltered.lookup);
  const lookupDipObject = useAppSelector(state => state.dipartimentiFiltered.lookupObject);
  const unitaOperative = useAppSelector(state => state.unitaOperative.validUnitaOperativa);
  // const [unitaOperative, setUnitaOperative] = useState<UnitaOperativa[]>([]);

  useEffect(() => {
    if (fixedProps.idStruttura != null) {
      dispatch(fetchAllExtendedByIdStruttura({ idStruttura: fixedProps.idStruttura as number }))
    }
  }, [dispatch, fixedProps.idStruttura]);

  /**
   * Tabs
   */
  const [tabValue, setTabValue] = useState<TabIndex | null>(TabIndex.DOTAZIONE_ORGANICA_NUMERICA);
  const handleTabIndexChange = (event: React.ChangeEvent<{}>, newValue: TabIndex) => {
    setTabValue(newValue);
  };
  const handleTabChange = (index: TabIndex) => {
    return {
      id: 'tab-' + index,
      'aria-controls': 'tabpanel-' + index,
    };
  }

  /**
   * Lunga Assenza controller
   */
  const [isLA, setIsLA] = useState<boolean>(true);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsLA(state => !state);
  };

  const handleDateChange = (d: Date | null, field?: string) => {
    let fieldDate: string = '';
    if (d) {
      let month = (d.getMonth() + 1).toString().padStart(2, '0');
      let day = d.getDate().toString().padStart(2, '0');
      fieldDate = d.getFullYear() + "-" + month + "-" + day + "T00:00:00";
    }
    setStates(prev => { return { ...prev, [dateSelectName]: fieldDate } });
  };

  useEffect(() => {
    dispatch(fetchLookupStrutture());
  }, [dispatch]);

  useEffect(() => {
    if (fixedProps.idStruttura && fixedProps.presenzaData) {
      if (isFixedPropsChanged)
        dispatch(fetchAllValidByIdStrutturaInForza({ idStruttura: Number(fixedProps.idStruttura), presenzaData: fixedProps.presenzaData.toString() }));
      dispatch(fetchLookupDipartimenti({ idStruttura: Number(fixedProps.idStruttura) }));
    }
  }, [dispatch, fixedProps.idStruttura, fixedProps.presenzaData, isFixedPropsChanged]);

  /**
   * update allfields on tabValue change
   */
  useEffect(() => {
    switch (tabValue) {
      case TabIndex.DOTAZIONE_ORGANICA_NUMERICA:
        setAllFieldsState(allDotazioneNumericaFields);
        break;
      case TabIndex.DOTAZIONE_ORGANICA_NOMINATIVA:
        setAllFieldsState(allDotazioneNominativaFields);
        break;
      case TabIndex.DOTAZIONE_ORGANICA_RIEPILOGO_TOTALE:
        setAllFieldsState(allDotazioneRiepilogoTotaleFields);
        break;
    }
  }, [tabValue]);

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allDotazioneNumericaFields);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettoCurrentData>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: ColumnExt<AnagraficaSoggettoCurrentData> = {
          title: f.titleKey ? t(f.titleKey) : "",
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          sorting: false,
          external: { fieldData: f }
        };

        if (f.validate2) {
          obj.validate = (rowData) => {
            let resp: boolean | { isValid: boolean, helperText: string } = false;
            let resp2: boolean | { isValid: boolean, helperText: string } = false;

            if (f.validate2)
              resp2 = f.validate2(
                rowData[f.field as AnagraficaSoggettoCurrentDataKeys],
                rowData[f.field2Validation as AnagraficaSoggettoCurrentDataKeys],
                f.keyTradValidation2 ? t(f.keyTradValidation2) : ''
              );

            if (f.validate) {
              resp = f.validate(
                rowData[f.field as AnagraficaSoggettoCurrentDataKeys],
                f.keyTradValidation ? t(f.keyTradValidation) : ''
              );
            }
            if (resp === true) {
              return resp2;
            } else return resp;
          }
        } else if (f.validate) {
          obj.validate = (rowData) => {
            if (f.validate)
              return f.validate(
                rowData[f.field as AnagraficaSoggettoCurrentDataKeys],
                f.keyTradValidation ? t(f.keyTradValidation) : ''
              );
            return false
          }
        }

        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.render) {
          obj.render = f.render;
        }

        if (f.defaultGroupOrder != null) {
          obj.defaultGroupOrder = f.defaultGroupOrder;
        }

        if (f.field === 'unitaOperativaNome') {
          obj.cellStyle = { minWidth: "10px" }
          obj.headerStyle = { minWidth: "10px" }
        }

        if (tabValue === TabIndex.DOTAZIONE_ORGANICA_NUMERICA) {
          // Default styles
          obj.headerStyle = {
            fontSize: '0.75em',
            lineHeight: '1.25em',
            textAlign: 'center',
            padding: '3px'
          };
          obj.cellStyle = {
            padding: "5px",
            border: '1px solid #cecece',
            boxSizing: 'border-box'
          }

          const less = 150;

          if (["unitaOperativaNome"].includes(f.field)) {
            const left = 150 - less;
            const zIndex = 100;

            obj.headerStyle = {
              ...obj.headerStyle,
              backgroundColor: '#eee',
              zIndex: zIndex + 10,
              position: 'sticky',
              left,
            };
            obj.cellStyle = {
              ...obj.cellStyle,
              padding: '0px',
              fontWeight: 'bold',
              backgroundColor: '#fff',
              zIndex: zIndex,
              position: 'sticky',
              left,
              border: undefined
            };
            obj.render = (rowData: TCurrentValidAnagraficaSoggetti) => {
              return <Box
                style={{
                  boxSizing: 'border-box',
                  width: '200px',
                  lineHeight: '1.25em',
                  padding: '5px',
                  paddingLeft: '20px',
                  fontSize: '0.75em'
                }}
              >
                {rowData[f.field as keyof TCurrentValidAnagraficaSoggetti] ?? ''}
              </Box>
            }
          } else if (["qualificaIncarico"].includes(f.field)) {
            const left = 350 - less;
            const zIndex = 100;

            obj.headerStyle = {
              ...obj.headerStyle,
              backgroundColor: '#eee',
              zIndex: zIndex + 10,
              position: 'sticky',
              left,
            };
            obj.cellStyle = {
              ...obj.cellStyle,
              padding: '0px',
              backgroundColor: '#fff',
              zIndex: zIndex,
              position: 'sticky',
              left,
              border: undefined
            };
            obj.render = (rowData: TCurrentValidAnagraficaSoggetti) => {
              return <Box
                style={{
                  boxSizing: 'border-box',
                  minHeight: '27px',
                  width: '360px',
                  lineHeight: '1.25em',
                  padding: '5px',
                  borderRight: '1px solid #cecece',
                  fontSize: '0.75em'
                }}
              >
                {rowData[f.field as keyof TCurrentValidAnagraficaSoggetti] ?? ''}
              </Box>
            }
          } else if (["note"].includes(f.field)) {
            obj.cellStyle = {
              ...obj.cellStyle,
              borderLeft: undefined
            };
            obj.render = (rowData: TCurrentValidAnagraficaSoggetti) => {
              return <Box
                style={{
                  width: '300px',
                  fontSize: '0.75em',
                  lineHeight: '1.25em',
                }}
              >
                {rowData[f.field as keyof TCurrentValidAnagraficaSoggetti] ?? ''}
              </Box>
            }
          } else if (["postiLetto"].includes(f.field)) {
            obj.render = (rowData: TCurrentValidAnagraficaSoggetti) => {
              return <Box
                style={{
                  width: '150px',
                  padding: '5px',
                  lineHeight: '1.25em',
                  fontSize: '0.75em'
                }}
              >
                {rowData[f.field as keyof TCurrentValidAnagraficaSoggetti] ?? ''}
              </Box>
            }
          } else if (!['unitaOperativa'].includes(f.field)) {
            obj.headerStyle = {
              ...obj.headerStyle,
              // padding: '3px',
              minWidth: '60px',
              maxWidth: '60px'
            };
            obj.cellStyle = {
              ...obj.cellStyle,
              padding: '0px',
              paddingRight: '5px'
            };
          }
        }

        /*+++*/
        if (tabValue === TabIndex.DOTAZIONE_ORGANICA_NUMERICA)
          switch (f.field) {
            case idDipartimentoConst:
              obj.lookup = lookupDip;
              break;
          }
        /*+++*/
        return obj;
      })
    );
  }, [t, allFieldsState, excludedFieldInTable, tabValue, lookupDip]);

  const errorBE = useAppSelector(state => state.anagraficaSoggetti.error);
  const validAnagraficaSoggetti = useAppSelector((state) => state.anagraficaSoggetti.validAnagraficaSoggetti);
  const statusValidAnagraficaSoggetti = useAppSelector((state) => state.anagraficaSoggetti.statusValidAnagraficaSoggetti);

  const [riepilogoTotaliDati, setRiepilogoTotaliDati] = useState<RiepilogoTotali[]>([]);

  const [data, setData] = useState<Array<AnagraficaSoggettoCurrentData | DotazioneNumericaModel>>([]);

  const [currentValidAnagraficaSoggetti, setCurrentValidAnagraficaSoggetti] = useState<TCurrentValidAnagraficaSoggetti[]>(validAnagraficaSoggetti);
  useEffect(() => {
    const _data = isLA ? validAnagraficaSoggetti : validAnagraficaSoggetti.filter(e => !e.combinazioneTurni || e.combinazioneTurni.toLowerCase() !== 'la');

    setCurrentValidAnagraficaSoggetti(() => {

      return _data.map(elem => {
        let partTimeInfo = undefined;

        if (elem.partTime != null) {
          partTimeInfo = elem.partTimeTipo + ' (' + elem.partTime + ')';
        }

        return {
          ...elem,
          nominativo: elem.cognome?.trim() + ' ' + elem.nome?.trim(),
          idDipartimento: elem.idDipartimentoUnitaOperativa,
          dipartimento: elem.dipartimentoDescrizione?.trim(),
          unitaOperativa: elem.unitaOperativaNome?.trim() + ' (' + elem.dipartimentoDescrizione?.trim() + ')',
          qualifica: elem.qualificaIncaricoDescrizioneBreve != null ? (elem.qualificaIncaricoDescrizioneBreve?.trim() + ' - ' + elem.qualificaIncaricoDescrizione?.trim()) : (elem.qualificaDescrizioneBreve?.trim() + ' - ' + elem.qualificaDescrizione?.trim()),
          partTimeInfo,
          legge104Numero: elem.legge104Numero === 0 ? null : elem.legge104Numero
        } as TCurrentValidAnagraficaSoggetti;
      })
    });
  }, [isLA, validAnagraficaSoggetti]);

  useEffect(() => {
    const newData: Array<Partial<DotazioneNumericaModel>> = [];

    unitaOperative.forEach(uo => {
      const _newData: Required<DotazioneNumericaModel> = {
        idUnitaOperativa: uo.idUnitaOperativa,
        idDipartimento: uo.idDipartimento,
        idCentroDiCosto: uo.idCentroDiCosto,
        idQualifica: null,
        unitaOperativaNome: uo.nome,
        dipartimentoDescrizione: uo.dipartimentoNome, //uo.dipartimentoDescrizione,
        qualificaDescrizioneBreve: '',
        qualificaIncaricoDescrizioneBreve: '',
        unitaOperativa: uo.nome,
        qualificaIncarico: '',
        centroDiCostoCodice: lookupDipObject.find(elem => elem.idDipartimento === uo.idDipartimento)?.centroDiCostoCodice ?? '',
        totale: 0,
        postiLetto: null,
        totaleCdc: 0,
        h24Cdc: 0,
        h12Cdc: 0,
        partTimeCdc: 0,
        assenze6MesiCdc: 0,
        l104: 0,
        totaleLimit: 0,
        movimentiCarichiLimit: 0,
        esoneroTurniLimit: 0,
        altroLimit: 0,
        unitaPrevisteUO: 0,
        totalePresentiUO: 0,
        carentiEsuberoUO: 0
      }

      newData.push(_newData);
    });

    const TOTAL_TABLE: Record<string, RiepilogoTotali> = {
      TOTAL: {
        qualificaIncarico: t('totalLabel'),
        totaleCdc: 0,
        h24Cdc: 0,
        h12Cdc: 0,
        partTimeCdc: 0,
        assenze6MesiCdc: 0,
        l104: 0,
        totaleLimit: 0
      }
    }

    currentValidAnagraficaSoggetti.forEach(current => {
      /**
       * Controlla se esiste il dato sull'array,
       * se esiste, ciclo su newData e aggiorno il totale
       * altrimenti, aggiungo il nuovo entry sull'array
      */

      const idQualifica = current.idQualificaIncarico ?? current.idQualifica;
      const qualificaDescrizione = (current.idQualificaIncarico != null ? current.qualificaIncaricoDescrizione : current.qualificaDescrizione) ?? '';

      let isUpdate = false;
      let tipoTurno: 'h12' | 'h24' | 'pt' | 'assenza' = 'h12';

      if (current.combinazioneTurniDescrizioneBreve?.toLowerCase() === 'la') tipoTurno = 'assenza';
      else if (current.partTime != null) tipoTurno = 'pt';
      else if (current.combinazioneTurniDescrizioneBreve?.toLowerCase() === 'h24') tipoTurno = 'h24';
      else tipoTurno = 'h12';

      for (let i = 0; i < newData.length; i++) {

        if (newData[i].idDipartimento === current.idDipartimento
          && newData[i].idUnitaOperativa === current.idUnitaOperativa
          && newData[i].idQualifica === idQualifica
        ) {

          (newData[i].totale!)++;

          newData[i].postiLetto = current.postiLetto;

          // Totale Assegnati al CDC
          (newData[i].totaleCdc!)++;
          newData[i].h24Cdc! += (tipoTurno === 'h24' ? 1 : 0);
          newData[i].h12Cdc! += (tipoTurno === 'h12' ? 1 : 0);
          newData[i].partTimeCdc! += (tipoTurno === 'pt' ? 1 : 0);
          newData[i].assenze6MesiCdc! += (tipoTurno === 'assenza' ? 1 : 0);

          newData[i].l104! += (current.legge104Numero != null ? 1 : 0);

          // Limitazioni
          newData[i].totaleLimit = 0;           // per il momento 0
          newData[i].movimentiCarichiLimit = 0;
          newData[i].esoneroTurniLimit = 0;
          newData[i].altroLimit = 0;

          // UO
          newData[i].unitaPrevisteUO = 0;       // per il momento 0
          newData[i].totalePresentiUO = newData[i].totaleCdc! - newData[i].assenze6MesiCdc!;      // totaleCdc - assenze6MesiCdc
          newData[i].carentiEsuberoUO = newData[i].assenze6MesiCdc! - newData[i].totalePresentiUO!;      // unitaPrevisteUO - totalePresentiUO

          TOTAL_TABLE.TOTAL.totaleCdc++;
          TOTAL_TABLE.TOTAL.h24Cdc += (tipoTurno === 'h24' ? 1 : 0);
          TOTAL_TABLE.TOTAL.h12Cdc += (tipoTurno === 'h12' ? 1 : 0);
          TOTAL_TABLE.TOTAL.partTimeCdc += (tipoTurno === 'pt' ? 1 : 0);
          TOTAL_TABLE.TOTAL.assenze6MesiCdc += (tipoTurno === 'assenza' ? 1 : 0);
          TOTAL_TABLE.TOTAL.l104 += (current.legge104Numero != null ? 1 : 0);
          TOTAL_TABLE.TOTAL.totaleLimit = 0;

          if (idQualifica != null && TOTAL_TABLE[idQualifica] != null) {
            TOTAL_TABLE[idQualifica].totaleCdc++;
            TOTAL_TABLE[idQualifica].h24Cdc += (tipoTurno === 'h24' ? 1 : 0);
            TOTAL_TABLE[idQualifica].h12Cdc += (tipoTurno === 'h12' ? 1 : 0);
            TOTAL_TABLE[idQualifica].partTimeCdc += (tipoTurno === 'pt' ? 1 : 0);
            TOTAL_TABLE[idQualifica].assenze6MesiCdc += (tipoTurno === 'assenza' ? 1 : 0);
            TOTAL_TABLE[idQualifica].l104 += (current.legge104Numero != null ? 1 : 0);
            TOTAL_TABLE[idQualifica].totaleLimit = 0;
          }

          isUpdate = true;
          break;
        }
      }

      if (!isUpdate) {
        const NOT_FOUND = -1;
        let ind = newData.findIndex(elem => {
          return elem.idUnitaOperativa === current.idUnitaOperativa
            && elem.idDipartimento === current.idDipartimento
            && elem.idQualifica == null;
        });

        if (ind !== NOT_FOUND) {
          newData[ind] = {
            ...newData[ind],
            idQualifica: current.idQualificaIncarico,
            qualificaDescrizioneBreve: current.qualificaDescrizioneBreve ?? '',
            qualificaIncaricoDescrizioneBreve: current.qualificaIncaricoDescrizioneBreve ?? '',
            qualificaIncarico: current.qualificaIncaricoDescrizioneBreve + ' - ' + current.qualificaIncaricoDescrizione,
            dipartimentoDescrizione: newData[ind].dipartimentoDescrizione ?? current.dipartimentoDescrizione,
            totale: 1,

            postiLetto: current.postiLetto,

            // Totale Assegnati al CDC
            totaleCdc: 1,
            h24Cdc: tipoTurno === 'h24' ? 1 : 0,
            h12Cdc: tipoTurno === 'h12' ? 1 : 0,
            partTimeCdc: tipoTurno === 'pt' ? 1 : 0,
            assenze6MesiCdc: tipoTurno === 'assenza' ? 1 : 0,

            l104: current.legge104Numero != null ? 1 : 0,

            // Limitazioni
            totaleLimit: 0,           // per il momento 0
            movimentiCarichiLimit: 0,
            esoneroTurniLimit: 0,
            altroLimit: 0,

            // UO
            unitaPrevisteUO: 0,       // per il momento 0
            totalePresentiUO: 1 - (tipoTurno === 'assenza' ? 1 : 0),      // totaleCdc - assenze6MesiCdc
            carentiEsuberoUO: (tipoTurno === 'assenza' ? 1 : 0) - 1,      // unitaPrevisteUO - totalePresentiUO
          }

          TOTAL_TABLE.TOTAL.totaleCdc++;
          TOTAL_TABLE.TOTAL.h24Cdc += (tipoTurno === 'h24' ? 1 : 0);
          TOTAL_TABLE.TOTAL.h12Cdc += (tipoTurno === 'h12' ? 1 : 0);
          TOTAL_TABLE.TOTAL.partTimeCdc += (tipoTurno === 'pt' ? 1 : 0);
          TOTAL_TABLE.TOTAL.assenze6MesiCdc += (tipoTurno === 'assenza' ? 1 : 0);
          TOTAL_TABLE.TOTAL.l104 += (current.legge104Numero != null ? 1 : 0);
          TOTAL_TABLE.TOTAL.totaleLimit = 0;

          if (idQualifica != null) {
            if (TOTAL_TABLE[idQualifica] == null) {
              TOTAL_TABLE[idQualifica] = {
                qualificaIncarico: qualificaDescrizione,
                totaleCdc: 1,
                h24Cdc: (tipoTurno === 'h24' ? 1 : 0),
                h12Cdc: (tipoTurno === 'h12' ? 1 : 0),
                partTimeCdc: (tipoTurno === 'pt' ? 1 : 0),
                assenze6MesiCdc: (tipoTurno === 'assenza' ? 1 : 0),
                l104: (current.legge104Numero != null ? 1 : 0),
                totaleLimit: 0,
              }
            } else {
              TOTAL_TABLE[idQualifica].totaleCdc++;
              TOTAL_TABLE[idQualifica].h24Cdc += (tipoTurno === 'h24' ? 1 : 0);
              TOTAL_TABLE[idQualifica].h12Cdc += (tipoTurno === 'h12' ? 1 : 0);
              TOTAL_TABLE[idQualifica].partTimeCdc += (tipoTurno === 'pt' ? 1 : 0);
              TOTAL_TABLE[idQualifica].assenze6MesiCdc += (tipoTurno === 'assenza' ? 1 : 0);
              TOTAL_TABLE[idQualifica].l104 += (current.legge104Numero != null ? 1 : 0);
              TOTAL_TABLE[idQualifica].totaleLimit = 0;
            }
          }
        } else {
          ind = newData.findIndex(elem => {
            return elem.idUnitaOperativa === current.idUnitaOperativa
              && elem.idDipartimento === current.idDipartimento;
          });

          newData.push({
            idDipartimento: current.idDipartimentoUnitaOperativa,
            idUnitaOperativa: current.idUnitaOperativa,
            idQualifica: current.idQualificaIncarico ?? current.idQualifica,
            idCentroDiCosto: newData[ind]?.idCentroDiCosto,

            centroDiCostoCodice: newData[ind]?.centroDiCostoCodice ?? '',
            qualificaDescrizioneBreve: current.qualificaDescrizioneBreve ?? '',
            qualificaIncaricoDescrizioneBreve: current.qualificaIncaricoDescrizioneBreve ?? '',
            qualificaIncarico: current.qualificaIncaricoDescrizioneBreve + ' - ' + current.qualificaIncaricoDescrizione,
            unitaOperativa: current.unitaOperativaNome + ' (' + current.dipartimentoDescrizione + ')',
            dipartimentoDescrizione: current.dipartimentoDescrizione,
            unitaOperativaNome: current.unitaOperativaNome,
            totale: 1,

            postiLetto: current.postiLetto,

            // Totale Assegnati al CDC
            totaleCdc: 1,
            h24Cdc: tipoTurno === 'h24' ? 1 : 0,
            h12Cdc: tipoTurno === 'h12' ? 1 : 0,
            partTimeCdc: tipoTurno === 'pt' ? 1 : 0,
            assenze6MesiCdc: tipoTurno === 'assenza' ? 1 : 0,

            l104: current.legge104Numero != null ? 1 : 0,

            // Limitazioni
            totaleLimit: 0,           // per il momento 0
            movimentiCarichiLimit: 0,
            esoneroTurniLimit: 0,
            altroLimit: 0,

            // UO
            unitaPrevisteUO: 0,       // per il momento 0
            totalePresentiUO: 1 - (tipoTurno === 'assenza' ? 1 : 0),      // totaleCdc - assenze6MesiCdc
            carentiEsuberoUO: (tipoTurno === 'assenza' ? 1 : 0) - 1,      // unitaPrevisteUO - totalePresentiUO
          });

          TOTAL_TABLE.TOTAL.totaleCdc++;
          TOTAL_TABLE.TOTAL.h24Cdc += (tipoTurno === 'h24' ? 1 : 0);
          TOTAL_TABLE.TOTAL.h12Cdc += (tipoTurno === 'h12' ? 1 : 0);
          TOTAL_TABLE.TOTAL.partTimeCdc += (tipoTurno === 'pt' ? 1 : 0);
          TOTAL_TABLE.TOTAL.assenze6MesiCdc += (tipoTurno === 'assenza' ? 1 : 0);
          TOTAL_TABLE.TOTAL.l104 += (current.legge104Numero != null ? 1 : 0);
          TOTAL_TABLE.TOTAL.totaleLimit = 0;

          if (idQualifica != null) {
            if (TOTAL_TABLE[idQualifica] == null) {
              TOTAL_TABLE[idQualifica] = {
                qualificaIncarico: qualificaDescrizione,
                totaleCdc: 1,
                h24Cdc: (tipoTurno === 'h24' ? 1 : 0),
                h12Cdc: (tipoTurno === 'h12' ? 1 : 0),
                partTimeCdc: (tipoTurno === 'pt' ? 1 : 0),
                assenze6MesiCdc: (tipoTurno === 'assenza' ? 1 : 0),
                l104: (current.legge104Numero != null ? 1 : 0),
                totaleLimit: 0,
              }
            } else {
              TOTAL_TABLE[idQualifica].totaleCdc++;
              TOTAL_TABLE[idQualifica].h24Cdc += (tipoTurno === 'h24' ? 1 : 0);
              TOTAL_TABLE[idQualifica].h12Cdc += (tipoTurno === 'h12' ? 1 : 0);
              TOTAL_TABLE[idQualifica].partTimeCdc += (tipoTurno === 'pt' ? 1 : 0);
              TOTAL_TABLE[idQualifica].assenze6MesiCdc += (tipoTurno === 'assenza' ? 1 : 0);
              TOTAL_TABLE[idQualifica].l104 += (current.legge104Numero != null ? 1 : 0);
              TOTAL_TABLE[idQualifica].totaleLimit = 0;
            }
          }
        }
      }
    });

    setRiepilogoTotaliDati(Object.values(TOTAL_TABLE));

    setData(tabValue === TabIndex.DOTAZIONE_ORGANICA_NOMINATIVA
      ? currentValidAnagraficaSoggetti as AnagraficaSoggettoCurrentData[]
      : sortAscObjectsBy(newData as DotazioneNumericaModel[], ['unitaOperativaNome'])
    );
  }, [currentValidAnagraficaSoggetti, lookupDipObject, t, tabValue, unitaOperative]);

  const [exportDataExtra, setExportDataExtra] = useState<PDFExtraData>(() => {
    const _idStruttura = fixedProps['idStruttura'];
    const _presenzaData = fixedProps['presenzaData'];

    return {
      head: {
        title: [t("structureTitle"), t("dateTitle")],
        value: [
          _idStruttura ? lookupStr[_idStruttura] : null,
          _presenzaData ? getDateDDMMYYYY(new Date(_presenzaData)) : null,
        ]
      },
      misc: {
        currentValidAnagraficaSoggetti
      }
    }
  });

  /**
   * set export data (head and additional) based on selected tab
   */
  useEffect(() => {
    if (tabValue === TabIndex.DOTAZIONE_ORGANICA_NUMERICA) {
      setExportDataExtra(() => {
        const _idStruttura = fixedProps['idStruttura'];
        const _presenzaData = fixedProps['presenzaData'];

        return {
          head: {
            title: [t("structureTitle"), t("dateTitle"), t("includeLongAbsenceTitle")],
            value: [
              _idStruttura ? lookupStr[_idStruttura] : null,
              _presenzaData ? getDateDDMMYYYY(new Date(_presenzaData)) : null,
              t(isLA ? 'yes' : 'no'),
            ]
          },
          misc: { riepilogoTotaliDati }
        }
      });
    } else {
      setExportDataExtra(() => {
        const _idStruttura = fixedProps['idStruttura'];
        const _presenzaData = fixedProps['presenzaData'];

        return {
          head: {
            title: [t("structureTitle"), t("dateTitle"), t("includeLongAbsenceTitle")],
            value: [
              _idStruttura ? lookupStr[_idStruttura] : null,
              _presenzaData ? getDateDDMMYYYY(new Date(_presenzaData)) : null,
              t(isLA ? 'yes' : 'no'),
            ]
          }
        }
      });
    }
  }, [fixedProps, isLA, lookupDip, lookupStr, riepilogoTotaliDati, t, tabValue]);

  useEffect(() => {
    return () => {
      dispatch(resetDipartimentiFiltered());
      dispatch(resetAnagraficaSoggetti());
      dispatch(resetStrutture());
    }
  }, [dispatch])

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={4}>
              <SimpleIdSelects
                selectsArray={[
                  {
                    name: strSelectName,
                    lookup: lookupStr,
                    label: strSelectLabel,
                    disabled: false,
                    breakpoints: { xs: 12, sm: 12, md: 12, lg: 12 }
                  },
                ]}
                states={states}
                setStates={setStates}
              />
            </Grid>
            {
              states[strSelectName] &&
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === "it-IT" || i18n.language === "it" ? it : enGB}>
                  <DatePicker
                    variant="inline"
                    label={dateSelectLabel}
                    format={"dd/MM/yyyy"}
                    onChange={(e) => handleDateChange(e)}
                    autoOk={true}
                    value={states[dateSelectName] ? states[dateSelectName] : null}
                    fullWidth
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
            {
              states[strSelectName] && states[dateSelectName] &&
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <FormControl>
                  <FormControlLabel
                    control={
                      <MuiSwitch
                        checked={isLA}
                        onChange={handleChange}
                        name="lungaAssenza"
                        color="primary"
                      />
                    }
                    labelPlacement="start"
                    label={t("includeLongAbsenceLabel")}
                  />
                </FormControl>
              </Grid>
            }
          </Grid>
        </Box>
      </Paper>
      <Box marginTop={2}>
        {
          states[strSelectName] &&
            states[dateSelectName] ? (
            <>
              <Switch>
                <Route path={dotazioneOrganicaPath} exact>
                  <>
                    <AppBar position="static" className={classes.appbar}>
                      <Tabs value={tabValue} onChange={handleTabIndexChange} classes={{ indicator: classes.indicator }}>
                        <Tab label={t("numericalTabLabel")} {...handleTabChange(TabIndex.DOTAZIONE_ORGANICA_NUMERICA)} />
                        <Tab label={t("nominativeTabLabel")} {...handleTabChange(TabIndex.DOTAZIONE_ORGANICA_NOMINATIVA)} />
                        <Tab label={t("summaryTabLabel")} {...handleTabChange(TabIndex.DOTAZIONE_ORGANICA_RIEPILOGO_TOTALE)} />
                      </Tabs>
                    </AppBar>
                    {/* DOTAZIONE ORGANICA NUMERICA */}
                    <TabPanel value={tabValue} index={TabIndex.DOTAZIONE_ORGANICA_NUMERICA}>
                      <ReadOnlyMaterialTable
                        title={t("numericalOrganicEquipmentTabLabel")}
                        columns={columns}
                        data={data}
                        fetchAllValid={fetchAllValidByIdStrutturaInForza}
                        statusValid={statusValidAnagraficaSoggetti}
                        errorBE={errorBE}
                        logoUri={logoUri}
                        fixedProps={fixedProps}
                        exportDataExtra={exportDataExtra}
                        isExportLandscape={true}
                        localizedDatePicker={true}
                        extraOptions={{
                          doubleHorizontalScroll: true,
                          minBodyHeight: calculateMTableHeight(0, true, true),
                          maxBodyHeight: calculateMTableHeight(0, true, true),
                        }}
                        pdfOptions={{
                          format: 'a3'
                        }}
                        exportPDFCallback={exportPDFNumerica}
                      />
                    </TabPanel>
                    {/* DOTAZIONE ORGANICA NOMINATIVA */}
                    <TabPanel value={tabValue} index={TabIndex.DOTAZIONE_ORGANICA_NOMINATIVA}>
                      <ReadOnlyMaterialTable
                        title={t("nameOrganicEquipmentTabLabel")}
                        columns={columns}
                        data={data}
                        fetchAllValid={fetchAllValidByIdStrutturaInForza}
                        statusValid={statusValidAnagraficaSoggetti}
                        errorBE={errorBE}
                        logoUri={logoUri}
                        fixedProps={fixedProps}
                        exportDataExtra={exportDataExtra}
                        isExportLandscape={true}
                        localizedDatePicker={true}
                        extraOptions={{
                          minBodyHeight: calculateMTableHeight(0, true, true),
                          maxBodyHeight: calculateMTableHeight(0, true, true),
                        }}
                        exportPDFCallback={exportPDFNominativa}
                      />
                    </TabPanel>
                    {/* DOTAZIONE ORGANICA RIEPILOGO TOTALE */}
                    <TabPanel value={tabValue} index={TabIndex.DOTAZIONE_ORGANICA_RIEPILOGO_TOTALE}>
                      <ReadOnlyMaterialTable
                        title={t("summaryTabLabel")}
                        columns={columns}
                        data={riepilogoTotaliDati}
                        fetchAllValid={fetchAllValidByIdStrutturaInForza}
                        statusValid={statusValidAnagraficaSoggetti}
                        errorBE={errorBE}
                        logoUri={logoUri}
                        fixedProps={fixedProps}
                        exportDataExtra={exportDataExtra}
                        isExportLandscape={true}
                        localizedDatePicker={true}
                        extraOptions={{
                          doubleHorizontalScroll: true,
                          minBodyHeight: calculateMTableHeight(0, true, true),
                          maxBodyHeight: calculateMTableHeight(0, true, true),
                        }}
                        pdfOptions={{
                          format: 'a4'
                        }}
                        exportPDFCallback={exportPDFRiepilogo}
                      />
                    </TabPanel>
                  </>
                </Route>
                <Route>
                  <Redirect to={notFoundPath} />
                </Route>
              </Switch>
            </>
          ) : (
            <Switch>
              <Route path={dotazioneOrganicaPath} exact>
                <Paper elevation={2}>
                  <Box p={4}>
                    <Typography align="center" variant="h5">
                      {!states[strSelectName]
                        ? strSelectLabel
                        : dateSelectLabel}
                    </Typography>
                  </Box>
                </Paper>
              </Route>
              <Route><Redirect to={dotazioneOrganicaPath} /></Route>
            </Switch>
          )}
      </Box>
    </>
  );
};

export default DotazioneOrganicaW;

function exportPDFNominativa(cols: Record<string, any>[], _data: Record<string, any>[], tableData: TableData, extra: Extra, pdfOptions?: PDFOptions): jsPDF {
  const { t, theme } = extra;
  const { HEADER_SPACE, TABLE_MARGIN_BOTTOM, MARGIN_H } = extra.spacing;

  const PAPER_FORMAT = pdfOptions?.format ?? 'a4';
  const PAPER_ORIENTATION = pdfOptions?.orientation ?? 'portrait';

  const DEPT_TITLE_SPACE = 15;

  const filteredCols = cols.filter(elem => !elem.external?.fieldData.exportExclude);

  let header: Record<string, unknown>[] = [];

  const groupsTitle = filteredCols.filter(col => col.defaultGroupOrder != null).map(col => col.title);

  let newCols = filteredCols.filter((elem: Record<string, any>) => {
    return [undefined, null].includes(elem.defaultGroupOrder as any)
  });

  header = newCols.map((colonna: Record<string, any>) => {
    const retval = {
      ...colonna,
      cellWidth: { colonna: colonna.width ? (colonna.width as number) / 5 : 'auto' } as object | number,
      styles: {
        fillColor: theme.palette.primary.main,
        halign: 'center',
        valign: 'middle'
      }
    }

    return retval;
  });

  const tabelle: { title: string, data: RowInput[] }[] = [];

  (tableData.groupedData as Record<string, any>[]).forEach((gruppo) => {
    const baseFillColor = 220;
    const step = 15;

    const mapData = (data: Record<string, unknown>[]) => {
      return data.map((riga: Record<string, unknown>) => {
        return header.map((colonna, index) => {
          const pattern: RegExp = /^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}$/;    // yyyy-mm-ddT##:##:##
          const val = riga[colonna.field as string] as string;
          let cellDef: CellDef = {};

          if (pattern.test(val)) {
            cellDef.content = getDateDDMMYYYY(new Date(val));
          } else if (typeof val === "boolean") {
            cellDef.content = val ? t("yes") : t("no");
          } else cellDef.content = val;

          if (cellDef.content == null) {
            cellDef.content = colonna.defaultValue as string ?? "";
          }
          if (newCols[index] && newCols[index].cellStyle && newCols[index].cellStyle['backgroundColor']) {
            cellDef.styles = { fillColor: newCols[index].cellStyle['backgroundColor'] };
          }

          if (colonna.type === 'numeric') {
            cellDef.styles = {
              ...cellDef.styles,
              halign: 'right',
              valign: 'middle'
            }
          } else if (['date', 'datetime'].includes(colonna.type as string)) {
            cellDef.styles = {
              ...cellDef.styles,
              cellWidth: 25,
              halign: 'center',
              valign: 'middle'
            }
          }

          if (index === 0) {
            cellDef.styles = {
              ...cellDef.styles,
              cellPadding: index === 0 ? {
                top: 2,
                left: 5,
                right: 5
              } : undefined
            }
          }

          // Override
          if (['dataAssunzione', 'dataFineContratto', 'tipoContratto', 'combinazioneTurniDescrizioneBreve', 'legge104Numero'].includes(colonna.field as string)) {
            const cellWidth = 25;
            if (cellDef.styles) {
              cellDef.styles.cellWidth = cellWidth;
            } else {
              cellDef.styles = { cellWidth };
            }
          } else if (['partTimeTipo', 'matricola', 'combinazioneTurniDescrizioneBreve'].includes(colonna.field as string)) {
            const cellWidth = 20;
            if (cellDef.styles) {
              cellDef.styles.cellWidth = cellWidth;
            } else {
              cellDef.styles = { cellWidth };
            }
          } else if (['idoneitaDescrizione'].includes(colonna.field as string)) {
            const cellWidth = 50;
            if (cellDef.styles) {
              cellDef.styles.cellWidth = cellWidth;
            } else {
              cellDef.styles = { cellWidth };
            }
          }

          return cellDef;
        }) as RowInput
      }) as RowInput[][]
    }

    const mapGroup = (group: Record<string, unknown>, _level: number) => {
      const subGroup = group['groups'] as any[];
      const groupData = group['data'] as any[];

      let retval: Record<string, unknown>[][] = [
        [
          {
            content: (groupsTitle[_level + 1] ?? '') + ': ' + (group['value'] ?? ''),
            styles: {
              halign: 'left',
              fontStyle: 'bold',
              fillColor: baseFillColor + _level * step,
              cellPadding: {
                top: 2,
                bottom: 2,
                left: _level === 0 ? 2 : _level * 10,
                right: 2
              }
            },
            colSpan: header.length
          } as Record<string, unknown>
        ]
      ];

      if (subGroup?.length > 0) {
        subGroup.forEach(elem => {
          retval = retval.concat(mapGroup(elem, _level + 1))
        })
      } else {
        retval = retval.concat(mapData(groupData) as any[][]);

        const total = groupData.length;
        retval.push([
          {
            content: t('totalParam') + ': ' + total,
            colSpan: header.length,
            styles: {
              halign: 'right',
              fontStyle: 'bold',
              cellPadding: {
                top: 2,
                bottom: 2,
                right: 5
              }
            }
          }
        ])
      }

      return retval;
    }

    let level = 0;

    // Inizio algoritmo
    if (gruppo['groups']?.length > 0) { // Se esistono sotto gruppi
      const dati = {
        title: gruppo['value'] ?? '',
        data: [] as RowInput[]
      };
      gruppo['groups'].forEach((_gruppo: Record<string, unknown>,) => {
        dati.data = dati.data.concat(mapGroup(_gruppo, level));
      });
      tabelle.push(dati);
    }
  });

  const reworkData = (data: RowInput[], headerLength: number) => {
    return data.length > 0
      ? data
      : [[{
        content: t('noDataPresentLabel'),
        styles: {
          halign: 'center',
          fontStyle: 'italic'
        } as CellDef,
        colSpan: headerLength
      }]] as RowInput[];
  }

  const doc = new jsPDF({ orientation: PAPER_ORIENTATION, format: PAPER_FORMAT, });

  doc.setFontSize(12);

  // DATI
  tabelle.forEach((tabella, index) => {
    if (index > 0) {
      doc.addPage(PAPER_FORMAT, PAPER_ORIENTATION);
    }

    doc.text(
      tabella.title ?? '',
      DimensioneFogli[PAPER_FORMAT][PAPER_ORIENTATION].width / 2,
      HEADER_SPACE + TABLE_MARGIN_BOTTOM,
      {
        align: 'center'
      }
    );

    autoTable(doc, {
      startY: HEADER_SPACE + DEPT_TITLE_SPACE,
      margin: {
        top: HEADER_SPACE,
        horizontal: MARGIN_H
      },
      head: [header],
      body: reworkData(tabella.data, header.length),
      theme: "grid",
    });
  });

  return doc;
}

function exportPDFNumerica(cols: Record<string, any>[], _data: Record<string, any>[], tableData: TableData, extra: Extra, pdfOptions?: PDFOptions): jsPDF {
  const { t, theme } = extra;
  const { HEADER_SPACE, MARGIN_H } = extra.spacing;

  const PAPER_FORMAT = pdfOptions?.format ?? 'a4';
  const PAPER_ORIENTATION = pdfOptions?.orientation ?? 'portrait';

  const riepilogoTotaliDati = extra.exportDataExtra?.misc?.riepilogoTotaliDati as any[];

  const excludedFields = ['note'];    // Temporarily excluded

  const filteredCols = cols
    .filter(elem => !elem.external?.fieldData.exportExclude)
    .filter(elem => !excludedFields.includes(elem.field));

  let header: Record<string, unknown>[] = [];
  let modData: RowInput[] = [];

  if (tableData.groupedData.length > 0) {

    // const whiteColumns = ['h24Cdc', 'h12Cdc', 'partTimeCdc', 'l104', 'movimentiCarichiLimit', 'esoneroTurniLimit', 'altroLimit', 'totalePresentiUO'];
    const grayColumns = ['totaleCdc', 'assenze6MesiCdc', 'totaleLimit', 'unitaPrevisteUO', 'carentiEsuberoUO'];
    const boldColumns = ['totaleCdc', 'totaleLimit', 'carentiEsuberoUO'];

    const groupsTitle = filteredCols.filter(col => col.defaultGroupOrder != null).map(col => col.title);

    let newCols = filteredCols/* .filter((elem: Record<string, any>) => {
      return [undefined, null].includes(elem.defaultGroupOrder as any)
    }) */;

    header = newCols.map((colonna: Record<string, any>) => {
      let title = colonna.title;
      switch (colonna.field) {
        case 'assenze6MesiCdc':
          title = colonna.title.replace('≥', '>=')
          break;
        case 'movimentiCarichiLimit':
          title = t('loadMovementShortParam');
          break;
        case 'partTimeCdc':
          title = t('partTimeShortParam');
          break;
      }
      const col: CellDef = {
        ...colonna,
        title,
        styles: {
          fillColor: theme.palette.primary.main,
          halign: 'center',
          valign: 'middle',
          fontSize: 8,
          cellWidth: 10,
          cellPadding: {
            top: 1,
            bottom: 1,
            left: 1,
            right: 1
          }
        }
      };

      // Override
      // const DIP_WIDTH = 40;
      const UO_WIDTH = 100;
      const QUALIFICA_WIDTH = 16;
      const POSTI_LETTO_WIDTH = 30;
      const ALTRO_WIDTH = 11;
      const ALTRO_WIDTH_LARGO = 17;

      const normalFontSizeColumns = ['h24Cdc', 'h12Cdc', 'partTimeCdc', 'l104'];
      const normalFontSizeColumns1 = ['altroLimit', 'movimentiCarichiLimit', 'esoneroTurniLimit', 'unitaPrevisteUO', 'totalePresentiUO', 'carentiEsuberoUO'];
      const largoColumns = ['totaleCdc', 'assenze6MesiCdc', 'totaleLimit'];

      // Altro	Unità Previste	Totale Presenti	Unità Carenti / Esubero

      if (['dipartimentoDescrizione'].includes(colonna.field)) {
        col.styles = {
          ...col.styles,
          // cellWidth: DIP_WIDTH
          cellWidth: DimensioneFogli[PAPER_FORMAT][PAPER_ORIENTATION].width - (
            (MARGIN_H * 2) +
            (ALTRO_WIDTH * normalFontSizeColumns.length) +
            (ALTRO_WIDTH_LARGO * largoColumns.length) +
            (ALTRO_WIDTH_LARGO * normalFontSizeColumns1.length) +
            // DIP_WIDTH +
            POSTI_LETTO_WIDTH + QUALIFICA_WIDTH + UO_WIDTH)
        }
      } else if (['unitaOperativaNome'].includes(colonna.field)) {
        col.styles = {
          ...col.styles,
          cellWidth: UO_WIDTH
        }
      } else if (['qualificaIncarico'].includes(colonna.field)) {
        col.styles = {
          ...col.styles,
          cellWidth: QUALIFICA_WIDTH
        }
      } else if (['note'].includes(colonna.field)) {
        col.styles = {
          ...col.styles,
          cellWidth: DimensioneFogli[PAPER_FORMAT][PAPER_ORIENTATION].width - (
            (MARGIN_H * 2) +
            (ALTRO_WIDTH * normalFontSizeColumns.length) +
            (ALTRO_WIDTH_LARGO * largoColumns.length) +
            (ALTRO_WIDTH_LARGO * normalFontSizeColumns1.length) +
            // DIP_WIDTH +
            POSTI_LETTO_WIDTH + QUALIFICA_WIDTH + UO_WIDTH)
        }
      } else if (['postiLetto'].includes(colonna.field)) {
        col.styles = {
          ...col.styles,
          cellWidth: POSTI_LETTO_WIDTH
        }
      } else {
        col.styles = {
          ...col.styles,
          fontSize: col.styles!.fontSize, //normalFontSizeColumns.includes(colonna.field) || largoColumns.includes(colonna.field) ? col.styles.fontSize : 5,
          cellWidth: largoColumns.includes(colonna.field) || normalFontSizeColumns1.includes(colonna.field) ? ALTRO_WIDTH_LARGO : ALTRO_WIDTH
        }
      }

      return col;
    }) as Record<string, unknown>[];

    const sortedGroupedData = sortAscObjectsBy(tableData.groupedData as Record<string, any>[], ['value']);

    sortedGroupedData.forEach((gruppo) => {
      const baseFillColor = 220;
      const step = 15;

      const mapData = (_data: Record<string, unknown>[], _level: number) => {
        const data = sortAscObjectsBy(_data, ['unitaOperativaNome', 'qualificaIncarico'])
        return data.map((riga: Record<string, unknown>, rowIndex: number, arr: Record<string, unknown>[]) => {
          return header.map((colonna) => {
            const value = riga[colonna.field as string] as string;
            let cellDef: CellDef = {};

            if (colonna.field === 'qualificaIncarico')
              cellDef.content = (riga['qualificaIncaricoDescrizioneBreve'] || riga['qualificaDescrizioneBreve']) as string;
            else if (colonna.field === 'dipartimentoDescrizione') {
              const cdc = [riga['centroDiCostoCodice'], riga['dipartimentoDescrizione']].filter(elem => (elem !== '' && elem != null));
              cellDef.content = cdc.join(' - ');
            } else
              cellDef.content = value;

            if (cellDef.content == null) {
              cellDef.content = "";
            }

            cellDef.styles = {
              ...cellDef.styles,
              fontSize: 8,
              lineColor: 10,
              cellPadding: {
                top: 1,
                bottom: 1,
                left: 1,
                right: 1
              }
            };

            if (colonna.type === 'numeric') {
              cellDef.styles = {
                ...cellDef.styles,
                halign: 'right',
                valign: 'middle'
              };
            } else if (['date', 'datetime'].includes(colonna.type as string)) {
              cellDef.styles = {
                ...cellDef.styles,
                halign: 'center',
                valign: 'middle'
              }
            }

            if (grayColumns.includes(colonna.field as string)) {
              cellDef.styles = {
                ...cellDef.styles,
                fontStyle: boldColumns.includes(colonna.field as string) ? 'bold' : 'normal',
                fillColor: baseFillColor + _level * step,
              }
            }

            // Merge and center rows
            if (['dipartimentoDescrizione', 'unitaOperativaNome'].includes(colonna.field as string)) {
              cellDef.styles = {
                ...cellDef.styles,
                fontSize: 8,
                fontStyle: 'unitaOperativaNome' === colonna.field ? 'bold' : undefined,
              }

              let counter: number | undefined = 1;
              for (let i = rowIndex; i < arr.length; i++) {
                if (i < arr.length - 1 && riga[colonna.field as string] === arr[i + 1][colonna.field as string]) counter++;
                else if (rowIndex > 0 && riga[colonna.field as string] === arr[rowIndex - 1][colonna.field as string]) {
                  counter = undefined;
                  break;
                }
                else {
                  break;
                }
              }

              if (!counter) return undefined;

              cellDef = {
                ...cellDef,
                rowSpan: counter,
                styles: {
                  ...cellDef.styles,
                  valign: 'middle'
                }
              }
            }

            return cellDef;
          }) as RowInput
        }) as RowInput[][]
      }

      const mapGroup = (group: Record<string, unknown>, _level: number) => {
        const subGroup = group['groups'] as any[];
        const groupData = group['data'] as any[];

        let retval: Record<string, unknown>[][] = [
          [
            {
              content: groupsTitle[_level - 1] + ': ' + group['value'],
              styles: {
                halign: 'left',
                fontStyle: 'bold',
                fillColor: baseFillColor + _level * step,
                cellPadding: {
                  top: 2,
                  bottom: 2,
                  left: _level * 10,
                  right: 2
                }
              },
              colSpan: header.length
            } as Record<string, unknown>
          ]
        ];

        if (subGroup?.length > 0) {
          subGroup.forEach(elem => {
            retval = retval.concat(mapGroup(elem, _level + 1))
          })
        } else {
          retval = retval.concat(mapData(groupData, _level) as any[][])
        }

        return retval;
      }

      let level = 1;

      let tableRows: any[] = [];

      if (gruppo['groups']?.length > 0) { // Se esistono sotto gruppi
        gruppo['groups'].forEach((_gruppo: Record<string, unknown>,) => {
          tableRows = tableRows.concat(mapGroup(_gruppo, level + 1));
        })
      } else {  // mappare i dati
        // Eliminare le colonne definito undefined (rowSpan)
        tableRows = tableRows.concat(mapData(gruppo['data'], level) as any[][]).map(elem => elem.filter((e: any) => e));
      }

      modData = modData.concat(tableRows)
    });


    /**
     * Inserisce all'ultima riga il conteggio dei totali
     */

    const totaliLabelKey = 'postiLetto';

    const totali = {
      ...riepilogoTotaliDati[riepilogoTotaliDati.length - 1],
      [totaliLabelKey]: riepilogoTotaliDati[riepilogoTotaliDati.length - 1]['qualificaIncarico']
    };
    delete totali.qualificaIncarico;

    const ROW_TOTAL = header.map((colonna, index) => {
      const borderColor = 10;

      const field = colonna.field as string;
      const cellDef: CellDef = {};

      cellDef.styles = {
        ...cellDef.styles,
        fontSize: 8,
        fontStyle: 'bold',
        lineColor: borderColor,
        halign: 'right',
        valign: 'middle',
        cellPadding: {
          top: 1,
          bottom: 1,
          left: 1,
          right: 1
        }
      };

      if (Object.keys(totali).includes(colonna.field as string)) {
        cellDef.content = totali[field];

        if (field === totaliLabelKey) {
          cellDef.styles.halign = 'center';
        }

        return cellDef
      } else {
        const borderWidth = 0.1;

        cellDef.content = '';
        cellDef.styles.lineWidth = {
          left: 0,
          right: 0,
          top: borderWidth,
          bottom: 0
        };

        const totaliLength = Object.keys(totali).length;
        const offset = header.findIndex(elem => elem.field === totaliLabelKey);
        const lastIndex = totaliLength + offset;  // indice della colonna che ha un valore 'Totale'

        if (index === lastIndex) {
          cellDef.styles.lineWidth = {
            left: borderWidth,
            right: 0,
            top: borderWidth,
            bottom: 0
          }
        }

        return cellDef;
      }
    })

    modData.push(ROW_TOTAL);
  }

  const reworkData = (data: RowInput[], headerLength: number) => {
    return data.length > 0
      ? data
      : [[{
        content: t('noDataPresentLabel'),
        styles: {
          halign: 'center',
          fontStyle: 'italic'
        } as CellDef,
        colSpan: headerLength
      }]] as RowInput[];
  }

  const doc = new jsPDF({ orientation: PAPER_ORIENTATION, format: PAPER_FORMAT, });

  /*
   * DATI
   */
  autoTable(doc, {
    startY: HEADER_SPACE,
    margin: {
      top: HEADER_SPACE,
      horizontal: MARGIN_H,
    },
    head: [header],
    body: reworkData(modData, header.length),
    headStyles: {
      fillColor: theme.palette.primary.main,
      halign: 'center',
      cellPadding: 2,
      valign: 'middle'
    },
    theme: "grid",
    styles: {
      fontSize: pdfOptions && pdfOptions.bodyFontSize ? pdfOptions.bodyFontSize : 10,
    },
  });

  return doc;
}

function exportPDFRiepilogo(cols: Record<string, any>[], _data: Record<string, any>[], tableData: TableData, extra: Extra, pdfOptions?: PDFOptions): jsPDF {
  const { t, theme } = extra;
  const { HEADER_SPACE, MARGIN_H } = extra.spacing;

  const PAPER_FORMAT = pdfOptions?.format ?? 'a4';
  const PAPER_ORIENTATION = pdfOptions?.orientation ?? 'portrait';

  const filteredCols = cols.filter(elem => !elem.external?.fieldData.exportExclude);

  let header: Record<string, unknown>[] = [];
  let modData: RowInput[] = [];

  // const grayColumns = ['totaleCdc', 'assenze6MesiCdc', 'totaleLimit', 'unitaPrevisteUO', 'carentiEsuberoUO'];
  // const boldColumns = ['totaleCdc', 'totaleLimit', 'carentiEsuberoUO'];

  let newCols = filteredCols.filter((elem: Record<string, any>) => {
    return [undefined, null].includes(elem.defaultGroupOrder as any)
  });

  header = newCols.map((colonna: Record<string, any>) => {
    let title = colonna.title;
    switch (colonna.field) {
      case 'assenze6MesiCdc':
        title = colonna.title.replace('≥', '>=')
        break;
      case 'partTimeCdc':
        title = t('partTimeShortParam');
        break;
    }
    const col = {
      ...colonna,
      title,
      cellWidth: { colonna: colonna.width ? (colonna.width as number) / 5 : 'auto' },
      styles: {
        fillColor: theme.palette.primary.main,
        halign: 'center',
        valign: 'middle',
        fontSize: 8,
        cellWidth: 10
      }
    };

    // Override
    const ALTRO_WIDTH_LARGO = 19;

    const normalFontSizeColumns = ['h24Cdc', 'h12Cdc', 'partTimeCdc', 'l104'];
    const largoColumns = ['totaleCdc', 'assenze6MesiCdc', 'totaleLimit'];

    if (['qualificaIncarico'].includes(colonna.field)) {
      col.styles = {
        ...col.styles,
        cellWidth: DimensioneFogli[PAPER_FORMAT][PAPER_ORIENTATION].width - (
          (MARGIN_H * 2) +
          (ALTRO_WIDTH_LARGO * (largoColumns.length + normalFontSizeColumns.length)))
      }
    } else {
      col.styles = {
        ...col.styles,
        fontSize: col.styles.fontSize, //normalFontSizeColumns.includes(colonna.field) || largoColumns.includes(colonna.field) ? col.styles.fontSize : 5,
        cellWidth: ALTRO_WIDTH_LARGO // largoColumns.includes(colonna.field) || normalFontSizeColumns1.includes(colonna.field) ? ALTRO_WIDTH_LARGO : ALTRO_WIDTH
      }
    }

    return col;
  });

  // const baseFillColor = 220;
  // const step = 15;

  const mapData = (data: Record<string, unknown>[], _level: number) => {
    return data.map((riga: Record<string, unknown>, rowIndex: number, arr: Record<string, unknown>[]) => {
      return header.map((colonna) => {
        const value = riga[colonna.field as string] as string;
        let cellDef: CellDef = {};

        cellDef.content = value;

        if (cellDef.content == null) {
          cellDef.content = "";
        }

        cellDef.styles = {
          ...cellDef.styles,
          cellPadding: {
            top: 1,
            bottom: 1,
            left: 2,
            right: 2
          }
        };

        if (colonna.type === 'numeric' || (rowIndex === data.length - 1 && colonna.field === 'qualificaIncarico')) {
          cellDef.styles = {
            ...cellDef.styles,
            halign: 'right',
            valign: 'middle'
          };
        } else if (['date', 'datetime'].includes(colonna.type as string)) {
          cellDef.styles = {
            ...cellDef.styles,
            halign: 'center',
            valign: 'middle'
          }
        }

        // if (grayColumns.includes(colonna.field as string)) {
        //   cellDef.styles = {
        //     ...cellDef.styles,
        //     fontStyle: boldColumns.includes(colonna.field as string) ? 'bold' : 'normal',
        //     fillColor: baseFillColor + _level * step,
        //   }
        // }

        return cellDef;
      }) as RowInput
    }) as RowInput[][]
  }

  let level = 1;

  let tableRows: any[] = [
    // [
    //   {
    //     content: groupsTitle[level - 1] + ': ' + gruppo['value'],
    //     styles: {
    //       halign: 'left',
    //       fontStyle: 'bold',
    //       fillColor: baseFillColor + step,
    //     },
    //     colSpan: header.length
    //   } as Record<string, unknown>
    // ]
  ];

  // Eliminare le colonne definito undefined (rowSpan)
  tableRows = tableRows.concat(mapData(_data, level) as any[][]).map(elem => elem.filter((e: any) => e));

  modData = modData.concat(tableRows);
  // }

  const reworkData = (data: RowInput[], headerLength: number) => {
    return data.length > 0
      ? data
      : [[{
        content: t('noDataPresentLabel'),
        styles: {
          halign: 'center',
          fontStyle: 'italic'
        } as CellDef,
        colSpan: headerLength
      }]] as RowInput[];
  }

  const doc = new jsPDF({ orientation: PAPER_ORIENTATION, format: PAPER_FORMAT, });

  /*
   * DATI
   */
  autoTable(doc, {
    startY: HEADER_SPACE,
    margin: {
      top: HEADER_SPACE,
      horizontal: MARGIN_H,
    },
    head: [header],
    body: reworkData(modData, header.length),
    headStyles: {
      fillColor: theme.palette.primary.main,
      halign: 'center',
      cellPadding: 2,
      valign: 'middle'
    },
    theme: "grid",
    styles: {
      fontSize: pdfOptions && pdfOptions.bodyFontSize ? pdfOptions.bodyFontSize : 10,
    },
  });

  return doc;
}