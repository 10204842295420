import { Fields } from "./Fields";

export const elementIdProps = [];
export const elementRenderProps = [];

export const allDotazioneNumericaFields: Fields[] = [
  {
    field: "dipartimentoDescrizione", titleKey: "departmentParam", required: false, show: true, defaultGroupOrder: 0
  },

  {
    field: "unitaOperativaNome", titleKey: "operationalUnitParam", required: false, show: true
  },

  {
    field: "qualificaIncarico", titleKey: "qualificationParam", required: false, show: true,
  },

  {
    field: "note", titleKey: "noteParam", required: false, show: true,
  },

  {
    field: "postiLetto", titleKey: "sleepingPlacesParam", type: 'string', required: false, show: true,
  },

  // Totale Assegnati al CDC
  {
    field: "totaleCdc", titleKey: "totalAssignedParam", type: 'numeric', required: false, show: true,
  },
  {
    field: "h24Cdc", titleKey: "h24ShiftParam", type: 'numeric', required: false, show: true,
  },
  {
    field: "h12Cdc", titleKey: "h12ShiftParam", type: 'numeric', required: false, show: true,
  },
  {
    field: "partTimeCdc", titleKey: "partTimeParam", type: 'numeric', required: false, show: true,
  },

  // No gruppo
  {
    field: "assenze6MesiCdc", titleKey: "absentGT6MonthsParam", type: 'numeric', required: false, show: true,
  },
  {
    field: "l104", titleKey: "l104ShortParam", type: 'numeric', required: false, show: true,
  },

  // Limitazioni
  {
    field: "totaleLimit", titleKey: "totalLimitationParam", type: 'numeric', required: false, show: true,
  },
  {
    field: "movimentiCarichiLimit", titleKey: "loadMovementParam", type: 'numeric', required: false, show: true,
  },
  {
    field: "esoneroTurniLimit", titleKey: "exemptionFromShiftsParam", type: 'numeric', required: false, show: true,
  },
  {
    field: "altroLimit", titleKey: "otherParam", type: 'numeric', required: false, show: true,
  },

  // UO
  {
    field: "unitaPrevisteUO", titleKey: "expectedLimitsParam", type: 'numeric', required: false, show: true,
  },
  {
    field: "totalePresentiUO", titleKey: "totalPresentParam", type: 'numeric', required: false, show: true,
  },
  {
    field: "carentiEsuberoUO", titleKey: "lackExcessParam", type: 'numeric', required: false, show: true,
  },
];

export const allDotazioneNominativaFields: Fields[] = [
  {
    field: "unitaOperativa", titleKey: "operationalUnitParam", required: false, show: true, defaultGroupOrder: 0
  },

  {
    field: "qualifica", titleKey: "qualificationParam", required: false, show: true, defaultGroupOrder: 1
  },

  {
    field: "matricola", titleKey: "numberParam", required: false, show: true, lookupField: true
  },

  {
    field: "nominativo", titleKey: "nominativeParam", required: false, show: true, lookupField: true,
  },

  {
    field: "partTimeTipo", titleKey: "partTimeParam", required: false, show: true, defaultValue: ''
  },

  {
    field: "legge104Numero", type: 'numeric', titleKey: "law104NumberParam", required: false, show: true, defaultValue: '-'
  },

  {
    field: "idoneitaDescrizione", titleKey: "eligibilityParam", required: false, show: true, defaultValue: ''
  },

  {
    field: "combinazioneTurniDescrizioneBreve", titleKey: "shiftsParam", required: false, show: true,
  },

  {
    field: "tipoContratto", titleKey: "contractParam", required: false, show: true,
  },

  {
    field: "dataAssunzione", titleKey: "assumptionDateParam", type: "date", required: false, show: true,
  },

  {
    field: "dataFineContratto", titleKey: "assumptionDateEndParam", type: "date", required: false, show: true,
  },
];

export const allDotazioneRiepilogoTotaleFields: Fields[] = [
  {
    field: "qualificaIncarico", titleKey: "qualificationParam", required: false, show: true,
  },
  // Totale Assegnati al CDC
  {
    field: "totaleCdc", titleKey: "totalAssignedParam", type: 'numeric', required: false, show: true,
  },
  {
    field: "h24Cdc", titleKey: "h24ShiftParam", type: 'numeric', required: false, show: true,
  },
  {
    field: "h12Cdc", titleKey: "h12ShiftParam", type: 'numeric', required: false, show: true,
  },
  {
    field: "partTimeCdc", titleKey: "partTimeParam", type: 'numeric', required: false, show: true,
  },

  // No gruppo
  {
    field: "assenze6MesiCdc", titleKey: "absentGT6MonthsParam", type: 'numeric', required: false, show: true,
  },
  {
    field: "l104", titleKey: "l104ShortParam", type: 'numeric', required: false, show: true,
  },

  // Limitazioni
  {
    field: "totaleLimit", titleKey: "totalLimitationParam", type: 'numeric', required: false, show: true,
  },
]