import { Dispatch, SetStateAction } from 'react'
import {
  makeStyles,
  createStyles,
  Theme,
  Modal,
  Backdrop,
  Fade,
  Paper,
  Grid,
  IconButton,
  Box
} from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons';
import clsx from 'clsx';

import InfoSoggettoPianificazione from './insertTurniModal/InfoSoggettoPianificazione';
import TableTurni from './insertTurniModal/TableTurni';
import { RiepilogoPianificazioneDTO } from '../../PianificazioniPreventivoW';
import { OrderedListOfTurni } from './Calendar';
import InsertTurnoForm from './insertTurniModal/InsertTurnoForm';
import { resetError as resetErrorPianificazioni } from '../../../../../store/slices/pianificazioniSlice';
import { useAppDispatch } from '../../../../../store/hooks';
import { isAfter } from 'date-fns';
import { getTodayEnd } from '../../../../../utils/utilfunctions';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
    // Paper Modal
    minDimension: {
      maxWidth: 750
    },
  }),
);

interface InsertTurniModalProps {
  currentSelectedAS: RiepilogoPianificazioneDTO;
  currentSelectedDate: Date;
  preparedData: OrderedListOfTurni[][];
  selectedDateIndex: number;
  openState: {
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>
  };
  fixedProps: {
    idStruttura: number;
    idDipartimento: number;
    idUnitaOperativa: number;
    mese: number;
    anno: number;
  };
  approvato: boolean;
  editable?: boolean;
}

const InsertTurniModal = (props: InsertTurniModalProps) => {
  const { currentSelectedAS, currentSelectedDate, preparedData, selectedDateIndex, openState, fixedProps, approvato, editable } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(resetErrorPianificazioni())
    openState.setOpen(false);
  };

  const isDateAfter = isAfter(currentSelectedDate, new Date(getTodayEnd()));

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openState.open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Fade in={openState.open}>
        <Paper elevation={2} className={clsx(classes.minDimension)}>
          <Grid container justifyContent='flex-end'>
            <Grid item>
              <IconButton onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Box p={2}>
            {/* {
              statusValidUnitaOperativaPianificazioni === StatusEnum.Failed &&
              <Messaggio />
            } */}

            <InfoSoggettoPianificazione
              currentSelectedDate={currentSelectedDate}
              currentSelectedAS={currentSelectedAS}
            />

            <TableTurni
              selectedDateIndex={selectedDateIndex}
              preparedData={preparedData}
              currentSelectedDate={currentSelectedDate}
              currentSelectedAS={currentSelectedAS}
              handleClose={handleClose}
              approvato={approvato}
              isDateNowOrAfter={isDateAfter}
              fixedProps={fixedProps}
              editable={editable}
            />

            {
              (editable || (!approvato && isDateAfter)) &&
              <InsertTurnoForm
                fixedProps={fixedProps}
                currentSelectedAS={currentSelectedAS}
                currentSelectedDate={currentSelectedDate}
              />
            }
          </Box>
        </Paper>
      </Fade>
    </Modal>
  )
}

export default InsertTurniModal