
import { Fields } from "./Fields";

export type UnitaOperativaFormazioneKeys = keyof UnitaOperativaFormazione;

export const elementIdProps: UnitaOperativaFormazioneKeys[] = ["idStruttura", "idDipartimento", "idUnitaOperativa","idFormazione"];
export const elementRenderProps: UnitaOperativaFormazioneKeys[] = [];

export const allFields: Fields[] = [
  {
    field: "idFormazione", required: true, show: true, titleKey: "trainingParam", editable: "onAdd", lookupField: true,
  },
  {
    field: "descrizione", required: true, show: false, titleKey: "trainingParam", editable: "never",
  },
]

export interface UnitaOperativaFormazione {
  idStruttura: number,
  idDipartimento: number,
  idUnitaOperativa: number,
  idFormazione: number,
  descrizione: string,
  version: number
}


