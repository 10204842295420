import { FieldType } from "../../../../../../models/Fields";
import { getDateDDMMYYYY } from "../../../../../../utils/utilfunctions";

export const defaultValue = '-';

export type GroupEntry = Record<string, [string, FieldType]>;
export interface Group {
  [groupName: string]: GroupEntry
}

export function filterGroup(group: GroupEntry, data: Record<string, any>) {
  const temp: Record<string, any> = {};

  Object.keys(group).forEach(key => {
    const dataType = group[key][1];

    const _key = key + ',' + dataType;

    temp[_key] = data && data[key]
      ? data[key]
      : defaultValue;
  })

  return temp;
};

export const renderData = (value: string | boolean | number | unknown, dataType: FieldType | 'lookup', translation: (x: string) => string): string => {
  let retval = value;

  switch (dataType) {
    case 'date':
      retval = getDateDDMMYYYY(new Date(value as string)) || value;
      break;
    case 'boolean':
      retval = value === true ? translation('yes') : translation('no');
      break;
  }

  return retval as string;
};