import { Column } from "@material-table/core";
import { useCallback, useEffect, useMemo, useState, } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { anagrafichePath } from "../../../utils/utilconst";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import {
  fetchExtendedByIdAndDate,
  resetError as resetErrorAnagraficaSoggetti
} from "../../../store/slices/anagraficaSoggettiSlice";

import {
  insert,
  update,
  logicalDel,
  physicalDel,
  restore,
  resetError as resetErrorAnagraficaSoggettiContestazione
} from "../../../store/slices/anagraficaSoggettiContestazioneSlice";
import { elementIdProps, elementRenderProps, AnagraficaSoggettiContestazione, allFields } from '../../../models/AnagraficaSoggettiContestazioni';
import useInitValid from "./hooks/useInitValid";
import useInitDeleted from "./hooks/useInitDeleted";
import { PDFExtraData } from "../../../models/Utils";
import { Fields } from "../../../models/Fields";

interface AnagraficaSoggettiContestazioneWProps {
  idAnagraficaSoggetto: number,
  anagraficaSoggettiContestazione: AnagraficaSoggettiContestazione[],
  anagraficaSoggettiContestazioneEliminati: AnagraficaSoggettiContestazione[]
}

const AnagraficaSoggettiContestazioneW = ({ idAnagraficaSoggetto, anagraficaSoggettiContestazione, anagraficaSoggettiContestazioneEliminati }: AnagraficaSoggettiContestazioneWProps) => {
  const { t } = useTranslation();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const dispatch = useAppDispatch();

  const abilitazione = useAppSelector(state => getAbilitazione(state, anagrafichePath));
  const errorBE = useAppSelector(state => state.anagraficaSoggettiContestazione.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorAnagraficaSoggetti());
    dispatch(resetErrorAnagraficaSoggettiContestazione());
  }, [dispatch]);


  const lookupAnagraficaSoggetto = useAppSelector(state => state.anagraficaSoggetti.lookup);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  const idAnagSoggConst = "idAnagraficaSoggetto";

  const fixedProps = useMemo(() => {
    return {
      [idAnagSoggConst]: idAnagraficaSoggetto,
    }
  }, [idAnagraficaSoggetto]);

  const title = t('disputeTitle');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettiContestazione>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettiContestazione> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }

        if (f.validate) {
          obj.validate = (rowData): any => {
            type AnagraficaSoggettiContestazioneKeys = keyof AnagraficaSoggettiContestazione;

            if (f.validate) {
              return f.validate(rowData[f.field as AnagraficaSoggettiContestazioneKeys], f.keyTradValidation ? t(f.keyTradValidation) : '')
            }

            return undefined;
          };
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        return obj;
      }));
  }, [allFieldsState, excludedFieldInTable, t]);

  const statusValidAnagraficaSoggettiContestazione = useAppSelector(state => state.anagraficaSoggettiContestazione.statusValidAnagraficaSoggettiContestazioni);
  const statusDeletedAnagraficaSoggettiContestazione = useAppSelector(state => state.anagraficaSoggettiContestazione.statusDeletedAnagraficaSoggettiContestazioni);

  const [data, setData] = useState<Array<AnagraficaSoggettiContestazione>>([]);

  useEffect(() => {
    toggleValidDeleted ? setData(anagraficaSoggettiContestazione) : setData(anagraficaSoggettiContestazioneEliminati);
  }, [
    anagraficaSoggettiContestazione,
    anagraficaSoggettiContestazioneEliminati,
    toggleValidDeleted,
  ]);

  useInitValid(
    statusValidAnagraficaSoggettiContestazione,
    () => dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto }))
  );
  useInitDeleted(
    statusDeletedAnagraficaSoggettiContestazione,
    () => dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto }))
  );

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  const exportDataExtra: PDFExtraData = useMemo(() => {
    const _idAnagSoggConst = fixedProps[idAnagSoggConst];

    const _nominativo = _idAnagSoggConst ? lookupAnagraficaSoggetto[_idAnagSoggConst] : ''

    return {
      head: {
        title: [t('nominativeLabel')],
        value: [_nominativo]
      }
    }
  }, [fixedProps, lookupAnagraficaSoggetto, t]);

  return <CrudMaterialTable
    abilitazione={abilitazione}
    title={title}
    columns={columns}
    columnsButton={true}
    data={data}
    elementIdProps={elementIdProps}
    elementRenderProps={elementRenderProps}
    fetchAllValid={fetchExtendedByIdAndDate}
    insert={insert}
    restore={restore}
    logicalDel={logicalDel}
    physicalDel={physicalDel}
    update={update}
    dataValid={toggleValidDeleted}
    setToggleValidDeleted={setToggleValidDeleted}
    statusValid={statusValidAnagraficaSoggettiContestazione}
    statusDeleted={statusDeletedAnagraficaSoggettiContestazione}
    errorBE={errorBE}
    fixedProps={fixedProps}
    exportDataExtra={exportDataExtra}
    logoUri={logoUri}
    nullableTextFields={true}
    localizedDatePicker={true}
    resetErrorCallback={resetError}
    extraOptions={{
      minBodyHeight: 150,
      maxBodyHeight: 460
    }}
  />
}
export default AnagraficaSoggettiContestazioneW;
