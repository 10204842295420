import MaterialTable, { Column, Icons, Options } from "@material-table/core";
import { useTheme } from "@material-ui/core";
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { AsyncThunk } from "@reduxjs/toolkit";
import { forwardRef, MutableRefObject } from "react";
import useReadOnlyMaterialTable from "./useReadOnlyMaterialTable";
import { ExportType } from '../../../../utils/utildata';
import { PDFExtraData, PDFOptions } from '../../../../models/Utils';
import { FixedProps, TCsvCallback, TPdfCallback } from "../../../../utils/data.types";

const icons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
}
export interface ReadOnlyMaterialTableProps<T> {
  title: string;
  columns: Array<Column<any>>;
  columnsButton?: boolean;
  data: Array<any>;
  fetchAllValid?: AsyncThunk<T[], void, {}> | AsyncThunk<T[], T, {}> | AsyncThunk<T, T, {}>;
  statusValid: string;
  errorBE: string | null;
  logoUri: string | null;
  localizedDatePicker?: boolean;
  nullableTextFields?: boolean;
  fixedProps?: FixedProps;
  exportDataExtra?: PDFExtraData;
  exportType?: ExportType;
  isExportLandscape?: boolean;
  extraOptions?: Options<object>;
  pdfOptions?: PDFOptions;
  isLoading?: boolean;
  exportPDFCallback?: TPdfCallback;
  exportCSVCallback?: TCsvCallback;
  exportMenuRef?: MutableRefObject<Record<string, any>[]>
}

const ReadOnlyMaterialTable = ({
  title, columns, columnsButton, data,
  fetchAllValid,
  statusValid,
  errorBE, localizedDatePicker, nullableTextFields, fixedProps, exportDataExtra,
  exportType, isExportLandscape, logoUri, extraOptions, pdfOptions, isLoading,
  exportPDFCallback, exportCSVCallback, exportMenuRef
}: ReadOnlyMaterialTableProps<any>) => {

  const theme = useTheme();
  const { loading, materialTableRef, localization, editable, actions, options, components } = useReadOnlyMaterialTable(
    theme, title, logoUri,
    columnsButton ? true : false,
    statusValid, errorBE, fetchAllValid, localizedDatePicker, nullableTextFields, fixedProps, exportDataExtra,
    exportType, isExportLandscape, pdfOptions, exportPDFCallback, exportCSVCallback, isLoading, exportMenuRef);

  const updatedOptions: Options<object> = {
    ...options,
    ...extraOptions,
    pageSize: extraOptions?.pageSize ?? 10,
    draggable: extraOptions?.draggable ?? false,
    paging: extraOptions?.paging ?? false,
    padding: extraOptions?.padding ?? 'dense',
    defaultExpanded: extraOptions?.defaultExpanded ?? true
  }

  return (
    <MaterialTable
      components={components}
      icons={icons}
      tableRef={materialTableRef}
      title={title}
      columns={columns}
      data={data}
      editable={editable}
      actions={actions}
      options={updatedOptions}
      localization={localization}
      isLoading={loading}
    />
  )
}
export default ReadOnlyMaterialTable;