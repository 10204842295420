import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  makeStyles,
  Theme,
  createStyles,
  Switch as MuiSwitch,
  Checkbox,
  TextField
} from '@material-ui/core';
import clsx from 'clsx';
import { DeleteRounded as DeleteRoundedIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { OrderedListOfTurni } from '../Calendar';
import {
  useAppDispatch,
  useAppSelector
} from '../../../../../../store/hooks';
import {
  getDateYYYYMMDD, getDateYYYYMMDD_BackEnd
} from '../../../../../../utils/utilfunctions';
import { addTurno, checkTurnoOrTurnoAssenzaPianificabile, deleteTurno, modificaCodiceTurno, modificaReperibilita, modificaTurnoFisso as modificaTurnoFissoAct, refreshSoggettoEQualifica } from '../../../../../../store/slices/pianificazioniSlice';
import { NuovoTurno, Pianificazione, UnitaOperativePianificazione } from '../../../../../../models/Pianificazioni';
import { RiepilogoPianificazioneDTO } from '../../../PianificazioniPreventivoW';
import { useEffect, useMemo, useState, useCallback } from 'react';
import { FixedProps } from '../../../../../../utils/data.types';
import { IAnagraficaLookupObject } from './InsertTurnoForm';
import lodashUtil from 'lodash';
import { TurniAbilitati } from '../../../../../../store/slices/anagraficaSoggettiTurnoAbilitatoSlice';
import { Assenza } from '../../../../../../models/Assenze';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2, 4, 3),
      marginTop: '2em',
      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    hideShowOnHover: {
      '&:hover svg': {
        visibility: 'visible',
      }
    },
    hidden: {
      visibility: 'hidden',
    },
  }),
);

interface TurnoFissoCodiceTurno {
  codiceTurno?: string,
  inizioTurnoFisso: boolean,
  modificaInizioTurnoFissoCodiceTurno: boolean,
}

function elimina(dispatch: any, idAnagraficaSoggetto: number, idQualifica: number, data: string, idTurno?: number, idTurnoAssenza?: number) {
  const selectedTurno: Partial<Pianificazione> = {
    idAnagraficaSoggetto: idAnagraficaSoggetto,
    idQualifica: idQualifica,
    pianificazioneData: data,
    idTurno: idTurno,
    idTurnoAssenza: idTurnoAssenza,
  }

  dispatch(deleteTurno(selectedTurno as Pianificazione));
};

function isPianificazioneEquals(p1: Pianificazione, p2: Pianificazione) {
  return ((p1.idStruttura as number) === (p2?.idStruttura as number) &&
    (p1.idDipartimento as number) === (p2?.idDipartimento as number) &&
    (p1.idUnitaOperativa as number) === (p2?.idUnitaOperativa as number) &&
    (p1.idAnagraficaSoggetto as number) === (p2.idAnagraficaSoggetto as number) &&
    ((p1.idTurno as number) === (p2.idTurno as number) || (p1.idTurnoAssenza as number) === (p2.idTurnoAssenza as number)) &&
    p1.pianificazioneData === p2.pianificazioneData);
}

function sostituisciTurnoOAssenza(dispatch: any, handleClose: () => void, oldTurnoOAssenza: number, nuovoTurno: NuovoTurno, approvato: boolean, fixedProps: FixedProps, currentSelectedAS: RiepilogoPianificazioneDTO, currentSelectedDate: Date, validUnitaOperativaPianificazioni: UnitaOperativePianificazione, validPianificazioni: Pianificazione[], lookups: any) {
  const { turniDescrizioneLookup, turniDescrizioneBreveLookup, assenzaAbilitatiDescrizione, assenzaAbilitatiDescrizioneBreve } = lookups;

  const isTurno = nuovoTurno.idTurno != null;

  const oldPianificazione: Partial<Pianificazione> = {
    idStruttura: (fixedProps?.idStruttura as number),
    idDipartimento: (fixedProps?.idDipartimento as number),
    idUnitaOperativa: (fixedProps?.idUnitaOperativa as number),
    idAnagraficaSoggetto: (currentSelectedAS.idAnagraficaSoggetto as number),
    idTurno: nuovoTurno.idTurno != null ? oldTurnoOAssenza : undefined,
    idTurnoAssenza: nuovoTurno.idTurnoAssenza != null ? oldTurnoOAssenza : undefined,
    pianificazioneData: getDateYYYYMMDD_BackEnd(currentSelectedDate, 'START')
  }

  const currentPianificazione = validPianificazioni.find(elem => isPianificazioneEquals(elem, oldPianificazione as Pianificazione));

  if (!currentPianificazione) return;

  const nuovaPianificazione: Pianificazione = {
    ...currentPianificazione,
    idTurno: nuovoTurno.idTurno ? nuovoTurno.idTurno : undefined,
    turnoDescrizione: nuovoTurno.idTurno ? turniDescrizioneLookup[nuovoTurno.idTurno] : undefined,
    turnoDescrizioneBreve: nuovoTurno.idTurno ? turniDescrizioneBreveLookup[nuovoTurno.idTurno] : undefined,

    idTurnoAssenza: nuovoTurno.idTurnoAssenza ? nuovoTurno.idTurnoAssenza : undefined,
    turnoAssenzaDescrizione: nuovoTurno.idTurnoAssenza ? assenzaAbilitatiDescrizione[nuovoTurno.idTurnoAssenza] : undefined,
    turnoAssenzaDescrizioneBreve: nuovoTurno.idTurnoAssenza ? assenzaAbilitatiDescrizioneBreve[nuovoTurno.idTurnoAssenza] : undefined,

    tipoInserimento: 'Manuale',

    inizioTurnoFisso: false,
    modificaInizioTurnoFissoCodiceTurno: false,
    progressivo: undefined,

    oreTurno: nuovoTurno.oreTurno,
    minutiTurno: nuovoTurno.minutiTurno,

    preventivo: !approvato,
    consuntivo: true,

    nota: '',
  }

  if ((nuovoTurno.idTurno || nuovoTurno.idTurnoAssenza) && validUnitaOperativaPianificazioni) {
    dispatch(checkTurnoOrTurnoAssenzaPianificabile({
      idAnagraficaSoggetto: currentSelectedAS.idAnagraficaSoggetto,
      idStruttura: Number(fixedProps.idStruttura),
      idDipartimento: Number(fixedProps.idDipartimento),
      idUnitaOperativa: Number(fixedProps.idUnitaOperativa),
      idTurno: nuovoTurno.idTurno,
      idTurnoAssenza: nuovoTurno.idTurnoAssenza,
      dataRiferimento: getDateYYYYMMDD_BackEnd(currentSelectedDate, 'START'),
    })).then((data: any) => {
      if (data.payload === true) {
        const selectedTurno: Partial<Pianificazione> = {
          idAnagraficaSoggetto: currentSelectedAS.idAnagraficaSoggetto,
          idQualifica: currentSelectedAS.idQualifica,
          pianificazioneData: getDateYYYYMMDD_BackEnd(currentSelectedDate, 'START'),
          idTurno: isTurno ? oldTurnoOAssenza : undefined,
          idTurnoAssenza: !isTurno ? oldTurnoOAssenza : undefined,
        }

        dispatch(deleteTurno(selectedTurno as Pianificazione));
        dispatch(addTurno(nuovaPianificazione as Pianificazione));
        dispatch(refreshSoggettoEQualifica({
          unitaOperativePianificazione: validUnitaOperativaPianificazioni,
          pianificazione: validPianificazioni
            .filter(elem => !isPianificazioneEquals(elem, oldPianificazione as Pianificazione))
            .concat([nuovaPianificazione as Pianificazione]),
        })).then(() => {
          handleClose();
        });
      }
    })
  }
}

function aggiornaReperibilita(dispatch: any, idAnagraficaSoggetto: number, idQualifica: number, data: string, valore: boolean, idTurno?: number, idTurnoAssenza?: number) {
  const selectedTurno: Partial<Pianificazione> = {
    idAnagraficaSoggetto: idAnagraficaSoggetto,
    idQualifica: idQualifica,
    pianificazioneData: data,
    idTurno: idTurno,
    idTurnoAssenza: idTurnoAssenza,
  }

  dispatch(modificaReperibilita({ pianificazione: selectedTurno as Pianificazione, valore: valore }));
}

interface TableTurniProps {
  currentSelectedAS: RiepilogoPianificazioneDTO;
  currentSelectedDate: Date;
  preparedData: OrderedListOfTurni[][];
  selectedDateIndex: number;
  handleClose: () => void;
  approvato: boolean;
  isDateNowOrAfter: boolean;
  fixedProps: FixedProps;
  editable?: boolean;
}

const TableTurni = (props: TableTurniProps) => {
  const { selectedDateIndex, currentSelectedAS, currentSelectedDate, handleClose, approvato, isDateNowOrAfter, fixedProps, editable } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const updateSelectedTurni = (oldID: number, newID: number, isTurno: boolean, approvato: boolean) => {
    if (oldID === newID) return;

    if (validUnitaOperativaPianificazioni) {

      let ore = 0;
      let minuti = 0;

      if (isTurno) {
        const _turno = turniAbilitatiFiltered.find(elem => (elem.idTurno as number) === (newID as number));
        ore = _turno?.ore ?? 0;
        minuti = _turno?.minuti ?? 0;
      } else {
        const _assenza = validAssenze.find(elem => (elem.idTurnoAssenza as number) === (newID as number));
        ore = _assenza?.ore ?? 0;
        minuti = _assenza?.minuti ?? 0;
      }

      const nuovoTurno: NuovoTurno = {
        idTurno: isTurno ? newID : undefined,
        idTurnoAssenza: !isTurno ? newID : undefined,
        oreTurno: ore,
        minutiTurno: minuti
      };

      sostituisciTurnoOAssenza(
        dispatch,
        handleClose,
        oldID,
        nuovoTurno,
        approvato,
        fixedProps,
        currentSelectedAS,
        currentSelectedDate,
        validUnitaOperativaPianificazioni,
        validPianificazioni,
        {
          anagraficaLookupObject,
          turniDescrizioneLookup,
          turniDescrizioneBreveLookup,
          assenzaAbilitatiDescrizione,
          assenzaAbilitatiDescrizioneBreve
        }
      );
    }
  }

  const anagraficaLookupObject = useAppSelector(state => state.anagraficaSoggettiUnitaOperative.lookupObject) as Record<string | number, IAnagraficaLookupObject>;
  const turniDescrizioneLookup = useAppSelector(state => state.turni.lookupDescrizione);
  const turniDescrizioneBreveLookup = useAppSelector(state => state.turni.lookupDescrizioneBreve);
  const assenzaAbilitatiDescrizione = useAppSelector(state => state.assenze.lookupValidDescrizione);
  const assenzaAbilitatiDescrizioneBreve = useAppSelector(state => state.assenze.lookupValidDescrizioneBreve);

  const turniAbilitatiFiltered = useAppSelector(state => state.anagraficaSoggettiTurnoAbilitato.turniAbilitati);
  const validAssenze = useAppSelector(state => state.assenze.validAssenze);

  const validPianificazioni = useAppSelector(state => state.pianificazione.validPianificazioni);
  const validUnitaOperativaPianificazioni = useAppSelector(state => state.pianificazione.validUnitaOperativaPianificazioni);

  const currentPianificazioniTurni: Pianificazione[] = useMemo(() => {
    return validPianificazioni
      ?.filter(elem => elem.pianificazioneData.startsWith(getDateYYYYMMDD(currentSelectedDate)) && elem.idAnagraficaSoggetto === currentSelectedAS.idAnagraficaSoggetto)
      ?? [];
  }, [currentSelectedAS.idAnagraficaSoggetto, currentSelectedDate, validPianificazioni]);

  const [codiciTurno, setCodiciTurno] = useState<Record<string, string>>({});

  const handleCodiceTurnoChange = useCallback((IDTurno: number) => {
    const ok = window.confirm('Sei sicuro di voler modificare il codice turno?');

    if (ok) {
      const modificaLocale = new Promise<void>((resolve) => {
        const modifiedPianificazione: Partial<Pianificazione> = {
          idAnagraficaSoggetto: currentSelectedAS.idAnagraficaSoggetto,
          idQualifica: currentSelectedAS.idQualifica,
          pianificazioneData: getDateYYYYMMDD_BackEnd(currentSelectedDate),
          idTurno: IDTurno,
          codiceTurno: codiciTurno[IDTurno]?.length === 0 ? undefined : codiciTurno[IDTurno],
          modificaInizioTurnoFissoCodiceTurno: true
        }

        dispatch(modificaCodiceTurno(modifiedPianificazione as Pianificazione));
        resolve();
      })

      modificaLocale.then(() => {
        setMakeChangesInPianificazioni(true);
      });
    }

  }, [codiciTurno, currentSelectedAS.idAnagraficaSoggetto, currentSelectedAS.idQualifica, currentSelectedDate, dispatch]);

  const [reperibilitaCheckBoxValues, setReperibilitaCheckBoxValues] = useState<boolean[]>([]);
  useEffect(() => {
    setReperibilitaCheckBoxValues(state => {
      return currentPianificazioniTurni.map(({ reperibilita }) => reperibilita);
    });
  }, [currentPianificazioniTurni]);

  const [switchState, setSwitchState] = useState<TurnoFissoCodiceTurno[]>(
    currentPianificazioniTurni.map(({ inizioTurnoFisso, modificaInizioTurnoFissoCodiceTurno }) => {
      return {
        inizioTurnoFisso: inizioTurnoFisso ?? false,
        modificaInizioTurnoFissoCodiceTurno: modificaInizioTurnoFissoCodiceTurno ?? false
      };
    })
  );
  useEffect(() => {
    setSwitchState(_ => {
      return currentPianificazioniTurni.map(({ inizioTurnoFisso, modificaInizioTurnoFissoCodiceTurno }) => {
        return {
          inizioTurnoFisso: inizioTurnoFisso ?? false,
          modificaInizioTurnoFissoCodiceTurno: modificaInizioTurnoFissoCodiceTurno ?? false
        };
      });
    });

    setCodiciTurno(() => {
      const retval: Record<string, string> = {};

      currentPianificazioniTurni.forEach(({ idTurno, idTurnoAssenza, codiceTurno }) => {
        const id = idTurno ?? idTurnoAssenza;
        if (id != null) {
          retval[id] = codiceTurno ?? '';
        }
      })

      return retval;
    })
  }, [currentPianificazioniTurni]);

  const handleTurnoFissoChange = (value: boolean, idTurno: number) => {
    const ok = window.confirm('Sei sicuro di volerlo impostare come turno fisso?');

    if (ok) {
      const modificaLocale = new Promise<void>((resolve) => {
        const _codiceTurno = codiciTurno[idTurno];

        const modifiedPianificazione: Partial<Pianificazione> = {
          idAnagraficaSoggetto: currentSelectedAS.idAnagraficaSoggetto,
          idQualifica: currentSelectedAS.idQualifica,
          pianificazioneData: getDateYYYYMMDD_BackEnd(currentSelectedDate),
          codiceTurno: _codiceTurno,
          idTurno: idTurno,
          inizioTurnoFisso: value,
          modificaInizioTurnoFissoCodiceTurno: true
        }

        dispatch(modificaTurnoFissoAct(modifiedPianificazione as Pianificazione));
        resolve();
      })

      modificaLocale.then(() => {
        setMakeChangesInPianificazioni(true);
      });
    }
  };

  const handleReperibilitaChange = (index: number, value: boolean) => {
    setReperibilitaCheckBoxValues(state => {
      const newVal = [...state];
      newVal[index] = value;
      return newVal;
    });
  };

  useEffect(() => {
    return () => {
      setSwitchState([]);
      setReperibilitaCheckBoxValues([]);
    }
  }, []);

  const [makeChangesInPianificazioni, setMakeChangesInPianificazioni] = useState<boolean>(false);

  useEffect(() => {
    if (makeChangesInPianificazioni && validUnitaOperativaPianificazioni) {
      dispatch(refreshSoggettoEQualifica({
        unitaOperativePianificazione: validUnitaOperativaPianificazioni,
        pianificazione: validPianificazioni,
      })).then(() => handleClose());
      setMakeChangesInPianificazioni(false);
    }
  }, [dispatch, handleClose, makeChangesInPianificazioni, validPianificazioni, validUnitaOperativaPianificazioni]);

  return (
    <TableContainer className={classes.paper}>
      <Table size={currentPianificazioniTurni.length > 0 ? 'small' : 'medium'}>
        <TableHead>
          <TableRow>
            <TableCell width={650}>{t('shiftAbsenceTitle')}</TableCell>
            <TableCell width={20}>{t('availabilityTitle')}</TableCell>
            <TableCell width={20}>{t('hoursTitle')}</TableCell>
            <TableCell width={20}>{t('fixedShiftTitle')}</TableCell>
            <TableCell width={20}>{t('shiftCodeTitle')}</TableCell>
            <TableCell width={10}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* Turni */}
          {
            currentPianificazioniTurni.length > 0
              ? currentPianificazioniTurni.map((_pianificazione, index) => {
                const { idTurno, idTurnoAssenza,
                  turnoDescrizione, turnoAssenzaDescrizione, turnoDescrizioneBreve, turnoAssenzaDescrizioneBreve,
                  codiceTurno, combinazioneTurni } = _pianificazione;
                const turniFissi = combinazioneTurni?.split('-') ?? [];

                const _id: number = (idTurno ?? idTurnoAssenza)!;
                const _descrizione: string = (turnoDescrizione ?? turnoAssenzaDescrizione ?? '');
                const _descrizioneBreve: string = (turnoDescrizioneBreve ?? turnoAssenzaDescrizioneBreve ?? '');

                const UOEsterna = currentSelectedAS.turniObj[currentSelectedDate.getDate() - 1]
                  ?.find(elem => elem.descrizione.toLowerCase() === (turnoDescrizione ?? turnoAssenzaDescrizione ?? '').toLowerCase())
                  ?.uoEsterna ?? false;

                let ore = _pianificazione.oreTurno;
                let minuti = _pianificazione.minutiTurno;

                return (
                  <TableRow key={'add-table' + index} className={clsx(classes.hideShowOnHover)}>
                    {/**
                      * Turno/Assenza
                      */}
                    <TableCell width={650}>
                      {
                        ((UOEsterna || (!editable && (approvato || !isDateNowOrAfter))))
                          ? (_descrizione ?? _descrizioneBreve ?? '')
                          : <select
                            style={{
                              fontSize: '1em',
                              width: '100%',
                              border: '0px transparent solid',
                              cursor: 'pointer'
                            }}
                            onChange={(e) => updateSelectedTurni(_id, Number(e.target.value), turniAbilitatiFiltered.some(elem => Number(elem.idTurno) === Number(_id)), approvato)}
                          >
                            {
                              turniAbilitatiFiltered.some(elem => Number(elem.idTurno) === Number(_id))
                                ? lodashUtil.sortBy(
                                  turniAbilitatiFiltered,
                                  [
                                    (elem: TurniAbilitati) => elem.descrizione
                                  ]).map(turnoAbilitato => {
                                    return <option value={turnoAbilitato.idTurno} selected={Number(_id) === Number(turnoAbilitato.idTurno)}>
                                      {turnoAbilitato.descrizione ?? turnoAbilitato.descrizioneBreve ?? ''}
                                    </option>
                                  })
                                : lodashUtil.sortBy(
                                  validAssenze,
                                  [
                                    (elem: Assenza) => elem.descrizione
                                  ]).map(assenza => {
                                    return <option value={assenza.idTurnoAssenza} selected={Number(_id) === Number(assenza.idTurnoAssenza)}>
                                      {assenza.descrizione ?? assenza.descrizioneBreve ?? ''}
                                    </option>
                                  })
                            }
                          </select>
                      }
                    </TableCell>
                    {/**
                      * Reperibilità
                      */}
                    <TableCell width={20}>
                      {
                        turniFissi.includes(_descrizioneBreve?.trim()) &&
                        <Checkbox
                          checked={reperibilitaCheckBoxValues[index] ?? false}
                          disabled={(UOEsterna || (!editable && (approvato || !isDateNowOrAfter)))}
                          onChange={(e) => {
                            handleReperibilitaChange(index, !reperibilitaCheckBoxValues[index]);
                            aggiornaReperibilita(
                              dispatch,
                              currentSelectedAS.idAnagraficaSoggetto,
                              currentSelectedAS.idQualifica,
                              // controllare la data
                              getDateYYYYMMDD(currentSelectedDate) + 'T00:00:00',
                              e.target.checked,
                              _id,
                              _id
                            )
                            setMakeChangesInPianificazioni(true);
                          }}
                        />
                      }
                    </TableCell>
                    {/**
                      * Ore/Minuti
                      */}
                    <TableCell width={20}>{((ore ?? '0').toString() + ':' + (minuti?.toString().padStart(2, '0') ?? '00'))}</TableCell>
                    {/**
                       * Turno Fisso
                       */}
                    <TableCell width={20}>
                      {
                        turniFissi.includes(_descrizioneBreve?.trim()) &&
                        <MuiSwitch
                          checked={switchState[index]?.inizioTurnoFisso ?? false}
                          onChange={() => handleTurnoFissoChange(!switchState[index]?.inizioTurnoFisso, _id)}
                          disabled={(UOEsterna || (!editable && (approvato || !isDateNowOrAfter))) || switchState.some(elem => elem.inizioTurnoFisso && !elem.modificaInizioTurnoFissoCodiceTurno)}
                          name={'MuiSwitch' + selectedDateIndex + '' + index}
                          color='primary'
                        />
                      }
                    </TableCell>
                    {/**
                      * Codice Turno
                      */}
                    <TableCell width={20}>
                      {
                        (UOEsterna || (!editable && (approvato || !isDateNowOrAfter)))
                          ? (codiceTurno ?? '')
                          : <TextField
                            fullWidth
                            value={codiciTurno[_id]}
                            onChange={(e) => {
                              setCodiciTurno(state => {
                                return {
                                  ...state,
                                  [_id]: e.target.value.toUpperCase()
                                }
                              })
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                handleCodiceTurnoChange(_id);
                              }
                            }}
                          />
                      }
                    </TableCell>
                    {/**
                      * Delete icon
                      */}
                    <TableCell width={10} align='right'>
                      {
                        ((editable || (!approvato && isDateNowOrAfter)) && !UOEsterna) &&
                        <IconButton
                          className={clsx(classes.hidden)}
                          onClick={() => {
                            elimina(
                              dispatch,
                              currentSelectedAS.idAnagraficaSoggetto,
                              currentSelectedAS.idQualifica,
                              // controllare la data
                              getDateYYYYMMDD(currentSelectedDate) + 'T00:00:00',
                              _id,
                              _id
                            );
                            setMakeChangesInPianificazioni(true);
                          }}>
                          <DeleteRoundedIcon fontSize='small' />
                        </IconButton>
                      }
                    </TableCell>
                  </TableRow>
                );
              })
              : (
                <TableRow>
                  <TableCell colSpan={4} align='center'>{t('noDataToShowLabel')}</TableCell>
                </TableRow>
              )
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default TableTurni