import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { Column } from "@material-table/core";
import { useTranslation } from "react-i18next";
import {
  elementIdProps,
  elementRenderProps,
  Presenza,
  allFields as allFieldsPresenza,
  PresenzaExtended,
  PresenzaKeys,
} from "../../../models/Presenze";
import {
  allFields as allFieldsAssenza,
  ConteggioAssenza,
  ConteggioAssenzaKeys
} from '../../../models/ConteggioAssenze';
import {
  allFields as allFieldsQualifica,
  ConteggioQualifica,
  ConteggioQualificaKeys,
} from '../../../models/ConteggioQualifica';
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { notFoundPath, presenzePath } from "../../../utils/utilconst";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import { AppBar, Box, Button, createStyles, Grid, makeStyles, Tab, Tabs, Theme, Typography, ListItem, ListItemText, List, Divider, Paper, Switch as MuiSwitch, Tooltip } from "@material-ui/core";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import { LookupAnagraficaSoggettoElem } from '../../../models/AnagraficaSoggetti';

import {
  fetchAssenzeBetweenDates,
  fetchByDate,
  fetchConteggioPerQualifica,
  fetchPreviousInfo,
  insert,
  physicalDel,
  update,
  resetPresenzaSingola,
  reset as resetPresenze,
  resetError as resetErrorPresenze,
  chiusuraGiornata
} from "../../../store/slices/presenzeSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import {
  lookupByUnitaOperativa as fetchAnagraficaSoggettiPerUnitaOperativaLookup,
  lookupByNameAndIDs as fetchASLookupByName,
  cleanLookupEsterno,
  reset as resetAnagraficaSoggettiUnitaOperative,
  resetError as resetErrorAnagraficaSoggettiUnitaOperative
} from "../../../store/slices/anagraficaSoggettiUnitaOperativeSlice";
import { fetchAllValid as fetchArticoliLeggeLookup, reset as resetArticoliLegge, resetError as resetErrorArticoliLegge } from "../../../store/slices/articoliLeggeSlice";
import { fetchAllValid as fetchAssenzeLookup, reset as resetAssenze, resetError as resetErrorAssenze } from "../../../store/slices/assenzeSlice";
import { lookup as fetchStruttureLookup, reset as resetStrutture, resetError as resetErrorStrutture, fetchExtendedById as fetchStrutturaExtendedById } from "../../../store/slices/struttureSlice";
import { lookupFiltered as fetchDipartimentiLookup, cleanLookup as cleanDipartimentiLookup, reset as resetDipartimentiFiltered, resetError as resetErrorDipartimentiFiltered } from "../../../store/slices/dipartimentiFilteredSlice";
import { lookupFiltered as fetchUnitaOperativeLookup, cleanLookup as cleanUOLookup, reset as resetUnitaOperativaFiltered, resetError as resetErrorUnitaOperativeFiltered } from "../../../store/slices/unitaOperativeFilteredSlice";
import { fetchAllValid as fetchTurniLookup, reset as resetTurni, resetError as resetErrorTurni } from '../../../store/slices/turniSlice';
import { fetchAllValid as fetchQualificaLookup, reset as resetQualifiche, resetError as resetErrorQualifiche } from '../../../store/slices/qualificheSlice';
import { fetchAllValidById as fetchQualifichePerAnagraficaSoggetto, QualificheAbilitate, reset as resetAnagraficaSoggettiQualifica, resetError as resetErrorAnagrificheSoggettiQualifica } from '../../../store/slices/anagraficaSoggettiQualificaSlice';
import { fetchAllValidById as fetchTurniAbilitatiPerAnagraficaSoggetto, reset as resetAnagraficaSoggettiTurnoAbilitato, resetError as resetErrorTurnoAbilitazione } from '../../../store/slices/anagraficaSoggettiTurnoAbilitatoSlice';

import { useHistory, Switch, Route, Redirect } from "react-router-dom";
import { componentTabsPath, componentInsertPath } from "../../../utils/innerFuncPaths";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import GeneralForm, { ChecksCallbackReturn, OptionalArgs } from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import { createLookup, daysInMonth, getTodayStart, getDateDDMMYYYY, isUrlRoot } from '../../../utils/utilfunctions';
import { Fields, Handler } from "../../../models/Fields";
import {
  Lookup,
  PDFExtraData,
  PDFOptions
} from '../../../models/Utils';
import ReadOnlyMaterialTable from '../tables/ReadOnly/ReadOnlyMaterialTable';
import { Colors, ColorsKeys, DimensioneFogli, ExportType } from "../../../utils/utildata";
import i18n from "../../../i18n";
import { it, enGB } from "date-fns/locale";
import LegendaW from "./presenzeComponents/LegendaW";
import { differenceInHours, differenceInMinutes, addDays, minutesInHour } from 'date-fns';
import { findByIdOrganizzazioneAndIdAnagraficaSoggettoData as findTimbratureByIdOrganizzazioneAndIdAnagraficaSoggettoData, resetTimbraturaAnagrificaSoggetto } from "../../../store/slices/timbratureSlice";
import { openDialogConfirm, resetYesClickDialogConfirm } from "../../../store/slices/dialogsSlice";
import { Timbrature } from "../../../models/Timbrature";
import { CellDefExt, ColumnExt, ComponentStateSetter, Extra, TableData } from "../../../utils/data.types";
import TabPanel from "../tabs/TabPanel/TabPanel";
import { calculateMTableHeight } from "../../../utils/styleconst";
import autoTable, { CellDef, CellInput, RowInput } from "jspdf-autotable";
import jsPDF from "jspdf";
import StatusIconIndicator from "../svgs/StatusIconIndicator";

type PresenzeDataType = Presenza & ConteggioAssenza & ConteggioQualifica;
type PresenzeDataTypeKeys = PresenzaKeys | ConteggioAssenzaKeys | ConteggioQualificaKeys;

const today = new Date();

const useStyles1 = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      borderTopLeftRadius: "4px",
      borderTopRightRadius: "4px",
    },
    indicator: {
      backgroundColor: "#fff",
    },
    // approvazione (modifica)
    groupFormTitle: {
      backgroundColor: "#fff",
      transform: "translate(50px,-50%)",
      left: 0,
      top: 0,
      position: "absolute",
      paddingTop: "0.25rem",
      paddingBottom: "0.25rem",
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem"
    },
    mt_1: {
      marginTop: "0.25rem"
    },
  }),
);

enum TabIndex {
  PRESENZE,
  CONTEGGIO_QUALIFICA,
  CONTEGGIO_ASSENZA
}

export interface PresenzaForm extends PresenzaExtended {
  includiInseriti: boolean
  timbraturaInizioTurnoIndicatore: string,
  timbraturaFineTurnoIndicatore: string,
}

function removeInternalAnagraficaSoggetto(esterna: Lookup, interna: Lookup): Lookup {
  let retval: Lookup = {};
  const internalID = Object.keys(interna);

  Object.keys(esterna).forEach(id => {
    if (!internalID.includes(id))
      retval[id] = esterna[id];
  });

  return retval;
}

function isPianificata(current: PresenzaForm, pianificata: Presenza): boolean {

  const campiDaControllare = [
    'reperibilita',
    'prestazioneAggiuntiva',
    'idAnagraficaSoggetto',
    'idQualifica',
    'idTurno',
    // 'oraInizioTurno',
    // 'oraFineTurno',
    'idTurnoAssenza',
    'idArticoloLegge'
  ];

  return campiDaControllare.every((field) => {
    return field === 'idAnagraficaSoggetto'
      ? (pianificata[field]?.toString() === current[field]?.toString() || pianificata[field]?.toString() === current['idAnagraficaSoggetto int']?.toString() || pianificata[field]?.toString() === current['idAnagraficaSoggetto est']?.toString())
      : (pianificata && current && ((pianificata as Record<string, any>)[field]?.toString() === (current as Record<string, any>)[field]?.toString() || ([undefined, null, false].includes((pianificata as Record<string, any>)[field]) && [undefined, null, false].includes((current as Record<string, any>)[field]))));
  });
}

const PresenzeW = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const logoUri = useAppSelector((state) => state.authInfo.logoUri);
  const abilitazione = useAppSelector((state) => getAbilitazione(state, presenzePath));
  const title = t("attendanceTitle");
  const classes = useStyles1();
  const isCurrentPageNotIndex = !isUrlRoot(history.location.pathname, presenzePath);
  const resetError = useCallback(() => {
    dispatch(resetErrorArticoliLegge());
    dispatch(resetErrorAssenze());
    dispatch(resetErrorStrutture());
    dispatch(resetErrorDipartimentiFiltered());
    dispatch(resetErrorTurni());
    dispatch(resetErrorQualifiche());
    dispatch(resetErrorAnagrificheSoggettiQualifica());
    dispatch(resetErrorTurnoAbilitazione());
    dispatch(resetErrorPresenze());
    dispatch(resetErrorAnagraficaSoggettiUnitaOperative());
    dispatch(resetErrorUnitaOperativeFiltered());
  }, [dispatch]);

  const [legendaData, setLegendaData] = useState<{ idAnagraficaSoggetti: number | null, presenzaData: string } | null>(null);
  const [obj, setObj] = useState<PresenzaForm | null>(null);

  const strSelectName = useMemo(() => t("structuresTitle"), [t]);
  const strSelectLabel = useMemo(() => t("structureSelect"), [t]);
  const dipSelectName = useMemo(() => t("departmentsTitle"), [t]);
  const dipSelectLabel = useMemo(() => t("departmentSelect"), [t]);
  const unitOpSelectName = useMemo(() => t("operatingUnitTitle"), [t]);
  const unitOpSelectLabel = useMemo(() => t("operatingUnitSelect"), [t]);
  const dateSelectName = useMemo(() => t("dateTitle"), [t]);
  const dateSelectLabel = useMemo(() => t("dateSelect"), [t]);

  /**
   * Invocata quando esce dal General Form
   */
  const clearance = useCallback(() => {
    dispatch(resetPresenzaSingola());
    dispatch(resetTimbraturaAnagrificaSoggetto());
    setSelectedVersoTimbratura(null);
    setSelectedTimbratura(null);
    setSelectedInizio(-1);
    setSelectedFine(-1);
  }, [dispatch]);

  const [states, setStates] = useState<{
    [selectName: string]: number | string | null;
  }>({
    [strSelectName]: null,
    [dipSelectName]: null,
    [unitOpSelectName]: null,
    [dateSelectName]: getTodayStart(),
  });

  useEffect(() => {
    setStates({
      [strSelectName]: null,
      [dipSelectName]: null,
      [unitOpSelectName]: null,
      [dateSelectName]: getTodayStart(),
    });
  }, [dateSelectName, dipSelectName, strSelectName, unitOpSelectName]);

  const fixedProps = useMemo(() => {
    return {
      idStruttura: states[strSelectName],
      idDipartimento: states[dipSelectName],
      idUnitaOperativa: states[unitOpSelectName],
      presenzaData: states[dateSelectName],
    };
  }, [states, strSelectName, dipSelectName, unitOpSelectName, dateSelectName]);

  const fixedPropsAssenza = useMemo(() => {
    let da = "";
    let a = "";

    if (fixedProps.presenzaData) {
      const currentDate: Date = new Date(fixedProps.presenzaData);
      const currentMonth: string = currentDate.getMonth() + 1 > 9 ? (currentDate.getMonth() + 1).toString() : "0" + (currentDate.getMonth() + 1);

      da = currentDate.getFullYear() + "-" + currentMonth + "-01T00:00:00";
      a = currentDate.getFullYear() + "-" + currentMonth + "-" + daysInMonth(currentDate.getMonth() + 1, currentDate.getFullYear()) + "T00:00:00";
    }

    return {
      idStruttura: states[strSelectName],
      idDipartimento: states[dipSelectName],
      idUnitaOperativa: states[unitOpSelectName],
      da: da,
      a: a
    }
  }, [dipSelectName, fixedProps.presenzaData, states, strSelectName, unitOpSelectName]);

  const [isFixedPropsChanged, setIsFixedPropsChanged] = useState(false)
  useEffect(() => {
    setIsFixedPropsChanged(true);
  }, [fixedProps.idStruttura, fixedProps.idDipartimento, fixedProps.idUnitaOperativa, fixedProps.presenzaData]);

  const timbratureInizioTurnoLista = useAppSelector(state => state.timbrature.validTimbratureAnagraficaSoggetto?.timbratureInizioTurno)
  const timbratureFineTurnoLista = useAppSelector(state => state.timbrature.validTimbratureAnagraficaSoggetto?.timbratureFineTurno)

  const [selectedInizio, setSelectedInizio] = useState(-1)
  const [selectedFine, setSelectedFine] = useState(-1)

  const idAnagraficaSoggettoConst = "idAnagraficaSoggetto";
  const idAnagraficaSoggettoInternaConst = "idAnagraficaSoggetto int";
  const idAnagraficaSoggettoEsternaConst = "idAnagraficaSoggetto est";
  const idTurnoAssenzaConst = "idTurnoAssenza";
  const turnoAssenzaDescrizioneConst = "turnoAssenzaDescrizione";
  const idArticoloLeggeConst = "idArticoloLegge";
  const articoloLeggeDescrizioneConst = "articoloLeggeDescrizione";
  const idUnitaOperativaConst = "idUnitaOperativa";
  const idTurnoConst = "idTurno";
  const turnoDescrizioneConst = "turnoDescrizione";
  const idQualificaConst = "idQualifica";
  const escludeFlagConst = "includiInseriti";
  const reperibilitaConst = "reperibilita";
  const prestazioneAggiuntivaConst = "prestazioneAggiuntiva";

  const oraInizioTurnoConst = "oraInizioTurno";
  const oraFineTurnoConst = "oraFineTurno";
  const oreTurnoConst = 'oreTurno';
  const minutiTurnoConst = 'minutiTurno';

  const lookupStr = useAppSelector((state) => state.strutture.lookup);
  const lookupDip = useAppSelector((state) => state.dipartimentiFiltered.lookup);
  const lookupUnitOp = useAppSelector((state) => state.unitaOperativeFiltered.lookup);
  const lookupTurnoAssenza = useAppSelector((state) => state.assenze.lookupValidDescrizione);
  const lookupTurni = useAppSelector(state => state.turni.lookupDescrizione);
  const lookupTurniBreve = useAppSelector(state => state.turni.lookupDescrizioneBreve);
  const lookupQualifiche = useAppSelector(state => state.qualifiche.lookupDescrizioneBreve);
  const lookupAnagraficaSoggettoPerUnitaOperativa = useAppSelector((state) => state.anagraficaSoggettiUnitaOperative.lookup);
  const lookupAltreAnagraficheEsterne = useAppSelector(state => state.anagraficaSoggettiUnitaOperative.lookupEsterno);    // utilizzato dal form

  const lookupArticoloLeggeTemp = useAppSelector((state) => state.articoliLegge.lookup);
  const lookupArticoloLegge = useMemo(() => ({ null: t("fieldNullSelect"), ...lookupArticoloLeggeTemp }), [lookupArticoloLeggeTemp, t]);

  const strutturaTurniOre = useAppSelector(state => state.strutture.strutturaEstesa?.struttureTurniOre);
  const qualificheAbilitateFiltered = useAppSelector(state => state.anagraficaSoggettiQualifica.qualificheAbilitate);
  const turniAbilitatiFiltered = useAppSelector(state => state.anagraficaSoggettiTurnoAbilitato.turniAbilitati);
  const validAssenze = useAppSelector(state => state.assenze.validAssenze);
  const validTurni = useAppSelector(state => state.turni.validTurni);

  const [lookupAnagraficheEsterneByID, setLookupAnagraficheEsterneByID] = useState<Lookup>({});                          // utilizzato dal view
  const [lookupAnagraficheEsterne, setLookupAnagraficheEsterne] = useState<Lookup>({});
  const [lookupMerge, setLookupMerge] = useState<Lookup>(lookupAnagraficaSoggettoPerUnitaOperativa);

  const [formLookups, setFormLookups] = useState<{ [key: string]: Lookup }>({
    [idAnagraficaSoggettoInternaConst]: lookupAnagraficaSoggettoPerUnitaOperativa,
    [idAnagraficaSoggettoEsternaConst]: lookupAnagraficheEsterne,
    [idAnagraficaSoggettoConst]: lookupMerge,
    [idTurnoAssenzaConst]: lookupTurnoAssenza,
    [idArticoloLeggeConst]: lookupArticoloLegge,
    [idUnitaOperativaConst]: lookupUnitOp,
    [idTurnoConst]: createLookup(turniAbilitatiFiltered, idTurnoConst, ['descrizione']),
    [idQualificaConst]: createLookup(qualificheAbilitateFiltered, idQualificaConst, ['descrizioneBreve'])
  });

  useEffect(() => {
    // per lookup del view
    setLookupMerge({ ...lookupAnagraficaSoggettoPerUnitaOperativa, ...lookupAnagraficheEsterneByID });
  }, [lookupAnagraficheEsterneByID, lookupAnagraficaSoggettoPerUnitaOperativa]);

  useEffect(() => {
    setLookupAnagraficheEsterne(removeInternalAnagraficaSoggetto(lookupAltreAnagraficheEsterne, lookupAnagraficaSoggettoPerUnitaOperativa));
  }, [lookupAltreAnagraficheEsterne, lookupAnagraficaSoggettoPerUnitaOperativa]);

  useEffect(() => {
    // per lookup del form
    setFormLookups(state => {
      const updatedEsterneLookup: Lookup = { ...lookupAnagraficheEsterne, ...lookupAnagraficheEsterneByID }
      return {
        ...state,
        [idAnagraficaSoggettoEsternaConst]: updatedEsterneLookup,
        [idAnagraficaSoggettoConst]: lookupMerge,
        [idTurnoAssenzaConst]: lookupTurnoAssenza,
        [idArticoloLeggeConst]: lookupArticoloLegge,
        [idUnitaOperativaConst]: lookupUnitOp,
        [idTurnoConst]: createLookup(turniAbilitatiFiltered, idTurnoConst, ['descrizione']),
        [idQualificaConst]: createLookup(qualificheAbilitateFiltered, idQualificaConst, ['descrizioneBreve'])

      };
    })
  }, [
    lookupAltreAnagraficheEsterne,
    lookupAnagraficaSoggettoPerUnitaOperativa,
    lookupAnagraficheEsterne,
    lookupAnagraficheEsterneByID,
    lookupArticoloLegge,
    lookupMerge,
    lookupTurnoAssenza,
    lookupUnitOp,
    qualificheAbilitateFiltered,
    turniAbilitatiFiltered,
    validAssenze
  ]);

  /**
   * Tabs
   */
  const [tabValue, setTabValue] = useState<TabIndex | null>(TabIndex.PRESENZE);
  const handleTabIndexChange = (event: React.ChangeEvent<{}>, newValue: TabIndex) => {
    setTabValue(newValue);
  };
  const handleTabChange = (index: TabIndex) => {
    return {
      id: 'tab-' + index,
      'aria-controls': 'tabpanel-' + index,
    };
  }

  const _allFieldsPresenza: Fields[] = useMemo(() => allFieldsPresenza.map(i => {
    if (i.field === escludeFlagConst) {
      return {
        ...i,
        customFormFieldRender: (data: PresenzaForm, field: Fields, handler: Handler, hasError: boolean, helperText: string, setData?: ComponentStateSetter<PresenzaForm>) => {
          return (
            <Tooltip
              title={
                <Typography variant='caption'>
                  {t(field.titleKey)}
                </Typography>
              }
            >
              <MuiSwitch
                checked={data?.includiInseriti}
                color='primary'
                onChange={(e) => {
                  setData && setData(state => {
                    return {
                      ...state,
                      [escludeFlagConst]: !data?.includiInseriti
                    }
                  });
                  handler.boolean(e, field.field)
                }}
              />
            </Tooltip>
          )
        }
      }
    }

    return i;
  }), [t]);

  /**
   * update allfields on tabValue change
   */
  useEffect(() => {
    switch (tabValue) {
      case TabIndex.PRESENZE:
        setAllFieldsState(_allFieldsPresenza.filter(elem => ['table', 'both', undefined, null].includes(elem.showOn)));
        break;
      case TabIndex.CONTEGGIO_QUALIFICA:
        setAllFieldsState(allFieldsQualifica.filter(elem => ['table', 'both', undefined, null].includes(elem.showOn)));
        break;
      case TabIndex.CONTEGGIO_ASSENZA:
        setAllFieldsState(allFieldsAssenza.filter(elem => ['table', 'both', undefined, null].includes(elem.showOn)));
        break;
    }
  }, [_allFieldsPresenza, tabValue]);

  /**
   * fetch data
   */
  useEffect(() => {
    if (fixedProps.idStruttura &&
      fixedProps.idDipartimento &&
      fixedProps.idUnitaOperativa &&
      fixedProps.presenzaData) {

      switch (tabValue) {
        case TabIndex.PRESENZE:
          if (isFixedPropsChanged) {
            dispatch(fetchByDate({
              idStruttura: Number(fixedProps.idStruttura),
              idDipartimento: Number(fixedProps.idDipartimento),
              idUnitaOperativa: Number(fixedProps.idUnitaOperativa),
              dataRiferimento: fixedProps.presenzaData.toString()
            }));
            dispatch(fetchConteggioPerQualifica({
              idStruttura: Number(fixedProps.idStruttura),
              idDipartimento: Number(fixedProps.idDipartimento),
              idUnitaOperativa: Number(fixedProps.idUnitaOperativa),
              presenzaData: fixedProps.presenzaData.toString(),
            }));
          }
          break;
        case TabIndex.CONTEGGIO_QUALIFICA:
          isFixedPropsChanged && dispatch(fetchConteggioPerQualifica({
            idStruttura: Number(fixedProps.idStruttura),
            idDipartimento: Number(fixedProps.idDipartimento),
            idUnitaOperativa: Number(fixedProps.idUnitaOperativa),
            presenzaData: fixedProps.presenzaData.toString(),
          }));
          break;
        case TabIndex.CONTEGGIO_ASSENZA:
          isFixedPropsChanged && dispatch(fetchAssenzeBetweenDates({
            idStruttura: Number(fixedPropsAssenza.idStruttura),
            idDipartimento: Number(fixedPropsAssenza.idDipartimento),
            idUnitaOperativa: Number(fixedPropsAssenza.idUnitaOperativa),
            da: fixedPropsAssenza.da,
            a: fixedPropsAssenza.a,
          }));
          break;
      }
    }
  }, [dispatch, fixedProps.idDipartimento, fixedProps.idStruttura, fixedProps.idUnitaOperativa, fixedProps.presenzaData, fixedPropsAssenza.a, fixedPropsAssenza.da, fixedPropsAssenza.idDipartimento, fixedPropsAssenza.idStruttura, fixedPropsAssenza.idUnitaOperativa, isFixedPropsChanged, tabValue]);

  /**
   * fetch lookups (strutture, dipartimenti, unità operative)
   */
  useEffect(() => {
    if (fixedProps.idStruttura && fixedProps.idDipartimento && fixedProps.idUnitaOperativa) {
      dispatch(
        fetchAnagraficaSoggettiPerUnitaOperativaLookup({
          idStruttura: fixedProps.idStruttura as number,
          idDipartimento: fixedProps.idDipartimento as number,
          idUnitaOperativa: fixedProps.idUnitaOperativa as number,
        })
      );
    } else if (fixedProps.idStruttura && fixedProps.idDipartimento) {
      dispatch(
        fetchUnitaOperativeLookup({
          idStruttura: fixedProps.idStruttura as number,
          idDipartimento: fixedProps.idDipartimento as number,
        })
      );
    } else if (fixedProps.idStruttura) {
      dispatch(fetchDipartimentiLookup({ idStruttura: fixedProps.idStruttura as number }));
      dispatch(fetchStrutturaExtendedById({ idStruttura: fixedProps.idStruttura as number }));
    } else if (!fixedProps.idStruttura) {
      dispatch(fetchStruttureLookup());
      dispatch(fetchAssenzeLookup());
      dispatch(fetchArticoliLeggeLookup());
      dispatch(fetchTurniLookup());
      dispatch(fetchQualificaLookup());
    }
  }, [dispatch, fixedProps.idDipartimento, fixedProps.idStruttura, fixedProps.idUnitaOperativa, fixedProps.presenzaData, tabValue]);

  useEffect(() => {
    dispatch(cleanDipartimentiLookup());
    dispatch(cleanUOLookup());
  }, [dispatch]);

  const excludedFieldInTable = useMemo(() => [idAnagraficaSoggettoEsternaConst, idAnagraficaSoggettoInternaConst, escludeFlagConst, idQualificaConst, idTurnoAssenzaConst, idTurnoConst, idArticoloLeggeConst], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(_allFieldsPresenza as Fields[]);
  const [columns, setColumns] = useState<Array<Column<PresenzeDataType>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState
        .filter(f => !excludedFieldInTable.includes(f.field))
        .filter(f => ['both', 'table', undefined, null].includes(f.showOn))
        .map((f) => {
          let obj: ColumnExt<PresenzeDataType> = {
            title: f.titleKey ? t(f.titleKey) : "",
            field: f.field === idAnagraficaSoggettoInternaConst ? idAnagraficaSoggettoConst : f.field,
            removable: f.removable ?? !f.required,
            editable: f.editable ? f.editable : "always",
            defaultSort: f.sort,
            emptyValue: f.defaultValue,
            sorting: false,
            external: { fieldData: f }
          };

          switch (tabValue) {
            case TabIndex.PRESENZE:
              obj.sorting = [turnoDescrizioneConst, idAnagraficaSoggettoConst].includes(f.field);
              break;
            case TabIndex.CONTEGGIO_QUALIFICA:
              obj.sorting = [turnoDescrizioneConst].includes(f.field);
              break;
          }

          if (f.validate2) {
            obj.validate = (rowData) => {
              let resp: boolean | { isValid: boolean, helperText: string } = false;
              let resp2: boolean | { isValid: boolean, helperText: string } = false;

              if (f.validate2) {
                resp2 = f.validate2(
                  rowData[f.field as PresenzeDataTypeKeys],
                  rowData[f.field2Validation as PresenzeDataTypeKeys],
                  f.keyTradValidation2 ? t(f.keyTradValidation2) : ''
                );
              }

              if (f.validate) {
                resp = f.validate(
                  rowData[f.field as PresenzeDataTypeKeys],
                  f.keyTradValidation ? t(f.keyTradValidation) : ''
                );
              }
              if (resp === true) {
                return resp2;
              } else return resp;
            }
          } else if (f.validate) {
            obj.validate = (rowData) => {
              if (f.validate)
                return f.validate(
                  rowData[f.field as PresenzeDataTypeKeys],
                  f.keyTradValidation ? t(f.keyTradValidation) : ''
                );
              return false;
            }
          }

          if (!f.show) {
            obj.hidden = true;
            if (f.field === idAnagraficaSoggettoEsternaConst) obj.hiddenByColumnsButton = true;
            else obj.hiddenByColumnsButton = false;
          }
          if (f.type && f.type !== "image" && f.type !== "file") {
            obj.type = f.type;
          }

          if (f.render) {
            obj.render = f.render;
          }

          if (['oraInizioTurno', 'oraFineTurno'].includes(f.field)) {
            obj.cellStyle = (data: any, rowData: Presenza) => {
              if ((rowData?.idTimbraturaFineTurno && f.field === 'oraFineTurno') || (rowData?.idTimbraturaInizioTurno && f.field === 'oraInizioTurno'))
                return { backgroundColor: '#00dd6e' }
              return {}
            }
          }

          if (f.defaultGroupOrder != null) {
            obj.defaultGroupOrder = f.defaultGroupOrder;
          }

          /*+++*/
          if (tabValue === TabIndex.CONTEGGIO_QUALIFICA)
            switch (f.field) {
              case 'colorMonitoring':
                obj.render = (rowData) => {
                  const color = rowData.colorMonitoring as ColorsKeys;

                  const tooltipText = () => {
                    let retval = '';

                    switch (color) {
                      case 'Arancione':
                        retval = 'statusConteggioArancione';
                        break;
                      case 'Verde':
                        retval = 'statusConteggioVerde';
                        break;
                      case 'Rosso':
                        retval = 'statusConteggioRosso';
                        break;
                      case 'Bianco':
                        retval = 'statusConteggioBianco';
                        break;
                      case 'Giallo':
                        retval = 'statusConteggioGiallo';
                        break;
                    }

                    return t(retval);
                  }

                  return (
                    <Tooltip
                      enterDelay={50}
                      title={<span>{tooltipText()}</span>}
                    >
                      <StatusIconIndicator color={Colors[color]} />
                    </Tooltip>
                  )
                }
                break;
            }
          /*+++*/
          return obj;
        })
    );
  }, [
    t,
    lookupTurnoAssenza,
    lookupArticoloLegge,
    lookupMerge,
    lookupUnitOp,
    lookupTurni,
    lookupQualifiche,
    allFieldsState,
    excludedFieldInTable,
    tabValue
  ]);

  const [fieldsApprovazione, setFieldsApprovazione] = useState<Fields[]>([]);
  useEffect(() => {
    setFieldsApprovazione([
      {
        field: "approvazioneConcessaData", type: 'date', titleKey: "approvalGrantedDate", required: false, show: false, group: "approval"
      },
      {
        field: "approvazioneNegataData", type: 'date', titleKey: "approvalDeniedDate", required: false, show: false, group: "approval"
      },
      {
        field: "approvazioneRichiestaMotivo", type: 'string', titleKey: "approvalOftheReasonRequest", required: false, show: false, group: "approval", colsNum: 12, multiline: true,
      },
    ]);
  }, [allFieldsState, t]);

  const errorBE = useAppSelector((state) => state.presenze.error);
  const validPresenze = useAppSelector((state) => state.presenze.validPresenze);
  const currentPresenza = useAppSelector(state => state.presenze.presenza);
  const statusValidPresenze = useAppSelector((state) => state.presenze.statusValidPresenze);

  const validConteggioAssenze = useAppSelector((state) => state.presenze.conteggioAssenza);
  const statusValidConteggioAssenze = useAppSelector((state) => state.presenze.statusConteggioAssenza);

  const validConteggioQualifica = useAppSelector((state) => state.presenze.conteggioQualifica);
  const statusValidConteggioQualifica = useAppSelector((state) => state.presenze.statusConteggioQualifica);

  const [data, setData] = useState<Array<Presenza | ConteggioAssenza | ConteggioQualifica>>([]);

  const [initPresenza, setInitPresenza] = useState<PresenzaForm>(currentPresenza as PresenzaForm);
  useEffect(() => {
    setInitPresenza(currentPresenza as PresenzaForm);
  }, [currentPresenza]);

  useEffect(() => {
    /** lista dei LookupAnagraficaSoggettoElem validi */
    const ASPresenze: LookupAnagraficaSoggettoElem[] = [];

    switch (tabValue) {
      case TabIndex.PRESENZE:
        validPresenze.forEach(presenza => {
          if (presenza.anagraficaSoggetto && !lookupAnagraficaSoggettoPerUnitaOperativa[presenza.anagraficaSoggetto[0].idAnagraficaSoggetto])
            ASPresenze.push(presenza.anagraficaSoggetto[0]);
        });

        setLookupAnagraficheEsterneByID(createLookup(ASPresenze, idAnagraficaSoggettoConst, ["cognome", "nome"]));
        setData(validPresenze.map(elem => {
          return {
            ...elem,
            qualifica: elem.qualificaDescrizioneBreve + ' - ' + elem.qualificaDescrizione,
            nominativo: elem.anagraficaSoggetto?.[0].cognome + ' ' + elem.anagraficaSoggetto?.[0].nome
          }
        }));

        break;
      case TabIndex.CONTEGGIO_QUALIFICA:
        setData(validConteggioQualifica.map(elem => {
          return {
            ...elem,
            qualifica: elem.qualificaDescrizioneBreve + ' - ' + elem.qualificaDescrizione
          }
        }));

        break;
      case TabIndex.CONTEGGIO_ASSENZA:
        setData(validConteggioAssenze.map(elem => {
          return {
            ...elem,
            qualifica: elem.qualificaDescrizioneBreve + ' - ' + elem.qualificaDescrizione
          }
        }));

        break;
    }
  }, [dispatch, fixedProps.idDipartimento, fixedProps.idStruttura, fixedProps.idUnitaOperativa, fixedProps.presenzaData, lookupAnagraficaSoggettoPerUnitaOperativa, tabValue, validPresenze, validConteggioAssenze, validConteggioQualifica]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      dispatch(resetArticoliLegge());
      dispatch(resetAssenze());
      dispatch(resetStrutture());
      dispatch(resetDipartimentiFiltered());
      dispatch(resetUnitaOperativaFiltered());
      dispatch(resetTurni());
      dispatch(resetQualifiche());
      dispatch(resetAnagraficaSoggettiQualifica());
      dispatch(resetAnagraficaSoggettiTurnoAbilitato());
      dispatch(resetPresenze());
      dispatch(resetAnagraficaSoggettiUnitaOperative());
    };
  }, [dispatch]);

  const handleDateChange = (d: Date | null, field?: string) => {
    let fieldDate: string | null = null;
    if (d) {
      let month = (d.getMonth() + 1).toString().padStart(2, '0');
      let day = d.getDate().toString().padStart(2, '0');
      fieldDate = d.getFullYear() + "-" + month + "-" + day + "T00:00:00";
    }
    setStates(prev => { return { ...prev, [dateSelectName]: fieldDate } });
  };

  const defaultValuesOnInsert = useMemo(() => {
    return { [escludeFlagConst]: false, }
  }, []);

  const insertCallback = () => {
    setObj(null);
    setInitPresenza(currentPresenza as PresenzaForm);

    setAllFieldsState(_allFieldsPresenza.map(i => {
      if ([oraInizioTurnoConst, oraFineTurnoConst, idArticoloLeggeConst, reperibilitaConst, prestazioneAggiuntivaConst].includes(i.field)) {
        return {
          ...i,
          formHidden: true
        }
      }

      if ([turnoDescrizioneConst, turnoAssenzaDescrizioneConst, articoloLeggeDescrizioneConst].includes(i.field)) {
        return {
          ...i,
          showOn: 'hidden'
        }
      }
      return i;
    }));

    setLegendaData({
      idAnagraficaSoggetti: null,
      presenzaData: fixedProps.presenzaData?.toString() ?? '',
    });

    const currentValidPresenze: Record<string, string> = {};
    const validPresenzeIDASList: number[] = [];
    const IDASPerUOList = Object.keys(lookupAnagraficaSoggettoPerUnitaOperativa);
    validPresenze.forEach(e => !e.reperibilita && validPresenzeIDASList.push(e.idAnagraficaSoggetto));

    IDASPerUOList.forEach(e => {
      if (!validPresenzeIDASList.includes(Number(e)))
        currentValidPresenze[e] = lookupAnagraficaSoggettoPerUnitaOperativa[e]
    })

    setFormLookups(state => {
      return {
        ...state,
        [idAnagraficaSoggettoInternaConst]: currentValidPresenze,
      }
    })

    history.push(presenzePath + componentInsertPath);
  };

  const updateDetailCallback = (presenza: Presenza) => {
    setObj({
      ...presenza,
      timbraturaInizioTurnoIndicatore: presenza.idTimbraturaInizioTurno ? t("stampingassigned") : false,
      timbraturaFineTurnoIndicatore: presenza.idTimbraturaFineTurno ? t("stampingassigned") : false,
    } as PresenzaForm);
    setInitPresenza(presenza as PresenzaForm);

    setLegendaData({
      idAnagraficaSoggetti: presenza.idAnagraficaSoggetto,
      presenzaData: fixedProps.presenzaData?.toString() ?? '',
    });

    dispatch(fetchQualifichePerAnagraficaSoggetto([presenza.idAnagraficaSoggetto, fixedProps.presenzaData]));
    dispatch(fetchTurniAbilitatiPerAnagraficaSoggetto([fixedProps.idStruttura, presenza.idAnagraficaSoggetto, fixedProps.presenzaData]));
    dispatch(findTimbratureByIdOrganizzazioneAndIdAnagraficaSoggettoData({
      idAnagraficaSoggetto: presenza.idAnagraficaSoggetto,
      data: presenza.presenzaData
    }));

    setAllFieldsState(() =>
      _allFieldsPresenza
        .filter(i => ![escludeFlagConst, idAnagraficaSoggettoEsternaConst].includes(i.field))
        .map(i => {
          if ([oraInizioTurnoConst, oraFineTurnoConst].includes(i.field)) {
            return {
              ...i,
              formHidden: false
            }
          }

          if (presenza.idTurno) {
            if ([reperibilitaConst, prestazioneAggiuntivaConst].includes(i.field)) {
              return {
                ...i,
                formHidden: false,
                showOn: 'both'
              }
            }

            if ([idTurnoConst, idTurnoAssenzaConst, turnoAssenzaDescrizioneConst, articoloLeggeDescrizioneConst].includes(i.field)) {
              return {
                ...i,
                showOn: 'hidden'
              }
            }

            if (['turnoDescrizione'].includes(i.field)) {
              return {
                ...i,
                colsNum: 12,
                showOn: 'both'
              }
            }
          } else if (presenza.idTurnoAssenza) {
            if ([idArticoloLeggeConst].includes(i.field)) {
              return {
                ...i,
                formHidden: false,
                showOn: 'both'
              }
            }

            if ([turnoAssenzaDescrizioneConst, articoloLeggeDescrizioneConst].includes(i.field)) {
              return {
                ...i,
                colsNum: 12,
                showOn: 'both'
              }
            }

            if ([idTurnoAssenzaConst, idTurnoConst, turnoDescrizioneConst].includes(i.field)) {
              return {
                ...i,
                showOn: 'hidden'
              }
            }
          }

          return i;
        })
    );

    history.push(presenzePath + componentTabsPath);
  };

  const backButtonCallback = () => {
    setAllFieldsState(_allFieldsPresenza)
  }

  const localActionInsert = (formData: PresenzaForm) => {
    dispatch(insert(formData))
    backButtonCallback();
  }

  const localActionUpdate = (formData: PresenzaForm) => {
    dispatch(update(formData))
    backButtonCallback();
  }

  /**
   * Se il valore iniziale di una presenza di un soggetto ha un idTurno settato,
   * settare oraInizioTurno e oraFineTurno a readonly = false
   * mentre se idTurnoAssenza è settato,
   * settare oraInizioTurno e oraFineTurno a readonly = true
   */
  useEffect(() => {
    setAllFieldsState(state => {
      const newState = [...state];

      newState.forEach((elem, index, array) => {
        if ((['oraInizioTurno', 'oraFineTurno', 'idArticoloLegge'].includes(elem.field)) && initPresenza?.idTurno) {
          array[index].required = elem.field === 'oraInizioTurno';
          array[index].readonly = false;
          if (elem.field === 'idArticoloLegge') {
            array[index].required = false;
            array[index].readonly = true;
          }
        } else if ((['oraInizioTurno', 'oraFineTurno', 'idArticoloLegge'].includes(elem.field)) && initPresenza?.idTurnoAssenza) {
          array[index].required = false;
          array[index].readonly = true;
          if (elem.field === 'idArticoloLegge') {
            array[index].required = false;
            array[index].readonly = false;
          }
        }
      })

      return newState;
    })
  }, [initPresenza?.idTurno, initPresenza?.idTurnoAssenza]);

  const postDialogConfirmCallback = (setGeneralFormObject: Dispatch<SetStateAction<Presenza>>) => {
    setGeneralFormObject(state => {
      let approvazioneRichiesta = false;
      if (errorBE?.startsWith('#')) {
        approvazioneRichiesta = true;
      }
      return {
        ...state,
        approvazioneRichiesta: approvazioneRichiesta,
      }
    })
  };

  const [isPianificataFlag, setIsPianificataFlag] = useState<boolean>(false);
  //useEffect che imposta isPianificataFlag al variare di obj, defaultValuesOnInsert e initPresenza
  useEffect(() => {
    if (obj?.progressivo) {
      setIsPianificataFlag(isPianificata(obj, initPresenza));
    }
    else {
      setIsPianificataFlag(false);
    }
  }, [obj, initPresenza]);

  const [generalFormObjectSetter, setGeneralFormObjectSetter] = useState<ComponentStateSetter<PresenzaForm> | null>(null);

  const formCallback = useCallback((presenza: PresenzaForm | null, field: string, optionalArgs: OptionalArgs<PresenzaForm>): ChecksCallbackReturn | void => {
    if (presenza) {
      const setIdQualifica = (qualificheAbilitate: QualificheAbilitate[]) => {
        const defaultQualifica = qualificheAbilitate.find(elem => elem.selected);

        optionalArgs.setInternalObj(state => {
          return {
            ...state,
            idQualifica: defaultQualifica?.idQualifica
          }
        });
      }

      // Soggetto interno selezionato
      if (presenza[idAnagraficaSoggettoInternaConst] && field === idAnagraficaSoggettoInternaConst) {
        dispatch(fetchQualifichePerAnagraficaSoggetto([presenza[idAnagraficaSoggettoInternaConst], fixedProps.presenzaData]))
          .then(data => setIdQualifica(data.payload as QualificheAbilitate[]));
        dispatch(fetchTurniAbilitatiPerAnagraficaSoggetto([fixedProps.idStruttura, presenza[idAnagraficaSoggettoInternaConst], fixedProps.presenzaData]));
        if (fixedProps.presenzaData) {
          dispatch(fetchPreviousInfo({
            idAnagraficaSoggetto: presenza[idAnagraficaSoggettoInternaConst],
            presenzaData: fixedProps.presenzaData.toString(),
            idStruttura: Number(fixedProps.idStruttura),
            idDipartimento: Number(fixedProps.idDipartimento),
            idUnitaOperativa: Number(fixedProps.idUnitaOperativa),
          }));
        }
        dispatch(findTimbratureByIdOrganizzazioneAndIdAnagraficaSoggettoData({
          idAnagraficaSoggetto: presenza[idAnagraficaSoggettoInternaConst],
          data: presenza.presenzaData
        }));

        // Soggetto esterno selezionato
      } else if (presenza[idAnagraficaSoggettoEsternaConst] && field === idAnagraficaSoggettoEsternaConst) {
        dispatch(fetchQualifichePerAnagraficaSoggetto([presenza[idAnagraficaSoggettoEsternaConst], fixedProps.presenzaData]))
          .then(data => setIdQualifica(data.payload as QualificheAbilitate[]));
        dispatch(fetchTurniAbilitatiPerAnagraficaSoggetto([fixedProps.idStruttura, presenza[idAnagraficaSoggettoEsternaConst], fixedProps.presenzaData]));
        if (fixedProps.presenzaData) {
          dispatch(fetchPreviousInfo({ idAnagraficaSoggetto: presenza[idAnagraficaSoggettoEsternaConst], presenzaData: fixedProps.presenzaData.toString() }));
        }
        dispatch(findTimbratureByIdOrganizzazioneAndIdAnagraficaSoggettoData({
          idAnagraficaSoggetto: presenza[idAnagraficaSoggettoEsternaConst],
          data: presenza.presenzaData
        }));
      }

      /**
       * Data changes here
       */
      optionalArgs.setInternalObj(state => {
        const newState: PresenzaForm = { ...state };

        const clearOreMinutiInfo = () => {
          newState.oraInizioTurno = '';
          newState.oraFineTurno = '';
          newState.oreTurno = '';
          newState.minutiTurno = '';
        }

        const clearAssenzaInfo = () => {
          newState.idTurnoAssenza = undefined;
          newState.idArticoloLegge = undefined;
          clearOreMinutiInfo();
        }

        const clearTurnoInfo = () => {
          newState.idTurno = undefined;
          newState.reperibilita = undefined;
          newState.prestazioneAggiuntiva = undefined;
          clearOreMinutiInfo();
        }

        if (field === oraInizioTurnoConst || field === oraFineTurnoConst) {
          // Imposta ore e minuti del turno in base al turno abilitato
          const selectedTurno = turniAbilitatiFiltered.find(elem => elem.idTurno === presenza.idTurno);
          newState.oreTurno = selectedTurno?.ore ?? 0;
          newState.minutiTurno = '00';

          const isAssenzaParziale = validAssenze?.find(elem => elem.idTurnoAssenza === Number(presenza[field]))?.assenzaParziale;

          if (newState.idTurnoAssenza && isAssenzaParziale) {
            newState.oraFineTurno = newState.oraInizioTurno;
          }

          // Calcolo ore e minuti
          if (presenza.oraInizioTurno && presenza.oraFineTurno) {
            const startDate = new Date();
            const endDate = new Date();

            const startTime = presenza.oraInizioTurno;
            const endTime = presenza.oraFineTurno;

            startDate.setHours(Number(startTime?.split(':')[0]));
            startDate.setMinutes(Number(startTime?.split(':')[1]));

            endDate.setHours(Number(endTime?.split(':')[0]));
            endDate.setMinutes(Number(endTime?.split(':')[1]));

            const totalMinutes = differenceInMinutes(endDate, startDate);
            const hours = Math.floor(totalMinutes / minutesInHour);
            const minutes = totalMinutes % minutesInHour;

            newState.oreTurno = Number.isNaN(hours) ? 0 : hours;
            newState.minutiTurno = Number.isNaN(minutes) ? '00' : minutes;
          }

          /**
           * Riassegna
           * Mostra la dicitura "timbratura assegnata" se oraInizioTurno/oraFineTurno coincide con una timbratura
           */
          if (initPresenza) {
            if (field === oraInizioTurnoConst) {
              if (initPresenza.timbraturaInizioTurnoIndicatore && newState.oraInizioTurno === initPresenza.oraInizioTurno) {
                newState.timbraturaInizioTurnoIndicatore = t("stampingassigned");
                if (newState.idTimbraturaInizioTurno) {
                  newState.idTimbraturaInizioTurno = initPresenza.idTimbraturaInizioTurno
                }
              }
            }

            if (field === oraFineTurnoConst)
              if (initPresenza.idTimbraturaFineTurno && newState.oraFineTurno === initPresenza.oraFineTurno) {
                newState.timbraturaFineTurnoIndicatore = t("stampingassigned");
                if (newState.idTimbraturaFineTurno) {
                  newState.idTimbraturaFineTurno = initPresenza.idTimbraturaFineTurno
                }
              }
          }

          if (presenza.oraInizioTurno && presenza.oraFineTurno) {
            const oraInizioTurno = presenza.oraInizioTurno;
            const oraFineTurno = presenza.oraFineTurno;

            const ID_NOTTE = Object.entries(lookupTurniBreve ?? {})
              .filter(elem => elem[1].trim().toLowerCase().startsWith('n'))
              .map(elem => elem[0]);

            const FROM_HOURS = Number(oraInizioTurno.split(':')[0]);
            const FROM_MINUTES = Number(oraInizioTurno.split(':')[1]);
            const TO_HOURS = Number(oraFineTurno.split(':')[0]);
            const TO_MINUTES = Number(oraFineTurno.split(':')[1]);

            const inizioDate = new Date();
            inizioDate.setHours(FROM_HOURS, FROM_MINUTES);

            const fineDate = new Date();
            fineDate.setHours(TO_HOURS + (TO_HOURS >= FROM_HOURS ? 0 : 24), TO_MINUTES);

            const mezzaNotte = addDays(new Date(), 1);
            mezzaNotte.setHours(0, 0);

            let oreVal = differenceInHours(fineDate, inizioDate);
            let minutiVal = differenceInMinutes(fineDate, inizioDate) % minutesInHour;

            const _idTurno = newState[idTurnoConst]?.toString();

            if (oreVal >= 0 && minutiVal >= 0) {  // stesso giorno && fine >= inizio
              newState[oreTurnoConst] = (oreVal && oreVal > 0) ? oreVal : '00';
              newState[minutiTurnoConst] = (minutiVal && minutiVal > 0) ? minutiVal : '00';
            } else if (_idTurno && !ID_NOTTE.includes(_idTurno)) { // stesso giorno && fine < inizio && turno != 'notte'
              newState[oreTurnoConst] = 0;
              newState[minutiTurnoConst] = 0;
            } else if (_idTurno && ID_NOTTE.includes(_idTurno) && (oreVal < 0 || minutiVal < 0)) {
              oreVal = differenceInHours(mezzaNotte, inizioDate);
              minutiVal = differenceInMinutes(mezzaNotte, inizioDate) % minutesInHour

              newState[oreTurnoConst] = (oreVal && oreVal > 0) ? oreVal : '00';
              newState[minutiTurnoConst] = (minutiVal && minutiVal > 0) ? minutiVal : '00';
            }
          }
        }

        // Turno selezionato
        if (field === idTurnoConst) {
          clearAssenzaInfo();

          let turno: any = turniAbilitatiFiltered.find(elem => Number(elem.idTurno) === Number(presenza.idTurno));

          if (!turno || (turno?.ore == null && turno?.minuti == null)) {
            turno = strutturaTurniOre?.find(elem => Number(elem.idTurno) === Number(presenza.idTurno));
            if (!turno || (turno?.ore == null && turno?.minuti == null)) {
              turno = validTurni.find(elem => Number(elem.idTurno) === Number(presenza.idTurno));
            }
          }

          newState.oreTurno = turno?.ore ?? '0';
          newState.minutiTurno = turno?.minuti?.toString().padStart(2, '0') ?? '00';
        }

        // Assenza selezionata
        if (field === idTurnoAssenzaConst) {
          clearTurnoInfo();

          const assenza = validAssenze.find(elem => Number(elem.idTurnoAssenza) === Number(presenza.idTurnoAssenza));

          newState.oreTurno = assenza?.ore ?? '0';
          newState.minutiTurno = assenza?.minuti?.toString().padStart(2, '0') ?? '00';
        }

        // Soggetto interno selezionato
        if (field === idAnagraficaSoggettoInternaConst) {
          if (presenza[idAnagraficaSoggettoInternaConst]) {
            newState.idAnagraficaSoggetto = presenza[idAnagraficaSoggettoInternaConst];
          }
          newState[idAnagraficaSoggettoEsternaConst] = null;
          clearAssenzaInfo();
          clearTurnoInfo();
        }

        // Soggetto esterno selezionato
        if (field === idAnagraficaSoggettoEsternaConst) {
          if (presenza[idAnagraficaSoggettoEsternaConst]) {
            newState.idAnagraficaSoggetto = presenza[idAnagraficaSoggettoEsternaConst];
          }
          newState[idAnagraficaSoggettoInternaConst] = null;
          clearAssenzaInfo();
          clearTurnoInfo();
        }

        // const isAnagraficaInternaEmpty = (): boolean => null === presenza[idAnagraficaSoggettoInternaConst];
        // const isAnagraficaEsternaEmpty = (): boolean => null === presenza[idAnagraficaSoggettoEsternaConst];

        // const retval = isAnagraficaInternaEmpty() && isAnagraficaEsternaEmpty() && !optionalArgs.isUpdate
        //   ? defaultValue as PresenzaForm
        //   : newState as PresenzaForm;

        // const defaultValue = {
        //   [escludeFlagConst]: state.includiInseriti,
        //   [reperibilitaConst]: state.reperibilita,
        //   idStruttura: Number(fixedProps?.idStruttura),
        //   idDipartimento: Number(fixedProps?.idDipartimento),
        //   idUnitaOperativa: Number(fixedProps?.idUnitaOperativa),
        //   presenzaData: fixedProps?.presenzaData?.toString()
        // }
        // setIsPianificataFlag(isPianificata(newState, initPresenza));

        return newState;
      });

      /**
       * Fields properties changes here
       */
      optionalArgs.setFields(state => {
        return state.map(i => {
          // Esclude inseriti
          if (field === escludeFlagConst) {
            if ([escludeFlagConst].includes(i.field))
              return {
                ...i,
                titleKey: presenza.includiInseriti ? 'excludeInsertedParam' : 'includeInsertedParam'
              }
          }

          // Turno selezionato
          if (field === idTurnoConst) {
            if ([idTurnoConst, idTurnoAssenzaConst].includes(i.field)) {
              return {
                ...i,
                required: i.field === idTurnoConst
              };
            }

            if ([oraInizioTurnoConst, oraFineTurnoConst].includes(i.field)) {
              return {
                ...i,
                formHidden: false,
                showOn: 'both',
                required: false,
                titleKey: i.field === oraInizioTurnoConst ? 'turnStartParam' : 'turnEndDateParam'
              }
            }

            if ([idArticoloLeggeConst].includes(i.field)) {
              return {
                ...i,
                showOn: 'hidden',
                formHidden: true
              }
            }

            if ([reperibilitaConst, prestazioneAggiuntivaConst].includes(i.field)) {
              return {
                ...i,
                showOn: 'form',
                formHidden: false
              }
            }
          }

          // Assenza selezionata
          if (field === idTurnoAssenzaConst) {
            const isAssenzaParziale = validAssenze?.find(elem => elem.idTurnoAssenza === Number(presenza[field]))?.assenzaParziale;

            if ([idTurnoConst, idTurnoAssenzaConst].includes(i.field)) {
              return {
                ...i,
                required: i.field === idTurnoAssenzaConst
              };
            }

            if ([reperibilitaConst, prestazioneAggiuntivaConst].includes(i.field)) {
              return {
                ...i,
                showOn: 'form',
                formHidden: true
              }
            }

            if ([idArticoloLeggeConst].includes(i.field)) {
              return {
                ...i,
                showOn: 'form',
                formHidden: false
              }
            }

            if ([oraInizioTurnoConst, oraFineTurnoConst].includes(i.field)) {
              return {
                ...i,
                formHidden: !isAssenzaParziale,
                showOn: 'both',
                required: isAssenzaParziale,
                titleKey: i.field === oraInizioTurnoConst ? 'absenceStartDateParam' : 'absenceEndDateParam'
              }
            }
          }

          // Soggetto interno
          if (field === idAnagraficaSoggettoInternaConst) {
            if ([idAnagraficaSoggettoEsternaConst].includes(i.field)) {
              return {
                ...i,
                required: false
              }
            }

            if ([idAnagraficaSoggettoInternaConst].includes(i.field)) {
              return {
                ...i,
                required: true
              }
            }
          }

          // Soggetto esterno
          if (field === idAnagraficaSoggettoEsternaConst) {
            if ([idAnagraficaSoggettoEsternaConst].includes(i.field)) {
              return {
                ...i,
                required: true
              }
            }

            if ([idAnagraficaSoggettoInternaConst].includes(i.field)) {
              return {
                ...i,
                required: false
              }
            }
          }

          return i;
        }) as Fields[];
      });

      const _presenzaData = fixedProps.presenzaData;
      if (_presenzaData && [idAnagraficaSoggettoInternaConst, idAnagraficaSoggettoEsternaConst].includes(field)) {
        setLegendaData(state => {
          return {
            idAnagraficaSoggetti: field === idAnagraficaSoggettoEsternaConst ? presenza[idAnagraficaSoggettoEsternaConst] : presenza[idAnagraficaSoggettoInternaConst],
            presenzaData: _presenzaData.toString()
          }
        })
      }

      /**
       * Quando il valore di object[escludeFlagConst] è true,
       * filtra la lista dei soggetti interni togliendo quelli già inseriti
       */
      if (field === escludeFlagConst) {
        let currentValidPresenze: Record<string, string> = {};

        // exclude selected AnagraficaSoggetto
        if (!presenza[field]) {
          const validPresenzeIDASList: number[] = [];
          const IDASPerUOList = Object.keys(lookupAnagraficaSoggettoPerUnitaOperativa);

          validPresenze.forEach(e => !e.reperibilita && validPresenzeIDASList.push(e.idAnagraficaSoggetto));

          IDASPerUOList.forEach(e =>
            !validPresenzeIDASList.includes(Number(e))
            && (currentValidPresenze[e] = lookupAnagraficaSoggettoPerUnitaOperativa[e])
          )
        } else {
          currentValidPresenze = lookupAnagraficaSoggettoPerUnitaOperativa;
        }

        /**
         * Aggiorna il lookup dei soggetti interni utilizzando quelli filtrati
         */
        setFormLookups(state => {
          return {
            ...state,
            [idAnagraficaSoggettoInternaConst]: currentValidPresenze,
          }
        })
      }

      if (field === idTurnoConst) {
        optionalArgs.removeHelperText(idTurnoAssenzaConst);
        if (validTurni?.find(elem => elem.idTurno === Number(presenza.idTurno))?.richiestaApprovazione) {
          return {
            type: 'info',
            field: idTurnoConst,
            message: 'Turno soggetto ad approvazione',
          };
        }
      }

      if (field === idTurnoAssenzaConst) {
        optionalArgs.removeHelperText(idTurnoConst);
        if (validAssenze?.find(elem => elem.idTurnoAssenza === Number(presenza.idTurnoAssenza))?.richiestaApprovazione) {
          return {
            type: 'info',
            field: idTurnoAssenzaConst,
            message: 'Assenza soggetta ad approvazione',
          };
        }
      }
    }

    // if (!generalFormObjectSetter)
    setGeneralFormObjectSetter(null);

  }, [dispatch, fixedProps.idDipartimento, fixedProps.idStruttura, fixedProps.idUnitaOperativa, fixedProps.presenzaData, initPresenza, lookupAnagraficaSoggettoPerUnitaOperativa, lookupTurniBreve, strutturaTurniOre, t, turniAbilitatiFiltered, validAssenze, validPresenze, validTurni]);

  /**
   * Function used for retrieving updated object from General Form
   */
  const objectCallback = useCallback((object: PresenzaForm) => {
    setObj(object);
  }, []);

  /**
   * Search token and save results to slice. Called by autocomplete in GeneralForm.
   * @param {string} token - string to be searched
   * @param {number} minLen - minimum acceptable length of token
   */
  const searchAction = (token: string, minLen?: number) => {
    if (token.length === minLen && fixedProps.presenzaData) {
      dispatch(fetchASLookupByName({
        idStruttura: Number(fixedProps.idStruttura),
        idDipartimento: Number(fixedProps.idDipartimento),
        idUnitaOperativa: Number(fixedProps.idUnitaOperativa),
        dataRif: fixedProps.presenzaData.toString(),
        token: token,
      }))
    }
    else if (token.length === 0) {
      dispatch(cleanLookupEsterno());
    }
  }

  const [exportDataExtra, setExportDataExtra] = useState<PDFExtraData>();

  /**
   * set export data (head and additional) based on selected tab
   */
  useEffect(() => {
    if (tabValue === TabIndex.PRESENZE) {
      setExportDataExtra({
        head: {
          title: [t("structureTitle"), t("departmentTitle"), t("operatingUnitTitle"), t("dateTitle")],
          value: [
            fixedProps.idStruttura ? lookupStr[fixedProps.idStruttura] : '',
            fixedProps.idDipartimento ? lookupDip[fixedProps.idDipartimento] : '',
            fixedProps.idUnitaOperativa ? lookupUnitOp[fixedProps.idUnitaOperativa] : '',
            fixedProps.presenzaData ? getDateDDMMYYYY(new Date(fixedProps.presenzaData)) : ''
          ]
        },
        misc: {
          validConteggioQualifica,
          qualificaFields: allFieldsQualifica.filter(elem => ['table', 'both', undefined, null].includes(elem.showOn))
        }
      });
    } else {
      setExportDataExtra({
        head: {
          title: [t("structureTitle"), t("departmentTitle"), t("operatingUnitTitle"), t("dateTitle")],
          value: [
            fixedProps.idStruttura ? lookupStr[fixedProps.idStruttura] : '',
            fixedProps.idDipartimento ? lookupDip[fixedProps.idDipartimento] : '',
            fixedProps.idUnitaOperativa ? lookupUnitOp[fixedProps.idUnitaOperativa] : '',
            fixedProps.presenzaData ? getDateDDMMYYYY(new Date(fixedProps.presenzaData)) : ''
          ]
        }
      });
    }
  }, [fixedProps.idDipartimento, fixedProps.idStruttura, fixedProps.idUnitaOperativa, fixedProps.presenzaData, lookupDip, lookupStr, lookupUnitOp, t, tabValue, validConteggioQualifica]);

  useEffect(() => {
    return () => {
      dispatch(cleanDipartimentiLookup());
      dispatch(cleanUOLookup());
      setStates({});
      setObj(null);
      setLegendaData(null);
      setLookupMerge({});
      setFormLookups({});
      setTabValue(null);
      setAllFieldsState([]);
      setColumns([]);
      setData([]);
      setExportDataExtra(undefined);
    }
  }, [dispatch]);

  const isTurnoNotte = (presenza: Presenza): boolean => {
    const inizio_hour = Number(presenza?.oraInizioTurno?.split(':')[0]);
    const inizio_minute = Number(presenza?.oraInizioTurno?.split(':')[1]);

    const fine_hour = Number(presenza?.oraFineTurno?.split(':')[0]);
    const fine_minute = Number(presenza?.oraFineTurno?.split(':')[1]);

    if (Number.isNaN(inizio_hour) || Number.isNaN(inizio_minute) || (Number.isNaN(fine_hour)) || (Number.isNaN(fine_minute)))
      return false;
    return undefined !== presenza && undefined !== presenza.idTurno &&
      lookupTurniBreve[presenza.idTurno]?.toLowerCase().startsWith('n') &&
      presenza?.oraInizioTurno.length > 0 &&
      (presenza?.oraFineTurno ? presenza?.oraFineTurno < presenza?.oraInizioTurno : true)
  }

  const clearErrorMessageDialog = useCallback(() => {
    dispatch(resetErrorPresenze());
  }, [dispatch])

  const dialogResponse = useAppSelector(state => state.dialogs.dialogConfirm.click);
  const [selectedVersoTimbratura, setSelectedVersoTimbratura] = useState<'entrata' | 'uscita' | null>(null);
  const [selectedTimbratura, setSelectedTimbratura] = useState<Timbrature | null>(null);
  useEffect(() => {
    switch (dialogResponse) {
      case 'yes':
        switch (selectedVersoTimbratura) {
          case 'entrata':
            // TODO: general form inizio turno
            generalFormObjectSetter && generalFormObjectSetter(state => {
              const newState = { ...state }

              if (selectedTimbratura?.oraInizioTurno && newState[oraFineTurnoConst]) {
                const oraInizioTurno = selectedTimbratura?.oraInizioTurno;
                const oraFineTurno = newState[oraFineTurnoConst];

                const ID_NOTTE = Object.entries(lookupTurniBreve ?? {})
                  .filter(elem => elem[1].trim().toLowerCase().startsWith('n'))
                  .map(elem => elem[0]);

                const FROM_HOURS = Number(oraInizioTurno.split(':')[0]);
                const FROM_MINUTES = Number(oraInizioTurno.split(':')[1]);
                const TO_HOURS = Number(oraFineTurno.split(':')[0]);
                const TO_MINUTES = Number(oraFineTurno.split(':')[1]);

                const inizioDate = new Date();
                inizioDate.setHours(FROM_HOURS, FROM_MINUTES);

                const fineDate = new Date();
                fineDate.setHours(TO_HOURS + (TO_HOURS >= FROM_HOURS ? 0 : 24), TO_MINUTES);

                const mezzaNotte = addDays(new Date(), 1);
                mezzaNotte.setHours(0, 0);

                const oreVal = differenceInHours(fineDate, inizioDate);
                const minutiVal = differenceInMinutes(fineDate, inizioDate) % minutesInHour;

                if (oreVal >= 0 && minutiVal >= 0) {  // stesso giorno && fine >= inizio
                  newState["oreTurno"] = oreVal;
                  newState["minutiTurno"] = minutiVal;
                } else if (newState[idTurnoConst] && !ID_NOTTE.includes(newState[idTurnoConst].toString())) { // stesso giorno && fine < inizio && turno != 'notte'
                  newState["oreTurno"] = 0;
                  newState["minutiTurno"] = 0;
                } else if (newState[idTurnoConst] && ID_NOTTE.includes(newState[idTurnoConst].toString()) && (oreVal < 0 || minutiVal < 0)) {
                  newState["oreTurno"] = differenceInHours(mezzaNotte, inizioDate);
                  newState["minutiTurno"] = differenceInMinutes(mezzaNotte, inizioDate) % minutesInHour;
                }
              }

              return {
                ...newState,
                oraInizioTurno: selectedTimbratura?.oraInizioTurno,
                //timbraturaInizioTurno: selectedTimbratura,
                timbraturaInizioTurnoIndicatore: t("stampingassigned"),
                idTimbraturaInizioTurno: selectedTimbratura?.idTimbratura
              } as PresenzaForm
            })

            setSelectedVersoTimbratura(null);

            break;
          case 'uscita':
            // TODO: general form fine turno
            generalFormObjectSetter && generalFormObjectSetter(state => {
              const newState = { ...state }

              if (newState[oraInizioTurnoConst] && selectedTimbratura?.oraFineTurno) {
                const oraInizioTurno = newState[oraInizioTurnoConst];
                const oraFineTurno = selectedTimbratura?.oraFineTurno;

                const ID_NOTTE = Object.entries(lookupTurniBreve ?? {})
                  .filter(elem => elem[1].trim().toLowerCase().startsWith('n'))
                  .map(elem => elem[0]);

                const FROM_HOURS = Number(oraInizioTurno.split(':')[0]);
                const FROM_MINUTES = Number(oraInizioTurno.split(':')[1]);
                const TO_HOURS = Number(oraFineTurno.split(':')[0]);
                const TO_MINUTES = Number(oraFineTurno.split(':')[1]);

                const inizioDate = new Date();
                inizioDate.setHours(FROM_HOURS, FROM_MINUTES);

                const fineDate = new Date();
                fineDate.setHours(TO_HOURS + (TO_HOURS >= FROM_HOURS ? 0 : 24), TO_MINUTES);

                const mezzaNotte = addDays(new Date(), 1);
                mezzaNotte.setHours(0, 0);

                const oreVal = differenceInHours(fineDate, inizioDate);
                const minutiVal = differenceInMinutes(fineDate, inizioDate) % minutesInHour;

                if (oreVal >= 0 && minutiVal >= 0) {  // stesso giorno && fine >= inizio
                  newState["oreTurno"] = oreVal;
                  newState["minutiTurno"] = minutiVal;
                } else if (newState[idTurnoConst] && !ID_NOTTE.includes(newState[idTurnoConst].toString())) { // stesso giorno && fine < inizio && turno != 'notte'
                  newState["oreTurno"] = 0;
                  newState["minutiTurno"] = 0;
                } else if (newState[idTurnoConst] && ID_NOTTE.includes(newState[idTurnoConst].toString()) && (oreVal < 0 || minutiVal < 0)) {
                  newState["oreTurno"] = differenceInHours(mezzaNotte, inizioDate);
                  newState["minutiTurno"] = differenceInMinutes(mezzaNotte, inizioDate) % minutesInHour;
                }
              }

              return {
                ...newState,
                oraFineTurno: selectedTimbratura?.oraFineTurno,
                //timbraturaFineTurno: selectedTimbratura,
                timbraturaFineTurnoIndicatore: t("stampingassigned"),
                idTimbraturaFineTurno: selectedTimbratura?.idTimbratura
              } as PresenzaForm
            })
            setSelectedVersoTimbratura(null);
            break;
        }
        dispatch(resetYesClickDialogConfirm())
        break;
      case 'no':
        switch (selectedVersoTimbratura) {
          case 'entrata':
            setSelectedInizio(-1);
            setSelectedVersoTimbratura(null);
            break;
          case 'uscita':
            setSelectedFine(-1);
            setSelectedVersoTimbratura(null);
            break;
        }

        dispatch(resetYesClickDialogConfirm())
        break;
    }
  }, [dialogResponse, dispatch, generalFormObjectSetter, lookupTurniBreve, selectedTimbratura, selectedTimbratura?.oraFineTurno, selectedTimbratura?.oraInizioTurno, selectedVersoTimbratura, t]);

  const ListaTimbrature = () => {
    return <>
      <Box border={2} borderColor="#dee2e6" borderRadius={4} px={3} py={4} mt={2}>
        <Typography variant={'h6'}>{t('stampingParam')}</Typography>
        <Grid container>
          <Grid item xs>
            <Box border={2} borderColor="#dee2e6" borderRadius={4} px={3} py={4} mt={2}>
              <Typography variant={'h6'}>{t('entry')}</Typography>
              <List dense style={{ maxHeight: 150, overflowY: 'auto' }}>
                {
                  timbratureInizioTurnoLista &&
                  [...timbratureInizioTurnoLista]?.sort((a, b) => {
                    if (undefined !== a.oraInizioTurno && undefined !== b.oraInizioTurno) {
                      if (a.oraInizioTurno > b.oraInizioTurno) return 1
                      else if (a.oraInizioTurno < b.oraInizioTurno) return -1
                      else return 0
                    }
                    else if (undefined === b.oraInizioTurno) return 1;
                    else if (undefined === a.oraInizioTurno) return -1;
                    else return 0;
                  })
                    ?.map((elem, index) => {
                      return <>
                        <ListItem
                          disabled={elem.presenza !== undefined && elem.presenza !== null}
                          button
                          selected={selectedInizio === index}
                          onClick={() => {
                            setSelectedInizio(index);
                            setSelectedTimbratura(elem);
                            setSelectedVersoTimbratura('entrata');
                            dispatch(openDialogConfirm({
                              title: 'Conferma',
                              text: 'Conferma inizio turno'
                            }));
                          }}
                        >
                          {
                            elem.data && <ListItemText primary={getDateDDMMYYYY(new Date(elem.data)) + ' ' + elem.oraInizioTurno} />
                          }
                        </ListItem>
                        <Divider />
                      </>
                    })
                }
              </List>
            </Box>
          </Grid>
          <Grid item xs>
            <Box position="relative" border={2} borderColor="#dee2e6" borderRadius={4} px={3} py={4} mt={2} maxHeight={250}>
              <Typography variant={'h6'}>{t('exits')}</Typography>
              <List dense style={{ maxHeight: 150, overflowY: 'auto' }}>
                {
                  timbratureFineTurnoLista &&
                  [...timbratureFineTurnoLista]?.sort((a, b) => {
                    if (undefined !== a.oraFineTurno && undefined !== b.oraFineTurno) {
                      if (a.oraFineTurno > b.oraFineTurno) return 1
                      else if (a.oraFineTurno < b.oraFineTurno) return -1
                      else return 0
                    }
                    else if (undefined === b.oraFineTurno) return 1;
                    else if (undefined === a.oraFineTurno) return -1;
                    else return 0;
                  })?.map((elem, index) => {
                    return <>
                      <ListItem
                        disabled={elem.presenza !== undefined && elem.presenza !== null}
                        button
                        selected={selectedFine === index}
                        onClick={() => {
                          setSelectedFine(index);
                          setSelectedTimbratura(elem);
                          setSelectedVersoTimbratura('uscita');
                          dispatch(openDialogConfirm({
                            title: 'Conferma',
                            text: 'Conferma fine turno'
                          }));
                        }}
                      >
                        {
                          elem.data && <ListItemText primary={getDateDDMMYYYY(new Date(elem.data)) + ' ' + elem.oraFineTurno} />
                        }
                      </ListItem>
                      <Divider />
                    </>
                  })
                }
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  }

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={8}>
              <SimpleIdSelects
                selectsArray={[
                  {
                    name: strSelectName,
                    lookup: lookupStr,
                    label: strSelectLabel,
                    disabled: isCurrentPageNotIndex,
                    breakpoints: { xs: 12, sm: 12, md: 12, lg: 4 },
                  },
                  {
                    name: dipSelectName,
                    lookup: lookupDip,
                    label: dipSelectLabel,
                    disabled: isCurrentPageNotIndex,
                    breakpoints: { xs: 12, sm: 12, md: 12, lg: 4 },
                    callback: cleanDipartimentiLookup,
                  },
                  {
                    name: unitOpSelectName,
                    lookup: lookupUnitOp,
                    label: unitOpSelectLabel,
                    disabled: isCurrentPageNotIndex,
                    breakpoints: { xs: 12, sm: 12, md: 12, lg: 4 },
                    callback: cleanUOLookup,
                  },
                ]}
                states={states}
                setStates={setStates}
              />
            </Grid>
            {(states[strSelectName] && states[dipSelectName] && states[unitOpSelectName]) &&
              <Grid item xs={12} sm={12} md={6} lg={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === "it-IT" || i18n.language === "it" ? it : enGB}>
                  <DatePicker
                    variant="inline"
                    label={dateSelectLabel}
                    format={"dd/MM/yyyy"}
                    onChange={(e) => handleDateChange(e)}
                    autoOk={true}
                    disabled={isCurrentPageNotIndex}
                    value={states[dateSelectName] ? states[dateSelectName] : null}
                    views={["year", "month", "date"]}
                    maxDate={today}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
            {
              (states[strSelectName] && states[dipSelectName] && states[unitOpSelectName] && states[dateSelectName]) &&
              <Grid item xs={12} sm={12} md={6} lg={2}>
                <Button
                  variant='contained'
                  onClick={() => {
                    dispatch(chiusuraGiornata(fixedProps))
                      .finally(() => {
                        if (fixedProps.presenzaData != null && fixedProps.idStruttura != null && fixedProps.idDipartimento != null && fixedProps.idUnitaOperativa != null) {
                          dispatch(fetchByDate({
                            idStruttura: fixedProps.idStruttura,
                            idDipartimento: fixedProps.idDipartimento,
                            idUnitaOperativa: fixedProps.idUnitaOperativa,
                            dataRiferimento: fixedProps.presenzaData
                          }))
                        }
                      })
                  }}
                >
                  {t('Chiusura giornata')}
                </Button>
              </Grid>
            }
          </Grid>
        </Box>
      </Paper>
      <Box marginTop={2}>
        {
          states[strSelectName] &&
            states[dipSelectName] &&
            states[unitOpSelectName] &&
            states[dateSelectName] ? (
            <>
              <Switch>
                <Route path={presenzePath} exact>
                  <>
                    <AppBar position="static" className={classes.appbar}>
                      <Tabs value={tabValue} onChange={handleTabIndexChange} classes={{ indicator: classes.indicator }}>
                        <Tab label={t("attendanceTabLabel")} {...handleTabChange(TabIndex.PRESENZE)} />
                        <Tab label={t("countQualificationTabLabel")} {...handleTabChange(TabIndex.CONTEGGIO_QUALIFICA)} />
                        <Tab label={t("countAbsenceTabLabel")} {...handleTabChange(TabIndex.CONTEGGIO_ASSENZA)} />
                      </Tabs>
                    </AppBar>
                    {/* Presenze */}
                    <TabPanel value={tabValue} index={TabIndex.PRESENZE}>
                      <CrudMaterialTableWithoutLogicDelete
                        abilitazione={abilitazione}
                        title={title}
                        columns={columns}
                        columnsButton={true}
                        data={data}
                        elementIdProps={elementIdProps}
                        elementRenderProps={elementRenderProps}
                        fetchAllValid={fetchByDate}
                        insert={insert}
                        physicalDel={physicalDel}
                        update={update}
                        statusValid={statusValidPresenze}
                        errorBE={errorBE}
                        logoUri={logoUri}
                        localizedDatePicker={true}
                        fixedProps={fixedProps}
                        exportDataExtra={exportDataExtra}
                        isExportLandscape={false}
                        insertCallback={insertCallback}
                        updateCallback={updateDetailCallback}
                        detailCallback={updateDetailCallback}
                        resetErrorCallback={resetError}
                        exportPDFCallback={exportPDF}
                        exportCSVCallback={exportCSV}
                        extraOptions={{
                          minBodyHeight: calculateMTableHeight(0, true, true),
                          maxBodyHeight: calculateMTableHeight(0, true, true),
                          defaultExpanded: true
                        }}
                      />
                    </TabPanel>
                    {/* Conteggio Qualifica */}
                    <TabPanel value={tabValue} index={TabIndex.CONTEGGIO_QUALIFICA}>
                      <ReadOnlyMaterialTable
                        title={t("qualificationCountTitle")}
                        columns={columns}
                        data={data}
                        fetchAllValid={fetchConteggioPerQualifica}
                        statusValid={statusValidConteggioQualifica}
                        errorBE={errorBE}
                        logoUri={logoUri}
                        fixedProps={fixedProps}
                        exportDataExtra={exportDataExtra}
                        exportType={ExportType.PDF}
                        isExportLandscape={false}
                        extraOptions={{
                          minBodyHeight: calculateMTableHeight(0, true, true),
                          maxBodyHeight: calculateMTableHeight(0, true, true),
                          defaultExpanded: true
                        }}
                      />
                    </TabPanel>
                    {/* Conteggio Assenze */}
                    <TabPanel value={tabValue} index={TabIndex.CONTEGGIO_ASSENZA}>
                      <ReadOnlyMaterialTable
                        title={t("absenceCountTitle")}
                        columns={columns}
                        data={data}
                        fetchAllValid={fetchAssenzeBetweenDates}
                        statusValid={statusValidConteggioAssenze}
                        errorBE={errorBE}
                        logoUri={logoUri}
                        fixedProps={fixedPropsAssenza}
                        exportDataExtra={exportDataExtra}
                        exportType={ExportType.PDF}
                        isExportLandscape={false}
                        extraOptions={{
                          minBodyHeight: calculateMTableHeight(0, true, true),
                          maxBodyHeight: calculateMTableHeight(0, true, true),
                          defaultExpanded: true
                        }}
                      />
                    </TabPanel>
                  </>
                </Route>
                <Route path={presenzePath + componentInsertPath} exact>
                  <InnerComponentViews
                    abilitazione={abilitazione}
                    mainUri={presenzePath}
                    tabsUri={presenzePath + componentInsertPath}
                    tabsView={false}
                    buttonTitle={t("attendanceTitle")}
                    backButtonCallback={backButtonCallback}
                    info1={t("newAttendanceTitle")}
                    tabs={[
                      {
                        label: t("attendanceTabLabel"),
                        tabPath: "",
                        abilitazione: Abilitazione.READ_UPDATE,
                        componentIf: (
                          <Grid container spacing={2}>
                            <Grid item xs xl>
                              <GeneralForm
                                readOnly={false}
                                language={i18n.language}
                                componentPath={presenzePath}
                                localAction={localActionInsert}
                                fixedProps={fixedProps}
                                status={statusValidPresenze}
                                error={errorBE}
                                update={false}
                                fields={allFieldsState}
                                translate={t}
                                lookups={formLookups}
                                autoCompleteSearchAction={searchAction}
                                checksCallback={formCallback}
                                obj={defaultValuesOnInsert}
                                excludedFields={["idAnagraficaSoggetto", "articoloLeggeDescrizione"]}
                                clearance={clearance}
                                dialogProps={{
                                  body: t('addChangeTurnPrompt'),
                                  condition: isTurnoNotte,
                                  postDialogConfirmCallback: postDialogConfirmCallback,
                                  clearErrorMessage: clearErrorMessageDialog
                                }}
                                objectCallback={objectCallback}
                              />
                              {
                                initPresenza && initPresenza.progressivo &&
                                <Box display='flex' justifyContent='end'>
                                  <StatusIconIndicator color={isPianificataFlag ? 'green' : 'red'} />
                                  <Typography style={{ color: isPianificataFlag ? 'green' : 'red', fontWeight: 'bold' }} >
                                    {
                                      isPianificataFlag
                                        ? t("attendanceScheduled")
                                        : t('attendanceNotScheduled')
                                    }
                                  </Typography>
                                </Box>
                              }
                              {
                                (timbratureFineTurnoLista || timbratureInizioTurnoLista) &&
                                <ListaTimbrature />
                              }
                            </Grid>
                            <Grid item xs={4} xl={3}>
                              <LegendaW
                                idAnagraficaSoggetto={legendaData?.idAnagraficaSoggetti ? legendaData.idAnagraficaSoggetti : undefined}
                                presenzaData={legendaData ? legendaData.presenzaData : undefined}
                              />
                            </Grid>
                          </Grid>
                        ),
                        componentElse: <Redirect to={presenzePath} />,
                      },
                    ]}
                  />
                </Route>
                <Route path={presenzePath + componentTabsPath} exact>
                  <InnerComponentViews
                    abilitazione={abilitazione}
                    mainUri={presenzePath}
                    tabsUri={presenzePath + componentTabsPath}
                    tabsView={false}
                    buttonTitle={t("attendanceTitle")}
                    backButtonCallback={backButtonCallback}
                    info1={""}
                    tabs={[
                      {
                        label: t("attendanceTabLabel"),
                        tabPath: "",
                        abilitazione: Abilitazione.READ_UPDATE,
                        componentIf: (
                          <Grid container spacing={2}>
                            <Grid item xs xl>
                              <Box>
                                <GeneralForm
                                  readOnly={false}
                                  language={i18n.language}
                                  componentPath={presenzePath}
                                  localAction={localActionUpdate}
                                  fixedProps={fixedProps}
                                  status={statusValidPresenze}
                                  error={errorBE}
                                  update={true}
                                  fields={allFieldsState}
                                  translate={t}
                                  lookups={formLookups}
                                  autoCompleteSearchAction={searchAction}
                                  checksCallback={formCallback}
                                  clearance={clearance}
                                  obj={obj}
                                  excludedFields={["idAnagraficaSoggetto int", "idAnagraficaSoggetto est", "includiInseriti", "articoloLeggeDescrizione"]}
                                  dialogProps={{
                                    body: t('addChangeTurnPrompt'),
                                    condition: isTurnoNotte,
                                    postDialogConfirmCallback: postDialogConfirmCallback,
                                    clearErrorMessage: clearErrorMessageDialog
                                  }}
                                />
                                {
                                  initPresenza && initPresenza.progressivo &&
                                  <Box display='flex' justifyContent='end'>
                                    <StatusIconIndicator color={isPianificataFlag ? 'green' : 'red'} />
                                    <Typography style={{ color: isPianificataFlag ? 'green' : 'red', fontWeight: 'bold' }} >
                                      {
                                        isPianificataFlag
                                          ? t("attendanceScheduled")
                                          : t('attendanceNotScheduled')
                                      }
                                    </Typography>
                                  </Box>
                                }
                                {
                                  ((timbratureFineTurnoLista != null && timbratureFineTurnoLista.length > 0)
                                    || (timbratureInizioTurnoLista != null && timbratureInizioTurnoLista.length > 0)) &&
                                  <ListaTimbrature />
                                }
                                {
                                  /* AGGIUNTA GRUPPO APPROVAZIONE */
                                  (obj as Presenza)?.approvazioneRichiesta &&
                                  <Box mt={2}>
                                    <GeneralForm
                                      readOnly={true}
                                      language={i18n.language}
                                      fixedProps={fixedProps}
                                      status={statusValidPresenze}
                                      error={errorBE}
                                      update={true}
                                      fields={fieldsApprovazione}
                                      translate={t}
                                      lookups={formLookups}
                                      obj={obj}
                                    />
                                  </Box>
                                }
                              </Box>
                            </Grid>
                            <Grid item xs={4} xl={3}>
                              <LegendaW
                                idAnagraficaSoggetto={legendaData?.idAnagraficaSoggetti ? legendaData.idAnagraficaSoggetti : undefined}
                                presenzaData={legendaData ? legendaData.presenzaData : undefined}
                              />
                            </Grid>
                          </Grid>
                        ),
                        componentElse: <Redirect to={presenzePath} />,
                      },
                    ]}
                  />
                </Route>
                <Route>
                  <Redirect to={notFoundPath} />
                </Route>
              </Switch>
            </>
          ) : (
            <Switch>
              <Route path={presenzePath} exact>
                <Paper elevation={2}>
                  <Box p={4}>
                    <Typography align="center" variant="h5">
                      {!states[strSelectName]
                        ? strSelectLabel
                        : !states[dipSelectName]
                          ? dipSelectLabel
                          : !states[unitOpSelectName]
                            ? unitOpSelectLabel
                            : dateSelectLabel}
                    </Typography>
                  </Box>
                </Paper>
              </Route>
              <Route><Redirect to={presenzePath} /></Route>
            </Switch>
          )
        }
      </Box >
    </>
  );
};

export default PresenzeW;

function exportPDF(cols: Record<string, any>[], data: Record<string, any>[], tableData: TableData, extra: Extra, pdfOptions?: PDFOptions): jsPDF {
  const { t, theme } = extra;
  const { HEADER_SPACE, MARGIN_H } = extra.spacing;

  const PAPER_FORMAT = pdfOptions?.format ?? 'a4';
  const PAPER_ORIENTATION = pdfOptions?.orientation ?? 'portrait';

  let header: CellDefExt[] = [];
  let modData: RowInput[] = [];

  const firstColumnPadding = {
    top: 2,
    left: 5,
    bottom: 2,
    right: 5
  }

  const filteredCols = cols.filter(elem => !elem.external?.fieldData.exportExclude);

  if (tableData.groupedData.length > 0) {
    let newCols = filteredCols.filter((elem: Record<string, any>) => {
      return [undefined, null].includes(elem.defaultGroupOrder as any)
    })

    header = newCols.map((colonna: Record<string, any>, index) => {
      let halign = 'left';

      if (colonna.type === 'numeric') {
        halign = 'right';
      } else if (['date', 'datetime'].includes(colonna.type as string)) {
        halign = 'center';
      }

      return {
        field: colonna.field,
        content: (colonna.title as string).replace(' ', '\n'),
        rowSpan: 1,
        colSpan: 1,
        styles: {
          fillColor: theme.palette.primary.main,
          textColor: '#fff',
          halign,
          valign: 'middle',
          fontSize: 10,
          cellPadding: index === 0 ? firstColumnPadding : undefined
        }
      } as CellDefExt
    });

    tableData.groupedData
      .sort((a, b) => (a.value as string).localeCompare(b.value as string))
      .map((gruppo) => {
        let retval = gruppo['data'].map((riga: Record<string, unknown>) => {
          return header.map(({ field }) => {
            let val = riga[field as string] as string;

            let cellDef: CellDefExt = {
              field,
              extra: riga
            };

            /**
             ************** Gestione valore **************
             */

            if (field === 'presenzaPianificata') {
              cellDef.content = riga['progressivo'] != null ? t('yes') : '';
            } else if (field === 'idAnagraficaSoggetto') {
              cellDef.content = (riga['anagraficaSoggetto'] as any)[0]['cognome'] + ' ' + (riga['anagraficaSoggetto'] as any)[0]['nome'];
            } else if (val == null) {
              cellDef.content = '';
            } else cellDef.content = val;

            /**
             ************** Gestione style **************
             */
            if (['oreTurno', 'minutiTurno'].includes(field)) {
              cellDef.styles = {
                halign: 'right'
              }
            }
            if (['presenzaPianificata'].includes(field)) {
              cellDef.styles = {
                halign: 'center'
              }
            }
            if (['nominativo'].includes(field)) {
              cellDef.styles = {
                cellPadding: firstColumnPadding
              }
            }

            return cellDef;
          }) as RowInput
        })

        return [
          [
            {
              field: 'qualificaDescrizioneBreve',
              content: t('qualificationTitle') + ': ' + gruppo['value'],
              styles: {
                halign: 'left',
                fontStyle: 'bold',
                fillColor: '#cecece'
              },
              colSpan: header.length
            }
          ],
          ...retval
        ];

      })
      .forEach(elem => {
        modData.push(...elem)
      })

  }

  const doc = new jsPDF({ orientation: PAPER_ORIENTATION, format: PAPER_FORMAT, unit: 'mm', });

  const reworkData = (data: RowInput[], headerLength: number) => {
    return data.length > 0
      ? data
      : [[{
        content: t('noDataPresentLabel'),
        styles: {
          halign: 'center',
          fontStyle: 'italic'
        } as CellDef,
        colSpan: headerLength
      }]] as RowInput[];
  }

  /*
   * DATI
   */

  // Presenze
  autoTable(doc, {
    startY: HEADER_SPACE,
    margin: {
      top: HEADER_SPACE,
      horizontal: MARGIN_H
    },
    head: [header],
    body: reworkData(modData, header.length),
    theme: "grid",
  });

  // ------------------------------------- Personale in Reperibilità ------------------------------------- //

  const getPartition = (field: string) => {
    const TOTAL = 5;
    return field === 'nominativo' ? (3 / TOTAL) : (2 / TOTAL)
  }

  const tableMarginBottom = 10;
  const titleSpace = 15;

  let _header: CellInput[] = filteredCols
    .filter(elem => ['nominativo', 'turnoDescrizione'].includes(elem.field))
    .map((colonna, index) => {
      let halign = 'left';

      if (colonna.type === 'numeric') {
        halign = 'right';
      } else if (['date', 'datetime'].includes(colonna.type as string)) {
        halign = 'center';
      }

      return {
        ...colonna,
        styles: {
          ...colonna.styles,
          cellWidth: (DimensioneFogli[PAPER_FORMAT][PAPER_ORIENTATION].width - (MARGIN_H * 2)) * getPartition(colonna.field),
          halign,
          valign: 'middle',
          cellPadding: index === 0 ? firstColumnPadding : undefined
        }
      }
    })
    .filter(e => e) as CellInput[];

  let _data: RowInput[] = (modData as CellDefExt[][])
    .filter((riga) => (riga?.[0]?.extra as Presenza)?.reperibilita || riga?.[0]?.field === 'qualificaDescrizioneBreve')
    .filter((riga, index, array) => {  // toglie il gruppo vuoto
      if (index < array.length - 1 && riga[0].field === 'qualificaDescrizioneBreve') return array[index + 1][0].field !== 'qualificaDescrizioneBreve';
      else return riga[0].field !== 'qualificaDescrizioneBreve';
    }).map(riga => {
      if (!riga.some(elem => elem.field === 'qualificaDescrizioneBreve')) {
        return riga.filter(r => (_header as CellDefExt[]).map(h => h.field).includes(r.field));
      }
      else return riga;
    }) as RowInput[];

  let lastY = (doc as any).lastAutoTable.finalY;
  let lastPage = (doc as any).lastAutoTable.pageNumber;

  if (_data.length > 0) {
    doc.setPage(lastPage);
    doc.setFontSize(12);
    doc.text(
      'Personale in Reperibilità',
      DimensioneFogli[PAPER_FORMAT][PAPER_ORIENTATION].width / 2,
      lastY + tableMarginBottom,
      {
        align: 'center'
      }
    );
    autoTable(doc, {
      startY: lastY + titleSpace,
      margin: {
        top: HEADER_SPACE,
        horizontal: MARGIN_H
      },
      head: [_header],
      body: reworkData(_data, _header.length),
      theme: "grid",
    });
  }

  // ------------------------------------- Personale in Prestazione Aggiuntiva ------------------------------------- //

  _header = filteredCols
    .filter(elem => ['nominativo', 'turnoDescrizione'].includes(elem.field))
    .map((colonna, index) => {

      let halign = 'left';

      if (colonna.type === 'numeric') {
        halign = 'right';
      } else if (['date', 'datetime'].includes(colonna.type as string)) {
        halign = 'center';
      }

      return {
        ...colonna,
        styles: {
          ...colonna.styles,
          cellWidth: (DimensioneFogli[PAPER_FORMAT][PAPER_ORIENTATION].width - (MARGIN_H * 2)) * getPartition(colonna.field),
          halign,
          valign: 'middle',
          cellPadding: index === 0 ? firstColumnPadding : undefined
        }
      }
    })
    .filter(e => e) as CellInput[];

  _data = (modData as CellDefExt[][])
    .filter((riga) => (riga?.[0]?.extra as Presenza)?.prestazioneAggiuntiva || riga?.[0]?.field === 'qualificaDescrizioneBreve')
    .filter((riga, index, array) => {  // toglie il gruppo vuoto
      if (index < array.length - 1 && riga[0].field === 'qualificaDescrizioneBreve') return array[index + 1][0].field !== 'qualificaDescrizioneBreve';
      else return riga[0].field !== 'qualificaDescrizioneBreve';
    })
    .map(riga => {
      if (!riga.some(elem => elem.field === 'qualificaDescrizioneBreve')) {
        return riga.filter(r => (_header as CellDefExt[]).map(h => h.field).includes(r.field));
      }
      else return riga;
    }) as RowInput[];

  if (_data.length > 0) {
    lastY = (doc as any).lastAutoTable.finalY;
    lastPage = (doc as any).lastAutoTable.pageNumber;

    doc.setPage(lastPage);
    doc.setFontSize(12);
    doc.text(
      'Personale in Prestazione Aggiuntiva',
      DimensioneFogli[PAPER_FORMAT][PAPER_ORIENTATION].width / 2,
      lastY + tableMarginBottom,
      {
        align: 'center'
      }
    );

    autoTable(doc, {
      startY: lastY + titleSpace,
      margin: {
        top: HEADER_SPACE,
        horizontal: MARGIN_H
      },
      head: [_header],
      body: reworkData(_data, _header.length),
      theme: "grid",
    });
  }

  // ------------------------------------- Conteggio per Qualifica ------------------------------------- //

  const qualificaFields: Fields[] = extra.exportDataExtra?.misc?.qualificaFields ?? [];
  const validConteggioQualifica: ConteggioQualifica[] = extra.exportDataExtra?.misc?.validConteggioQualifica ?? [];

  _header = qualificaFields
    .filter(f => !['qualificaDescrizioneBreve', 'colorMonitoring', 'differenzaNumReperibilitaRichieste', 'differenzaNumAddetti', 'differenzaNumLegale'].includes(f.field) && f.defaultGroupOrder == null)
    .map((colonna, index) => {
      let halign: 'left' | 'center' | 'right' = 'left';

      if (colonna.type === 'numeric') {
        halign = 'right';
      } else if (['date', 'datetime'].includes(colonna.type as string)) {
        halign = 'center';
      }

      return {
        field: colonna.field,
        content: t(colonna.titleKey),
        rowSpan: 1,
        colSpan: 1,
        styles: {
          fillColor: theme.palette.primary.main,
          textColor: '#fff',
          halign,
          valign: 'middle',
          fontSize: 10,
          cellWidth: colonna.field === 'turnoDescrizione' ? undefined : 40,
          cellPadding: index === 0 ? firstColumnPadding : undefined
        }
      }
    });

  const groups: Array<Record<'idQualifica' | 'qualificaDescrizione' | 'qualificaDescrizioneBreve', string | number>> = [];
  validConteggioQualifica.forEach(elem => {
    if (groups.find(e => e.idQualifica === elem.idQualifica) === undefined) {
      groups.push({
        idQualifica: elem.idQualifica,
        qualificaDescrizione: elem.qualificaDescrizione,
        qualificaDescrizioneBreve: elem.qualificaDescrizioneBreve
      })
    }
  });
  groups.sort((a, b) => (a.qualificaDescrizioneBreve as string).localeCompare(b.qualificaDescrizioneBreve as string));

  _data = [];
  groups.forEach(group => {
    const datiPerQualifica = validConteggioQualifica.filter(elem => elem.idQualifica === group.idQualifica);

    const rows: any[] = [];
    datiPerQualifica.forEach(riga => {
      const row = (_header as CellDefExt[]).map((elem, index) => {
        return {
          field: elem.field,
          content: riga[elem.field as ConteggioQualificaKeys] ?? '',
          styles: {
            halign: elem.field === 'turnoDescrizione' ? 'left' : 'right',
            cellPadding: {
              ...firstColumnPadding,
              right: elem.field === 'turnoDescrizione' ? undefined : 2
            },
          },
          colSpan: 1,
          rowSpan: 1
        };
      });

      rows.push(row);
    });

    _data.push([
      {
        field: 'qualificaDescrizioneBreve',
        content: t('qualificationTitle') + ': ' + (group.qualificaDescrizioneBreve as string) + ' - ' + (group.qualificaDescrizione as string),
        styles: {
          halign: 'left',
          fontStyle: 'bold',
          fillColor: '#cecece'
        },
        colSpan: qualificaFields.length
      } as CellDefExt
    ]);
    _data = _data.concat(rows);
  });

  lastY = (doc as any).lastAutoTable.finalY;
  lastPage = (doc as any).lastAutoTable.pageNumber;

  doc.setPage(lastPage);
  doc.setFontSize(12);
  doc.text(
    'Conteggio Per Qualifica',
    DimensioneFogli[PAPER_FORMAT][PAPER_ORIENTATION].width / 2,
    lastY + tableMarginBottom,
    {
      align: 'center'
    }
  );

  autoTable(doc, {
    startY: lastY + titleSpace,
    margin: {
      top: HEADER_SPACE,
      horizontal: MARGIN_H
    },
    head: [_header],
    body: reworkData(_data, _header.length),
    theme: "grid",
  });

  return doc;
}

function exportCSV(cols: Record<string, any>[], data: Record<string, any>[], tableData: TableData, csvData: string, sep: string): string {
  // headers
  const filteredCols = cols.filter(elem => !elem.external?.fieldData.exportExclude);

  const newCols = filteredCols.filter(elem => elem.title !== 'Qualifica');
  newCols.unshift(filteredCols.find(elem => elem.title === 'Qualifica')!);

  newCols.forEach((col, ind) => {
    csvData += col.title;
    if (ind !== filteredCols.length - 1) csvData += sep;
    else csvData += "\n";
  });
  // data
  tableData.groupedData.forEach((group) => {
    group['data'].forEach((riga: Presenza) => {
      newCols.forEach(colonna => {
        const field = colonna.field as PresenzaKeys | 'presenzaPianificata';
        let entry = '';

        if (field === 'presenzaPianificata') {
          entry = riga.progressivo != null ? 'Si' : '';
        } else {
          entry = (riga[field] ?? '').toString();
        }

        csvData += entry + sep;
      });
      csvData += '\n';
    })
  })

  return csvData;
}