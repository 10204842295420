import { validateInputTextMinMaxLen } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type PrescrizioniKeys = keyof Prescrizioni;

export const elementIdProps: PrescrizioniKeys[] = ["idPrescrizione"];
export const elementRenderProps: PrescrizioniKeys[] = ["descrizione"];

export const allFields: Fields[] = [
    {
        field: "descrizioneBreve", type: "string", required: true,
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 255, false, helperText, true), keyTradValidation: "size1to255ParamValidation",
        show: true, titleKey: "descriptionShortParam"
    },
    {
        field: "descrizione", type: "string", required: true,
        validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 1, 255, false, helperText, true), keyTradValidation: "size1to255ParamValidation",
        show: true, titleKey: "descriptionParam"
    }    
]
export interface Prescrizioni {
    idPrescrizione: number,
    descrizioneBreve: string,
    descrizione: string,
    version: number
}