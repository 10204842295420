import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, Lookup, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getAll, getExtendedEntityById, getNotExtendedEntityById, lookupService, lookupServiceFiltered, upd } from '../../services/services.service'
import { Struttura, elementIdProps, LookupStrutturaElem, StrutturaExtended, StrutturaKeys, StrutturaExtendedKeys } from '../../models/Strutture';
import { createLookup } from '../../utils/utilfunctions';
import { StruttureFestivita, StruttureFestivitaKeys } from '../../models/StruttureFestivita';
import { StrutturaTurnoOre, StrutturaTurnoOreKeys } from '../../models/StruttureTurniOre';

const microservice = "strutture";
const entity = "strutture";
interface StruttureState {
  statusValidStrutture: StatusEnum,
  validStrutture: Struttura[],
  statusValidStruttureFestivita: StatusEnum,
  validStruttureFestivita: StruttureFestivita[],
  statusDeletedStruttureFestivita: StatusEnum,
  deletedStruttureFestivita: StruttureFestivita[],
  struttura: Struttura | null,
  strutturaEstesa: StrutturaExtended | null,
  lookup: Lookup,
  idSet: { [nome: string]: number[] },
  error: string | null
}

const initialState: StruttureState = {
  statusValidStrutture: StatusEnum.Succeeded,
  validStrutture: [],
  statusValidStruttureFestivita: StatusEnum.Succeeded,
  validStruttureFestivita: [],
  statusDeletedStruttureFestivita: StatusEnum.Succeeded,
  deletedStruttureFestivita: [],
  struttura: null,
  strutturaEstesa: null,
  lookup: {},
  idSet: {},
  error: null
}

// cerca/strutture/lookup/noabilitazione
export const lookupNoAbilitazione = createAsyncThunk(microservice + '/lookupNoAbilitazione', async () => {
  const response = await lookupServiceFiltered(microservice, entity, 'noabilitazione', []);
  return response.data as LookupStrutturaElem[];
});

// cerca/strutture/id/{extended = true}/{idStruttura}
export const fetchExtendedById = createAsyncThunk(microservice + '/fetchExtendedById', async (obj: { idStruttura: number }) => {
  let ids = [obj.idStruttura];
  const response = await getExtendedEntityById(microservice, entity, ids);
  return response.data as StrutturaExtended;
});

// cerca/strutture/id/{extended = false}/{idStruttura}
export const fetchNotExtendedById = createAsyncThunk(microservice + '/fetchNotExtendedById', async (obj: { idStruttura: number }) => {
  let ids = [obj.idStruttura];
  const response = await getNotExtendedEntityById(microservice, entity, ids);
  return response.data as Struttura;
});

// cerca/strutture/all
export const fetchAll = createAsyncThunk(microservice + '/fetchAll', async () => {
  const response = await getAll(microservice, entity);
  return response.data as Struttura[];
});

// cerca/strutture/lookup
export const lookup = createAsyncThunk(microservice + '/lookup', async () => {
  const response = await lookupService(microservice, entity);
  return response.data as LookupStrutturaElem[];
});

// inserisci/strutture
export const insert = createAsyncThunk(microservice + '/insert', async (struttura: Struttura) => {
  const response = await create(struttura, microservice, entity);
  return response.data as Struttura;
});

// modifica/strutture
export const update = createAsyncThunk(microservice + '/update', async (struttura: Struttura) => {
  const response = await upd(struttura, microservice, entity);
  return response.data as Struttura;
});

// delete/strutture/id/{idStruttura}
export const physicalDel = createAsyncThunk(microservice + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});

export const struttureSlice = createSlice({
  name: entity,
  initialState,
  reducers: {
    cleanLookup: (state: StruttureState) => {
      state.lookup = {};
    },
    reset: (state: StruttureState) => {
      return initialState;
    },
    resetError: (state: StruttureState) => {
      state.error = initialState.error;
      state.statusValidStrutture = initialState.statusValidStrutture;
      state.statusDeletedStruttureFestivita = initialState.statusDeletedStruttureFestivita;
      state.statusValidStruttureFestivita = initialState.statusValidStruttureFestivita;
    }
  },
  extraReducers: builder => {
    // fetchExtendedById
    builder.addCase(fetchExtendedById.pending, (state) => {
      state.statusValidStrutture = StatusEnum.Loading;
    })
    builder.addCase(fetchExtendedById.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidStrutture = StatusEnum.Failed;
      state.statusValidStruttureFestivita = StatusEnum.Failed;
      state.statusDeletedStruttureFestivita = StatusEnum.Failed;
      state.strutturaEstesa = null;
    })
    builder.addCase(fetchExtendedById.fulfilled, (state, { payload }: PayloadAction<StrutturaExtended>) => {
      state.statusValidStrutture = StatusEnum.Succeeded;
      state.strutturaEstesa = payload;

      state.statusValidStruttureFestivita = StatusEnum.Succeeded;
      state.validStruttureFestivita = payload.struttureFestivita ?? [];

      state.statusDeletedStruttureFestivita = StatusEnum.Succeeded;
      state.deletedStruttureFestivita = payload.struttureFestivitaEliminati ?? [];

      const reworkedPayload: StrutturaExtended = {
        ...payload,
        struttureTurniOre: payload?.struttureTurniOre ?? [],
        struttureTurniOreEliminati: payload?.struttureTurniOreEliminati ?? [],
        struttureFestivita: payload?.struttureFestivita ?? [],
        struttureFestivitaEliminati: payload?.struttureFestivitaEliminati ?? [],

      }

      state.strutturaEstesa = reworkedPayload;
      const createIdSet = (obj: (StrutturaTurnoOre[] | StruttureFestivita[]), id: StrutturaTurnoOreKeys & StruttureFestivitaKeys): number[] => {
        const retval: number[] = [];

        obj?.forEach(elemObject => {
          if ('mese' in elemObject) {
            retval.push((elemObject as StruttureFestivita)[id]);
          } else {
            retval.push((elemObject as StrutturaTurnoOre)[id]);
          }
        });

        return retval;
      }

      const extendeds = [
        { nome: "struttureTurniOre", id: "idStruttura" },
        { nome: "struttureTurniOreEliminati", id: "idStruttura" },
        { nome: "struttureFestivita", id: "idStruttura" },
        { nome: "struttureFestivitaEliminati", id: "idStruttura" },

      ];

      const extendedPayload = payload as StrutturaExtended;

      extendeds.forEach(extName => {
        const _data = extendedPayload[extName.nome as StrutturaExtendedKeys];
        if (_data)
          state.idSet[extName.nome] = createIdSet(_data as any, extName.id as (StrutturaTurnoOreKeys & StruttureFestivitaKeys));
      });

    })

    // fetchNotExtendedById
    builder.addCase(fetchNotExtendedById.pending, (state) => {
      state.statusValidStrutture = StatusEnum.Loading;
    })
    builder.addCase(fetchNotExtendedById.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidStrutture = StatusEnum.Failed;
      state.struttura = null;
    })
    builder.addCase(fetchNotExtendedById.fulfilled, (state, { payload }: PayloadAction<Struttura>) => {
      state.statusValidStrutture = StatusEnum.Succeeded;
      state.struttura = payload;
    })

    // fetchAll
    builder.addCase(fetchAll.pending, (state) => {
      state.statusValidStrutture = StatusEnum.Loading;
    })
    builder.addCase(fetchAll.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidStrutture = StatusEnum.Failed;
      state.validStrutture = [];
    })
    builder.addCase(fetchAll.fulfilled, (state, { payload }: PayloadAction<Struttura[]>) => {
      state.statusValidStrutture = StatusEnum.Succeeded;
      state.validStrutture = payload ?? [];
      state.lookup = createLookup(payload, 'idStruttura', ['nome']);
    })

    // lookupNoAbilitazione
    builder.addCase(lookupNoAbilitazione.pending, (state) => {
      state.statusValidStrutture = StatusEnum.Loading;
    })
    builder.addCase(lookupNoAbilitazione.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidStrutture = StatusEnum.Failed;
      state.lookup = {};
    })
    builder.addCase(lookupNoAbilitazione.fulfilled, (state, { payload }: PayloadAction<LookupStrutturaElem[]>) => {
      state.statusValidStrutture = StatusEnum.Succeeded;
      state.lookup = createLookup(payload, 'idStruttura', ['nome']);
    })

    // lookup
    builder.addCase(lookup.pending, (state) => {
      state.statusValidStrutture = StatusEnum.Loading;
    })
    builder.addCase(lookup.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidStrutture = StatusEnum.Failed;
      state.lookup = {};
    })
    builder.addCase(lookup.fulfilled, (state, { payload }: PayloadAction<LookupStrutturaElem[]>) => {
      state.statusValidStrutture = StatusEnum.Succeeded;
      state.lookup = createLookup(payload, 'idStruttura', ['nome']);
    })

    // insert
    builder.addCase(insert.pending, (state) => {
      state.statusValidStrutture = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidStrutture = StatusEnum.Failed;
      state.struttura = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<Struttura>) => {
      state.statusValidStrutture = StatusEnum.Succeeded;
      state.struttura = payload;
      state.validStrutture = state.validStrutture.concat([payload]);
    })

    // update
    builder.addCase(update.pending, (state) => {
      state.statusValidStrutture = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidStrutture = StatusEnum.Failed;
      state.struttura = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<Struttura>) => {
      state.statusValidStrutture = StatusEnum.Succeeded;
      state.validStrutture = state.validStrutture.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.struttura = payload;
    })

    // physicalDel
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidStrutture = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidStrutture = StatusEnum.Failed;
      state.validStrutture = [];
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidStrutture = StatusEnum.Succeeded;
      state.validStrutture = state.validStrutture.filter(el => payload.ids.some(idObj => el[idObj.name as StrutturaKeys] !== idObj.id));
    })
  }
});

export const { cleanLookup, reset, resetError } = struttureSlice.actions;
export default struttureSlice.reducer;