import { Column } from "@material-table/core";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { anagrafichePath } from "../../../utils/utilconst";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import {
  fetchExtendedByIdAndDate,
  resetError as resetErrorAnagraficaSoggetti
} from "../../../store/slices/anagraficaSoggettiSlice";
import {
  logicalDel,
  physicalDel,
  restore,
  insert,
  update,
  resetError as resetErrorAnagraficaSoggettiSchedaSanitaria
} from "../../../store/slices/anagraficaSoggettiSchedaSanitariaSlice";
import {
  fetchAllValid as fetchAllValidCodiceVisite,
  resetError as resetErrorCodiciVisite
} from '../../../store/slices/codiciVisiteSlice';
import {
  fetchAllValid as fetchAllValidIdoneita,
  resetError as resetErrorIdoneita
} from '../../../store/slices/idoneitaSlice';
import { elementIdProps, elementRenderProps, AnagraficaSoggettiSchedaSanitaria, allFields, AnagraficaSoggettiSchedaSanitariaKeys } from '../../../models/AnagraficaSoggettiSchedaSanitaria';
import useInitValid from "./hooks/useInitValid";
import useInitDeleted from "./hooks/useInitDeleted";
import { PDFExtraData } from "../../../models/Utils";
import { Fields } from "../../../models/Fields";
import { Redirect, Route, Switch, useHistory, } from "react-router-dom";
import { fetchAllValid as fetchAllValidPrescrizioni } from "../../../store/slices/prescrizioniSlice";
import { componentInsertPath, componentTabsPath, schedaSanitariaTabPath } from "../../../utils/innerFuncPaths";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import i18n from "../../../i18n";
import GeneralForm from "../forms/GeneralForm";

interface AnagraficaSoggettiSchedaSanitariaWProps {
  idAnagraficaSoggetto: number,
  anagraficaSoggettiSchedaSanitaria: AnagraficaSoggettiSchedaSanitaria[],
  anagraficaSoggettiSchedaSanitariaEliminati: AnagraficaSoggettiSchedaSanitaria[]
}

const AnagraficaSoggettiSchedaSanitariaW = ({ idAnagraficaSoggetto, anagraficaSoggettiSchedaSanitaria, anagraficaSoggettiSchedaSanitariaEliminati }: AnagraficaSoggettiSchedaSanitariaWProps) => {
  const { t } = useTranslation();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const abilitazione = useAppSelector(state => getAbilitazione(state, anagrafichePath));
  const errorBE = useAppSelector(state => state.anagraficaSoggettiSchedaSanitaria.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorAnagraficaSoggetti());
    dispatch(resetErrorAnagraficaSoggettiSchedaSanitaria());
    dispatch(resetErrorCodiciVisite());
    dispatch(resetErrorIdoneita());
  }, [dispatch]);

  const idoneitaLookup = useAppSelector(state => state.idoneita.lookup);
  const codiceVisitaLookup = useAppSelector(state => state.codiciVisite.lookup);
  const prescrizioneLookup = useAppSelector(state => state.prescrizioni.lookup);
  const lookupAnagraficaSoggetto = useAppSelector(state => state.anagraficaSoggetti.lookup);

  const statusAnagraficaSoggettiEstesa = useAppSelector(state => state.anagraficaSoggetti.statusAnagraficaSoggettoEstesa);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  const idAnagSoggConst = "idAnagraficaSoggetto"
  const idIdoneita = "idIdoneita";
  const idCodiceVisita = "idCodiceVisita";
  const prescrizione = "prescrizione";

  const fixedProps = useMemo(() => {
    return {
      [idAnagSoggConst]: idAnagraficaSoggetto,
    }
  }, [idAnagraficaSoggetto]);

  const title = t('healthCardTitle');

  // Servono per recuperare i lookup
  useEffect(() => {
    dispatch(fetchAllValidCodiceVisite());
    dispatch(fetchAllValidIdoneita());
    dispatch(fetchAllValidPrescrizioni());
  }, [dispatch]);

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettiSchedaSanitaria>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettiSchedaSanitaria> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }

        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate) {
              return f.validate(rowData[f.field as AnagraficaSoggettiSchedaSanitariaKeys], f.keyTradValidation ? t(f.keyTradValidation) : '')
            }
            return false;
          };
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        /*+++*/
        switch (f.field) {
          case idIdoneita:
            obj.lookup = idoneitaLookup;
            break;
          case idCodiceVisita:
            obj.lookup = codiceVisitaLookup;
            break;
        }
        /*+++*/
        return obj;
      }));
  }, [idoneitaLookup, codiceVisitaLookup, t, allFieldsState, excludedFieldInTable, prescrizioneLookup]);

  const statusValidAnagraficaSoggettiSchedaSanitaria = useAppSelector(state => state.anagraficaSoggettiSchedaSanitaria.statusValidAnagraficaSoggettiSchedeSanitarie);
  const statusDeletedAnagraficaSoggettiSchedaSanitaria = useAppSelector(state => state.anagraficaSoggettiSchedaSanitaria.statusDeletedAnagraficaSoggettiSchedeSanitarie);

  const [data, setData] = useState<Array<AnagraficaSoggettiSchedaSanitaria>>([]);

  useEffect(() => {
    if (toggleValidDeleted)
      setData(anagraficaSoggettiSchedaSanitaria);
    else
      setData(anagraficaSoggettiSchedaSanitariaEliminati);
  }, [
    toggleValidDeleted,
    anagraficaSoggettiSchedaSanitaria,
    anagraficaSoggettiSchedaSanitariaEliminati,
  ]);

  useInitValid(
    statusValidAnagraficaSoggettiSchedaSanitaria,
    () => dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto }))
  );
  useInitDeleted(
    statusDeletedAnagraficaSoggettiSchedaSanitaria,
    () => dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto }))
  );

  const [obj, setObj] = useState<AnagraficaSoggettiSchedaSanitaria | null>(null);

  const formLookups = useMemo(() => {
    return {
      [idIdoneita]: idoneitaLookup,
      [idCodiceVisita]: codiceVisitaLookup,
      [prescrizione]: prescrizioneLookup
    }
  }, [codiceVisitaLookup, idoneitaLookup, prescrizioneLookup]);

  const stringifyPrescrizione = (formData: AnagraficaSoggettiSchedaSanitaria) => {
    if (formData.prescrizione != null && formData.prescrizione?.length > 0)
      formData.prescrizione = (formData.prescrizione as unknown as string[]).join(';');
    else
      formData.prescrizione = undefined;
  }

  const parsePrescrizione = (formData: AnagraficaSoggettiSchedaSanitaria) => {
    if (formData.prescrizione != null)
      formData.prescrizione = formData.prescrizione.split(';') as unknown as string;
  }

  const localActionInsert = useCallback((formData: AnagraficaSoggettiSchedaSanitaria) => {
    stringifyPrescrizione(formData)
    dispatch(insert(formData));
  }, [dispatch]);

  const localActionUpdate = useCallback((formData: AnagraficaSoggettiSchedaSanitaria) => {
    stringifyPrescrizione(formData)
    dispatch(update(formData));
  }, [dispatch]);

  const insertCallback = () => {
    history.push(uriPath + componentInsertPath);
  }

  const updateDetailCallback = (formData: AnagraficaSoggettiSchedaSanitaria) => {
    parsePrescrizione(formData);
    setObj(formData);
    history.push(uriPath + componentTabsPath);
  }

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  const exportDataExtra: PDFExtraData = useMemo(() => {
    const _idAnagSoggConst = fixedProps[idAnagSoggConst];

    const _nominativo = _idAnagSoggConst ? lookupAnagraficaSoggetto[_idAnagSoggConst] : ''

    return {
      head: {
        title: [t('nominativeLabel')],
        value: [_nominativo]
      }
    }
  }, [fixedProps, lookupAnagraficaSoggetto, t]);

  const uriPath = anagrafichePath + componentTabsPath + schedaSanitariaTabPath; // /anagrafiche/dettaglio/schedaSanitaria

  return (
    <Switch>
      <Route path={uriPath + componentInsertPath}>
        <InnerComponentViews
          abilitazione={abilitazione}
          mainUri={uriPath}
          tabsUri={uriPath + componentInsertPath}
          tabsView={false}
          buttonTitle={t("personalData")}
          info1={t('healthCardTitle')}
          tabs={[
            {
              label: t("attendanceTabLabel"),
              tabPath: "",
              abilitazione: Abilitazione.READ_UPDATE,
              componentIf: (
                <GeneralForm
                  readOnly={false}
                  language={i18n.language}
                  componentPath={uriPath}
                  localAction={localActionInsert}
                  fixedProps={fixedProps}
                  status={statusAnagraficaSoggettiEstesa}
                  error={errorBE}
                  update={false}
                  fields={allFieldsState}
                  translate={t}
                  lookups={formLookups}
                />
              ),
              componentElse: <Redirect to={anagrafichePath} />,
            },
          ]}
        />
      </Route>
      <Route path={uriPath + componentTabsPath}>
        <InnerComponentViews
          abilitazione={abilitazione}
          mainUri={uriPath}
          tabsUri={uriPath + componentTabsPath}
          tabsView={false}
          buttonTitle={t("personalData")}
          info1={t('healthCardTitle')}
          tabs={[
            {
              label: t("attendanceTabLabel"),
              tabPath: "",
              abilitazione: Abilitazione.READ_UPDATE,
              componentIf: (
                <GeneralForm
                  readOnly={false}
                  language={i18n.language}
                  componentPath={uriPath}
                  localAction={localActionUpdate}
                  fixedProps={fixedProps}
                  status={statusAnagraficaSoggettiEstesa}
                  error={errorBE}
                  update={true}
                  fields={allFieldsState}
                  translate={t}
                  lookups={formLookups}
                  obj={obj}
                />
              ),
              componentElse: <Redirect to={anagrafichePath} />,
            },
          ]}
        />
      </Route>
      <Route path='*'>
        <CrudMaterialTable
          abilitazione={abilitazione}
          title={title}
          columns={columns}
          columnsButton={true}
          data={data}
          elementIdProps={elementIdProps}
          elementRenderProps={elementRenderProps}
          fetchAllValid={fetchExtendedByIdAndDate}
          restore={restore}
          logicalDel={logicalDel}
          insertCallback={insertCallback}
          updateCallback={updateDetailCallback}
          physicalDel={physicalDel}
          dataValid={toggleValidDeleted}
          setToggleValidDeleted={setToggleValidDeleted}
          statusValid={statusAnagraficaSoggettiEstesa}
          statusDeleted={statusAnagraficaSoggettiEstesa}
          errorBE={errorBE}
          fixedProps={fixedProps}
          exportDataExtra={exportDataExtra}
          isExportLandscape={true}
          logoUri={logoUri}
          nullableTextFields={true}
          localizedDatePicker={true}
          resetErrorCallback={resetError}
          extraOptions={{
            minBodyHeight: 150,
            maxBodyHeight: 460
          }}
        />
      </Route>
    </Switch>

  )
}
export default AnagraficaSoggettiSchedaSanitariaW;