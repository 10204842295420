import { Column } from "@material-table/core";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Struttura, elementIdProps, elementRenderProps, allFields } from "../../../models/Strutture";
import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { fetchAll, fetchExtendedById as fetchStruttureExtendedById, insert, physicalDel, update, reset as resetStrutture, resetError as resetErrorStruttura } from "../../../store/slices/struttureSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { notFoundPath, strutturePath } from "../../../utils/utilconst";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import GeneralForm from "../forms/GeneralForm";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { componentInsertPath, componentTabsPath, dipartimentiTabPath, festivitaTabPath, oreTurniTabPath, strutturaTabPath, struttureSediTabPath } from "../../../utils/innerFuncPaths";
import StruttureTurniOreW from "./StruttureTurniOreW";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import DipartimentiDiStrutturaW from "./DipartimentiDiStrutturaW";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import i18n from "../../../i18n";
import { Fields } from "../../../models/Fields";
import FestivitaDiStrutturaW from "./FestivitaDiStrutturaW";
import { Box, Button, createStyles, Grid, IconButton, makeStyles, Modal, Paper, Theme } from "@material-ui/core";
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';
import StruttureSediW from "./StruttureSediW";
import { calculateMTableHeight } from "../../../utils/styleconst";
import { ColumnExt } from "../../../utils/data.types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 20,
      maxWidth: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
      overflow: 'auto',
    },
    paper: {
      maxHeight: '85vh'
    },
    image: {
      height: 200,
    }
  }),
);

const StruttureW = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const classes = useStyles();

  const abilitazione = useAppSelector(state => getAbilitazione(state, strutturePath));

  const resetError = useCallback(() => {
    dispatch(resetErrorStruttura());
  }, [dispatch]);

  const title = t('structuresTitle');

  const [logo, setLogo] = useState<string>('');
  const [isShowLogo, setIsShowLogo] = useState<boolean>(false);
  const handleOpen = () => setIsShowLogo(true);
  const handleClose = () => {
    setLogo('');
    setIsShowLogo(false)
  };

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<Struttura>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: ColumnExt<Struttura> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field, removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
          external: { fieldData: f }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        // render image type
        if (f.type === 'image') {
          obj.render = (rowData: Struttura) => {

            const handleClick = () => {
              setLogo((f.field === 'logo1' ? rowData?.logo1 : rowData?.logo2) ?? '');
              handleOpen();
            }

            return (
              <Button
                size='small'
                variant='outlined'
                color='primary'
                onClick={handleClick}
              >
                {t('show')}
              </Button>
            )
          }
        }

        return obj;
      })
    );
  }, [allFieldsState, excludedFieldInTable, t]);

  const errorBE = useAppSelector(state => state.strutture.error);

  const validStrutture = useAppSelector(state => state.strutture.validStrutture);
  const statusValidStrutture = useAppSelector(state => state.strutture.statusValidStrutture);

  const strutturaEstesa = useAppSelector(state => state.strutture.strutturaEstesa);

  const [data, setData] = useState<Array<Struttura>>([]);

  const [obj, setObj] = useState<Struttura | null>(null);

  useEffect(() => {
    setData(validStrutture);
  }, [validStrutture]);

  const insertCallback = () => {
    history.push(strutturePath + componentInsertPath);
  }

  const updateDetailCallback = (str: Struttura) => {
    setObj(str);
    dispatch(fetchStruttureExtendedById({ idStruttura: str.idStruttura }));
    history.push(strutturePath + componentTabsPath);
  }

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setObj(null);
      setAllFieldsState([]);
      dispatch(resetStrutture());
    };
  }, [dispatch]);

  return (
    <>
      <Switch>
        <Route path={strutturePath} exact>
          <CrudMaterialTableWithoutLogicDelete
            abilitazione={abilitazione}
            title={title}
            columns={columns}
            columnsButton={true}
            data={data}
            elementIdProps={elementIdProps}
            elementRenderProps={elementRenderProps}
            fetchAllValid={fetchAll}
            physicalDel={physicalDel}
            statusValid={statusValidStrutture}
            errorBE={errorBE}
            logoUri={logoUri}
            insertCallback={insertCallback}
            updateCallback={updateDetailCallback}
            detailCallback={updateDetailCallback}
            resetErrorCallback={resetError}
            extraOptions={{
              minBodyHeight: calculateMTableHeight(0, false, false),
              maxBodyHeight: calculateMTableHeight(0, false, false)
            }}
          />
        </Route>
        <Route path={strutturePath + componentInsertPath} exact>
          <InnerComponentViews abilitazione={abilitazione} mainUri={strutturePath} tabsUri={strutturePath + componentInsertPath} tabsView={false}
            buttonTitle={t('structuresTitle')} info1={t("newStructureTitle")}
            tabs={
              [
                {
                  label: t("structuresTitle"), tabPath: "",
                  abilitazione: Abilitazione.READ_UPDATE,
                  componentIf: <GeneralForm
                    readOnly={false}
                    language={i18n.language}
                    componentPath={strutturePath}
                    action={insert}
                    status={statusValidStrutture}
                    error={errorBE}
                    update={false}
                    fields={allFields}
                    translate={t}
                  />,
                  componentElse: <Redirect to={strutturePath} />
                }
              ]
            } />
        </Route>
        <Route path={strutturePath + componentTabsPath}>
          {
            obj ? (
              <InnerComponentViews abilitazione={abilitazione} mainUri={strutturePath} tabsUri={strutturePath + componentTabsPath} tabsView={true}
                buttonTitle={t('structuresTitle')} info1={obj.nome}
                tabs={
                  [
                    {
                      label: t("structuresTitle"),
                      tabPath: strutturaTabPath,
                      abilitazione: Abilitazione.READ_UPDATE,
                      componentIf: <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={strutturePath}
                        action={update}
                        status={statusValidStrutture}
                        error={errorBE}
                        obj={obj}
                        update={true}
                        fields={allFields}
                        translate={t}
                      />,
                      componentElse: <GeneralForm
                        readOnly={true}
                        language={i18n.language}
                        componentPath={strutturePath}
                        action={update}
                        status={statusValidStrutture}
                        error={errorBE}
                        obj={obj}
                        update={true}
                        fields={allFields}
                        translate={t}
                      />
                    },
                    {
                      label: t("workShiftTabLabel"),
                      tabPath: oreTurniTabPath,
                      abilitazione: Abilitazione.READ,
                      componentIf: <StruttureTurniOreW idStruttura={obj.idStruttura} />,
                      componentElse: <Redirect to={strutturePath} />
                    },
                    {
                      label: t("departmentsTitle"),
                      tabPath: dipartimentiTabPath,
                      abilitazione: Abilitazione.READ,
                      componentIf: <DipartimentiDiStrutturaW idStruttura={obj.idStruttura} />,
                      componentElse: <Redirect to={strutturePath} />
                    },
                    {
                      label: t("holidayTabLabel"),
                      tabPath: festivitaTabPath,
                      abilitazione: Abilitazione.READ,
                      componentIf: <FestivitaDiStrutturaW
                        idStruttura={obj.idStruttura}
                        struttureFestivitaValidi={strutturaEstesa?.struttureFestivita ?? []}
                        struttureFestivitaEliminati={strutturaEstesa?.struttureFestivitaEliminati ?? []}
                      />,
                      componentElse: <Redirect to={strutturePath} />
                    },
                    {
                      label: t("officesTabLabel"),
                      tabPath: struttureSediTabPath,
                      abilitazione: Abilitazione.READ,
                      componentIf: <StruttureSediW idStruttura={obj.idStruttura} />,
                      componentElse: <Redirect to={strutturePath} />
                    }
                  ]
                } />
            ) : <Redirect to={strutturePath} />
          }
        </Route>
        <Route path="*"><Redirect to={notFoundPath} /></Route>
      </Switch>
      <Modal
        open={isShowLogo}
        onClose={handleClose}
        className={clsx(classes.container)}
      >
        <Paper className={clsx(classes.paper)}>
          <Grid container>
            <Grid item xs={9} />
            <Grid item xs={3}>
              <Box display='flex' justifyContent='right'>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
          <Box
            display='flex'
            justifyContent='center'
            minWidth={800}
            maxWidth={800}
            mx='auto'
            p={10}
          >
            <img
              className={classes.image}
              src={logo?.startsWith('data')
                ? logo
                : ![null, undefined, ''].includes(logo)
                  ? 'data:image/png;base64,' + logo
                  : ''
              }
              alt={t('logo')}
            />
          </Box>
        </Paper>
      </Modal>
    </>
  )
}
export default StruttureW;