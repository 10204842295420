import { configureStore } from '@reduxjs/toolkit'
import { authInfoSlice } from './slices/authInfoSlice';
import { routingSlice } from './slices/routingSlice';
import { dialogsSlice } from './slices/dialogsSlice';
import { snackbarsSlice } from './slices/snackBarSlice';
import { funzionalitaSlice } from './slices/funzionalitaSlice';
import { articoliLeggeSlice } from './slices/articoliLeggeSlice';
import { assenzeSlice } from './slices/assenzeSlice';
import { assenzeProgrammateSlice } from './slices/assenzeProgrammateSlice';
import { centriDiCostoSlice } from './slices/centriDiCostoSlice';
import { codiciVisiteSlice } from './slices/codiciVisiteSlice';
import { formazioneSlice } from './slices/formazioneSlice';
import { idoneitaSlice } from './slices/idoneitaSlice';
import { prescrizioniSlice } from './slices/prescrizioniSlice';
import { motiviSlice } from './slices/motiviSlice';
import { qualificheSlice } from './slices/qualificheSlice';
import { turniSlice } from './slices/turniSlice';
import { struttureSlice } from './slices/struttureSlice';
import { struttureTurniOreSlice } from './slices/struttureTurniOreSlice';
import { dipartimentiSlice } from './slices/dipartimentiSlice';
import { dipartimentiFilteredSlice } from './slices/dipartimentiFilteredSlice';
import { unitaOperativeSlice } from './slices/unitaOperativeSlice';
import { unitaOperativeFilteredSlice } from './slices/unitaOperativeFilteredSlice';
import { ditteSlice } from './slices/ditteSlice';
import { ditteAnagraficaSoggettiSlice } from './slices/ditteAnagraficaSoggettiSlice';
import { presenzeSlice } from './slices/presenzeSlice';
import { unitaOperativeTurniNumeroAddettiSlice } from './slices/unitaOperativeTurniNumeroAddettiSlice';
import { anagraficaSoggettiSlice } from './slices/anagraficaSoggettiSlice';
import { anagraficaSoggettiUnitaOperativeSlice } from './slices/anagraficaSoggettiUnitaOperativeSlice';
import { anagraficaSoggettiContestazioneSlice } from './slices/anagraficaSoggettiContestazioneSlice';
import { anagraficaSoggettiFormazioneSlice } from './slices/anagraficaSoggettiFormazioneSlice';
import { anagraficaSoggettiQualificaSlice } from './slices/anagraficaSoggettiQualificaSlice';
import { anagraficaSoggettiSchedaSanitariaSlice } from './slices/anagraficaSoggettiSchedaSanitariaSlice';
import { anagraficaSoggettiSchedaProfessionaleSlice } from './slices/anagraficaSoggettiSchedaProfessionaleSlice';
import { anagraficaSoggettiTurnoAbilitatoSlice } from './slices/anagraficaSoggettiTurnoAbilitatoSlice';
import { anagraficaSoggettiDittaSlice } from './slices/anagraficaSoggettiDittaSlice';
import { anagraficaSoggettiDocumentiSlice } from './slices/anagraficaSoggettiDocumentiSlice';
import { appbarSlice } from './slices/appbarSlice';
import { abilitazioniUtentiSlice } from './slices/abilitazioniUtentiSlice';
import { pianificazioneSlice } from './slices/pianificazioniSlice';
import { guidaSlice } from './slices/guidaSlice';
import { festivitaSlice } from './slices/festivitaSlice';
import { struttureFestivitaSlice } from './slices/strutturaFestivitaSlice';
import { tipoDocumentoSlice } from './slices/tipoDocumentoSlice';
import { tipoIncaricoSlice } from './slices/TipoIncaricoSlice';
import { approvazionePresenzeSlice } from './slices/approvazionePresenzeSlice';
import { scadenzaPeriodoProvaSlice } from './slices/scadenzaPeriodoProvaSlice';
import { scadenzaContrattiSlice } from './slices/scadenzaContrattiSlice';
import { applicazioneSoftwareSlice } from './slices/applicazioniSoftwareSlice';
import { timbratureSlice } from './slices/timbratureSlice';
import { scadenzeVisiteSlice } from './slices/scadenzeVisiteSlice';
import { dipendentiPresenzeSlice } from './slices/dipendentiPresenzeSlice';
import { chatSlice } from './slices/chatSlice';
import { chatMessaggioSlice } from './slices/chatMessaggioSlice';
import { utentiPsSlice } from './slices/utentiPsSlice';
import { lookupSlice } from './slices/lookupSlice';
import { scadenzeFormazioneSlice } from './slices/scadenzaFormazioneSlice';
import { unitaOperativeFormazioneSlice } from './slices/unitaOperativaFormazioneSlice';
import { unitaOperativeCodiceVisiteSlice } from './slices/unitaOperativaCodiceVisiteSlice';
import { presenzeMancantiSlice } from './slices/presenzeMancantiSlice';
import { modelloTerminaleSlice } from './slices/modelliTerminaleSlice';
import { modelliTerminaleCausaliSlice } from './slices/modelliTerminaleCausaliSlice';
import { temporaryStateSlice } from './slices/temporaryStatesSlice';
import { unitaOperativeSediSlice } from './slices/unitaOperativeSediSlice';
import { struttureSediSlice } from './slices/struttureSediSlice';
import { dipartimentiSediSlice } from './slices/dipartimentiSediSlice';
import { combinazioniTurniSlice } from './slices/combinazioniTurniSlice';
import { utentiLoggatoSlice } from './slices/utenteLoggatoSlice';
import { lungheAssenzeSlice } from './slices/lungheAssenzeSlice';
import { unitaOperativeQualificheSlice } from './slices/unitaOperativeQualificheSlice';


const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
  reducer: {
    appbar: appbarSlice.reducer,
    authInfo: authInfoSlice.reducer,
    funzionalita: funzionalitaSlice.reducer,
    routing: routingSlice.reducer,
    dialogs: dialogsSlice.reducer,
    snackbars: snackbarsSlice.reducer,
    articoliLegge: articoliLeggeSlice.reducer,
    assenze: assenzeSlice.reducer,
    assenzeProgrammate: assenzeProgrammateSlice.reducer,
    centriDiCosto: centriDiCostoSlice.reducer,
    codiciVisite: codiciVisiteSlice.reducer,
    formazione: formazioneSlice.reducer,
    idoneita: idoneitaSlice.reducer,
    prescrizioni: prescrizioniSlice.reducer,
    motivi: motiviSlice.reducer,
    qualifiche: qualificheSlice.reducer,
    turni: turniSlice.reducer,
    strutture: struttureSlice.reducer,
    struttureturniore: struttureTurniOreSlice.reducer,
    dipartimenti: dipartimentiSlice.reducer,
    dipartimentiFiltered: dipartimentiFilteredSlice.reducer,
    unitaOperative: unitaOperativeSlice.reducer,
    unitaOperativeFiltered: unitaOperativeFilteredSlice.reducer,
    unitaOperativeTurniNumeroAddetti: unitaOperativeTurniNumeroAddettiSlice.reducer,
    unitaOperativeQualifiche: unitaOperativeQualificheSlice.reducer,
    unitaOperativaFormazione: unitaOperativeFormazioneSlice.reducer,
    unitaOperativaCodiceVisite: unitaOperativeCodiceVisiteSlice.reducer,
    ditte: ditteSlice.reducer,
    ditteAnagraficaSoggetti: ditteAnagraficaSoggettiSlice.reducer,
    presenze: presenzeSlice.reducer,
    anagraficaSoggetti: anagraficaSoggettiSlice.reducer,
    anagraficaSoggettiUnitaOperative: anagraficaSoggettiUnitaOperativeSlice.reducer,
    anagraficaSoggettiContestazione: anagraficaSoggettiContestazioneSlice.reducer,
    anagraficaSoggettiFormazione: anagraficaSoggettiFormazioneSlice.reducer,
    anagraficaSoggettiQualifica: anagraficaSoggettiQualificaSlice.reducer,
    anagraficaSoggettiSchedaSanitaria: anagraficaSoggettiSchedaSanitariaSlice.reducer,
    anagraficaSoggettiSchedaProfessionale: anagraficaSoggettiSchedaProfessionaleSlice.reducer,
    anagraficaSoggettiTurnoAbilitato: anagraficaSoggettiTurnoAbilitatoSlice.reducer,
    anagraficaSoggettiDitta: anagraficaSoggettiDittaSlice.reducer,
    anagraficaSoggettiDocumenti: anagraficaSoggettiDocumentiSlice.reducer,
    abilitazioniUtenti: abilitazioniUtentiSlice.reducer,
    pianificazione: pianificazioneSlice.reducer,
    guida: guidaSlice.reducer,
    festivita: festivitaSlice.reducer,
    struttureFestivita: struttureFestivitaSlice.reducer,
    tipoDocumento: tipoDocumentoSlice.reducer,
    tipoIncarico: tipoIncaricoSlice.reducer,
    approvazionePresenze: approvazionePresenzeSlice.reducer,
    scadenzaPeriodoProva: scadenzaPeriodoProvaSlice.reducer,
    scadenzaContratti: scadenzaContrattiSlice.reducer,
    scadenzeVisite: scadenzeVisiteSlice.reducer,
    scadenzaFormazione: scadenzeFormazioneSlice.reducer,
    presenzeMancanti: presenzeMancantiSlice.reducer,
    applicazioneSoftware: applicazioneSoftwareSlice.reducer,
    timbrature: timbratureSlice.reducer,
    dipendentipresenze: dipendentiPresenzeSlice.reducer,
    chat: chatSlice.reducer,
    chatMessaggio: chatMessaggioSlice.reducer,
    utentiPs: utentiPsSlice.reducer,
    utentiLoggato: utentiLoggatoSlice.reducer,
    lookup: lookupSlice.reducer,
    modelloTerminale: modelloTerminaleSlice.reducer,
    causali: modelliTerminaleCausaliSlice.reducer,
    temporary: temporaryStateSlice.reducer,
    unitaOperativeSedi: unitaOperativeSediSlice.reducer,
    struttureSedi: struttureSediSlice.reducer,
    dipartimentiSedi: dipartimentiSediSlice.reducer,
    combinazioniTurni: combinazioniTurniSlice.reducer,
    lungheAssenze: lungheAssenzeSlice.reducer
  }
})
export default store;
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch