import React from 'react'

import { createStyles, makeStyles, Modal, Theme, Grid, IconButton, Paper, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

import AnteprimaDocumenti from './AnteprimaDocumenti';
import { UploadedFile } from '../../../../models/UploadedFile';

interface AnteprimaModalProps {
  file: UploadedFile;
  open: boolean;
  handleClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: 20,
      maxWidth: 1000,
      marginLeft: 'auto',
      marginRight: 'auto',
      overflow: 'auto',
    },
    paper: {
      maxHeight: '85vh'
    }
  }),
);

export const AnteprimaModal = (props: React.PropsWithChildren<AnteprimaModalProps>) => {
  const classes = useStyles();

  return (
    <Modal
      className={clsx(classes.container)}
      open={props.open}
      onClose={props.handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper className={clsx(classes.paper)}>
        <Grid container>
          <Grid item xs={9} />
          <Grid item xs={3}>
            <Box display='flex' justifyContent='right'>
              <IconButton onClick={props.handleClose}>
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        {
          props.children ??
          <AnteprimaDocumenti
            key='anteprima'
            file={props.file}
            disableTitle={true}
            isDownloadable={true}
          />
        }
      </Paper>
    </Modal>
  )
}
