import { validateDate, validateRequired } from "../utils/utilfunctions";
import { Fields } from "./Fields";

export type AnagraficaSoggettiSchedaSanitariaKeys = keyof AnagraficaSoggettiSchedaSanitaria;

export const elementIdProps: AnagraficaSoggettiSchedaSanitariaKeys[] = ["idAnagraficaSoggetto", "idAnagraficaSoggettiSchedaSanitaria"];
export const elementRenderProps: AnagraficaSoggettiSchedaSanitariaKeys[] = [];

export const allFields: Fields[] = [

    {
        field: "dataVisita", type: "date", required: true, show: true, titleKey: "visitDateParam", sort: 'desc', readonly: false, group: 'noGroup',
        validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, true), keyTradValidation: "notValidDateValidation"
    },

    {
        field: "idCodiceVisita", required: true, show: true, titleKey: "visitCodeParam", autocomplete: true,
        validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    },

    // {
    //     field: "codiceVisitaDescrizione", required: true, show: true, titleKey: "visitCodeParam", editable: 'never',
    //     validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    // },

    {
        field: "idIdoneita", required: true, show: true, titleKey: "eligibilityParam", autocomplete: true,
        validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    },

    // {
    //     field: "idoneitaDescrizione", required: true, show: true, titleKey: "eligibilityParam", editable: 'never',
    //     validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
    // },

    {
        field: "prescrizione", type: 'string', required: false, show: true, titleKey: "prescriptionParam", showOn: 'table',
    },

    {
        field: "prescrizione", required: false, show: true, titleKey: "prescriptionParam", autocomplete: true, allowMultipleDraggable: true, showOn: 'form',
    },

    {
        field: "dataControlloSuccessivo", type: "date", required: false, show: true, titleKey: "nextcheckDateParam", sort: 'desc',
        validate: (x: string, helperText: string) => validateDate(x, 1970, null, helperText, false), keyTradValidation: "notValidDateValidation"
    }
]

export interface AnagraficaSoggettiSchedaSanitaria {
    idAnagraficaSoggetto: number,
    idAnagraficaSoggettiSchedaSanitaria: number,
    dataVisita: string,
    idCodiceVisita: number,
    codiceVisitaDescrizione: string,
    idIdoneita: number,
    idoneitaDescrizione: string,
    prescrizione?: string,
    dataControlloSuccessivo?: string,
    version: number,
}