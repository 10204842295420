import { Column } from "@material-table/core";
import { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { anagrafichePath } from "../../../utils/utilconst";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import {
  fetchExtendedByIdAndDate,
  resetError as resetErrorAnagraficaSoggetti
} from "../../../store/slices/anagraficaSoggettiSlice";
import {
  insert,
  physicalDel,
  update,
  resetError as resetErrorAnagraficaSoggettiDitta
} from "../../../store/slices/anagraficaSoggettiDittaSlice";
import { elementIdProp, elementRenderProp, AnagraficaSoggettiDitta, allFields, AnagraficaSoggettiDittaKeys } from '../../../models/AnagraficaSoggettiDitte';
import useInitValid from "./hooks/useInitValid";
import { PDFExtraData } from "../../../models/Utils";
import { Fields } from "../../../models/Fields";
import { Redirect, Route, Switch } from "react-router-dom";
import {
  resetError as resetErrorDitta
} from '../../../store/slices/ditteSlice';
import { componentInsertPath, componentTabsPath, dittaTabPath } from "../../../utils/innerFuncPaths";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import GeneralForm from "../forms/GeneralForm";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";

interface AnagraficaSoggettiDittaWProps {
  idAnagraficaSoggetto: number,
  anagraficaSoggettiDitta: AnagraficaSoggettiDitta[],
}

const AnagraficaSoggettiDittaW = ({ idAnagraficaSoggetto, anagraficaSoggettiDitta }: AnagraficaSoggettiDittaWProps) => {
  const { t } = useTranslation();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const dispatch = useAppDispatch();

  const abilitazione = useAppSelector(state => getAbilitazione(state, anagrafichePath));
  const errorBE = useAppSelector(state => state.anagraficaSoggettiDitta.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorAnagraficaSoggetti());
    dispatch(resetErrorAnagraficaSoggettiDitta());
    dispatch(resetErrorDitta());
  }, [dispatch]);

  const ditteLookup = useAppSelector(state => state.ditte.lookup);
  const lookupAnagraficaSoggetto = useAppSelector(state => state.anagraficaSoggetti.lookup);

  const idAnagSoggConst = "idAnagraficaSoggetto";
  const idDitta = "idDitta";

  const fixedProps = useMemo(() => {
    return {
      [idAnagSoggConst]: idAnagraficaSoggetto,
    }
  }, [idAnagraficaSoggetto]);

  const title = t('companyTitle');

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettiDitta>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettiDitta> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }

        if (f.validate2) {
          obj.validate = (rowData) => {
            let resp: boolean | { isValid: boolean, helperText: string } = false;
            let resp2: boolean | { isValid: boolean, helperText: string } = false;

            if (f.validate2) {
              resp2 = f.validate2(
                rowData[f.field as AnagraficaSoggettiDittaKeys],
                rowData[f.field2Validation as AnagraficaSoggettiDittaKeys],
                f.keyTradValidation2 ? t(f.keyTradValidation2) : ''
              );
            }

            if (f.validate) {
              resp = f.validate(
                rowData[f.field as AnagraficaSoggettiDittaKeys],
                f.keyTradValidation ? t(f.keyTradValidation) : ''
              );
            }
            if (resp) {
              return resp2;
            } else return resp;
          }
        } else if (f.validate) {
          obj.validate = (rowData) => {
            if (f.validate) {
              return f.validate(
                rowData[f.field as AnagraficaSoggettiDittaKeys],
                f.keyTradValidation ? t(f.keyTradValidation) : ''
              );
            }
            else return false;
          };
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        /*+++*/
        switch (f.field) {
          case idDitta:
            obj.lookup = ditteLookup;
            break;
        }
        /*+++*/
        return obj;
      }));
  }, [t, ditteLookup, allFieldsState, excludedFieldInTable]);

  const formLookups = useMemo(() => {
    return {
      idDitta: ditteLookup,
    }
  }, [ditteLookup]);

  const statusValidAnagraficaSoggettiDitte = useAppSelector(state => state.anagraficaSoggettiDitta.statusValidAnagraficaSoggettiDitte);

  const [data, setData] = useState<Array<AnagraficaSoggettiDitta>>([]);

  useEffect(() => {
    setData(anagraficaSoggettiDitta);
  }, [anagraficaSoggettiDitta]);

  useInitValid(
    statusValidAnagraficaSoggettiDitte,
    () => dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto })));

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  const exportDataExtra: PDFExtraData = useMemo(() => {
    const _idAnagSoggConst = fixedProps[idAnagSoggConst];

    const _nominativo = _idAnagSoggConst ? lookupAnagraficaSoggetto[_idAnagSoggConst] : ''

    return {
      head: {
        title: [t('nominativeLabel')],
        value: [_nominativo]
      }
    }
  }, [fixedProps, lookupAnagraficaSoggetto, t]);

  return (
    <Switch>
      <Route exact path={anagrafichePath + componentTabsPath + dittaTabPath + componentInsertPath}>
        <InnerComponentViews
          abilitazione={abilitazione}
          mainUri={anagrafichePath + componentTabsPath + dittaTabPath}
          tabsUri={anagrafichePath + componentTabsPath + dittaTabPath + componentInsertPath}
          tabsView={false}
          buttonTitle={title}
          info1={""}
          tabs={[
            {
              label: t("subjectRegistryTabLabel"),
              tabPath: "",
              abilitazione: Abilitazione.READ_UPDATE,
              componentIf: (
                <GeneralForm
                  readOnly={false}
                  language={i18n.language}
                  componentPath={anagrafichePath + componentTabsPath + dittaTabPath}
                  action={insert}
                  fixedProps={fixedProps}
                  status={statusValidAnagraficaSoggettiDitte}
                  error={errorBE}
                  update={false}
                  fields={allFieldsState}
                  translate={t}
                  lookups={formLookups}
                />
              ),
              componentElse: <Redirect to={anagrafichePath} />,
            },
          ]}
        />
      </Route>
      <Route path='*'>
        <CrudMaterialTableWithoutLogicDelete
          abilitazione={abilitazione}
          title={title}
          columns={columns}
          columnsButton={true}
          data={data}
          elementIdProps={elementIdProp}
          elementRenderProps={elementRenderProp}
          fetchAllValid={fetchExtendedByIdAndDate}
          insert={insert}
          physicalDel={physicalDel}
          update={update}
          statusValid={statusValidAnagraficaSoggettiDitte}
          errorBE={errorBE}
          fixedProps={fixedProps}
          exportDataExtra={exportDataExtra}
          logoUri={logoUri}
          nullableTextFields={true}
          localizedDatePicker={true}
          resetErrorCallback={resetError}
          extraOptions={{
            minBodyHeight: 150,
            maxBodyHeight: 460
          }}
        />
      </Route>
    </Switch>
  )
}
export default AnagraficaSoggettiDittaW;