
import { Fields } from "./Fields";

export type UnitaOperativaCodiceVisiteKeys = keyof UnitaOperativaCodiceVisite;

export const elementIdProps: UnitaOperativaCodiceVisiteKeys[] = ["idStruttura", "idDipartimento", "idUnitaOperativa", "idCodiceVisita"];
export const elementRenderProps: UnitaOperativaCodiceVisiteKeys[] = [];

export const allFields: Fields[] = [
  {
    field: "idCodiceVisita", required: true, show: true, titleKey: "visitCodeParam", editable: "onAdd", lookupField: true,
  },
]

export interface UnitaOperativaCodiceVisite {
  idStruttura: number,
  idDipartimento: number,
  idUnitaOperativa: number,
  idCodiceVisita: number,
  descrizione: string,
  version: number
}


