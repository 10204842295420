import { Fields } from "./Fields";
import { Presenza } from "./Presenze";

export type ApprovazionePresenzeKeys = keyof ApprovazionePresenze;

export const allFields: Fields[] = [
    {
        field: "presenzaData", titleKey: "dateParam.1", required: true, removable: false, show: true, type: "date", sort: 'desc'
    },

    {
        field: "nominativo", titleKey: "registryTitle", removable: false, required: true, show: true,
        render: (rowData: Presenza) => rowData.anagraficaSoggetto[0].cognome + ' ' + rowData.anagraficaSoggetto[0].nome
    },

    {
        field: "qualificaDescrizioneBreve", titleKey: "qualificationParam", required: true, removable: false, show: true,
    },

    {
        field: "turnoDescrizione", titleKey: "shiftParam", required: false, removable: false, show: true, defaultValue: "",
    },
    {
        field: "reperibilita", type: 'boolean', titleKey: "availabilityParam", required: false, show: true, colsNum: 10,
    },
    {
        field: "prestazioneAggiuntiva", type: 'boolean', titleKey: "additionalPerformance", required: false, show: true, defaultValue: "", editable: "never"
    },
    {
        field: "turnoAssenzaDescrizione", titleKey: "absenceShiftParam", required: false, removable: false, show: true, defaultValue: "",
    },
    {
        field: "oraInizioTurno", type: 'time', titleKey: "turnStartParam", required: false, show: false, readonly: true,
    },
    {
        field: "oraFineTurno", type: 'time', titleKey: "turnEndDateParam", required: false, show: false, readonly: true,
    },
    {
        field: "oreTurno", type: 'numeric', titleKey: "hoursParam", required: false, show: false, minVal: 0, maxVal: 24, readonly: true,
    },
    {
        field: "minutiTurno", type: 'numeric', titleKey: "minutesParam", required: false, show: false, minVal: 0, maxVal: 59, readonly: true,
    },
    {
        field: "articoloLeggeDescrizione", type: 'numeric', titleKey: "articleParam", required: false, show: false,
    },
    {
        field: "nota", type: 'string', titleKey: "noteParam", required: false, show: false, minLen: 0, maxLen: 255,
    },
    {
        field: "approvazioneMotivo", type: 'string', titleKey: "reasonParam", required: false, show: true,
    },
    {
        field: "approvazione", titleKey: "approval", required: false, removable: false, show: true, defaultValue: undefined, exportExclude: true
    },

];

export type ApprovazionePresenze = Presenza;