import { Box, Button, Grid, Paper, Typography, useTheme, } from "@material-ui/core";
import React, { useEffect, useMemo, useState, } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { pianificazioniDipendentiPath, } from "../../../utils/utilconst";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import {
  fetchAllLookupByAbilitazione as fetchAllAnagraficaSoggettiLookup,
  reset as resetAnagraficaSoggetti,
} from '../../../store/slices/anagraficaSoggettiSlice';
import {
  fetchAll as fetchAllFormazioni,
  reset as resetFormazione,
} from '../../../store/slices/formazioneSlice';
import { useHistory } from "react-router-dom";
import { componentTabsPath } from "../../../utils/innerFuncPaths";
import i18n from "../../../i18n";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { addFooter, addHeader, getDateYYYYMMDD, getDateYYYYMMDD_BackEnd, getTodayEnd, getTodayStart, openPDF, regroupData, } from '../../../utils/utilfunctions';
import DateFnsUtils from '@date-io/date-fns';
import { enGB, it, } from "date-fns/locale";
import { allFieldsAnagraficaSoggetto, Pianificazione, PianificazioniAnagraficaSoggetti } from "../../../models/Pianificazioni";
import { fetchByIdAnagraficaSoggettoandYearAndMonth as fetchPianificazioniByIdAnagraficaSoggettoandYearAndMonth } from "../../../store/slices/pianificazioniSlice";
import CalendarDipendentiPianificazioni from "./pianificazioniComponents/Calendar_Dipendenti/CalendarDipendentiPianificazioni";
import { ColumnExt, TableData } from "../../../utils/data.types";
import { isAfter } from "date-fns";
import { MonthsList, WeekDays } from "../../../utils/utildata";
import { createRiepilogoDatiAnagraficaSoggetto, exportPDFAnagrafica, NO_PRINT_SUFFIX, reworkPianificazioneAnagraficaFieldsNoBgColor, RiepilogoPianificazioneDTO, TipoInserimento } from "./PianificazioniConsuntivoW";
import { Column } from "@material-table/core";
import { PDFOptions, StatusEnum } from "../../../models/Utils";
import { fetchAll as fetchAllStrutture } from "../../../store/slices/struttureSlice";
import { fetchAllValidByIdStruttura as fetchAllValidDipartimenti } from "../../../store/slices/dipartimentiFilteredSlice";
import { fetchAllValidByIdStrutturaAndIdDipartimento as fetchAllValidUnitaOperative } from "../../../store/slices/unitaOperativeFilteredSlice";
import LoadingSvg from "../svgs/LoadingSvg";

function isUrlOnUpdate(url: string) {
  return url === pianificazioniDipendentiPath + componentTabsPath;
}

const PianificazioniDipendentiW = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useTheme();

  const logoUri = useAppSelector((state) => state.authInfo.logoUri);
  const errorBE = useAppSelector((state) => state.pianificazione.error);

  const soggettoSelectName = useMemo(() => t('subjectRegistryTitle'), [t]);
  const soggettoSelectLabel = useMemo(() => t('registrySelect'), [t]);
  const monthYearSelectName = useMemo(() => t("dateTitle"), [t]);
  const monthYearSelectLabel = useMemo(() => t("monthSelect"), [t]);

  const lookupAnagraficaSoggettoPerAbilitazione = useAppSelector((state) => state.anagraficaSoggetti.lookup);
  const lookupStrutture = useAppSelector(state => state.strutture.lookup);
  const lookupDipartimenti = useAppSelector(state => state.dipartimentiFiltered.lookup);
  const lookupUnitaOperative = useAppSelector(state => state.unitaOperativeFiltered.lookup);
  const unitaOperativePianificazione = useAppSelector(state => state.pianificazione.validUnitaOperativaPianificazioni);
  const validPianificazioni = useAppSelector(state => state.pianificazione.validPianificazioni);
  const validPianificazioniAnagraficaSoggetti = useAppSelector(state => state.pianificazione.validPianificazioniAnagraficaSoggetti);
  const validPianificazioniQualifiche = useAppSelector(state => state.pianificazione.validPianificazioniQualifiche);

  const statusValidUnitaOperativaPianificazioni = useAppSelector(state => state.pianificazione.statusValidUnitaOperativaPianificazioni);
  const statusValidUOPianificazioniSucceeded = useMemo(() => {
    return statusValidUnitaOperativaPianificazioni === StatusEnum.Succeeded;
  }, [statusValidUnitaOperativaPianificazioni])

  const [states, setStates] = useState<Record<string, string | number | null>>({
    [soggettoSelectName]: null,
    [monthYearSelectName]: null,
  });

  useEffect(() => {
    setStates({
      [monthYearSelectName]: getTodayStart(),
    });
  }, [monthYearSelectName]);

  const handleDateChange = (d: Date | null, field?: string) => {
    let fieldDate: string = "";
    if (d) {
      fieldDate = getDateYYYYMMDD_BackEnd(d, 'START');
    }
    setStates(prev => { return { ...prev, [monthYearSelectName]: fieldDate } });
  };

  const fixedProps = useMemo(() => {
    const _monthSelected = states[monthYearSelectName] as string;
    const selectedDate: Date | null = _monthSelected ? new Date(_monthSelected) : null;
    return {
      idAnagraficaSoggetto: states[soggettoSelectName],
      anno: selectedDate?.getFullYear() ?? null,
      mese: selectedDate ? selectedDate.getMonth() + 1 : null,
    };
  }, [states, monthYearSelectName, soggettoSelectName]);

  const isDateAfterToday = states[monthYearSelectName] != null && states[monthYearSelectName] !== '' ? isAfter(new Date(states[monthYearSelectName]!), new Date(getTodayEnd())) : false;

  const [pianificazioni, setPianificazioni] = useState<Pianificazione[]>([]);
  useEffect(() => {
    setPianificazioni(validPianificazioni.filter(elem => elem.idAnagraficaSoggetto === fixedProps.idAnagraficaSoggetto));
  }, [fixedProps.idAnagraficaSoggetto, validPianificazioni])

  useEffect(() => {
    dispatch(fetchAllStrutture());
    dispatch(fetchAllAnagraficaSoggettiLookup());
    dispatch(fetchAllFormazioni()); // la chiamata serve per il lookup
  }, [dispatch]);

  useEffect(() => {
    if (unitaOperativePianificazione?.idStruttura != null) {
      dispatch(fetchAllValidDipartimenti({
        idStruttura: unitaOperativePianificazione.idStruttura
      }));

      if (unitaOperativePianificazione?.idDipartimento != null) {
        dispatch(fetchAllValidUnitaOperative({
          idStruttura: unitaOperativePianificazione.idStruttura,
          idDipartimento: unitaOperativePianificazione.idDipartimento
        }))
      }
    }
  }, [dispatch, unitaOperativePianificazione?.idDipartimento, unitaOperativePianificazione?.idStruttura]);

  /**
   * Fetch dei dati
   */
  useEffect(() => {
    if (fixedProps.idAnagraficaSoggetto && fixedProps.anno && fixedProps.mese) {
      dispatch(fetchPianificazioniByIdAnagraficaSoggettoandYearAndMonth({
        idAnagraficaSoggetto: fixedProps.idAnagraficaSoggetto as number,
        anno: fixedProps.anno,
        mese: fixedProps.mese,
      }))
    }
  }, [dispatch, fixedProps.anno, fixedProps.idAnagraficaSoggetto, fixedProps.mese]);

  useEffect(() => {
    return () => {
      dispatch(resetAnagraficaSoggetti());
      dispatch(resetFormazione());
    };
  }, [dispatch]);

  /**
   * Generazione del pdf mese <= mese corrente
   */
  const [columnsAnagraficaSoggetto, setColumnsAnagraficaSoggetto] = useState<ColumnExt<Pianificazione>[]>([]);
  useEffect(() => {
    const festiviNormali = [WeekDays.SUNDAY];
    let _dateFestivi = validPianificazioni
      ?.filter(elem => elem.giornoFestivo || festiviNormali.includes(new Date(elem.pianificazioneData).getDay()))
      .map(elem => new Date(elem.pianificazioneData).getDate());

    _dateFestivi = [...new Set(_dateFestivi ?? [])];

    if (fixedProps.mese != null && fixedProps.anno != null) {
      /**
       * Generazione dei columns
       */
      setColumnsAnagraficaSoggetto(
        reworkPianificazioneAnagraficaFieldsNoBgColor(allFieldsAnagraficaSoggetto, fixedProps.mese, fixedProps.anno, t, validPianificazioniAnagraficaSoggetti)
          .map(f => {
            let obj: Column<Pianificazione> = {
              title: f.titleKey ? t(f.titleKey) : "",
              field: f.field,
              removable: f.removable ?? !f.required,
              editable: f.editable ? f.editable : "always",
              defaultSort: f.sort,
            };

            const isTurnoColumn = f.field.startsWith('_t');

            if (['qualificaDescrizioneBreve'].includes(f.field)) {
              obj.cellStyle = {
                padding: 0,
                backgroundColor: '#fff'
              };
              obj.headerStyle = {
                backgroundColor: '#cecece'
              }
            } else if (['codiceTurno'].includes(f.field)) {
              obj.cellStyle = {
                padding: 0,
                position: "sticky",
                left: 0,
                zIndex: 10,
                minWidth: 50,
                maxWidth: 50,
                backgroundColor: '#fff'
              }
              obj.headerStyle = {
                padding: 0,
                position: "sticky",
                left: 0,
                zIndex: 11,
                fontSize: '0.75em',
                minWidth: 50,
                maxWidth: 50,
                backgroundColor: '#fff'
              };
            }
            else if (['nominativo'].includes(f.field)) {
              obj.cellStyle = {
                padding: 0,
                position: "sticky",
                left: 50,
                zIndex: 10,
                backgroundColor: "#fff",
                minWidth: 300,
                maxWidth: 300,
              }
              obj.headerStyle = {
                padding: 0,
                paddingLeft: '16px',
                position: "sticky",
                left: 50,
                zIndex: 11,
                backgroundColor: "#ccc",
                fontSize: '0.75em',
                minWidth: 300,
                maxWidth: 300,
              };
            } else if (['oreLavorative'].includes(f.field)) {
              obj.cellStyle = {
                padding: 0,
                position: "sticky",
                left: 350,
                zIndex: 10,
                boxSizing: 'inherit',
                lineHeight: '1.25em',
                minWidth: 50,
                maxWidth: 50,
                backgroundColor: '#fff'
              }
              obj.headerStyle = {
                padding: 0,
                position: "sticky",
                left: 350,
                zIndex: 11,
                fontSize: '0.75em',
                textAlign: 'center',
                lineHeight: '1.25em',
                minWidth: 50,
                maxWidth: 50,
                backgroundColor: '#fff'
              };
            } else if (['oreTotale'].includes(f.field)) {
              obj.cellStyle = {
                padding: 0,
                position: "sticky",
                left: 400,
                zIndex: 10,
                boxSizing: 'inherit',
                minWidth: 50,
                maxWidth: 50,
                backgroundColor: '#fff'
              }
              obj.headerStyle = {
                padding: 0,
                position: "sticky",
                left: 400,
                zIndex: 11,
                fontSize: '0.75em',
                textAlign: 'center',
                minWidth: 50,
                maxWidth: 50,
                backgroundColor: '#fff'
              };
            } else if (['differenzaOre'].includes(f.field)) {
              obj.cellStyle = {
                padding: 0,
                position: "sticky",
                left: 450,
                zIndex: 10,
                boxSizing: 'inherit',
                lineHeight: '1.25em',
                minWidth: 50,
                maxWidth: 50,
                backgroundColor: '#fff'
              }
              obj.headerStyle = {
                padding: 0,
                position: "sticky",
                left: 450,
                zIndex: 11,
                fontSize: '0.75em',
                textAlign: 'center',
                lineHeight: '1.25em',
                minWidth: 50,
                maxWidth: 50,
                backgroundColor: '#fff'
              };
            } else if (['numeroRiposi'].includes(f.field)) {
              obj.cellStyle = {
                padding: 0,
                position: "sticky",
                left: 500,
                zIndex: 10,
                boxSizing: 'inherit',
                minWidth: 50,
                maxWidth: 50,
                backgroundColor: '#fff'
              }
              obj.headerStyle = {
                padding: 0,
                position: "sticky",
                left: 500,
                zIndex: 11,
                fontSize: '0.75em',
                textAlign: 'center',
                minWidth: 50,
                maxWidth: 50,
                backgroundColor: '#fff'
              };
            } else if (f.field.startsWith('separatore')) {
              obj.cellStyle = {
                borderStyle: 'solid',
                borderColor: '#e0e0e0',
                borderWidth: 1,
                backgroundColor: '#000',
                padding: 0,
              };
              obj.headerStyle = {
                backgroundColor: '#000',
                padding: 0,
                width: 5
              };
            } else if (isTurnoColumn) {
              obj.cellStyle = {
                borderStyle: 'solid',
                borderColor: '#e0e0e0',
                borderWidth: 1,
                padding: 0,
              };
              obj.headerStyle = {
                borderStyle: 'solid',
                borderColor: '#e0e0e0',
                borderWidth: 1,
                padding: 0,
                textAlign: 'center',
                fontSize: '0.75em',
                lineHeight: '1.25em'
              };
            } else {
              obj.align = 'center';
              obj.headerStyle = {
                padding: 10,
                fontSize: '0.75em',
                lineHeight: '1.25em'
              };
              obj.cellStyle = {
                borderStyle: 'solid',
                borderColor: '#e0e0e0',
                borderWidth: 1,
                backgroundColor: (_dateFestivi.includes(Number(f.field.substring(1))) ? '#cecece' : undefined),
                padding: 0,
              };
              if (!isNaN(Number(f.field.replace('_', ''))) && getDateYYYYMMDD(new Date()) === getDateYYYYMMDD(new Date(fixedProps.anno!, fixedProps.mese! - 1, Number(f.field.replace('_', ''))))) {
                obj.headerStyle = {
                  ...obj.headerStyle,
                  backgroundColor: '#ccffcc'
                }
              }
            }

            if (f.defaultGroupOrder !== null || f.defaultGroupOrder !== undefined) {
              obj.defaultGroupOrder = f.defaultGroupOrder;
            }

            return obj;
          })
      );
    }
  }, [dispatch, fixedProps.anno, fixedProps.mese, t, validPianificazioni, validPianificazioniAnagraficaSoggetti]);

  const generatePDF = () => {
    if (isDateAfterToday && unitaOperativePianificazione?.pdfProspettoPreventivo) {
      openPDF(unitaOperativePianificazione.pdfProspettoPreventivo!, { tabId: 'preventivo-dipendente' });
    } else {
      const _fixedProps = {
        idStruttura: unitaOperativePianificazione?.idStruttura as number,
        idDipartimento: unitaOperativePianificazione?.idDipartimento as number,
        idUnitaOperativa: unitaOperativePianificazione?.idUnitaOperativa as number,
        anno: unitaOperativePianificazione?.anno as number,
        mese: unitaOperativePianificazione?.mese as number
      };

      const pianificazioniAnagraficaSoggetti = createRiepilogoDatiAnagraficaSoggetto(
        validPianificazioni,
        validPianificazioniAnagraficaSoggetti,
        validPianificazioniQualifiche,
        unitaOperativePianificazione?.mese!,
        unitaOperativePianificazione?.anno!,
        0,
        _fixedProps
      );

      const newRiepilogoSoggetto: Partial<RiepilogoPianificazioneDTO>[] = pianificazioniAnagraficaSoggetti.map((elem: RiepilogoPianificazioneDTO) => {
        type MTValueDataType = string | number | undefined | number[] | (boolean | null)[] |
          (Record<string, any> | null)[] | TipoInserimento[] | PianificazioniAnagraficaSoggetti | Pianificazione;

        const temp: Record<string, MTValueDataType> = {
          idStruttura: elem.idStruttura,
          idDipartimento: elem.idDipartimento,
          idUnitaOperativa: elem.idUnitaOperativa,
          idAnagraficaSoggetto: elem.idAnagraficaSoggetto,
          nominativo: elem.nominativo,
          oreTotale: elem.oreTotale,
          minutiTotale: elem.minutiTotale,
          numeroRiposi: elem.numeroRiposi,
          qualificaDescrizione: elem.qualificaDescrizione,
          qualificaDescrizioneBreve: elem.qualificaDescrizioneBreve,
          coloreAnagraficaSoggetto: elem.coloreAnagraficaSoggetto,
          numeroAddettiUnitaOperativa: elem.numeroAddettiUnitaOperativa,
          numeroLegaleUnitaOperativa: elem.numeroLegaleUnitaOperativa,
          numeroReperibilitaRichieste: elem.numeroReperibilitaRichieste,
          tipoInserimento: elem.tipoInserimento,
          uoEsterna: elem.uoEsterna,
          tooltipDati: elem.tooltipDati,
          combinazioneTurni: elem.combinazioneTurni,
          turniObj: elem.turniObj,
          codiceTurno: elem.codiceTurno,
          coloreCodiceTurno: elem.coloreCodiceTurno,
          pianificazioneAnagraficaSoggetti: elem.pianificazioneAnagraficaSoggetti
        }

        // dati delle anagrafiche
        if (elem.idStruttura !== -1) {
          elem.turniObj.forEach((turni, index) => {
            let val = '';
            turni.forEach(turno => {
              val += (val !== '' ? ' ' : '') + turno.descrizioneBreve
            });
            temp['_' + (index + 1)] = val;
          });
        } else { // dati delle qualifica
          elem.quantita.forEach((quantita, index) => {
            temp['_' + (index + 1)] = quantita;
          });
          elem.coloreQualifica.forEach((coloreQualifica, index) => {
            temp['colore' + (index + 1)] = coloreQualifica;
          });
        }

        // statistica turni
        elem.statisticaTurni.forEach((totale, index) => {
          temp['_t' + index + NO_PRINT_SUFFIX] = totale;
        });

        return temp;
      });

      const groupedData = regroupData(columnsAnagraficaSoggetto, newRiepilogoSoggetto);

      const exportPDF = () => {
        const title = isDateAfterToday ? t('summaryPlanTabLabel') : t('postPlanTabLabel');
        const PAPER_FORMAT = 'a3';
        const PAPER_ORIENTATION = 'landscape';

        const imgHeight = 12;
        const Y_OFFSET = 5;

        const startY = imgHeight + 8 + Y_OFFSET;

        let _dateFestivi = validPianificazioni
          ?.filter(elem => elem.giornoFestivo || [WeekDays.SUNDAY].includes(new Date(elem.pianificazioneData).getDay()))
          .map(elem => new Date(elem.pianificazioneData).getDate());

        _dateFestivi = [...new Set(_dateFestivi ?? [])];

        const exportDataExtra = {
          head: {
            title: [t("structureTitle"), t("departmentTitle"), t("operatingUnitTitle"), t("yearTitle"), t("monthTitle")],
            value: [
              unitaOperativePianificazione?.idStruttura != null ? lookupStrutture[unitaOperativePianificazione.idStruttura] : '',
              unitaOperativePianificazione?.idDipartimento ? lookupDipartimenti[unitaOperativePianificazione.idDipartimento] : '',
              unitaOperativePianificazione?.idUnitaOperativa ? lookupUnitaOperative[unitaOperativePianificazione.idUnitaOperativa] : '',
              unitaOperativePianificazione?.anno,
              unitaOperativePianificazione?.mese ? t(MonthsList[unitaOperativePianificazione.mese - 1]) : ''
            ]
          },
          misc: {
            dateFestivi: _dateFestivi
          }
        }

        const MARGIN_H = 5;
        const HEADER_SPACE = exportDataExtra?.head ? 44 : startY;
        const TABLE_MARGIN_BOTTOM = 10;

        const filteredCols = columnsAnagraficaSoggetto.filter(elem => !elem?.external?.fieldData.exportExclude);

        const im = new Image();
        if (logoUri)
          im.src = logoUri.startsWith('data:') ? logoUri : 'data:image/png;base64,' + logoUri;

        const extra = {
          logoImage: im,
          title,
          t,
          theme,
          exportDataExtra,
          fixedProps: _fixedProps ?? {},
          spacing: {
            HEADER_SPACE,
            TABLE_MARGIN_BOTTOM,
            MARGIN_H
          }
        }

        const _pdfOptions: PDFOptions = {
          format: PAPER_FORMAT,
          orientation: PAPER_ORIENTATION
        };

        const pdfDoc = exportPDFAnagrafica(
          filteredCols,
          [],
          { groupedData } as unknown as TableData,
          extra,
          _pdfOptions,
          true
        );

        // addHeader(pdfDoc);
        addHeader(
          pdfDoc,
          {
            logoImage: im,
            MARGIN_H,
            Y_OFFSET,
            PAPER_FORMAT,
            PAPER_ORIENTATION,
            exportDataExtra,
            theme,
            title,
          }
        );
        // addFooter(pdfDoc);
        addFooter(
          pdfDoc,
          {
            MARGIN_H,
            PAPER_FORMAT,
            PAPER_ORIENTATION
          }
        );

        const base64String = pdfDoc.output('datauristring');
        return base64String;
      }

      openPDF(exportPDF(), { tabId: 'consuntivo-dipendente' });
    }
  }

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={4} lg={4}>
              <SimpleIdSelects
                selectsArray={[
                  {
                    name: soggettoSelectName,
                    lookup: lookupAnagraficaSoggettoPerAbilitazione,
                    label: soggettoSelectLabel,
                    disabled: false,
                    breakpoints: { xs: 12, md: 12, sm: 12, lg: 12 }
                  },
                ]}
                states={states}
                setStates={setStates}
              />
            </Grid>
            {
              states[soggettoSelectName] != null &&
              <Grid item xs={12} sm={4} md={4} lg={2}>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={i18n.language === "it-IT" || i18n.language === "it" ? it : enGB}>
                  <DatePicker
                    label={monthYearSelectLabel}
                    format={"MMMM yyyy"}
                    onChange={(e) => handleDateChange(e)}
                    okLabel={t('insertLabel')}
                    clearLabel={t('clearLabel')}
                    cancelLabel={t('cancelLabel')}
                    clearable={true}
                    disabled={isUrlOnUpdate(history.location.pathname)}
                    value={states[monthYearSelectName] ? states[monthYearSelectName] : null}
                    views={['month', 'year',]}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            }
            {
              states[soggettoSelectName] != null && states[monthYearSelectName] != null && statusValidUOPianificazioniSucceeded &&
              <Grid container item xs={12} sm={4} md={4} lg={6} justifyContent="flex-end" alignContent="center">
                <Button variant="contained" onClick={generatePDF}>
                  {t('showPlanLabel')}
                </Button>
              </Grid>
            }
          </Grid>
        </Box>
      </Paper>
      <Box marginTop={2}>
        {states[soggettoSelectName] && states[monthYearSelectName]
          ? (
            errorBE ?
              (
                <Paper elevation={2}>
                  <Box p={4}>
                    <Typography align="center" variant="h5">
                      {errorBE}
                    </Typography>
                  </Box>
                </Paper>
              ) : (
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    {
                      statusValidUOPianificazioniSucceeded
                        ? <CalendarDipendentiPianificazioni
                          anno={fixedProps.anno!}
                          mese={fixedProps.mese!}
                          pianificazioni={pianificazioni}
                        />
                        : <LoadingSvg color='primary' width={100} />
                    }
                  </Grid>
                </Grid>
              )
          ) : (
            <Paper elevation={2}>
              <Box p={4}>
                <Typography align="center" variant="h5">
                  {
                    !states[soggettoSelectName]
                      ? soggettoSelectLabel
                      : monthYearSelectLabel
                  }
                </Typography>
              </Box>
            </Paper>
          )}
      </Box>

    </>
  )
}
export default PianificazioniDipendentiW;