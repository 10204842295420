import { Column } from "@material-table/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { anagrafichePath } from "../../../utils/utilconst";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import {
  fetchExtendedByIdAndDate,
  resetError as resetErrorAnagraficaSoggetti
} from "../../../store/slices/anagraficaSoggettiSlice";

import {
  fetchAllValidById as fetchAllValidAnagraficaSoggettoFormazioniById,
  fetchAllDeletedById as fetchAllDeletedAnagraficaSoggettoFormazioniById,
  logicalDel,
  physicalDel,
  restore,
  insert,
  update,
  resetError as resetErrorAnagraficaSoggettiFormazione
} from "../../../store/slices/anagraficaSoggettiFormazioneSlice";
import {
  resetError as resetErrorFormazione
} from '../../../store/slices/formazioneSlice';
import { elementIdProps, elementRenderProps, AnagraficaSoggettiFormazione, allFields, AnagraficaSoggettiFormazioneKeys } from '../../../models/AnagraficaSoggettiFormazione';
import useInitValid from "./hooks/useInitValid";
import useInitDeleted from "./hooks/useInitDeleted";
import { PDFExtraData } from "../../../models/Utils";
import { Fields } from "../../../models/Fields";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import GeneralForm from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import { componentInsertPath, componentTabsPath, formazioneTabPath } from "../../../utils/innerFuncPaths";
import i18n from "../../../i18n";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import { AnteprimaModal } from "./AnagraficaSoggettiComponents/AnteprimaModal";
import { UploadedFile } from "../../../models/UploadedFile";
import { Button } from "@material-ui/core";

interface AnagraficaSoggettiFormazioneWProps {
  idAnagraficaSoggetto: number,
  anagraficaSoggettiFormazione: AnagraficaSoggettiFormazione[],
  anagraficaSoggettiFormazioneEliminati: AnagraficaSoggettiFormazione[]
}

const AnagraficaSoggettiFormazioneW = ({ idAnagraficaSoggetto, anagraficaSoggettiFormazione, anagraficaSoggettiFormazioneEliminati }: AnagraficaSoggettiFormazioneWProps) => {
  const { t } = useTranslation();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const abilitazione = useAppSelector(state => getAbilitazione(state, anagrafichePath));
  const errorBE = useAppSelector(state => state.anagraficaSoggettiFormazione.error);
  const resetError = useCallback(() => {
    dispatch(resetErrorFormazione());
    dispatch(resetErrorAnagraficaSoggettiFormazione());
    dispatch(resetErrorAnagraficaSoggetti());
  }, [dispatch]);

  const [uploadedFilenames, setUploadedFilenames] = useState<Record<string, string>>({});
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClose = () => {
    setIsOpen(false);
  }
  const handleOpen = () => {
    setIsOpen(true);
  }

  const formazioneLookup = useAppSelector(state => state.formazione.lookup);
  const lookupAnagraficaSoggetto = useAppSelector(state => state.anagraficaSoggetti.lookup);

  const statusAnagraficaSoggettiEstesa = useAppSelector(state => state.anagraficaSoggetti.statusAnagraficaSoggettoEstesa);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  const idAnagSoggConst = "idAnagraficaSoggetto";
  const defaultFilename = 'Attestato';

  const fixedProps = useMemo(() => {
    return {
      [idAnagSoggConst]: idAnagraficaSoggetto,
    }
  }, [idAnagraficaSoggetto]);

  const title = t('trainingTitle');

  useEffect(() => {
    if (idAnagraficaSoggetto) {
      dispatch(fetchAllValidAnagraficaSoggettoFormazioniById({ idAnagraficaSoggetto }));
      dispatch(fetchAllDeletedAnagraficaSoggettoFormazioniById({ idAnagraficaSoggetto }));
    }
  }, [dispatch, idAnagraficaSoggetto]);

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettiFormazione>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<AnagraficaSoggettiFormazione> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field,
          removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          // emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as AnagraficaSoggettiFormazioneKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          };
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = false;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }

        if (f.type && f.type === "file") {
          obj.render = (rowData: AnagraficaSoggettiFormazione) => {
            const attestato = rowData.attestato;
            if (attestato != null) {
              return <Button
                onClick={() => {
                  handleOpen();
                  setUploadedFile({
                    content: attestato,
                    name: defaultFilename
                  });
                }}
                size='small'
                variant='outlined'
                color='primary'>
                {t('show')}
              </Button>
            } else {
              return '';
            }
          };
        }

        return obj;
      }));
  }, [allFieldsState, excludedFieldInTable, formazioneLookup, t]);

  const statusValidAnagraficaSoggettiFormazione = useAppSelector(state => state.anagraficaSoggettiFormazione.statusValidAnagraficaSoggettiFormazioni);
  const statusDeletedAnagraficaSoggettiFormazione = useAppSelector(state => state.anagraficaSoggettiFormazione.statusDeletedAnagraficaSoggettiFormazioni);

  const [data, setData] = useState<Array<AnagraficaSoggettiFormazione>>([]);

  useEffect(() => {
    toggleValidDeleted ? setData(anagraficaSoggettiFormazione) : setData(anagraficaSoggettiFormazioneEliminati);
  }, [
    anagraficaSoggettiFormazione,
    anagraficaSoggettiFormazioneEliminati,
    toggleValidDeleted
  ]);

  useInitValid(
    statusValidAnagraficaSoggettiFormazione,
    () => dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto }))
  );
  useInitDeleted(
    statusDeletedAnagraficaSoggettiFormazione,
    () => dispatch(fetchExtendedByIdAndDate({ idAnagraficaSoggetto }))
  );

  const [obj, setObj] = useState<AnagraficaSoggettiFormazione | null>(null);

  const formLookups = useMemo(() => {
    return {
      idFormazione: formazioneLookup
    }
  }, [formazioneLookup]);

  const insertCallback = () => {
    setUploadedFile(null);
    history.push(uriPath + componentInsertPath);
  }

  const updateDetailCallback = (formData: AnagraficaSoggettiFormazione) => {
    setObj(formData);

    if (formData.attestato) {
      setUploadedFile({
        content: formData.attestato,
        name: defaultFilename
      });
      setUploadedFilenames({
        attestato: defaultFilename
      });
    }

    history.push(uriPath + componentTabsPath);
  }

  const [uploadedFile, setUploadedFile] = useState<UploadedFile | null>(null);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
    };
  }, [dispatch]);

  const exportDataExtra: PDFExtraData = useMemo(() => {
    const _idAnagSoggConst = fixedProps[idAnagSoggConst];

    const _nominativo = _idAnagSoggConst ? lookupAnagraficaSoggetto[_idAnagSoggConst] : ''

    return {
      head: {
        title: [t('nominativeLabel')],
        value: [_nominativo]
      }
    }
  }, [fixedProps, lookupAnagraficaSoggetto, t]);

  const uriPath = anagrafichePath + componentTabsPath + formazioneTabPath; // /anagrafiche/dettaglio/formazione

  return (
    <Switch>
      <Route path={uriPath + componentInsertPath}>
        <InnerComponentViews
          abilitazione={abilitazione}
          mainUri={uriPath}
          tabsUri={uriPath + componentInsertPath}
          tabsView={false}
          buttonTitle={t("personalData")}
          info1={t('healthCardTitle')}
          tabs={[
            {
              label: t("attendanceTabLabel"),
              tabPath: "",
              abilitazione: Abilitazione.READ_UPDATE,
              componentIf: (
                <GeneralForm
                  readOnly={false}
                  language={i18n.language}
                  componentPath={uriPath}
                  action={insert}
                  fixedProps={fixedProps}
                  status={statusAnagraficaSoggettiEstesa}
                  error={errorBE}
                  update={false}
                  fields={allFieldsState}
                  translate={t}
                  lookups={formLookups}
                />
              ),
              componentElse: <Redirect to={anagrafichePath} />,
            },
          ]}
        />
      </Route>
      <Route path={uriPath + componentTabsPath}>
        <InnerComponentViews
          abilitazione={abilitazione}
          mainUri={uriPath}
          tabsUri={uriPath + componentTabsPath}
          tabsView={false}
          buttonTitle={t("personalData")}
          info1={t('healthCardTitle')}
          tabs={[
            {
              label: t("attendanceTabLabel"),
              tabPath: "",
              abilitazione: Abilitazione.READ_UPDATE,
              componentIf: (
                <GeneralForm
                  readOnly={false}
                  language={i18n.language}
                  componentPath={uriPath}
                  action={update}
                  fixedProps={fixedProps}
                  status={statusAnagraficaSoggettiEstesa}
                  error={errorBE}
                  update={true}
                  fields={allFieldsState}
                  translate={t}
                  lookups={formLookups}
                  obj={obj}
                  uploadedFilenames={uploadedFilenames}
                />
              ),
              componentElse: <Redirect to={anagrafichePath} />,
            },
          ]}
        />
      </Route>
      <Route path='*'>
        <CrudMaterialTable
          abilitazione={abilitazione}
          title={title}
          columns={columns}
          columnsButton={true}
          data={data}
          elementIdProps={elementIdProps}
          elementRenderProps={elementRenderProps}
          fetchAllValid={fetchExtendedByIdAndDate}
          restore={restore}
          logicalDel={logicalDel}
          physicalDel={physicalDel}
          insertCallback={insertCallback}
          updateCallback={updateDetailCallback}
          dataValid={toggleValidDeleted}
          setToggleValidDeleted={setToggleValidDeleted}
          statusValid={statusValidAnagraficaSoggettiFormazione}
          statusDeleted={statusDeletedAnagraficaSoggettiFormazione}
          errorBE={errorBE}
          fixedProps={fixedProps}
          exportDataExtra={exportDataExtra}
          logoUri={logoUri}
          nullableTextFields={true}
          localizedDatePicker={true}
          resetErrorCallback={resetError}
          extraOptions={{
            minBodyHeight: 150,
            maxBodyHeight: 460
          }}
        />
        {
          uploadedFile &&
          <AnteprimaModal
            open={isOpen}
            handleClose={handleClose}
            file={uploadedFile}
          />
        }
      </Route>
    </Switch>
  )
}
export default AnagraficaSoggettiFormazioneW;

