import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Ids, StatusEnum } from '../../models/Utils'
import { create, definitiveDeleteById, getExtendedEntityById, upd } from '../../services/services.service'
import { UnitaOperativaQualifiche, elementIdProps, UnitaOperativaQualificheKeys } from '../../models/UnitaOperativeQualifiche';
import { UnitaOperativaEstesa } from '../../models/UnitaOperative';

const microservice = "unitaoperative";
const entity = "unitaoperativequalifiche";

interface UnitaOperativeQualificheState {
  statusValidUnitaOperativaQualifiche: StatusEnum,
  validUnitaOperativaQualifiche: UnitaOperativaQualifiche[],
  unitaOperativaQualifiche: UnitaOperativaQualifiche | null,
  error: string | null
}

const initialState: UnitaOperativeQualificheState = {
  statusValidUnitaOperativaQualifiche: StatusEnum.Succeeded,
  validUnitaOperativaQualifiche: [],
  unitaOperativaQualifiche: null,
  error: null
}

export const fetchAllValidByFilter = createAsyncThunk(entity + '/fetchAllValidByFilter', async (obj: { idStruttura: number, idDipartimento: number, idUnitaOperativa: number }) => {
  const response = await getExtendedEntityById(microservice, microservice, [obj.idStruttura, obj.idDipartimento, obj.idUnitaOperativa]);
  const dat = response.data as UnitaOperativaEstesa;
  return dat.unitaOperativeQualifiche as UnitaOperativaQualifiche[];
});

export const insert = createAsyncThunk(entity + '/insert', async (unitaOperativaQualifiche: UnitaOperativaQualifiche) => {
  const response = await create(unitaOperativaQualifiche, microservice, entity);
  return response.data as UnitaOperativaQualifiche;
});

export const update = createAsyncThunk(entity + '/update', async (unitaOperativaQualifiche: UnitaOperativaQualifiche) => {
  const response = await upd(unitaOperativaQualifiche, microservice, entity);
  return response.data as UnitaOperativaQualifiche;
});

export const physicalDel = createAsyncThunk(entity + '/physicalDelete', async (ids: Ids<string>[]) => {
  await definitiveDeleteById(ids, microservice, entity);
  return { ids };
});


export const unitaOperativeQualificheSlice = createSlice({
  name: entity,
  initialState,
  reducers: {

    reset: () => {
      return initialState;
    },
    resetError: (state: UnitaOperativeQualificheState) => {
      state.error = initialState.error;
      state.statusValidUnitaOperativaQualifiche = initialState.statusValidUnitaOperativaQualifiche;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchAllValidByFilter.pending, (state) => {
      state.statusValidUnitaOperativaQualifiche = StatusEnum.Loading;
    })
    builder.addCase(fetchAllValidByFilter.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativaQualifiche = StatusEnum.Failed;
      state.validUnitaOperativaQualifiche = [];
    })
    builder.addCase(fetchAllValidByFilter.fulfilled, (state, { payload }: PayloadAction<UnitaOperativaQualifiche[]>) => {
      state.statusValidUnitaOperativaQualifiche = StatusEnum.Succeeded;
      state.validUnitaOperativaQualifiche = payload ?? [];
    })
    builder.addCase(insert.pending, (state) => {
      state.statusValidUnitaOperativaQualifiche = StatusEnum.Loading;
    })
    builder.addCase(insert.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativaQualifiche = StatusEnum.Failed;
      state.unitaOperativaQualifiche = null;
    })
    builder.addCase(insert.fulfilled, (state, { payload }: PayloadAction<UnitaOperativaQualifiche>) => {
      state.statusValidUnitaOperativaQualifiche = StatusEnum.Succeeded;
      state.unitaOperativaQualifiche = payload;
      state.validUnitaOperativaQualifiche = state.validUnitaOperativaQualifiche.concat([payload]);
    })
    builder.addCase(update.pending, (state) => {
      state.statusValidUnitaOperativaQualifiche = StatusEnum.Loading;
    })
    builder.addCase(update.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativaQualifiche = StatusEnum.Failed;
      state.unitaOperativaQualifiche = null;
    })
    builder.addCase(update.fulfilled, (state, { payload }: PayloadAction<UnitaOperativaQualifiche>) => {
      state.statusValidUnitaOperativaQualifiche = StatusEnum.Succeeded;
      state.validUnitaOperativaQualifiche = state.validUnitaOperativaQualifiche.map(el => {
        if (elementIdProps.every(prop => el[prop] === payload[prop])) {
          return { ...payload, version: payload.version + 1 };
        } else return el;
      });
      state.unitaOperativaQualifiche = payload;
    })
    builder.addCase(physicalDel.pending, (state) => {
      state.statusValidUnitaOperativaQualifiche = StatusEnum.Loading;
    })
    builder.addCase(physicalDel.rejected, (state, action) => {
      state.error = (action.error.message) ? action.error.message : "";
      state.statusValidUnitaOperativaQualifiche = StatusEnum.Failed;
    })
    builder.addCase(physicalDel.fulfilled, (state, { payload }: PayloadAction<{ ids: Ids<string>[] }>) => {
      state.statusValidUnitaOperativaQualifiche = StatusEnum.Succeeded;
      state.validUnitaOperativaQualifiche = state.validUnitaOperativaQualifiche.filter(el => payload.ids.some(idObj => el[idObj.name as UnitaOperativaQualificheKeys] !== idObj.id));
    })
  }
});

export const { reset, resetError } = unitaOperativeQualificheSlice.actions;
export default unitaOperativeQualificheSlice.reducer;