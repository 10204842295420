import { Column } from "@material-table/core";
import React, { useEffect, useMemo, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { allFields, elementIdProps, elementRenderProps, ModelliTerminale, ModelliTerminaleKeys } from "../../../models/ModelloTerminale";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { fetchAllDeleted, fetchAllValid, insert, logicalDel, physicalDel, restore, update, reset as resetModelloTerminale, resetError as resetErrorModelloTerminale } from "../../../store/slices/modelliTerminaleSlice";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import { modelliTerminaleCausaliPath, modelliTerminalePath } from "../../../utils/utilconst";
import CrudMaterialTable from "../tables/Crud/CrudMaterialTable";
import { Fields } from "../../../models/Fields";
import { sortingStringCI } from "../../../utils/utilfunctions";
import { Box, Grid } from "@material-ui/core";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import { componentInsertPath, componentTabsPath } from "../../../utils/innerFuncPaths";
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import GeneralForm from "../forms/GeneralForm";
import i18n from "../../../i18n";
import ModelliTerminaleCausaliW from "./ModelliTerminaleCausaliW";
import LoadingSvg from "../svgs/LoadingSvg";
import { FixedProps } from "../../../utils/data.types";
import { calculateMTableHeight } from "../../../utils/styleconst";

const ModelloTerminaleW = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useAppDispatch();
  const logoUri = useAppSelector(state => state.authInfo.logoUri);

  const abilitazione = useAppSelector(state => getAbilitazione(state, modelliTerminalePath));
  const errorBE = useAppSelector(state => state.modelloTerminale.error);

  const resetError = useCallback(() => {
    dispatch(resetErrorModelloTerminale());
  }, [dispatch])

  const title = t('terminalModelsTitle');

  const [fixedProps, setFixedProps] = useState<FixedProps>({
    idModelloTerminale: null
  });

  const excludedFieldInTable = useMemo(() => [], []);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFields);
  const [columns, setColumns] = useState<Array<Column<ModelliTerminale>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState.filter(f => ['both', 'table', undefined, null].includes(f.showOn)).map((f) => {
        let obj: Column<ModelliTerminale> = {
          title: f.titleKey ? t(f.titleKey) : '',
          field: f.field, removable: f.removable ?? !f.required,
          editable: f.editable ? f.editable : "always",
          defaultSort: f.sort,
          emptyValue: f.defaultValue ?? 'N/A',
        }
        if (f.validate) {
          obj.validate = rowData => {
            if (f.validate)
              return f.validate(rowData[f.field as ModelliTerminaleKeys], f.keyTradValidation ? t(f.keyTradValidation) : '');
            return false;
          }
        }
        if (!f.show) {
          obj.hidden = true;
          obj.hiddenByColumnsButton = true;
        }
        if (f.type && f.type !== "image" && f.type !== "file") {
          obj.type = f.type;
        }
        if (f.type === 'string')
          obj.customSort = (a, b) => sortingStringCI(a.descrizione, b.descrizione);
        return obj;
      })
    );
  }, [allFieldsState, excludedFieldInTable, t]);

  const validModelliTerminali = useAppSelector(state => state.modelloTerminale.validModelliTerminali);
  const deletedModelloTerminali = useAppSelector(state => state.modelloTerminale.deletedModelliTerminali);

  const statusValidModelliTerminali = useAppSelector(state => state.modelloTerminale.statusValidModelloTerminale);
  const statusDeletedModelliTerminali = useAppSelector(state => state.modelloTerminale.statusDeletedModelloTerminale);

  const [data, setData] = useState<Array<ModelliTerminale>>([]);

  const [toggleValidDeleted, setToggleValidDeleted] = useState<boolean>(true);

  useEffect(() => {
    toggleValidDeleted ? setData(validModelliTerminali) : setData(deletedModelloTerminali);
  }, [validModelliTerminali, deletedModelloTerminali, toggleValidDeleted]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setToggleValidDeleted(true);
      setAllFieldsState([]);
      dispatch(resetModelloTerminale());
    };
  }, [dispatch]);

  const [obj, setObj] = useState<ModelliTerminale>();

  const insertCallback = () => {
    history.push(modelliTerminalePath + componentInsertPath);
  };

  const updateDetailCallback = (modelloTerminale: ModelliTerminale) => {
    new Promise((resolve) => {
      setObj(modelloTerminale);
      setFixedProps({
        idModelloTerminale: modelloTerminale.idModelloTerminale
      });
      resolve(null);
    }).then(() => {
      history.push(modelliTerminalePath + componentTabsPath)
    })
  }

  const idModelloTerminale = React.useMemo(() => fixedProps.idModelloTerminale as number, [fixedProps.idModelloTerminale]);

  return (
    <Box marginTop={2}>
      <Switch>
        <Route path={modelliTerminalePath} exact>
          <CrudMaterialTable
            abilitazione={abilitazione}
            title={title}
            columns={columns}
            data={data}
            elementIdProps={elementIdProps}
            elementRenderProps={elementRenderProps}
            fetchAllValid={fetchAllValid}
            fetchAllDeleted={fetchAllDeleted}
            logicalDel={logicalDel}
            physicalDel={physicalDel}
            restore={restore}
            update={update}
            dataValid={toggleValidDeleted}
            setToggleValidDeleted={setToggleValidDeleted}
            statusValid={statusValidModelliTerminali}
            statusDeleted={statusDeletedModelliTerminali}
            insertCallback={insertCallback}
            updateCallback={updateDetailCallback}
            errorBE={errorBE}
            logoUri={logoUri}
            resetErrorCallback={resetError}
            extraOptions={{
              minBodyHeight: calculateMTableHeight(0, false, false),
              maxBodyHeight: calculateMTableHeight(0, false, false)
            }}
          />
        </Route>
        <Route path={modelliTerminalePath + componentInsertPath} exact>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={modelliTerminalePath}
            tabsUri={modelliTerminalePath + componentInsertPath}
            // tabsUri={''}
            tabsView={false}
            buttonTitle={t("terminalModelParam")}
            info1={t("")}
            tabs={[
              {
                label: t(""),
                tabPath: "",
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={modelliTerminalePath}
                        action={insert}
                        status={statusValidModelliTerminali}
                        error={errorBE}
                        update={false}
                        obj={{}}
                        fields={allFieldsState}
                        fixedProps={fixedProps}
                        translate={t}
                      />
                    </Grid>

                  </Grid>
                ),
                componentElse: <Redirect to={modelliTerminalePath} />,
              },
            ]}
          />
        </Route>
        <Route path={modelliTerminalePath + componentTabsPath}>
          <InnerComponentViews
            abilitazione={abilitazione}
            mainUri={modelliTerminalePath}
            tabsUri={modelliTerminalePath + componentTabsPath}
            tabsView={true}
            buttonTitle={title}
            info1={obj?.descrizione ?? ''}
            tabs={[
              {
                label: t("terminalModelParam"),
                tabPath: modelliTerminalePath,
                abilitazione: Abilitazione.READ_UPDATE,
                componentIf: (
                  <Grid container spacing={2}>
                    <Grid item xs xl>
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={modelliTerminalePath}
                        action={update}
                        status={statusValidModelliTerminali}
                        error={errorBE}
                        update={true}
                        obj={obj}
                        fields={allFieldsState}
                        fixedProps={fixedProps}
                        translate={t}
                      />
                    </Grid>
                  </Grid>
                ),
                componentElse: <Redirect to={modelliTerminalePath} />,
              },
              {
                label: t("explanationParam"),
                tabPath: modelliTerminaleCausaliPath,
                abilitazione: Abilitazione.READ,
                componentIf:
                  (idModelloTerminale !== null && idModelloTerminale !== undefined) && abilitazione
                    ? <ModelliTerminaleCausaliW idModelloTerminale={idModelloTerminale} abilitazione={abilitazione} />
                    : <LoadingSvg color="primary" width={100} />
                ,
                componentElse: <Redirect to={modelliTerminalePath} />
              }
            ]}
          />
        </Route>
      </Switch>
    </Box>
  )
}
export default ModelloTerminaleW;