import { validateInputTextMinMaxLen, validateRequired } from "../utils/utilfunctions";
import { Fields } from "./Fields";
import { UnitaOperativaCodiceVisite } from "./UnitaOperativaCodiceVisite";
import { UnitaOperativaFormazione } from "./UnitaOperativaFormazione";
import { UnitaOperativaQualifiche } from "./UnitaOperativeQualifiche";
import { UnitaOperativaTurnoNumeroAddetti } from "./UnitaOperativeTurniNumeroAddetti";

export type UnitaOperativaKeys = keyof UnitaOperativa;
export type UnitaOperativaEstesaKeys = keyof UnitaOperativaEstesa;

export const elementIdProps: UnitaOperativaKeys[] = ["idStruttura", "idDipartimento", "idUnitaOperativa"];
export const elementRenderProps: UnitaOperativaKeys[] = ["nome"];

export const allFields: Fields[] = [
  {
    field: "nome", type: "string", required: true, show: true, titleKey: "nameParam", colsNum: 6, sort: 'asc',
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 60, false, helperText, true), keyTradValidation: "size3to60ParamValidation",
  },
  {
    field: "descrizione", type: "string", required: false, show: true, titleKey: "descriptionParam", colsNum: 6,
    validate: (x: string, helperText: string) => validateInputTextMinMaxLen(x, 3, 255, false, helperText, false), keyTradValidation: "size3to255ParamValidation",
  },
  {
    field: "idCentroDiCosto", required: true, show: false, titleKey: "costCenterParam", lookupField: true, colsNum: 12, showOn: 'form',
    validate: (x: number, helperText: string) => validateRequired(x, helperText), keyTradValidation: "requiredValidation"
  },
  {
    field: "centroDiCostoDescrizione", required: false, show: true, titleKey: "costCenterParam", colsNum: 12, showOn: 'table'
  },
  {
    field: "idCentroDiCostoSecondario", required: false, show: false, titleKey: "secondaryCdcParam", lookupField: true, colsNum: 12, showOn: 'form',
  },
  {
    field: "centroDiCostoSecondarioDescrizione", required: false, show: false, titleKey: "secondaryCdcParam", colsNum: 12, showOn: 'table'
  },
  {
    field: "idCentroDiCostoCostiComuni", required: false, show: false, titleKey: "costCenterParamcommunes", lookupField: true, colsNum: 12, showOn: 'form',
  },
  {
    field: "centroDiCostoCostiComuniDescrizione", required: false, show: false, titleKey: "costCenterParamcommunes", colsNum: 12, showOn: 'table'
  },
  {
    field: "idCentroDiCostoLungaAssenza", required: false, show: false, titleKey: "longAbsenceCdcParam", lookupField: true, colsNum: 12, showOn: 'form',
  },
  {
    field: "centroDiCostoLungaAssenzaDescrizione", required: false, show: false, titleKey: "longAbsenceCdcParam", colsNum: 12, showOn: 'table'
  },
  {
    field: "ubicazione", type: "string", required: false, show: false, titleKey: "locationParam"
  },
  {
    field: "postiLetto", type: "string", required: false, show: false, titleKey: "sleepingPlacesParam"
  },
  {
    field: "note", type: "string", required: false, show: false, titleKey: "noteParam"
  },
  {
    field: "monitor", type: "boolean", required: false, show: false, titleKey: "monitorParam"
  },
  {
    field: "reperibilita", type: "boolean", required: false, show: false, titleKey: "availabilityParam"
  },
  {
    field: "reperibilitaFestivi", type: "boolean", required: false, show: false, titleKey: "holidayAvailabilityParam"
  },
]

export interface UnitaOperativa {
  idStruttura: number;
  idDipartimento: number;
  dipartimentoNome: string;
  dipartimentoCentroDiCostoDescrizione: string;
  idUnitaOperativa: number;
  nome: string;
  descrizione?: string;
  idCentroDiCosto: number;
  centroDiCostoCodice: string;
  centroDiCostoDescrizione: string;
  idCentroDiCostoSecondario: number;
  centroDiCostoSecondarioCodice: string;
  centroDiCostoSecondarioDescrizione: string;
  idCentroDiCostoLungaAssenza: number;
  centroDiCostoCostiComuniCodice: string;
  centroDiCostoLungaAssenzaDescrizione: string;
  idCentroDiCostoCostiComuni: number;
  centroDiCostoLungaAssenzaCodice: string;
  centroDiCostoCostiComuniDescrizione?: string;
  note: string;
  ubicazione: string;
  postiLetto: string;
  monitor: boolean;
  reperibilita: boolean;
  reperibilitaFestivi: boolean;
  version: number;
}

export interface UnitaOperativaEstesa extends UnitaOperativa {
  unitaOperativeTurniNumeroAddetti: UnitaOperativaTurnoNumeroAddetti[];
  unitaOperativeQualifiche: UnitaOperativaQualifiche[];
  unitaOperativaCodiceVisite: UnitaOperativaCodiceVisite[];
  unitaOperativaFormazione: UnitaOperativaFormazione[];
}

export interface LookupUnitaOperativaElem {
  idStruttura: number;
  idDipartimento: number;
  idUnitaOperativa: number;
  nome: string;
}