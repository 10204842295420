import { Column } from "@material-table/core";
import { Button, FormControl, FormControlLabel, Grid, IconButton, Switch as MuiSwitch, TextField, Tooltip, Typography } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import PrintIcon from '@material-ui/icons/Print';
import ContactEmergencyIcon from '@material-ui/icons/CreditCard';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { Abilitazione } from "../../../models/AbilitazioneEnum";
import {
  allFieldsAnagraficaSoggetti, allFieldsDatiProfessionali, allFieldsQualifica, allFieldsSchedaSanitaria, allFieldsTurniAbilitati, allFieldsUnitaOperativa, AnagraficaSoggettoCurrentData,
  AnagraficaSoggettoExtended,
  elementIdProps,
  elementRenderProps,
  FiltroAvanzatoAnagraficaSoggetto
} from "../../../models/AnagraficaSoggetti";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getAbilitazione } from "../../../store/slices/funzionalitaSlice";
import {
  anagraficaTabPath, componentInsertPath, componentTabsPath, contestazioniTabPath, dittaTabPath, documentoTabPath, formazioneTabPath,
  qualificaTabPath, schedaProfessionaleTabPath, schedaSanitariaTabPath, turnoAbilitatoTabPath, unitaOperativeTabPath
} from "../../../utils/innerFuncPaths";
import {
  anagraficheCoordinatorePath,
  anagrafichePath,
  notFoundPath
} from "../../../utils/utilconst";
import GeneralForm from "../forms/GeneralForm";
import InnerComponentViews from "../innerComponentViews/InnerComponentViews";
import SimpleIdSelects from "../selects/SimpleIdSelects";
import CrudMaterialTableWithoutLogicDelete from "../tables/CrudWithoutLogicDelete/CrudMaterialTableWithoutLogicDelete";
import AnagraficaSoggettiContestazioneW from "./AnagraficaSoggettiContestazioneW";
import AnagraficaSoggettiDittaW from "./AnagraficaSoggettiDittaW";
import AnagraficaSoggettiFormazioneW from "./AnagraficaSoggettiFormazioneW";
import AnagraficaSoggettiQualificaW from "./AnagraficaSoggettiQualificaW";
import AnagraficaSoggettiSchedaProfessionaleW from "./AnagraficaSoggettiSchedaProfessionaleW";
import AnagraficaSoggettiSchedaSanitariaW from "./AnagraficaSoggettiSchedaSanitariaW";
import AnagraficaSoggettiTurnoAbilitatoW from "./AnagraficaSoggettiTurnoAbilitatoW";
import AnagraficaSoggettiUnitaOperativaW from './AnagraficaSoggettiUnitaOperativaW';
import AnagraficaSoggettiDocumentiW from "./AnagraficaSoggettiDocumentiW";

import i18n from "../../../i18n";
import { AnagraficaSoggettiSchedaProfessionale } from "../../../models/AnagraficaSoggettiSchedeProfessionali";
import { Fields } from "../../../models/Fields";
import { PDFExtraData, StatusEnum } from "../../../models/Utils";
import { isUrlRoot, sortDescObjectsBy } from "../../../utils/utilfunctions";
import {
  fetchExtendedByIdAndDate as fetchAnagraficaSoggettiExtended,
  fetchAllValidByIdStrutturaInForzaFilter,
  insert,
  physicalDel, reset as resetAnagrafica,
  resetError as resetErrorAnagraficaSoggetti, update, fetchAllValidByFiltroAvanzato
} from "../../../store/slices/anagraficaSoggettiSlice";
import {
  fetchAllValid as fetchArticoloLeggeLookup,
  reset as resetArticoliLegge,
  resetError as resetErrorArticoliLegge
} from '../../../store/slices/articoliLeggeSlice';
import {
  fetchAllValid as fetchCodiceVisiteLookup,
  reset as resetCodiciVisite,
  resetError as resetErrorCodiciVisite
} from '../../../store/slices/codiciVisiteSlice';
import {
  lookupFiltered as fetchDipartimentiFiltered,
  reset as resetDipartimentiFiltered,
  resetError as resetErrorDipartimentiFiltered
} from '../../../store/slices/dipartimentiFilteredSlice';
import {
  fetchAllValid as fetchDitteLookup,
  reset as resetDitte,
  resetError as resetErrorDitte
} from '../../../store/slices/ditteSlice';
import {
  fetchAllValid as fetchFormazioneLookup,
  reset as resetFormazione,
  resetError as resetErrorFormazione
} from '../../../store/slices/formazioneSlice';
import {
  fetchAllValid as fetchIdoneitaLookup,
  reset as resetIdoneita,
  resetError as resetErrorIdoneita
} from '../../../store/slices/idoneitaSlice';
import {
  fetchAllValid as fetchMotiviLookup,
  reset as resetMotivi,
  resetError as resetErrorMotivi
} from '../../../store/slices/motiviSlice';
import {
  fetchAllValid as fetchQualificaLookup,
  reset as resetQualifiche,
  resetError as resetErrorQualifiche
} from "../../../store/slices/qualificheSlice";
import {
  lookup as fetchStruttureLookup,
  reset as resetStrutture,
  resetError as resetErrorStrutture,
  fetchNotExtendedById
} from "../../../store/slices/struttureSlice";
import {
  lookupFiltered as fetchUnitaOperativaFiltered,
  reset as resetUnitaOperativeFiltered,
  resetError as resetErrorUnitaOperativeFiltered
} from '../../../store/slices/unitaOperativeFilteredSlice';
import {
  reset as resetAnagraficaSoggettiContestazione,
} from "../../../store/slices/anagraficaSoggettiContestazioneSlice";
import {
  reset as resetAnagraficaSoggetti,
} from "../../../store/slices/anagraficaSoggettiSlice";
import {
  reset as resetAnagraficaSoggettiDitta,
} from "../../../store/slices/anagraficaSoggettiDittaSlice";
import {
  reset as resetAnagraficaSoggettiFormazione,
} from "../../../store/slices/anagraficaSoggettiFormazioneSlice";
import {
  reset as resetAnagraficaSoggettiQualifica,
} from "../../../store/slices/anagraficaSoggettiQualificaSlice";
import {
  reset as resetAnagraficaSoggettiSchedaProfessionale,
} from "../../../store/slices/anagraficaSoggettiSchedaProfessionaleSlice";
import {
  reset as resetAnagraficaSoggettiSchedaSanitaria,
} from "../../../store/slices/anagraficaSoggettiSchedaSanitariaSlice";
import {
  reset as resetAnagraficaSoggettiTurnoAbilitato,
} from "../../../store/slices/anagraficaSoggettiTurnoAbilitatoSlice";
import {
  reset as resetAnagraficaSoggettiUnitaOperative,
} from "../../../store/slices/anagraficaSoggettiUnitaOperativeSlice";
import {
  reset as resetAnagraficaSoggettiDocumenti,
} from "../../../store/slices/anagraficaSoggettiDocumentiSlice";
import { GeneratePDF } from "./AnagraficaSoggettiComponents/generatePDF/GeneratePDF";
import { AnteprimaModal } from "./AnagraficaSoggettiComponents/generatePDF/anteprima/AnteprimaModal";
import { useDebouncedCallback } from "use-debounce";
import GTModal from "../modal/GTModal";
import ImagePreview from "../modal/child/ImagePreview";
import GenerateCardPDF from "./AnagraficaSoggettiComponents/generateCardPDF/GenerateCardPDF";
import FilterListIcon from '@material-ui/icons/FilterList';
import FiltroAvanzato from "./AnagraficaSoggettiComponents/filtroAvanzato/FiltroAvanzato";
import { IFiltroAvanzatoField } from "./AnagraficaSoggettiComponents/filtroAvanzato/FiltroAvanzatoFields.model";
import { calculateMTableHeight } from "../../../utils/styleconst";
import { ColumnExt } from "../../../utils/data.types";

export interface Riepiloghi {
  anagraficaSoggetto: AnagraficaSoggettoExtended
}

const AnagraficaSoggettiW = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const logoUri = useAppSelector((state) => state.authInfo.logoUri);
  const dispatch = useAppDispatch();

  const combinazioneTurniLookup = useAppSelector((state => state.lookup.combinazioneTurniLookup))
  const tipoPartTimeLookup = useAppSelector((state => state.lookup.tipoPartTimeLookup))
  const SessoLookup = useAppSelector((state => state.lookup.sessoLookup))
  const struttura = useAppSelector(state => state.strutture.struttura);

  const abilitazione = useAppSelector((state) => getAbilitazione(state, anagrafichePath));

  const title = t("personalData");
  const strSelectName = t("structuresTitle");
  const strSelectLabel = t("structureSelect");

  const defaultValues = useMemo(() => {
    return {
      idQualifica: [],
      idFormazione: []
    }
  }, []);

  const [isInForza, setIsInForza] = useState<boolean>(true);
  const [toggleFiltroAvanzato, setToggleFiltroAvanzato] = useState<boolean>(false);
  const [advanceFilterData, setAdvanceFilterData] = useState<Record<string, (string | number)[]>>(defaultValues);

  /**
   * Ricerca per nominativo
   */
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchToken, setSearchToken] = useState<string>('');
  const handleSearchTokenChange = useDebouncedCallback((token: string) => setSearchToken(token), 300);

  const [states, setStates] = useState<{
    [selectName: string]: number | string | null;
  }>({ [strSelectName]: null });

  const fixedProps = useMemo(() => {
    return {
      idStruttura: states[strSelectName],
      inForza: isInForza,
      sottoStringa: searchToken,
      idQualifica: toggleFiltroAvanzato ? advanceFilterData['idQualifica'] : [],
      idFormazione: toggleFiltroAvanzato ? advanceFilterData['idFormazione'] : [],
    };
  }, [advanceFilterData, isInForza, searchToken, states, strSelectName, toggleFiltroAvanzato]);

  const [isOpenAnagraficaPdf, setIsOpenAnagraficaPdf] = useState<boolean>(false);
  const handleCloseAnagraficaPdf = () => {
    setIsOpenAnagraficaPdf(false);
  };
  const handleOpenAnagraficaPdf = () => {
    dispatch(fetchNotExtendedById({ idStruttura: fixedProps.idStruttura as number }));
    setIsOpenAnagraficaPdf(true);
  };

  const [isOpenCardPdf, setIsOpenCardPdf] = useState<boolean>(false);
  const handleCloseCardPdf = () => {
    setIsOpenCardPdf(false);
  };
  const handleOpenCardPdf = useCallback(() => {
    dispatch(fetchNotExtendedById({ idStruttura: fixedProps.idStruttura as number }));
    setIsOpenCardPdf(true);
  }, [dispatch, fixedProps.idStruttura]);

  const [modalImage, setModalImage] = useState<string>('');
  const [isOpenTableModal, setIsOpenTableModal] = useState<boolean>(false);
  const handleCloseTableModal = () => {
    setIsOpenTableModal(false);
  }
  const handleOpenTableModal = useCallback(() => {
    dispatch(fetchNotExtendedById({ idStruttura: fixedProps.idStruttura as number }));
    setIsOpenTableModal(true);
  }, [dispatch, fixedProps.idStruttura]);

  const currentPath = useMemo(() => history.location.pathname, [history.location.pathname]);
  const uriPath = useMemo(() => {
    return isCoordinator(currentPath) ? anagraficheCoordinatorePath : anagrafichePath;
  }, [currentPath]);

  const resetError = useCallback(() => {
    dispatch(resetErrorQualifiche());
    dispatch(resetErrorMotivi());
    dispatch(resetErrorIdoneita());
    dispatch(resetErrorCodiciVisite());
    dispatch(resetErrorFormazione());
    dispatch(resetErrorArticoliLegge());
    dispatch(resetErrorDitte());
    dispatch(resetErrorDipartimentiFiltered());
    dispatch(resetErrorUnitaOperativeFiltered());
    dispatch(resetErrorAnagraficaSoggetti());
    dispatch(resetErrorStrutture());
  }, [dispatch]);

  const excludedFieldInTable = useMemo(() => {
    const retval = [];

    if (!isCoordinator(currentPath))
      retval.push('modificaNumero104');

    return retval;
  }, [currentPath]);
  const [allFieldsState, setAllFieldsState] = useState<Fields[]>(allFieldsAnagraficaSoggetti);
  const [columns, setColumns] = useState<Array<Column<AnagraficaSoggettoCurrentData>>>([]);
  useEffect(() => {
    setColumns(
      allFieldsState
        .filter(f => !excludedFieldInTable.includes(f?.field))
        .filter(f => ['both', 'table', undefined, null].includes(f.showOn))
        .map((f) => {
          let obj: ColumnExt<AnagraficaSoggettoCurrentData> = {
            title: f.titleKey ? t(f.titleKey) : "",
            field: f.field,
            removable: f.removable ?? !f.required,
            editable: f.editable ? f.editable : "always",
            defaultSort: f.sort,
            emptyValue: f.defaultValue,
            external: { fieldData: f }
          };

          if (!f.show) {
            obj.hidden = true;
            obj.hiddenByColumnsButton = false;
          }

          if (isCoordinator(currentPath)) {
            if (['legge104Numero', 'legge104DataInizioVariazione'].includes(f.field)) {
              obj.hidden = false;
            }
          }

          switch (f.field) {
            case 'dataUscita':
            case 'turnoFisso':
            case 'dataInizioTurnoFisso':
              obj.hidden = true;
              obj.hiddenByColumnsButton = false;
              break;
          }

          if (f.type && f.type !== "image" && f.type !== "file") {
            obj.type = f.type;
          }

          if (f.render) obj.render = f.render;

          if (f.lookupField) {
            switch (f.field) {
              case 'combinazioneTurni':
              case 'turnoFisso':
                obj.lookup = combinazioneTurniLookup;
                break;
              case 'partTimeTipo':
                obj.lookup = tipoPartTimeLookup;
                break;
              case 'sesso':
                obj.lookup = SessoLookup;
                break;

            }
          }

          if (f.type === 'image') {
            obj.defaultSort = undefined;
            obj.render = (rowData: AnagraficaSoggettoCurrentData) => {
              return rowData.fotoSegnaletica
                ? <Button
                  onClick={() => {
                    handleOpenTableModal();
                    setModalImage(rowData.fotoSegnaletica ?? '');
                  }}
                  size='small'
                  variant='outlined'
                  color='primary'>
                  {t('show')}
                </Button>
                : ''
            }
          }

          return obj;
        })
    )
  }, [allFieldsState, excludedFieldInTable, currentPath, t, combinazioneTurniLookup, tipoPartTimeLookup, SessoLookup, handleOpenTableModal]);

  useEffect(() => {
    if (isCoordinator(currentPath))
      setAllFieldsState(state => {
        return [
          ...state,
          {
            field: "modificaNumero104", titleKey: "modificaNumero104Param", required: false, show: false,
            render: (rowData: AnagraficaSoggettoCurrentData) => {
              return (
                <>
                  <IconButton
                    disabled={!rowData.matricola}
                    onClick={() => alert('add')}      // TODO: implementa aggiungere scheda professionale con numero legge + 1
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                  <IconButton
                    disabled={!rowData.matricola}
                    onClick={() => alert('remove')}   // TODO: implementa aggiungere scheda professionale con numero legge - 1
                  >
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                </>
              )
            }
          },
        ]
      })
  }, [currentPath]);

  useEffect(() => {
    setAllFieldsState(state => {
      const temp = [...state];
      [...allFieldsDatiProfessionali, ...allFieldsSchedaSanitaria, ...allFieldsQualifica, ...allFieldsUnitaOperativa, ...allFieldsTurniAbilitati].forEach(elem => {
        temp.every(elemTemp => elemTemp?.field !== elem?.field) && temp.push(elem);
      })
      return temp;
    })
  }, []);

  useEffect(() => {
    if (isCoordinator(currentPath)) {
      const fieldsCoordinatore = ['matricola', 'qualificaDescrizioneBreve', 'cognome', 'nome', 'telefonoCellulare', 'email', 'legge104DataInizioVariazione', 'legge104Numero', 'modificaNumero104',]
      setAllFieldsState(state => {
        const temp: Fields[] = state.filter(elem => {
          return fieldsCoordinatore.includes(elem.field)
        });
        const newFields: Fields[] = [];
        fieldsCoordinatore.forEach(elem => {
          const field = temp.find(f => f.field === elem);
          if (field)
            newFields.push(field);
        })
        return newFields;
      })

    }
  }, [currentPath])

  const formLookups = useMemo(() => {
    return {
      combinazioneTurni: combinazioneTurniLookup,
      sesso: SessoLookup,
    }
  }, [SessoLookup, combinazioneTurniLookup]);

  const errorBE = useAppSelector((state) => state.anagraficaSoggetti.error);

  const validAnagraficaSoggetti = useAppSelector(state => state.anagraficaSoggetti.validAnagraficaSoggetti);
  const statusValidAnagraficaSoggetti = useAppSelector(state => state.anagraficaSoggetti.statusValidAnagraficaSoggetti);
  const anagraficaSoggettiEstesa = useAppSelector(state => state.anagraficaSoggetti.anagraficaSoggettoEstesa);

  const lookupQualifica = useAppSelector(state => state.qualifiche.lookupDescrizioneBreve);
  const lookupFormazione = useAppSelector(state => state.formazione.lookup);

  const [data, setData] = useState<Array<AnagraficaSoggettoCurrentData>>([]);

  const [obj, setObj] = useState<AnagraficaSoggettoCurrentData | null>(null);

  const [AS_Estesa, setAS_Estesa] = useState<AnagraficaSoggettoExtended | null>(null);

  useEffect(() => {
    setData(validAnagraficaSoggetti);
  }, [validAnagraficaSoggetti]);

  useEffect(() => {
    setAS_Estesa(anagraficaSoggettiEstesa)
  }, [anagraficaSoggettiEstesa]);

  useEffect(() => {
    dispatch(fetchStruttureLookup());
    dispatch(fetchQualificaLookup());
    dispatch(fetchFormazioneLookup());
  }, [dispatch]);

  const insertCallback = () => {
    history.push(uriPath + componentInsertPath);
  };

  const updateDetailCallback = (anagraficaSoggetto: AnagraficaSoggettoCurrentData) => {
    setObj(anagraficaSoggetto);

    dispatch(fetchAnagraficaSoggettiExtended({ idAnagraficaSoggetto: anagraficaSoggetto.idAnagraficaSoggetto }));
    dispatch(fetchQualificaLookup());
    dispatch(fetchMotiviLookup());
    dispatch(fetchIdoneitaLookup());
    dispatch(fetchCodiceVisiteLookup());
    dispatch(fetchFormazioneLookup());
    dispatch(fetchArticoloLeggeLookup());
    dispatch(fetchDitteLookup());
    dispatch(fetchMotiviLookup());
    dispatch(fetchDipartimentiFiltered({ idStruttura: anagraficaSoggetto.idStruttura }));
    dispatch(fetchUnitaOperativaFiltered({ idStruttura: anagraficaSoggetto.idStruttura, idDipartimento: anagraficaSoggetto.idDipartimentoUnitaOperativa }));

    history.push(uriPath + componentTabsPath);
  };

  useEffect(() => {
    setAS_Estesa(anagraficaSoggettiEstesa);
  }, [anagraficaSoggettiEstesa]);

  const handleChange = () => setIsInForza(state => !state);

  /**
   * a trigger to stop loading animation in material table
   */
  useEffect(() => {
    if (validAnagraficaSoggetti && [StatusEnum.Failed, StatusEnum.Succeeded].includes(statusValidAnagraficaSoggetti))
      setIsLoading(false)
  }, [statusValidAnagraficaSoggetti, validAnagraficaSoggetti]);

  const lookupStr = useAppSelector((state) => state.strutture.lookup);

  // title to be finalize
  const exportDataExtra: PDFExtraData = { head: { title: [], value: [] }, extra: [] };
  Object.keys(fixedProps).forEach(elem => {
    switch (elem) {
      case 'idStruttura':
        const idStrutturaValue = fixedProps[elem];
        if (exportDataExtra.head && idStrutturaValue)
          exportDataExtra.head['Struttura'] = lookupStr[idStrutturaValue];
        break;
    }
  });

  /*
   * change api call when isInForza radio is change
   */
  useEffect(() => {
    if (fixedProps.idStruttura) {
      setIsLoading(true);
      dispatch(fetchAllValidByIdStrutturaInForzaFilter({
        idStruttura: fixedProps.idStruttura as number,
        inForza: fixedProps.inForza,
        sottoStringa: fixedProps.sottoStringa?.length > 0 ? fixedProps.sottoStringa : undefined,
      }));
    }
  }, [dispatch, fixedProps.idStruttura, fixedProps.inForza, fixedProps.sottoStringa, isInForza]);

  useEffect(() => {
    return () => {
      setColumns([]);
      setData([]);
      setObj(null);
      setStates({});
      setAS_Estesa(null);
      setAllFieldsState([]);

      dispatch(resetAnagrafica());
      dispatch(resetQualifiche());
      dispatch(resetMotivi());
      dispatch(resetIdoneita());
      dispatch(resetCodiciVisite());
      dispatch(resetFormazione());
      dispatch(resetArticoliLegge());
      dispatch(resetDipartimentiFiltered());
      dispatch(resetUnitaOperativeFiltered());
      dispatch(resetDitte());
      dispatch(resetStrutture());
      dispatch(resetAnagraficaSoggetti());

      dispatch(resetAnagraficaSoggettiTurnoAbilitato());
      dispatch(resetAnagraficaSoggettiQualifica());
      dispatch(resetAnagraficaSoggettiContestazione());
      dispatch(resetAnagraficaSoggettiDitta());
      dispatch(resetAnagraficaSoggettiFormazione());
      dispatch(resetAnagraficaSoggettiSchedaProfessionale());
      dispatch(resetAnagraficaSoggettiSchedaSanitaria());
      dispatch(resetAnagraficaSoggettiUnitaOperative());
      dispatch(resetAnagraficaSoggettiDocumenti());
    };
  }, [dispatch]);

  const [riepiloghi, setRiepiloghi] = useState<Riepiloghi | null>(null);
  useEffect(() => {
    if (AS_Estesa) {
      setRiepiloghi({
        anagraficaSoggetto: AS_Estesa,
      });
    }
  }, [AS_Estesa, AS_Estesa?.anagraficaSoggettiFormazione, anagraficaSoggettiEstesa]);

  const localAction = (obj: AnagraficaSoggettoCurrentData) => {
    dispatch(insert(obj))
      .then(data => {
        if (data?.payload) {
          dispatch(resetAnagrafica());
          setObj(data.payload as AnagraficaSoggettoCurrentData);
          history.push(uriPath + componentTabsPath);
        }
      })
  }

  const updateAdvanceFilterData = useCallback((name: string, value: any[], reset?: boolean) => {
    if (toggleFiltroAvanzato) {
      setAdvanceFilterData(state => {
        if (reset) {
          const newState: Record<string, any[]> = {};
          Object.keys(state).forEach(key => newState[key] = []);
          return newState;
        }

        return {
          ...state,
          [name]: value
        }
      });
    }

    const fetchData = async (filtri: FiltroAvanzatoAnagraficaSoggetto) => {
      await dispatch(fetchAllValidByFiltroAvanzato({
        idStruttura: fixedProps.idStruttura as number,
        inForza: fixedProps.inForza,
        sottoStringa: fixedProps.sottoStringa,
        idQualifica: filtri.idQualifica,
        idFormazione: filtri.idFormazione,
      }));
      setIsLoading(false);
    }

    if (null !== fixedProps.idStruttura) {
      const filtriAttuali: FiltroAvanzatoAnagraficaSoggetto = {
        idQualifica: !toggleFiltroAvanzato || reset
          ? []
          : ('idQualifica' === name ? value : advanceFilterData['idQualifica']),
        idFormazione: !toggleFiltroAvanzato || reset
          ? []
          : ('idFormazione' === name ? value : advanceFilterData['idFormazione'])
      };

      setIsLoading(true); // show Material Table loading animation
      fetchData(filtriAttuali);
    }
  }, [advanceFilterData, dispatch, fixedProps, toggleFiltroAvanzato]);

  const filtroAvanzatoFields: IFiltroAvanzatoField[] = useMemo((): IFiltroAvanzatoField[] => {
    return [
      {
        name: 'idQualifica',
        label: 'qualificationParam',
        lookup: lookupQualifica,
        callback: updateAdvanceFilterData
      },
      {
        name: 'idFormazione',
        label: 'trainingParam',
        lookup: lookupFormazione,
        callback: updateAdvanceFilterData
      }
    ]
  }, [lookupQualifica, updateAdvanceFilterData, lookupFormazione]);

  return (
    <>
      <Paper elevation={2}>
        <Box p={4}>
          <Grid container alignItems="center">
            <Grid item container alignItems="center" spacing={2} xs={12} sm={12} md={11} lg={11}>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <SimpleIdSelects
                  selectsArray={[
                    {
                      name: strSelectName,
                      lookup: lookupStr,
                      label: strSelectLabel,
                      disabled: !isUrlRoot(currentPath, uriPath),
                      breakpoints: { xs: 12, sm: 12, md: 12, lg: 12 }
                    },
                  ]}
                  states={states}
                  setStates={setStates}
                />
              </Grid>
              {
                states[strSelectName] &&
                <>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <TextField
                      fullWidth
                      label={t('nameSearch')}
                      disabled={!isUrlRoot(currentPath, uriPath)}
                      onChange={(e) => handleSearchTokenChange(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={4} lg={4}>
                    <FormControl>
                      <FormControlLabel
                        control={
                          <MuiSwitch
                            disabled={!isUrlRoot(currentPath, uriPath)}
                            checked={isInForza}
                            onChange={handleChange}
                            name="inForza"
                            color="primary"
                          />
                        }
                        labelPlacement="start"
                        label={t("enforceLabel")}
                      />
                    </FormControl>
                  </Grid>
                </>
              }
            </Grid>
            <Grid item container alignItems="center" spacing={2} xs={12} sm={12} md={1} lg={1}>
              {
                states[strSelectName] && <>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Tooltip title={t('advanceFilter')}>
                      <IconButton
                        onClick={() => setToggleFiltroAvanzato(state => !state)}
                      >
                        <FilterListIcon />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </>
              }
            </Grid>
            <Grid item container alignItems='center' xs={12} sm={12} md={12} lg={12}>
              {
                states[strSelectName] &&
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <Box display={toggleFiltroAvanzato ? 'block' : 'none'}>
                    <FiltroAvanzato
                      fields={filtroAvanzatoFields}
                      defaultValues={defaultValues}
                      filtroMostrato={toggleFiltroAvanzato}
                      disabled={!isUrlRoot(currentPath, uriPath)}
                    />
                  </Box>
                </Grid>
              }
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box marginTop={2}>
        {states[strSelectName] ? (
          <Switch>
            <Route path={uriPath} exact>
              <CrudMaterialTableWithoutLogicDelete
                abilitazione={abilitazione}
                title={title}
                columns={columns}
                columnsButton={!isCoordinator(currentPath)}
                data={data}
                elementIdProps={elementIdProps}
                elementRenderProps={elementRenderProps}
                fetchAllValid={toggleFiltroAvanzato ? fetchAllValidByFiltroAvanzato : fetchAllValidByIdStrutturaInForzaFilter}
                physicalDel={physicalDel}
                statusValid={statusValidAnagraficaSoggetti}
                errorBE={errorBE}
                logoUri={logoUri}
                insertCallback={insertCallback}
                updateCallback={updateDetailCallback}
                detailCallback={updateDetailCallback}
                fixedProps={fixedProps}
                exportDataExtra={exportDataExtra}
                resetErrorCallback={resetError}
                localizedDatePicker={true}
                extraOptions={{
                  minBodyHeight: calculateMTableHeight(0, false, true, true),
                  maxBodyHeight: calculateMTableHeight(0, false, true, true),
                  paging: true,
                  pageSize: 20,
                  rowStyle: (rowData: AnagraficaSoggettoCurrentData) => {
                    return {
                      borderLeftWidth: 10,
                      borderLeftStyle: 'solid',
                      borderLeftColor: (rowData.codiceFiscaleValido) ? '#090' : '#fff'
                    }
                  }
                }}
                isLoading={isLoading}
              />
            </Route>
            <Route path={uriPath + componentInsertPath} exact>
              <InnerComponentViews
                abilitazione={abilitazione}
                mainUri={uriPath}
                tabsUri={uriPath + componentInsertPath}
                tabsView={false}
                buttonTitle={t("personalData")}
                info1={t("registryTitle")}
                tabs={[
                  {
                    label: t("registryTitle"),
                    tabPath: "",
                    abilitazione: Abilitazione.READ_UPDATE,
                    componentIf: (
                      <GeneralForm
                        readOnly={false}
                        language={i18n.language}
                        componentPath={uriPath}
                        fixedProps={fixedProps}
                        status={statusValidAnagraficaSoggetti}
                        error={errorBE}
                        update={false}
                        fields={allFieldsAnagraficaSoggetti}
                        translate={t}
                        lookups={formLookups}
                        localAction={localAction}
                      />
                    ),
                    componentElse: <Redirect to={uriPath} />,
                  },
                ]}
              />
            </Route>
            <Route path={uriPath + componentTabsPath} >
              {obj ? (
                <InnerComponentViews
                  abilitazione={abilitazione}
                  mainUri={uriPath}
                  tabsUri={uriPath + componentTabsPath}
                  tabsView={true}
                  buttonTitle={t("personalData")}
                  info1={obj?.nome + " " + obj?.cognome}
                  backButtonCallback={() => setRiepiloghi(null)}
                  tabs={[
                    {
                      label: t("dataregistryTitle"),
                      tabPath: anagraficaTabPath,
                      abilitazione: Abilitazione.READ_UPDATE,
                      componentIf: (
                        <>
                          <Box display='flex' justifyContent='right'>
                            <Tooltip title={t('printLabel') + ' ' + t('registryParamCardTitle')}>
                              <IconButton onClick={handleOpenCardPdf}>
                                <ContactEmergencyIcon />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t('printLabel') + ' ' + t('registryParamCardTitle')}>
                              <IconButton onClick={handleOpenAnagraficaPdf}>
                                <PrintIcon />
                              </IconButton>
                            </Tooltip>

                            {/* Card PDF */}
                            {
                              riepiloghi && struttura &&
                              <AnteprimaModal
                                file={<GenerateCardPDF
                                  riepiloghi={riepiloghi}
                                  logo={struttura.logo1 ?? struttura.logo2}
                                />}
                                handleClose={handleCloseCardPdf}
                                open={isOpenCardPdf}
                                anagrafica={{
                                  nome: obj?.nome,
                                  cognome: obj?.cognome
                                }}
                              />
                            }

                            {/* Anagrafica PDF */}
                            {
                              riepiloghi && struttura &&
                              <AnteprimaModal
                                file={<GeneratePDF
                                  riepiloghi={riepiloghi}
                                  logo={struttura.logo1 ?? struttura.logo2}
                                />}
                                handleClose={handleCloseAnagraficaPdf}
                                open={isOpenAnagraficaPdf}
                                anagrafica={{
                                  nome: obj?.nome,
                                  cognome: obj?.cognome
                                }}
                              />
                            }
                          </Box>
                          <GeneralForm
                            readOnly={false}
                            language={i18n.language}
                            componentPath={uriPath}
                            action={update}
                            status={statusValidAnagraficaSoggetti}
                            error={errorBE}
                            obj={obj}
                            update={true}
                            fields={allFieldsAnagraficaSoggetti}
                            translate={t}
                            lookups={formLookups}
                          />
                        </>
                      ),
                      componentElse: (
                        <GeneralForm
                          readOnly={true}
                          language={i18n.language}
                          componentPath={uriPath}
                          action={update}
                          status={statusValidAnagraficaSoggetti}
                          error={errorBE}
                          obj={obj}
                          update={true}
                          fields={allFieldsAnagraficaSoggetti}
                          translate={t}
                        />
                      ),
                    },
                    {
                      label: t("professionalDataTabLabel"),
                      tabPath: schedaProfessionaleTabPath,
                      abilitazione: Abilitazione.READ,
                      componentIf: <AnagraficaSoggettiSchedaProfessionaleW
                        idAnagraficaSoggetto={obj.idAnagraficaSoggetto}
                        anagraficaSoggettiSchedaProfessionale={AS_Estesa
                          ? sortDescObjectsBy(AS_Estesa.anagraficaSoggettiSchedeProfessionali, 'dataInizioValidita') as AnagraficaSoggettiSchedaProfessionale[]
                          : []
                        }
                      />,
                      componentElse: <Redirect to={uriPath} />
                    },
                    {
                      label: t("qualificationTabLabel"),
                      tabPath: qualificaTabPath,
                      abilitazione: Abilitazione.READ,
                      componentIf: <AnagraficaSoggettiQualificaW
                        idAnagraficaSoggetto={obj.idAnagraficaSoggetto}
                        anagraficaSoggettiQualifica={AS_Estesa ?
                          AS_Estesa.anagraficaSoggettiQualifica : []
                        }
                        anagraficaSoggettiQualificaEliminati={AS_Estesa ?
                          AS_Estesa.anagraficaSoggettiQualificaEliminati : []
                        }
                      />,
                      componentElse: <Redirect to={uriPath} />
                    },
                    {
                      label: t("operatingUnitTabLabel"),
                      tabPath: unitaOperativeTabPath,
                      abilitazione: Abilitazione.READ,
                      componentIf: <AnagraficaSoggettiUnitaOperativaW
                        idAnagraficaSoggetto={obj.idAnagraficaSoggetto}
                        anagraficaSoggettiUnitaOperativa={AS_Estesa ?
                          AS_Estesa.anagraficaSoggettiUnitaOperative : []
                        }
                      />,
                      componentElse: <Redirect to={uriPath} />
                    },
                    {
                      label: t("enabledShiftsTabLabel"),
                      tabPath: turnoAbilitatoTabPath,
                      abilitazione: Abilitazione.READ,
                      componentIf: <AnagraficaSoggettiTurnoAbilitatoW
                        idAnagraficaSoggetto={obj.idAnagraficaSoggetto}
                        anagraficaSoggettiTurnoAbilitato={AS_Estesa ?
                          AS_Estesa.anagraficaSoggettiTurniAbilitati : []
                        }
                      />,
                      componentElse: <Redirect to={uriPath} />
                    },
                    {
                      label: t("healthCardTabLabel"),
                      tabPath: schedaSanitariaTabPath,
                      abilitazione: Abilitazione.READ,
                      componentIf: <AnagraficaSoggettiSchedaSanitariaW
                        idAnagraficaSoggetto={obj.idAnagraficaSoggetto}
                        anagraficaSoggettiSchedaSanitaria={AS_Estesa ?
                          AS_Estesa.anagraficaSoggettiSchedaSanitaria : []
                        }
                        anagraficaSoggettiSchedaSanitariaEliminati={AS_Estesa ?
                          AS_Estesa.anagraficaSoggettiSchedaSanitariaEliminati : []
                        }
                      />,
                      componentElse: <Redirect to={uriPath} />
                    },
                    {
                      label: t("trainingTabLabel"),
                      tabPath: formazioneTabPath,
                      abilitazione: Abilitazione.READ,
                      componentIf: <AnagraficaSoggettiFormazioneW
                        idAnagraficaSoggetto={obj.idAnagraficaSoggetto}
                        anagraficaSoggettiFormazione={AS_Estesa ?
                          AS_Estesa.anagraficaSoggettiFormazione : []
                        }
                        anagraficaSoggettiFormazioneEliminati={AS_Estesa ?
                          AS_Estesa.anagraficaSoggettiFormazioneEliminati : []
                        }
                      />,
                      componentElse: <Redirect to={uriPath} />
                    },
                    {
                      label: t("disputeTabLabel"),
                      tabPath: contestazioniTabPath,
                      abilitazione: Abilitazione.READ,
                      componentIf: <AnagraficaSoggettiContestazioneW
                        idAnagraficaSoggetto={obj.idAnagraficaSoggetto}
                        anagraficaSoggettiContestazione={AS_Estesa ?
                          AS_Estesa.anagraficaSoggettiContestazioni : []
                        }
                        anagraficaSoggettiContestazioneEliminati={AS_Estesa ?
                          AS_Estesa.anagraficaSoggettiContestazioniEliminati : []
                        }
                      />,
                      componentElse: <Redirect to={uriPath} />
                    },
                    {
                      label: t("documents"),
                      tabPath: documentoTabPath,
                      abilitazione: Abilitazione.READ,
                      componentIf: <AnagraficaSoggettiDocumentiW
                        idAnagraficaSoggetto={obj.idAnagraficaSoggetto}
                        anagraficaSoggettiDocumenti={AS_Estesa ?
                          AS_Estesa.anagraficaSoggettiDocumenti : []
                        }
                        anagraficaSoggettiDocumentiEliminati={AS_Estesa ?
                          AS_Estesa.anagraficaSoggettiDocumentiEliminati : []
                        }
                      />,
                      componentElse: <Redirect to={uriPath} />
                    },
                    {
                      label: t("companiesTitle"),
                      tabPath: dittaTabPath,
                      abilitazione: Abilitazione.READ,
                      componentIf: <AnagraficaSoggettiDittaW
                        idAnagraficaSoggetto={obj.idAnagraficaSoggetto}
                        anagraficaSoggettiDitta={AS_Estesa ?
                          AS_Estesa.ditteAnagraficaSoggetti : []
                        }
                      />,
                      componentElse: <Redirect to={uriPath} />
                    },
                  ]}
                />
              ) : <Redirect to={uriPath} />
              }
            </Route>
            <Route path="*">
              <Redirect to={notFoundPath} />
            </Route>
          </Switch>
        ) : (
          <Switch>
            <Route path={uriPath} exact>
              <Paper elevation={2}>
                <Box p={4}>
                  <Typography align="center" variant="h5">
                    {t("structureSelect")}
                  </Typography>
                </Box>
              </Paper>
            </Route>
            <Route>
              <Redirect to={uriPath} />
            </Route>
          </Switch>
        )}
      </Box>
      {
        isOpenTableModal && modalImage &&
        <GTModal
          openState={{
            open: isOpenTableModal,
            setOpen: setIsOpenTableModal
          }}
          handleClose={() => {
            handleCloseTableModal();
            setModalImage('');
          }}
        >
          <ImagePreview
            image={modalImage}
            alt={"Foto"}
            mediaType="webp"
          />
        </GTModal>
      }
    </>
  );
};
export default AnagraficaSoggettiW;

function isCoordinator(currentPathname: string) {
  return currentPathname === anagraficheCoordinatorePath;
}