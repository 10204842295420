import { createSlice } from '@reduxjs/toolkit';

interface AppbarState {
  open: boolean,
}

const initialState: AppbarState = {
  open: true,
}

export const appbarSlice = createSlice({
  name: 'appbar',
  initialState,
  reducers: {
    toggleAppbarStatus: (state) => {
      state.open = !state.open;
    },
    openAppbarStatus: (state) => {
      state.open = true;
    },
    closeAppbarStatus: (state) => {
      state.open = false;
    }
  }
})

export const { toggleAppbarStatus, openAppbarStatus, closeAppbarStatus, } = appbarSlice.actions;
export default appbarSlice.reducer;