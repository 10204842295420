import React from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchRefreshToken, logoutAction } from '../../store/slices/authInfoSlice';
import { refreshTokenTime } from '../../utils/utilconst';

interface AuthProps {
  hasIdAppSoftwareCookie: boolean
}

const useAuth = (props: AuthProps) => {
  const { hasIdAppSoftwareCookie } = props;

  const dispatch = useAppDispatch();

  const [timer, setTimer] = React.useState<NodeJS.Timeout>();

  const token = useAppSelector(state => state.authInfo.token);

  /**
   * Gestione autenticazione
   */

  // Gestione del logout automatico quando non è presente un token (ogni 3 secondi)
  React.useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    if (!token) dispatch(logoutAction());
  }, [dispatch, hasIdAppSoftwareCookie, token]);

  // Gestione del refresh del token
  React.useEffect(() => {
    if (!hasIdAppSoftwareCookie) return;

    setTimer(t => {
      t && clearInterval(t);
      return setInterval(() => {
        dispatch(fetchRefreshToken());
      }, refreshTokenTime)
    });
  }, [token, dispatch, hasIdAppSoftwareCookie]);

  React.useEffect(() => () => clearInterval(timer), [timer]);
}

export default useAuth